import React from "react"
import PropTypes from "prop-types"
import { authProtectedRoutes } from "routes"

import ListTransfer from "components/Common/ListTransfer"
const FormAllowedRoutes = ({ allowedRoutes, setAllowedRoutes }) => {
  const allRoutes = authProtectedRoutes.map(route => route.path)

  const saveChanges = newChanges => {
    setAllowedRoutes(
      newChanges.map(change => {
        return { path: change, sidebar: false }
      })
    )
  }

  return (
    <div className="p-4">
      <ListTransfer
        dataSource={allRoutes}
        target={allowedRoutes}
        saveChanges={saveChanges}
        colOne="All Routes"
        colTwo="Allowed Routes"
      />
    </div>
  )
}

FormAllowedRoutes.propTypes = {
  setAllowedRoutes: PropTypes.func,
  allowedRoutes: PropTypes.array,
}

export default FormAllowedRoutes
