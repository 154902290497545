import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Button,
  Label,
  Input,
  FormText,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap"
import { useHistory } from "react-router-dom"

import {
  addRole,
  getRoleConfig,
  getRoleTypes,
  updateRole,
} from "helpers/backend_helper"
import FormSidebar from "components/User/form-sidebar"
import FormPermissions from "components/User/form-permissions"
import FormAllowedRoutes from "components/User/form-allowed-routes"
import ColorPreview from "components/Common/ColorPreview"
import ColorPickerModal from "components/Common/ColorPickerModal"
import { showToastMessage } from "components/Common/ToastWrapper"
import Breadcrumb from "components/Common/Breadcrumb"
import { useParams } from "react-router-dom/cjs/react-router-dom"

const AddUpdateRole = () => {
  const [permissions, setPermissions] = useState([])
  const [allowedRoutes, setAllowedRoutes] = useState([])
  const [roleName, setRoleName] = useState("")
  const [roleType, setRoleType] = useState("gazal")
  const [showColorPickerModal, setShowColorPickerModal] = useState(false)
  const [badgeColor, setBadgeColor] = useState("#00a82d")
  const [defaultRoute, setDefaultRoute] = useState("")
  const [roleTypes, setRoleTypes] = useState([])
  const [action, setAction] = useState("add")
  const history = useHistory()

  const { name } = useParams()

  const invalidPayloadErr = () => {
    const allowedPaths = allowedRoutes.map(r => r.path)

    if (!permissions.length) return "Permissions is Required"
    if (allowedRoutes.length === 0) return "Allowed Routes is Required"
    if (!defaultRoute || !allowedPaths.includes(defaultRoute))
      return "Default Route is Required"
    if (!badgeColor) return "Badge Color is Required"
    if (!roleName) return "Role Name is Required"
    if (!roleType) return "Role Type is Required"
  }

  const handleSubmit = async () => {
    const data = {
      permissions,
      allowedRoutes,
      defaultRoute,
      badgeColor,
      type: roleType,
      label: roleName,
      name: roleName,
    }

    const err = invalidPayloadErr()
    if (err) {
      showToastMessage({ success: false, message: err })
      return
    }
    try {
      action === "add" ? await addRole(data) : await updateRole(roleName, data)
      showToastMessage({
        success: true,
        message: `Role ${action}ed Successfully`,
      })
      history.push("/settings?tab=roles")
    } catch (err) {
      showToastMessage({ success: false, message: "Something Went Wrong" })
    }
  }

  useEffect(() => {
    if (name) {
      setAction("update")
      getRoleConfig(name).then(res => {
        const role = res.data

        setPermissions(role.permissions)
        setAllowedRoutes(role.allowedRoutes)
        setRoleName(role.name)
        setDefaultRoute(role.defaultRoute)
        setRoleType(role.type || "gazal")
        setBadgeColor(role.badgeColor)
      })
    }
  }, [])

  useEffect(() => {
    const fetchRoleTypes = async () => {
      const types = await getRoleTypes()
      if (!types) {
        showToastMessage({
          success: false,
          message: "failed to retrieve role types",
        })
      }
      return types.data
    }
    fetchRoleTypes().then(t => setRoleTypes(t))
  }, [])

  return (
    <div className="page-content ">
      <Breadcrumb title="config" breadcrumbItem="Add Role" />
      <Row className="justify-content-center">
        <Col lg={10}>
          <Card>
            <CardHeader className="text-center bg-white">
              <h4>Role Display</h4>
              <hr />
            </CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={3}>
                  <Label htmlFor="projectname" className="col-form-label">
                    Role Name
                  </Label>
                  <Input
                    id="projectname"
                    disabled={action === "update"}
                    value={roleName}
                    onChange={e => setRoleName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter Role Name..."
                  />
                  <FormText>
                    Follow snake case convention. E.g. role_name
                  </FormText>
                </Col>
                <Col lg={3}>
                  <Label htmlFor="projectname" className="col-form-label">
                    Role Type
                  </Label>
                  <Input
                    id="projectname"
                    value={roleType}
                    onChange={e => setRoleType(e.target.value)}
                    type="select"
                    className="form-control"
                    placeholder="Enter Role Name..."
                  >
                    {roleTypes &&
                      roleTypes.map((type, i) => (
                        <option key={i}>{type}</option>
                      ))}
                  </Input>
                </Col>

                <Col lg={3}>
                  <Label htmlFor="projectname" className="mb-2 col-form-label">
                    Badge Color
                  </Label>
                  <ColorPreview
                    color={badgeColor}
                    handleEdit={() => {
                      setShowColorPickerModal(true)
                    }}
                  />
                  {showColorPickerModal && (
                    <ColorPickerModal
                      showModal={showColorPickerModal}
                      prevColor={badgeColor}
                      setShowModal={setShowColorPickerModal}
                      saveChanges={color => setBadgeColor(color)}
                    />
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg={10}>
          <Card>
            <CardHeader className="text-center bg-white">
              <h4>Permissions</h4>
              <hr />
            </CardHeader>
            <CardBody>
              <FormPermissions
                permissions={permissions}
                setPermissions={setPermissions}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg={10}>
          <Card>
            <CardHeader className="text-center bg-white">
              <h4>Allowed Routes</h4>
              <hr />
            </CardHeader>
            <CardBody>
              <FormAllowedRoutes
                allowedRoutes={allowedRoutes.map(r => r.path)}
                setAllowedRoutes={setAllowedRoutes}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col lg={10}>
          <Card>
            <CardHeader className="text-center bg-white">
              <h4>Sidebar Routes</h4>
              <hr />
            </CardHeader>
            <CardBody>
              <FormSidebar
                setSavedDefaultRoute={setDefaultRoute}
                savedDefaultRoute={defaultRoute}
                savedRoutes={allowedRoutes}
                setSavedRoutes={setAllowedRoutes}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-2 ">
        <Col lg={11} className="text-end">
          <Button className="btn btn-lg btn-success" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default AddUpdateRole
