import {
  GET_ZONES,
  GET_ZONES_FAIL,
  GET_ZONES_SUCCESS,
  GET_ZONE_DETAILS,
  GET_ZONE_DETAILS_FAIL,
  GET_ZONE_DETAILS_SUCCESS,
  SET_ZONE,
  UPDATE_ZONE,
  UPDATE_ZONE_FAIL,
  UPDATE_ZONE_SUCCESS,
} from "./actionTypes"

export const getZones = () => ({
  type: GET_ZONES,
})

export const getZonesSuccess = zones => ({
  type: GET_ZONES_SUCCESS,
  payload: zones,
})

export const getZonesFail = error => ({
  type: GET_ZONES_FAIL,
  payload: error,
})

export const setZone = zone => ({
  type: SET_ZONE,
  payload: zone,
})

export const getZoneDetails = (zoneId, type, zoneUnder) => ({
  type: GET_ZONE_DETAILS,
  payload: { zoneId, type, zoneUnder },
})

export const getZoneDetailsSuccess = zone => ({
  type: GET_ZONE_DETAILS_SUCCESS,
  payload: zone,
})

export const getZoneDetailsFail = error => ({
  type: GET_ZONE_DETAILS_FAIL,
  payload: error,
})

export const updateZone = (zoneId, zoneType, updates) => ({
  type: UPDATE_ZONE,
  zoneId,
  zoneType,
  updates,
})

export const updateZoneSuccess = (zoneName, updates) => ({
  type: UPDATE_ZONE_SUCCESS,
  zoneName,
  updates,
})

export const updateZoneFail = error => ({
  type: UPDATE_ZONE_FAIL,
  payload: error,
})
