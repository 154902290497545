import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  InputGroup,
  FormGroup,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import "react-datepicker/dist/react-datepicker.css"
import { getAllRoles, updateUser, createUser } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
const FormUser = ({ toggle, user, isEdit, refetch }) => {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      email: (user && user.email.split("@")[0]) || "",
      role: (user && user.role) || "",
      sendInvite: (user && user.sendInvite) || "",
      jobPosition: (user && user.position) || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      role: Yup.string().required("Role is required"),
      email: Yup.string("Email must be valid"),
      jobPosition: Yup.string(),
    }),
    onSubmit: values => {
      values.email = values.email.trim() + "@gazal.app"
      values.type = "gazal"

      // validation.resetForm()
      // }
      if (isEdit) {
        values.updatedAt = Date.now()

        submitEdit(values)
      } else {
        values.createdAt = Date.now()
        submitCreate(values)
      }
      // toggle()
    },
  })

  const submitEdit = async values => {
    setLoading(true)
    try {
      await updateUser(user.uid, values)
      showToastMessage({
        success: true,
        message: "User Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Something went wrong",
      })
    }
    setLoading(false)
  }
  const submitCreate = async values => {
    setLoading(true)
    try {
      await createUser(values)

      showToastMessage({
        success: true,
        message: "User Created",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response?.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data?.messages,
        })
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
      }
    }
    setLoading(false)
  }

  const fetchRoles = async () => {
    if (!roles.length) {
      // TODO move it to dispatch
      const response = await getAllRoles()
      setRoles(Object.values(response.data))
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])
  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col md={6}>
            <FormGroup>
              <h4 className="font-size-14 mb-3">{"Details"}</h4>
              <div className="form-floating mb-3">
                <Input
                  autoFocus
                  type="text"
                  className="form-control"
                  id="firstNameInput"
                  name="firstName"
                  placeholder={"First Name"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName
                      ? true
                      : false
                  }
                />
                <Label htmlFor="firstNameInput">First Name</Label>
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="lastNameInput"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName
                      ? true
                      : false
                  }
                />
                <Label htmlFor="lastNameInput">Last Name</Label>
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="jobPositionInput"
                  name="jobPosition"
                  placeholder={"Job Position"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.jobPosition}
                />
                <Label htmlFor="jobPositionInput">Job Position</Label>
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <h4 className="font-size-14 mb-3">Role and Credentials</h4>
            <FormGroup>
              <div className="form-floating mb-3">
                <select
                  className="form-select text-capitalize"
                  name="role"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.role || ""}
                  // invalid={
                  //   validation.touched.role && validation.errors.role
                  //     ? true
                  //     : false
                  // }
                >
                  <option value="" label="Select Role">
                    Select Role
                  </option>
                  {roles.map(
                    (role, i) =>
                      role.type === "gazal" && (
                        <option
                          value={role.name}
                          className="text-capitalize"
                          key={i}
                        >
                          {role.name.split("_").join(" ")}
                          {/* {role.name} */}
                        </option>
                      )
                  )}
                </select>
                <Label htmlFor="floatingSelectGrid">Role</Label>
                {validation.touched.role && validation.errors.role ? (
                  <FormFeedback type="invalid">
                    {validation.errors.role}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>

            <InputGroup className="mb-3">
              <Input
                className="form-control"
                type="text"
                name="email"
                // placeholder={`Amount between 0.1 - ${maximumCost} SAR`}
                value={validation.values.email}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                // invalid={
                //   rangeValidation.touched.cost && rangeValidation.errors.cost
                //     ? true
                //     : false
                // }
              />{" "}
              <Label className="input-group-text" style={{ minWidth: "220px" }}>
                @gazal.app
              </Label>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-dark save-user"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

FormUser.propTypes = {
  toggle: PropTypes.any,
  refetch: PropTypes.func,
  user: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default FormUser
