import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"

import {
  Status,
  ExpiryDate,
  Summary,
  ReferralCode,
  Value,
  StartDate,
  Partner,
} from "./discounts-col"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap"
import TableContainer from "components/Common/Table/table-container"
import FormDiscount from "components/User/form-discount"
import ConfirmModal from "components/Common/ConfirmModal"
// eslint-disable-next-line react/prop-types
import { deleteDiscount } from "../../../helpers/backend_helper"
import { showToastMessage } from "../../../components/Common/ToastWrapper"
const DiscountsTable = ({ discounts, refetch }) => {
  const [discount, setDiscount] = useState()
  const [userList, setUserList] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const confirmContents = {
    action: "delete",
    resource: " discount",
  }
  const toggle = () => {
    setModal(!modal)
  }

  const handleEditDiscount = arg => {
    setIsEdit(true)
    const discount = arg

    setDiscount(discount)

    toggle()
  }

  const handleDeleteDiscount = discount => {
    setConfirmDelete(true)
    setDiscount(discount)
  }

  const confirmDeleteDiscount = async () => {
    try {
      await deleteDiscount(discount.discountId)
      showToastMessage({ success: true, message: "Discount deleted" })
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
    refetch({ force: true })
    setConfirmDelete(false)
  }
  const handleDiscountClick = () => {
    setDiscount()

    setIsEdit(false)

    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const discountData = cellProps.row.original
                  handleEditDiscount(discountData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const discountData = cellProps.row.original
                  handleDeleteDiscount(discountData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Partner",
        accessor: "company",
        filterable: true,
        // eslint-disable-next-line react/prop-types
        Cell: cellProps => {
          // eslint-disable-next-line react/prop-types
          return <Partner {...cellProps} />
        },
      },
      {
        Header: "Discount",
        accessor: "discountId",
        filterable: true,
        Cell: cellProps => {
          return <Summary {...cellProps} />
        },
      },

      {
        Header: "Value",
        accessor: "value",
        filterable: true,
        // eslint-disable-next-line react/prop-types
        Cell: cellProps => {
          // eslint-disable-next-line react/prop-types
          return <Value {...cellProps} />
        },
      },

      {
        Header: "Status",
        accessor: "isActive",
        filterable: true,
        // eslint-disable-next-line react/prop-types
        Cell: cellProps => {
          // eslint-disable-next-line react/prop-types
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        filterable: true,
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        filterable: true,
        Cell: cellProps => {
          return <ExpiryDate {...cellProps} />
        },
      },
      {
        Header: "Page Link",
        accessor: "referralCode",
        filterable: true,
        Cell: cellProps => {
          return <ReferralCode {...cellProps} />
        },
      },
    ],
    []
  )
  return (
    <Row>
      <ConfirmModal
        show={confirmDelete}
        confirmContents={confirmContents}
        onConfirmClick={confirmDeleteDiscount}
        onCloseClick={() => setConfirmDelete(false)}
      />
      <Col lg="12">
        <TableContainer
          columns={columns}
          data={discounts}
          isGlobalFilter={true}
          isAddDiscountList={true}
          handleDiscountClick={handleDiscountClick}
          customPageSize={10}
          className="custom-header-css"
          defaultSort={"metadata.lastSignInTime"}
        />

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? "Edit Discount" : "Add Discount"}
          </ModalHeader>
          <ModalBody>
            <FormDiscount
              toggle={toggle}
              discount={discount}
              isEdit={isEdit}
              refetch={refetch}
            />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  )
}
DiscountsTable.propTypes = {
  discounts: PropTypes.array,
  refetch: PropTypes.func,
}

export default DiscountsTable
