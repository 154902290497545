import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"
import ContentLoader from "react-content-loader"

const ZoneDetailsLoader = props => {
  return (
    <>
      <Row style={{ height: "70vh" }}>
        <Col lg={7} md={7} sm={12} xs={12}>
          <Card className="d-none d-lg-block h-100">
            <CardBody className="h-100">
              <ContentLoader
                viewBox="0 0 1200 970"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect height={"100%"} width={"100%"} />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col lg={5} md={5} sm={12} xs={12}>
          <Card className="d-none d-lg-block h-50">
            <CardBody className="h-50">
              <ContentLoader
                viewBox="0 0 1200 970"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
              >
                <rect x="50" y="20" rx="10" ry="10" width="500" height="50" />

                <rect x="50" y="125" rx="10" ry="10" width="350" height="30" />

<rect x="50" y="275" rx="10" ry="10" width="500" height="30" />

<rect x="650" y="275" rx="10" ry="10" width="500" height="30" />

<rect x="50" y="375" rx="10" ry="10" width="500" height="30" />

<rect x="650" y="375" rx="10" ry="10" width="500" height="30" />

<rect x="50" y="475" rx="10" ry="10" width="500" height="30" />

<rect x="650" y="475" rx="10" ry="10" width="500" height="30" />

<rect x="50" y="575" rx="10" ry="10" width="500" height="30" />

<rect x="650" y="575" rx="10" ry="10" width="500" height="30" />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <>
        <Card className="d-md-block d-sm-block d-lg-none">
          <CardBody>
            <ContentLoader
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect
                x="0"
                y="0"
                rx="4"
                ry="4"
                height="500"
                style={{ width: "100%" }}
              />
            </ContentLoader>
          </CardBody>
        </Card>
      </>
    </>
  )
}

ZoneDetailsLoader.propTypes = {}

export default ZoneDetailsLoader
