import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import jwt_decode from "jwt-decode"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  GET_CURRENT_USER,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  getCurrentUser,
  getCurrentUserSuccess,
  getCurrentUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"
import { getLoggedInUser } from "helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("uid", response.uid)
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("uid", response.uid)
      yield put(loginSuccess(response))
    }
    history.push("/vehicles")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))
    // }
    // history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()
    const response = yield call(fireBaseBackend.socialLoginUser)
    const decoded = jwt_decode(response._delegate.accessToken)

    // decoded.type = decoded.email.split("@")[1].split(".")[0]
    const { type, role } = decoded
    const unauthorized = type && type !== "gazal"
    if (!type || unauthorized) {
      // TODO - account still gets created in fb auth, find a way to delete it after throwing err
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
      throw Error("This account is not authorized")
    }
    localStorage.setItem(`type`, `${decoded.type}`)
    localStorage.setItem(`role`, `${decoded.role}`)
    localStorage.setItem("accessToken", response._delegate.accessToken)

    yield put(loginSuccess(decoded.user_id))

    const viewConfig = yield call(getLoggedInUser)
    yield put(getCurrentUserSuccess(viewConfig.data))

    const { defaultRoute } = viewConfig.data.roleData

    history.push(defaultRoute)
  } catch (error) {
    const errorMessage = error.message || authMessages["general"]
    yield put(apiError(errorMessage))
  }
}

// TODO this should be in users the rest should be in customers
function* getCurrentUserData() {
  try {
    const accessToken = localStorage.getItem("accessToken")
    const { role } = jwt_decode(accessToken)
    const viewConfig = yield call(getLoggedInUser)
    yield put(getCurrentUserSuccess(viewConfig.data))
  } catch (error) {
    // TODO refine
    const errorMessage = "Unauthorized"
    yield put(getCurrentUserFail(errorMessage))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(GET_CURRENT_USER, getCurrentUserData)
}

export default authSaga
