import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css"
import ActiveTripsTable from "components/Trip/active-trips-table"
import { getRtdbBackend } from "helpers/rtdb_helper"
import Restricted from "components/Common/Restricted"
import LoadingTable from "components/Loading/loading-table"
import AccessDenied from "pages/Utility/access-denied"
import {
  getCustomerDetails,
  getVehicleDetails,
} from "../../helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"

const ActiveTripsList = () => {
  const db = getRtdbBackend()
  const [activeTrips, setActiveTrips] = useState([])
  const [loadingActiveTrips, setLoadingActiveTrips] = useState(false)

  const mapTripFields = async (trip, scooterId) => {
    const promises = [
      db.customerDetails().child(trip.userId).once("value"),
      db
        .vehicleDetails()
        .orderByChild("scooterId")
        .equalTo(scooterId)
        .once("value"),
    ]

    const [customerSnap, vehicleSnap] = await Promise.all(promises)

    if (!customerSnap.exists() || !vehicleSnap.exists()) {
      throw Error(`Error fetching trip data for scooter ID: ${scooterId}`)
    }

    const userResult = customerSnap.val()
    // console.log("mapTripFields -> userResult:", userResult)
    const vehicleResult = Object.values(vehicleSnap.val())[0]
    const { contact, countryCode, credits } = userResult
    const { vehicleType, Battery, lockBattery, GPS_Status, IMEI } =
      vehicleResult

    return {
      ...trip,
      countryCode: countryCode,
      contact: contact,
      balance: credits,
      vehicleType,
      battery: vehicleType === "scooter" ? Battery : lockBattery,
      GPS_Status,
      scooterId,
      IMEI,
    }
  }

  useEffect(() => {
    // runs for every child in the collection and then everytime a child is added
    const activeTripsRef = db.allActiveTrips()
    setLoadingActiveTrips(true)
    const addListener = activeTripsRef.on("child_added", snapshot => {
      if (snapshot.exists()) {
        const trip = snapshot.val()
        const scooterId = snapshot.key
        mapTripFields(trip, scooterId)
          .then(t => {
            console.log("addListener -> t:", t)
            setActiveTrips(trips => [...trips, t])
            setLoadingActiveTrips(false)
          })
          .catch(err =>
            showToastMessage({
              success: false,
              message: err.message,
            })
          )
      }
    })

    const removeListener = activeTripsRef.on("child_removed", snapshot => {
      if (snapshot.exists()) {
        const removedTrip = snapshot.val()
        setActiveTrips(trips =>
          trips.filter(t => t.tripId != removedTrip.tripId)
        )
      }
    })

    return () => {
      activeTripsRef.off("child_added", addListener)
      activeTripsRef.off("child_removed", removeListener)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Active Trips | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Trips" breadcrumbItem="Active Trips" />
          <Restricted
            to="trips:SEARCH_ACTIVE_TRIPS"
            fallback={<AccessDenied />}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {loadingActiveTrips ? (
                      <LoadingTable />
                    ) : (
                      <ActiveTripsTable
                        trips={activeTrips}
                        loading={loadingActiveTrips}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActiveTripsList
