import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  loading: false,
  error: null,
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.uid.toString() === action.payload.uid.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
      }
    case EDIT_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.userId.toString() === action.payload.userId.toString()
            ? { user, ...action.payload }
            : user
        ),
        loading: false,
      }
    case CREATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default users
