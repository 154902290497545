import React, { useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { size, map } from "lodash"
import { Badge, UncontrolledCollapse } from "reactstrap"

const Name = cell => {
  return cell.value
    ? // <div className="text-capitalize">{cell.value.split("_").join(" ")}</div>
      cell.value
    : ""
}

const Role = cell => {
  return cell.value ? cell.value : ""
}

const Permissions = cell => {
  return cell.value ? (
    <>
      <Badge className="m-1 bg-info font-size-12">{cell.value.length} </Badge>
      Permissions
      <button
        className="float-end btn btn-rounded"
        id={`toggler-${cell.value.length}`}
      >
        {" "}
        <i className="mdi mdi-chevron-down " />
      </button>
      <UncontrolledCollapse toggler={`#toggler-${cell.value.length}`}>
        <ul>
          {cell.value.map((permission, key) => (
            <li key={key}>{permission}</li>
          ))}
        </ul>
      </UncontrolledCollapse>
    </>
  ) : (
    ""
  )
}

const DefaultRoute = cell => {
  return cell.value ? (
    <Link to={cell.value} target="_blank">
      {cell.value}
    </Link>
  ) : (
    "-"
  )
}

export { Name, Role, Permissions, DefaultRoute }
