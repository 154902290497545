/* eslint-disable react/prop-types */
import React from "react"
import { Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap"

export default function CardRemoveSelection({
  selectedVehiclesLength,
  removeSelection,
}) {
  return (
    <Card
      className="my-2"
      color="light"
      style={{
        width: "10rem",
        zIndex: 99999,
      }}
    >
      <CardHeader>{selectedVehiclesLength} Vehicle(s) selected</CardHeader>

      <ListGroup flush>
        <ListGroupItem
          className="text-danger"
          style={{
            cursor: "pointer",
          }}
          onClick={removeSelection}
        >
          <i className="mdi mdi-trash-can font-size-20 align-middle" /> Remove
          this shape
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
}
