import React, { useEffect, useMemo, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  Badge,
  Button,
} from "reactstrap"

import { showToastMessage } from "components/Common/ToastWrapper"
import Breadcrumb from "components/Common/Breadcrumb"
import {
  addBulkCodes,
  getVoucherCodes,
  getVoucherDetails,
} from "helpers/backend_helper"
import TableContainer from "components/Common/Table/table-container"
import { IdLink, StartDate } from "pages/Settings/Discounts/discounts-col"
import AccessDenied from "pages/Utility/access-denied"
import Restricted from "components/Common/Restricted"
import logoSecondary from "../../assets/images/logo-secondary.png"
import EmptyState from "components/Common/EmptyState"

const VoucherDetails = props => {
  const id = props.match.params.id
  const [voucher, setVoucher] = useState({})
  const [codes, setCodes] = useState([])

  const fetchVoucher = async voucherId => {
    const { data } = await getVoucherDetails(voucherId)
    setVoucher(data)
  }

  const fetchVoucherCodes = async voucherId => {
    const { data } = await getVoucherCodes(voucherId)
    setCodes(data)
  }

  const submitCodes = async count => {
    try {
      await addBulkCodes({ voucherId: id, codeCount: count })
      showToastMessage({ success: true, message: "codes added successfully" })
      await fetchVoucher(id)
      await fetchVoucherCodes(id)
    } catch (err) {
      showToastMessage({ success: false, message: "error adding codes" })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Status",
        accessor: "redeemed",
        Cell: cellProps => {
          return cellProps.value ? (
            <Badge color="danger">Redeemed</Badge>
          ) : (
            <Badge color="success">Available</Badge>
          )
        },
      },
      {
        Header: "Purchase Time",
        accessor: "purchaseTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Expiry Time",
        accessor: "expiryTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Redeem Time",
        accessor: "redeemTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "redeemed By",
        accessor: "redeemedBy",
        Cell: cellProps => {
          return <IdLink {...cellProps} path="/customer-detail" />
        },
      },
    ],
    []
  )

  useEffect(() => {
    fetchVoucher(id).catch(_err =>
      showToastMessage({ success: false, message: "Voucher Not Found" })
    )
    fetchVoucherCodes(id).catch(_err =>
      showToastMessage({
        success: false,
        message: "Something went wrong while fetching codes",
      })
    )
  }, [])

  return (
    <Restricted to="vouchers:VIEW_VOUCHER" fallback={<AccessDenied />}>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Voucher Details | Gazal</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Vouchers"
              titleLink="/vouchers"
              breadcrumbItem="Voucher Details"
            />
            {
              <>
                <Row>
                  {false && (
                    <Col lg={12}>
                      <div
                        className="alert alert-danger text-center mb-4 placeholder-glow"
                        role="alert"
                      >
                        An error has occured fetching voucher details,
                        <a
                          className="alert-link"
                          onClick={() => window.location.reload()}
                        >
                          {" "}
                          please try again.
                        </a>
                      </div>
                    </Col>
                  )}
                  <Col lg="3">
                    <Row>
                      <Card>
                        <CardBody>
                          <h4 className="text-center">{voucher.title}</h4>
                          <>
                            <Table className="table-responsive mb-0 table-bordered">
                              <tbody>
                                <tr key={10}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Status
                                  </th>
                                  <td>
                                    {voucher.isActive ? "Active" : "Inactive"}
                                  </td>
                                </tr>
                                <tr key={11}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Type
                                  </th>
                                  <td>{voucher.type}</td>
                                </tr>
                                <tr key={12}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Value
                                  </th>
                                  <td>
                                    <b>{voucher.value}</b> SAR
                                  </td>
                                </tr>
                                <tr key={13}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Expiry Span
                                  </th>
                                  <td>
                                    {voucher.expiryValue}{" "}
                                    <b>{voucher.expiryType}</b>
                                  </td>
                                </tr>

                                <tr key={14}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Redeem Limit
                                  </th>
                                  <td>{voucher.redeemLimit}</td>
                                </tr>
                                <tr key={15}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Redeem Count
                                  </th>
                                  <td>{voucher.redeemCount}</td>
                                </tr>
                                <tr key={16}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Code Count
                                  </th>
                                  <td>{voucher.codeCount}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                  <Col lg="9">
                    <Row>
                      <Col xl="12">
                        <Card>
                          <CardBody>
                            <h4 className="card-title mb-4">Voucher Codes</h4>
                            {codes.length ? (
                              <TableContainer
                                columns={columns}
                                data={codes}
                                isGlobalFilter={true}
                                customPageSize={10}
                                className="custom-header-css"
                                defaultSort={"metadata.lastSignInTime"}
                              />
                            ) : (
                              <EmptyState text="No Codes Found" />
                            )}
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <Button onClick={() => submitCodes(1)}>
                              Generate Code
                            </Button>
                            <Button
                              className="mx-2"
                              onClick={() => submitCodes(10)}
                            >
                              Generate 10 Codes
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
          </Container>
        </div>
      </React.Fragment>
    </Restricted>
  )
}

VoucherDetails.propTypes = {
  match: PropTypes.any,
}

export default withRouter(VoucherDetails)
