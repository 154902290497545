import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  getBlockReasons,
  updateCustomer,
  createCustomerBlockLog,
  getB2bPartners,
} from "helpers/backend_helper"
import { Button, Col, Row } from "reactstrap"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikSelect } from "components/Common/FormikFields"

const FormBlockCustomer = ({
  action,
  user,
  refresh,
  close,
  showToastMessage,
}) => {
  const [blockReasons, setBlockReasons] = useState([])
  const [b2bPartners, setB2bPartners] = useState([])

  const blockTypeOptions = [
    { label: `Full ${action}`, value: "full" },
    { label: `Partial ${action}`, value: "partial" },
  ]

  const fetchReasons = async () => {
    try {
      const { data } = await getBlockReasons()
      const selectObj = data.map(reason => ({
        value: reason.reason,
        label: reason.reason,
      }))
      setBlockReasons(selectObj)
    } catch (err) {
      showToastMessage({
        success: false,
        message: "Error retrieving Block Reasons",
      })
    }
  }

  const fetchB2bPartners = async () => {
    try {
      const { data } = await getB2bPartners()
      const selectObj = data.map(b => {
        return { label: b.name, value: b.name }
      })
      setB2bPartners(selectObj)
    } catch (err) {
      showToastMessage({
        success: false,
        message: "Error retrieving b2bPartners",
      })
    }
  }

  useEffect(() => {
    fetchReasons()
  }, [])

  useEffect(() => {
    fetchB2bPartners()
  }, [])

  const blockCustomer = async ({ type, reason, b2bPartner }) => {
    try {
      if (type === "partial") {
        await partialBlock(b2bPartner, reason)
      } else {
        await fullBlock(reason)
      }
      showToastMessage({ success: true, message: "User Blocked successfully" })
    } catch (err) {
      showToastMessage({
        success: false,
        message: `${err.message}`,
      })
    }

    refresh()
    close()
  }

  const unblockCustomer = async ({ type, b2bPartner }) => {
    try {
      if (type === "partial") {
        await partialUnblock(b2bPartner)
      } else {
        await fullUnblock()
      }
      showToastMessage({
        success: true,
        message: "User Unblocked successfully",
      })
    } catch (err) {
      showToastMessage({
        success: false,
        message: `${err.message}`,
      })
    }

    refresh()
    close()
  }

  const fullBlock = async reason => {
    if (user.statusCode === 2) {
      throw new Error("This User is Already Blocked")
    }

    const blockLog = {
      reason,
      isPartial: false,
      type: "block",
    }

    const update = {
      ...user,
      statusCode: 2,
      blockReason: reason,
    }

    await updateCustomer(user.userId, update)
    await createCustomerBlockLog(user.userId, blockLog)
  }

  const fullUnblock = async () => {
    if (user.statusCode === 0) {
      throw new Error("This User is Already Unblocked")
    }

    const unblockLog = {
      isPartial: false,
      type: "unblock",
    }

    const update = {
      ...user,
      statusCode: 0,
      blockReason: "NIL",
    }

    await updateCustomer(user.userId, update)
    await createCustomerBlockLog(user.userId, unblockLog)
  }

  const partialBlock = async (partner, reason) => {
    const alreadyBlocked = user.partiallyBlockedAt?.find(
      p => p.partner === partner
    )
    if (alreadyBlocked) {
      throw new Error(`This customer is already blocked at ${partner}`)
    }
    if (user.partiallyBlockedAt) {
      user.partiallyBlockedAt.push({ partner: partner, reason })
    } else {
      user.partiallyBlockedAt = [{ partner: partner, reason }]
    }

    const blockLog = {
      reason,
      isPartial: true,
      partner: partner,
      type: "block",
    }

    const update = {
      ...user,
      partiallyBlockedAt: user.partiallyBlockedAt,
    }

    await updateCustomer(user.userId, update)
    await createCustomerBlockLog(user.userId, blockLog)
  }

  const partialUnblock = async partner => {
    const alreadyBlocked = user.partiallyBlockedAt?.find(
      p => p.partner === partner
    )

    if (!alreadyBlocked) {
      throw new Error(`This Customer is already Unblocked at ${partner}`)
    }

    user.partiallyBlockedAt = user.partiallyBlockedAt.filter(
      item => item.partner !== partner
    )

    const update = {
      ...user,
      partiallyBlockedAt: user.partiallyBlockedAt,
    }
    await updateCustomer(user.userId, update)
    await createCustomerBlockLog(user.userId, {
      isPartial: true,
      partner: partner,
      type: "unblock",
    })
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          type: "",
          reason: "",
          b2bPartner: "",
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required(),
          reason: action === "Block" ? Yup.string().required() : Yup.string(),
          b2bPartner: Yup.string().when("type", {
            is: "partial",
            then: Yup.string().required(
              "B2B Partner is required for partial blocking"
            ),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={async values => {
          action === "Block"
            ? await blockCustomer(values)
            : unblockCustomer(values)
        }}
      >
        {formik => (
          <Form>
            <Row>
              <Col xl={12}>
                <FormikSelect
                  label={`${action} Type`}
                  name="type"
                  options={blockTypeOptions}
                />
              </Col>
            </Row>

            {action === "Block" && (
              <Row className="mt-3">
                <Col xl={12}>
                  <FormikSelect
                    label="Block Reason"
                    name="reason"
                    options={blockReasons}
                  />
                </Col>
              </Row>
            )}

            {formik.values.type === "partial" && (
              <Row className="mt-3">
                <Col xl={12}>
                  <FormikSelect
                    label="B2B Partner"
                    name="b2bPartner"
                    options={b2bPartners}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-3">
              <Col xl={2}>
                <Button className="btn btn-success" type="submit">
                  confirm
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

FormBlockCustomer.propTypes = {
  action: PropTypes.string,
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormBlockCustomer
