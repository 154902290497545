import CopyToClipboard from "components/Common/CopyToClipboard"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import Restricted from "components/Common/Restricted"
import useQuery from "hooks/useQuery"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import Flatpickr from "react-flatpickr"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {
  dateFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { getLogs as OnGetLogs } from "store/actions"
import { formatStandard } from "utils"
import logoSecondary from "../../../assets/images/logo-secondary.png"
import AuditModal from "./audit-modal"

const AuditTable = () => {
  let node = useRef()
  let url = new URL(window.location.href)
  const sizePerPage = 10

  const query = useQuery()
  const queryTab = url.searchParams.get("vtab") || "audit"
  const page = query.get("page") ? Number(query.get("page")) : 1
  const pageSize = query.get("pageSize")
    ? Number(query.get("pageSize"))
    : sizePerPage

  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [selectedLog, setSelectedLog] = useState(null)
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30)).getTime()
  )
  const [toDate, setToDate] = useState(new Date().getTime())

  const { logs, loading } = useSelector(state => ({
    logs: state.audit.logs,
    loading: state.audit.loading,
  }))

  const keyField = "logId"
  const auditListColumns = [
    {
      dataField: "menu",
      isDummyField: true,
      text: "Actions",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, log) => (
        // TODO fix the permission
        <Restricted to="customers:VIEW_CUSTOMER" fallback={"-"}>
          <Button
            id={`view-${log.logId}`}
            color="primary"
            size="sm"
            className="btn btn-sm btn-soft-primary mr-2"
            onClick={() => handleDetails(log)}
          >
            <i className="mdi mdi-eye-outline font-size-16" />
          </Button>
          <UncontrolledTooltip placement="top" target={`view-${log.logId}`}>
            View Details
          </UncontrolledTooltip>
        </Restricted>
      ),
    },
    {
      text: "Log ID",
      dataField: "logId",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, log) => (
        <span
          className="text-dark"
          style={{
            display: "block",
            width: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <CopyToClipboard text={log.logId || ""}>{log.logId}</CopyToClipboard>
        </span>
      ),
    },
    {
      text: "IP Address",
      dataField: "ip",
      sort: true,
      filter: textFilter(),
    },
    // {
    //   text: "Version",
    //   dataField: "version",
    //   sort: true,
    //   filter: textFilter(),
    // },
    {
      text: "Controller",
      dataField: "controller",
      sort: true,
      filter: textFilter(),
    },
    {
      text: "Date",
      dataField: "startTime",
      sort: true,
      filter: dateFilter(),
      formatter: (cellContent, log) => {
        return <span>{formatStandard(log?.startTime)}</span>
      },
    },
    {
      text: "Method",
      dataField: "method",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, log) => {
        return (
          <Badge
            color={
              {
                GET: "success",
                POST: "primary",
                PUT: "warning",
                DELETE: "danger",
              }[log.method]
            }
          >
            {log.method}
          </Badge>
        )
      },
    },
    {
      text: "User",
      dataField: "user",
      sort: true,
      filter: textFilter(),
    },
    {
      text: "Type",
      dataField: "type",
      sort: true,
      filter: selectFilter({
        options: {
          gazal: "Gazal",
          reseller: "Reseller",
          b2bPartner: "B2B Partner",
        },
      }),
    },
    {
      text: "Status",
      dataField: "httpStatus",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, log) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge color={getBadgeColor(log.httpStatus)} className="md">
            {log.httpStatus}
          </Badge>
        </div>
      ),
    },
  ]

  const handleDetails = log => {
    setSelectedLog(log)
    setShowModal(true)
  }

  const getBadgeColor = status => {
    if (status >= 200 && status < 300) {
      return "success"
    } else if (status >= 300 && status < 400) {
      return "secondary"
    } else if (status >= 400 && status < 500) {
      return "warning"
    } else if (status >= 500 && status < 600) {
      return "danger"
    } else {
      return "secondary"
    }
  }

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
          // url.searchParams.set("pageSize", page)
          // window.history.replaceState(null, null, url)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const onPageChange = (page, sizePerPage) => {
    setCurrentPage(page)
    // url.searchParams.set("page", page)
    // window.history.replaceState(null, null, url)
  }

  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="Audit Trail" />
  )

  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: logs.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    page: currentPage,
    sizePerPageOptionRenderer,
    onPageChange,
  }

  useEffect(() => {
    dispatch(OnGetLogs({ fromDate, toDate }))
  }, [])

  return (
    <React.Fragment>
      <AuditModal
        log={selectedLog}
        show={showModal}
        onCloseClick={() => [setShowModal(false), setSelectedLog(null)]}
      />
      <Card>
        <CardBody>
          <h4 className="header-title mt-0 mb-1">Audit Logs</h4>
          <p className="sub-header">
            This table shows the audit trail for requests coming to the server.
          </p>

          <Row>
            <Col lg={5}>
              <Label>From</Label>
              <Flatpickr
                className="form-control d-block bg-white"
                placeholder="Select from time"
                data-enable-time
                options={{
                  defaultDate: [fromDate],
                }}
                selected={fromDate}
                onChange={date => {
                  setFromDate(new Date(date).valueOf())
                }}
              />
            </Col>

            <Col lg={5}>
              <Label>To</Label>
              <Flatpickr
                className="form-control d-block bg-white"
                placeholder="Select to time"
                options={{
                  defaultDate: [toDate],
                }}
                data-enable-time
                selected={toDate}
                onChange={date => {
                  setToDate(new Date(date).valueOf())
                }}
              />
            </Col>

            <Col style={{marginTop: "26px"}} lg={2}>
              <Button
                className="btn btn-success btn-md"
                onClick={() => {
                  dispatch(OnGetLogs({ fromDate, toDate }))
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <CardBody>
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <ToolkitProvider
                      keyField={keyField}
                      data={logs}
                      columns={auditListColumns}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col lg={6} className={"d-flex align-items-center"}>
                              <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <Col lg={6}>
                              <div className="float-end">
                                <span className="p-2">Page Size</span>
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12 mt-4">
                              <div className="table-responsive table-light invert-scroll">
                                <BootstrapTable
                                  keyField={keyField}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  classes={
                                    "table align-middle table-nowrap table-hover top-horizontal-scroll"
                                  }
                                  bordered={false}
                                  striped={false}
                                  responsive
                                  ref={node}
                                  loading={loading}
                                  headerClasses="table-light"
                                  filter={filterFactory()}
                                  filterPosition="top"
                                />
                              </div>
                              {logs.length === 0 && (
                                <Row>
                                  <Col lg={12} className="text-center m-auto">
                                    <img
                                      src={logoSecondary}
                                      alt="logo"
                                      height="100"
                                    />
                                    <p className="text-muted">No Logs found</p>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col className="pagination pagination-rounded d-flex align-items-center justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )
                }}
              </PaginationProvider>
            </CardBody>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

AuditTable.propTypes = {
  logs: PropTypes.array,
  loading: PropTypes.bool,
}

export default AuditTable
