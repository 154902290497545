import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getVehicles as onGetVehicles } from "store/actions"
import { getAllStations, getZones, getLocations } from "helpers/backend_helper"

const useVehicleMap = () => {
  const dispatch = useDispatch()
  const { vehicles: vehiclesList, loading } = useSelector(state => ({
    vehicles: state.vehicles.vehicles,
    loading: state.vehicles.loading,
  }))

  const [stations, setStations] = useState([])
  const [locations, setLocations] = useState([])
  const [zones, setZones] = useState([])
  const [firstFetch, setFirstFetch] = useState(false)

  const fetchZones = async () => {
    const response = await getZones()
    const zones = response.data
    setZones(zones)
    setStations(zones)

    setLocations(
      zones.map(zone => ({
        lon: zone.zoneBoundaries[0].longitude,
        ...zone,
        name_en: zone.zoneName,
        lat: zone.zoneBoundaries[0].latitude,
      }))
    )
  }

  useEffect(() => {
    // if (!vehiclesList.length) {
    if (!firstFetch) {
      dispatch(onGetVehicles())
      setFirstFetch(true)
      const FOUR_MINUTES = 300000
      setInterval(() => dispatch(onGetVehicles()), FOUR_MINUTES)
    }
    // }
  }, [dispatch, vehiclesList, firstFetch])

  useEffect(() => {
    if (!zones.length) {
      fetchZones()
    }
  }, [])

  return [stations, locations, zones, vehiclesList, loading]
}

export default useVehicleMap
