import { getStatuses } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { apiError, getStatusesSuccess } from "./actions"
import { GET_STATUSES } from "./actionTypes"

function* fetchStatuses() {
    try {
        const response = yield call(getStatuses)
        yield put(getStatusesSuccess(response))
    } catch (error) {
        yield put(apiError(error))
    }
}

function* statusesSaga() {
    yield takeEvery(GET_STATUSES, fetchStatuses)
}

export default statusesSaga