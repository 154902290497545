import firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/database"

class RtdbBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig, "mainclient")
      //   this.db = firebase.database()
    }
  }

  /**
   * Listen to Active trip
   */

  activeTrip = (userId, tripId) =>
    firebase.database().ref(`Gazal SA/Trips/${userId}/${tripId}`)

  allActiveTrips = () => firebase.database().ref(`Gazal SA/Queue`)

  customerDetails = () => firebase.database().ref("Gazal SA/Users")
  vehicleDetails = () => firebase.database().ref("GPS")

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _rtdbBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initRtdbBackend = config => {
  if (!_rtdbBackend) {
    _rtdbBackend = new RtdbBackend(config)
  }
  return _rtdbBackend
}

/**
 * Returns the firebase backend
 */
const getRtdbBackend = () => {
  return _rtdbBackend
}

export { initRtdbBackend, getRtdbBackend }
