import React, { useState, useMemo } from "react"
import { Email, LastActive, CreatedAt, Role, FullName } from "./users-col"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap"
import TableContainer from "components/Common/Table/table-container"
import FormUser from "components/User/form-user"
import { showToastMessage } from "components/Common/ToastWrapper"
import ConfirmModal from "components/Common/ConfirmModal"
import { deleteUser } from "helpers/backend_helper"
// eslint-disable-next-line react/prop-types
const UsersTable = ({ users, refetch }) => {
  const [user, setUser] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const confirmContents = {
    action: "delete",
    resource: " user",
  }
  const toggle = () => {
    setModal(!modal)
  }

  const handleEditUser = arg => {
    setIsEdit(true)
    const user = arg

    setUser(user)

    toggle()
  }

  const handleDeleteUser = user => {
    setConfirmDelete(true)
    setUser(user)
  }

  const confirmDeleteUser = async () => {
    try {
      const response = await deleteUser(user.uid)

      showToastMessage({ success: true, message: "Discount deleted" })
    } catch (error) {
      showToastMessage({ success: false, message: error })
    }
    refetch({ force: true })
    setConfirmDelete(false)
  }

  const handleUserClick = () => {
    setUser()
    setIsEdit(false)

    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleEditUser(userData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original
                  handleDeleteUser(userData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
      {
        Header: "#",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: cellProps => (
          <>
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {cellProps.email.charAt(0)}
              </span>
            </div>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Name",
        // accessor: "firstName",
        filterable: true,
        // eslint-disable-next-line react/prop-types
        Cell: cellProps => {
          // eslint-disable-next-line react/prop-types
          return <FullName {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "customClaims.role",
        filterable: true,
        Cell: cellProps => {
          return <Role {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "metadata.creationTime",
        filterable: true,
        Cell: cellProps => {
          return <CreatedAt {...cellProps} />
        },
      },
      {
        Header: "Last Signed In",
        accessor: "metadata.lastSignInTime",
        filterable: true,
        Cell: cellProps => {
          return <LastActive {...cellProps} />
        },
      },
    ],
    []
  )
  return (
    <Row>
      <ConfirmModal
        show={confirmDelete}
        confirmContents={confirmContents}
        onConfirmClick={confirmDeleteUser}
        onCloseClick={() => setConfirmDelete(false)}
      />
      <Col lg="12">
        <TableContainer
          columns={columns}
          data={users}
          isGlobalFilter={true}
          isAddUserList={true}
          handleUserClick={handleUserClick}
          customPageSize={10}
          className="custom-header-css"
          defaultSort={"metadata.lastSignInTime"}
        />

        <Modal isOpen={modal} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit User" : "Add User"}
          </ModalHeader>
          <ModalBody>
            <FormUser
              toggle={toggle}
              user={user}
              isEdit={isEdit}
              refetch={refetch}
            />
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  )
}

export default UsersTable
