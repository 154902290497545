import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap"
import classNames from "classnames"
import { Link } from "react-router-dom/cjs/react-router-dom"

const ZoneFilter = ({
  zones = [],
  zoneType = "operation",
  setZoneType,
  setFilteredZones,
}) => {
  let url = new URL(window.location.href)

  const [page, setPage] = useState(url.searchParams.get("page") || 1)
  const [pageSize, setPageSize] = useState(
    url.searchParams.get("pageSize") || 10
  )
  const [zonesCount, setZonesCount] = useState(zones.length)
  const [controlZones, setControlZones] = useState([])

  const handlePageChange = page => {
    setPage(page)
    filterZones()
  }
  const handlePageSizeChange = e => {
    const size = parseInt(e.target.value, 10)
    setPageSize(size)
    setPage(1)
    filterZones()
  }
  const hasNextPage = () => page < Math.ceil(zonesCount / pageSize)
  const hasPreviousPage = () => page > 1
  const goToNextPage = () => {
    const newPage = page + 1
    if (hasNextPage()) {
      setPage(newPage)
    }
    filterZones()
  }
  const goToPreviousPage = () => {
    const newPage = page - 1
    if (newPage > 0) {
      setPage(newPage)
    }
    filterZones()
  }

  const filterZones = () => {
    // Filter zones by zoneType
    let filteredZones = zoneType === "operation" ? zones : controlZones
    setZonesCount(filteredZones.length)
    const startIndex = (page - 1) * pageSize
    const endIndex = page * pageSize
    // Filter zones by page and pageSize
    filteredZones = filteredZones.slice(startIndex, endIndex)
    setFilteredZones(filteredZones)
  }

  useEffect(() => {
    if (zones && zones.length) {
      const opsWithControl = zones.filter(zone => zone.controlZones)
      const controlZones = opsWithControl.reduce((acc, cur) => {
        const cz = Object.values(cur.controlZones)
        return [...acc, ...cz]
      }, [])
      setControlZones(controlZones)
    }
    filterZones()
  }, [zones, zoneType])

  useEffect(() => {
    filterZones()
  }, [page, pageSize])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col
              md={3}
              sm={12}
              lg={3}
              className="d-flex justify-content-start align-items-center"
            >
              <p className="text-muted fw-medium mb-0">
                Zone Count: {zonesCount}
              </p>
            </Col>
            <Col md={3} sm={12} lg={3} className="d-flex justify-content-start">
              <ul className="nav nav-pills btn-sm" role="tablist">
                <NavItem>
                  <NavLink
                    to={"#"}
                    className={classNames({ active: zoneType === "operation" })}
                    onClick={() => setZoneType("operation")}
                  >
                    Operation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to={"#"}
                    className={classNames({ active: zoneType === "control" })}
                    onClick={() => setZoneType("control")}
                  >
                    Control
                  </NavLink>
                </NavItem>
              </ul>
            </Col>
            <Col
              md={3}
              sm={12}
              lg={3}
              className="flex-grow-1 d-flex justify-content-end justify-content-sm-center"
            >
              <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>
                <Button
                  color="primary"
                  className="btn-sm"
                  type="button"
                  onClick={() => goToPreviousPage()}
                  disabled={!hasPreviousPage()}
                >
                  <i className="mdi mdi-less-than"> </i>
                  <span className="d-none d-md-inline">Prev Page</span>
                </Button>
              </div>
              <div className="form-input" style={{ width: "4rem" }}>
                <Input
                  type="number"
                  className="form-control form-control-sm"
                  value={page}
                  disabled
                  onChange={e => handlePageChange(parseInt(e.target.value, 10))}
                />
              </div>
              <div style={{ marginRight: "1rem", marginLeft: "1rem" }}>
                <Button
                  color="primary"
                  className="btn-sm"
                  type="button"
                  onClick={() => goToNextPage()}
                  disabled={!hasNextPage()}
                >
                  <span className="d-none d-md-inline">Next Page</span>{" "}
                  <i className="mdi mdi-greater-than"></i>
                </Button>
              </div>
              <div>
                <Input
                  type="select"
                  className="form-select form-select-sm"
                  value={pageSize}
                  onChange={e => handlePageSizeChange(e)}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                </Input>
              </div>
            </Col>

            <Col
              md={3}
              sm={12}
              lg={3}
              className="flex-grow-1 d-flex justify-content-end justify-content-sm-center"
            >
              {zoneType === "operation" ? (
                <Link to="/add-operation-zone">
                  <Button
                    type="button"
                    color="dark"
                    className="btn btn-rounded"
                  >
                    <i className="mdi mdi-plus me-1" />
                    Create operation zone
                  </Button>
                </Link>
              ) : (
                <Link to="/add-control-zone">
                  <Button
                    type="button"
                    color="dark"
                    className="btn btn-rounded"
                  >
                    <i className="mdi mdi-plus me-1" />
                    Create control zone
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ZoneFilter.propTypes = {
  zones: PropTypes.array.isRequired,
  zoneType: PropTypes.string.isRequired,
  setZoneType: PropTypes.func.isRequired,
  setFilteredZones: PropTypes.func.isRequired,
}

export default ZoneFilter
