import {
  TOGGLE_VEHICLES_VISIBLE,
  TOGGLE_STATIONS_VISIBLE,
  TOGGLE_CLUSTERS_VISIBLE,
  SET_DRAW_MODE,
} from "./actionTypes"

export const toggleVehicleVisibility = () => ({
  type: TOGGLE_VEHICLES_VISIBLE,
})

export const toggleStationVisibility = () => ({
  type: TOGGLE_STATIONS_VISIBLE,
})

export const toggleClusterVisibility = () => ({
  type: TOGGLE_CLUSTERS_VISIBLE,
})

export const changeDrawMode = isEnabled => ({
  type: SET_DRAW_MODE,
  payload: isEnabled,
})
