import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createControlZone, getZones } from "helpers/backend_helper"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import { showToastMessage } from "components/Common/ToastWrapper"
import {
  calclulateCenter,
  calculatePolygonRadius,
} from "components/Zones/common"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import ZoneMap from "./zone-map"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormikSelect, FormikTextInput } from "components/Common/FormikFields"

const AddControlZone = () => {
  const [zones, setZones] = useState([])
  const [zoneUnderOptions, setZoneUnderOptions] = useState([])
  const [bounds, setBounds] = useState([])
  const [zoneType, setZoneType] = useState("forbidden")
  const [center, setCenter] = useState({
    // Gazal HQ coordinates
    lat: 24.833723,
    lng: 46.734186,
  })

  const speedOptions = [
    { label: "25 km/h", value: 3 },
    { label: "20 km/h", value: 2 },
    { label: "15 km/h", value: 1 },
  ]

  const zoneTypeOptions = [
    { label: "Forbidden", value: "forbidden" },
    { label: "Low Speed", value: "lowSpeed" },
  ]

  const allowEndTripOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const fetchZones = async () => {
    const { data } = await getZones()
    return data
  }

  const history = useHistory()

  const handleZoneUnderChange = v => {
    const opZone = zones.find(z => z.zoneName === v)
    if (!bounds.length) {
      setCenter({
        lat: opZone.lat,
        lng: opZone.lng,
      })
    }
  }

  const handleSubmit = async values => {
    if (!bounds.length) {
      showToastMessage({ success: false, message: "Please create a polygon" })
      return
    }

    if (bounds.length < 3) {
      showToastMessage({
        success: false,
        message: "A polygon has a minimum of 3 points",
      })
      return
    }

    const center = calclulateCenter(bounds)
    const radius = calculatePolygonRadius(bounds, center)

    const payload = {
      ...values,
      zoneName: values.zoneName.trim(),
      zoneBoundaries: bounds.map(b => {
        return { latitude: b.lat, longitude: b.lng }
      }),
      zoneColor: zoneType === "forbidden" ? "#FF0000" : "#0000FF",
      zoneRadius: radius,
      speed: zoneType === "lowSpeed" ? values.speed : "",
      lat: center.lat,
      lng: center.lng,
      l: [-1, -1],
    }

    try {
      await createControlZone(payload)
      showToastMessage({
        success: true,
        message: "Control zone created successfully",
      })
      history.push("/zones")
    } catch (err) {
      showToastMessage({
        success: false,
        message: err.response?.data?.messages || "Something went wrong",
      })
    }
  }

  useEffect(() => {
    fetchZones().then(d => {
      setZones(d)
      setZoneUnderOptions(
        d.map(zone => ({ label: zone.zoneName, value: zone.zoneName }))
      )
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add control zone | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zones"
            titleLink="/add-control-zone"
            breadcrumbItem="Add control zone"
          />
          <Restricted to="zones:VIEW_ZONE" fallback={<AccessDenied />}>
            <>
              <section>
                <Row style={{ height: "50vh" }}>
                  <Col lg={7} md={7} sm={12} xs={12}>
                    <Card>
                      <CardBody>
                        <ZoneMap
                          bounds={bounds}
                          setBounds={setBounds}
                          initialCenter={center}
                        />
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        zoneName: "",
                        zoneType: "",
                        zoneUnder: "",
                        isAllowEndTrip: "",
                        speed: 1,
                      }}
                      validationSchema={Yup.object().shape({
                        zoneName: Yup.string()
                          .trim()
                          .required("Zone name is required"),
                        zoneType: Yup.string().required(
                          "Zone type is required"
                        ),
                        zoneUnder: Yup.string().required(
                          "Zone under is required"
                        ),
                        isAllowEndTrip: Yup.boolean().required(
                          "Allow end trip is required"
                        ),
                        speed: Yup.number().when("zoneType", {
                          is: "lowSpeed",
                          then: Yup.number()
                            .min(1)
                            .max(3)
                            .required("Zone speed limit is required"),
                          otherwise: Yup.number(),
                        }),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {formik => (
                        <Form>
                          <Card>
                            <CardBody>
                              <div className="d-flex flex-column">
                                <h3>Add control zone</h3>
                                <hr />
                                <Row>
                                  <Col lg={12}>
                                    <FormikTextInput
                                      label="Zone Name"
                                      name="zoneName"
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg={12}>
                                    <FormikSelect
                                      label="Zone Under"
                                      name="zoneUnder"
                                      onChange={handleZoneUnderChange}
                                      options={zoneUnderOptions}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg={4}>
                                    <FormikSelect
                                      label="Allow End Trip"
                                      name="isAllowEndTrip"
                                      options={allowEndTripOptions}
                                    />
                                  </Col>

                                  <Col lg={4}>
                                    <FormikSelect
                                      label="Zone Type"
                                      name="zoneType"
                                      options={zoneTypeOptions}
                                      onChange={v => setZoneType(v)}
                                    />
                                  </Col>

                                  {formik.values.zoneType === "lowSpeed" && (
                                    <Col lg={4}>
                                      <FormikSelect
                                        label="Speed"
                                        name="speed"
                                        options={speedOptions}
                                        value={speedOptions.find(
                                          o => o.value === formik.values.speed
                                        )}
                                      />
                                    </Col>
                                  )}
                                </Row>

                                <Row>
                                  <Col lg={10}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mt-4 "
                                    >
                                      Create Zone
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </section>
            </>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddControlZone)
