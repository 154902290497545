import CopyToClipboard from "components/Common/CopyToClipboard"
import PropTypes from "prop-types"
import React from "react"
import SyntaxHighlighter from "react-syntax-highlighter"
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { formatStandard } from "utils"
const AuditModal = ({ show, onCloseClick, log }) => {
  return (
    <React.Fragment>
      <Modal size="lg" isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalHeader toggle={onCloseClick}>Audit Log</ModalHeader>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col
              lg={12}
              className="d-flex align-items-center justify-content-center"
            >
              <h5>User Details</h5>
            </Col>
            <hr />
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">User</span>
                  {" : "}
                  <span>{log?.user}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Type</span>
                  {" : "}
                  <span>{log?.type}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Is Authenticated?</span>
                  {" : "}
                  <span>{log?.isAuthenticated ? "true" : "false"}</span>
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Role</span>
                  {" : "}
                  <span>{log?.role}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">IP Address</span>
                  {" : "}
                  <span>{log?.ip}</span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              className="d-flex align-items-center justify-content-center"
            >
              <h5>Response Details</h5>
            </Col>
            <hr />
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Status</span>
                  {" : "}
                  <span>{log?.httpStatus}</span>
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Error</span>
                  {" : "}
                  <span>{log?.errorMessage}</span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              className="d-flex align-items-center justify-content-center"
            >
              <h5>Request Details</h5>
            </Col>
            <hr />
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Host</span>
                  {" : "}
                  <span>{log?.host}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Version</span>
                  {" : "}
                  <span>{log?.version}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Path</span>
                  {" : "}
                  <span>{log?.path}</span>
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Method</span>
                  {" : "}
                  <span>{log?.method}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Controller</span>
                  {" : "}
                  <span>{log?.controller}</span>
                </p>
              </div>
              <div className="d-flex align-items-center">
                <p className="font-size-13">
                  <span className="fw-normal">Date</span>
                  {" : "}
                  <span>{formatStandard(log?.startTime)}</span>
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="d-flex align-items-center">
                <hr />
                <p className="font-size-13">
                  <span className="fw-normal">URL</span>
                  {" : "}
                  <span>
                    {"https://"}
                    {log?.host}
                    {log?.url}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="my-2">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="font-size-13">Body</h6>
                <CopyToClipboard
                  iconClassNames="font-size-16"
                  text={log?.fields || ""}
                />
              </div>
              <div className="d-flex align-items-center">
                <pre className="font-size-16 m-0 border rounded w-100">
                  <SyntaxHighlighter
                    showLineNumbers
                    language="json"
                    style={nord}
                    customStyle={{ marginBottom: "0" }}
                  >
                    {(typeof log?.fields === "object" &&
                      JSON.stringify(log?.fields, null, 2)) ||
                      (log?.fields || "")
                        .split(",")
                        .join(",\n\t")
                        .split("{")
                        .join("{\n\t")
                        .split("}")
                        .join("\n}")}
                  </SyntaxHighlighter>
                </pre>
              </div>
            </Col>
            <Col lg={12} className="my-2">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="font-size-13">Query</h6>
                <CopyToClipboard
                  iconClassNames="font-size-16"
                  text={log?.query || ""}
                />
              </div>
              <div className="d-flex align-items-center">
                <pre className="font-size-16 m-0 border rounded w-100">
                  <SyntaxHighlighter
                    showLineNumbers
                    language="json"
                    style={nord}
                    customStyle={{ marginBottom: "0" }}
                  >
                    {(typeof log?.query === "object" &&
                      JSON.stringify(log?.query, null, 2)) ||
                      (log?.query || "")
                        .split(",")
                        .join(",\n\t")
                        .split("{")
                        .join("{\n\t")
                        .split("}")
                        .join("\n}")}
                  </SyntaxHighlighter>
                </pre>
              </div>
            </Col>
            <Col lg={12} className="my-2">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="font-size-13">Path Params</h6>
                <CopyToClipboard
                  iconClassNames="font-size-16"
                  text={log?.params || ""}
                />
              </div>
              <div className="d-flex align-items-center">
                <pre className="font-size-16 m-0 border rounded w-100">
                  <SyntaxHighlighter
                    showLineNumbers
                    language="json"
                    style={nord}
                    customStyle={{ marginBottom: "0" }}
                  >
                    {(typeof log?.params === "object" &&
                      JSON.stringify(log?.params, null, 2)) ||
                      (log?.params || "")
                        .split(",")
                        .join(",\n\t")
                        .split("{")
                        .join("{\n\t")
                        .split("}")
                        .join("\n}")}
                  </SyntaxHighlighter>
                </pre>
              </div>
            </Col>
            <Col lg={12} className="my-2">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="font-size-13">Response Object</h6>
                <CopyToClipboard
                  iconClassNames="font-size-16"
                  text={log?.responseBody || ""}
                />
              </div>
              <div className="d-flex align-items-center">
                <pre className="font-size-16 m-0 border rounded w-100">
                  <SyntaxHighlighter
                    showLineNumbers
                    language="json"
                    style={nord}
                    customStyle={{ marginBottom: "0" }}
                  >
                    {String(JSON.parse(log?.responseBody || "{}"))
                      .replace("\\/\\/", "\t")
                      .split(",")
                        .join(",\n\t")
                        .split("{")
                        .join("{\n\t")
                        .split("}")
                        .join("\n\t}")}
                  </SyntaxHighlighter>
                </pre>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

AuditModal.propTypes = {
  show: PropTypes.any,
  onCloseClick: PropTypes.func,
  log: PropTypes.object,
}

export default AuditModal
