import React, { useEffect, useMemo, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Placeholder,
  Alert,
  Badge,
} from "reactstrap"
import classnames from "classnames"
import { showToastMessage } from "components/Common/ToastWrapper"
import {
  getCustomerDetails,
  getCustomerTrips,
  getCustomerPayments,
  getCustomerTransactions,
  updateCustomer,
  getZones,
  updateCustomerStatus,
  getCustomerBlockLogs,
  getCustomerVoucherHistory,
} from "helpers/backend_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PersonalInfo from "./personal-info"
import InvoiceHistory from "./invoice-history"
import DetailsOverview from "./details-overview"
import AccountOverview from "./account-overview"
import AdditionalInfo from "./additional-info"
import ConfirmModal from "./confirm-modal"
import UserTripsTable from "components/Trip/user-trips-table"
import {
  getPackages,
  getCustomerInvoices,
  getDiscount,
} from "../../../helpers/backend_helper"
import TransactionHistory from "./transaction-history"
import Modal from "components/Common/Modal"
import FormAddBalance from "components/Customer/Details/form-add-balance"
import PaymentHistory from "./payment-history"
import LoadingTable from "components/Loading/loading-table"
import useQuery from "hooks/useQuery"
import { isEmpty } from "lodash"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import CopyToClipboard from "components/Common/CopyToClipboard"
import FormAddDiscount from "../../../components/Customer/Details/form-add-discount"
import FormBlockCustomer from "../../../components/Customer/Details/form-block-customer"
import AccountHistory from "./account-history"
import TableContainer from "components/Common/Table/table-container"
import { StartDate } from "pages/Settings/Discounts/discounts-col"
import EmptyState from "components/Common/EmptyState"
import FormSendNotif from "components/Customer/Details/form-send-notif"

const CustomerDetails = props => {
  const [user, setUser] = useState({})
  const [allUserTrips, setAllUserTrips] = useState([])
  const [userTrips, setUserTrips] = useState([])
  const [userTripsView, setUserTripsView] = useState([])
  const [userTransactions, setUserTransactions] = useState([])
  const [userPayments, setUserPayments] = useState([])
  const [userVouchers, setUserVouchers] = useState([])
  const [balanceModal, setBalanceModal] = useState(false)
  const [discountModal, setDiscountModal] = useState(false)
  const [blockModal, setBlockModal] = useState(false)
  const [notifModal, setNotifModal] = useState(false)
  const [userInvoices, setUserInvoices] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingTab, setLoadingTab] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [stations, setStations] = useState([])
  const query = useQuery()
  const queryTab = query.get("tab") || "details"
  const [activeTab, toggleTab] = useState(queryTab)
  const [other_Menu, setother_Menu] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const [confirmContents, setConfirmContents] = useState({})
  const [confirmAction, setConfirmAction] = useState(() => {})

  const [blockLogs, setBlockLogs] = useState([])
  const [action, setAction] = useState("")

  const {
    match: { params },
  } = props

  const updateUrl = tab => {
    let url = new URL(window.location.href)
    url.searchParams.set("tab", tab)
    window.history.replaceState(null, null, url)
  }

  const fetchUserDetails = async args => {
    if (isEmpty(user) || args?.force) {
      try {
        setLoading(true)
        const response = await getCustomerDetails(params.id)
        const user = response.data
        if (user.hasActiveDiscount) {
          const response = await getDiscount(user.discountId)
          user.discount = response.data
        }
        setUser(user)

        const logsResponse = await getCustomerBlockLogs(params.id)
        const logsData = Object.values(logsResponse.data)
        let logs = []
        if (logsData.length) {
          logs = logsData.sort((a, b) => b.timestamp - a.timestamp)
        }
        setBlockLogs(logs)
        setLoading(false)
      } catch (error) {
        if (error.response.data.status === 404) {
          setErrorMsg("User does not exist")
        }
        setLoading(false)
      }
    }
  }

  const fetchUserTrips = async args => {
    if (!userTrips.length || args?.force) {
      setLoadingTab(true)
      try {
        const response = await getCustomerTrips(params.id)
        const trips = response.data
        setUserTrips(trips)
        setAllUserTrips(trips)
      } catch (error) {
        console.error("fetchUserDetails -> error", error)
      }
      setLoadingTab(false)
    }
  }

  const fetchUserInvoices = async () => {
    if (!userInvoices.length) {
      setLoadingTab(true)
      try {
        const response = await getCustomerInvoices(params.id)

        const invoices = response.data

        setUserInvoices(invoices)
      } catch (error) {
        console.error("fetchUserTransactions -> error", error)
      }

      setLoadingTab(false)
    }
  }

  const fetchUserPayments = async args => {
    if (!userPayments.length || args?.force) {
      setLoadingTab(true)
      try {
        const response = await getCustomerPayments(params.id)

        const payments = response.data
        const packageResponse = await getPackages()
        const packages = packageResponse.data
        const mappedPayments = payments.map(payment => ({
          ...payment,
          package:
            packages.find(pack => pack.packageId === payment.packageId) || {},
        }))

        setUserPayments(mappedPayments)
      } catch (error) {
        console.error("setUserPayments -> error", error)
      }

      setLoadingTab(false)
    }
  }

  const fetchUserTransactions = async () => {
    if (!userTransactions.length) {
      setLoadingTab(true)
      try {
        const response = await getCustomerTransactions(params.id)

        const transactions = response.data

        setUserTransactions(transactions)
      } catch (error) {
        console.error("fetchUserTransactions -> error", error)
      }

      setLoadingTab(false)
    }
  }

  const fetchCustomerVoucherHistory = async () => {
    try {
      const { data } = await getCustomerVoucherHistory(params.id)
      setUserVouchers(data)
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Error Retrieving Voucher History",
      })
    }
  }

  useEffect(() => {
    if (params && params.id) {
      fetchUserDetails(params.id)
      switch (activeTab) {
        case "invoices":
          return fetchUserInvoices()
        case "transactions":
          return fetchUserTransactions()
        case "trips":
          return fetchUserTrips()
        case "payments":
          return fetchUserPayments()
        case "voucherHistory":
          return fetchCustomerVoucherHistory()
      }
    }
  }, [])

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const deleteUser = () => {
    setConfirmModal(true)
    setConfirmContents({
      action: "delete",
      resource: ` user ${user.userName} ${user.userSurname}`,
    })
    const updates = {
      ...user,
      statusCode: 3,
    }
    setConfirmAction(() => () => updateAndRefresh(updates))
  }

  const revertDeleteUser = () => {
    setConfirmModal(true)
    setConfirmContents({
      action: "revert",
      resource: `user ${user.userName} ${user.userSurname}`,
    })
    const updates = {
      ...user,
      statusCode: 0,
    }
    setConfirmAction(() => () => updateAndRefresh(updates))
  }
  const addBalanceToUser = () => {
    setBalanceModal(true)
  }

  const addDiscountToUser = () => {
    setDiscountModal(true)
  }

  const updateAndRefresh = async updates => {
    try {
      await updateCustomer(user.userId, updates)
    } catch (error) {
      // TODO - handle error

      showToastMessage({ success: false, message: "Something went wrong" })
    }
    showToastMessage({ success: true, message: "User Updated successfully" })
    await fetchUserDetails({ force: true })
  }

  const updateStatus = async statusCode => {
    try {
      await updateCustomerStatus(user.userId, { statusCode })
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
    showToastMessage({ success: true, message: "Status Updated successfully" })
    await fetchUserDetails({ force: true })
  }

  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data
      ?.map(({ zoneName }) => ({
        label: zoneName,
        value: zoneName,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    setStations(stations)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Purchase Time",
        accessor: "purchaseTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Expiry Time",
        accessor: "expiryTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Redeem Time",
        accessor: "redeemTime",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
    ],
    []
  )
  const handleBlockModal = action => {
    setAction(action)
    setBlockModal(true)
  }

  useEffect(() => {
    fetchStations()
  }, [])

  return (
    <React.Fragment>
      <ConfirmModal
        show={confirmModal}
        confirmContents={confirmContents}
        onConfirmClick={confirmAction}
        onCloseClick={() => setConfirmModal(false)}
      />
      {/* BALANCE MODAL */}
      <Modal
        show={balanceModal}
        onCloseClick={() => setBalanceModal(false)}
        title={`Add Balance`}
        size="md"
      >
        <FormAddBalance
          user={user}
          refresh={() => fetchUserDetails({ force: true })}
          close={() => setBalanceModal(false)}
          showToastMessage={showToastMessage}
        />
      </Modal>
      {/* DISCOUNT MODAL */}
      <Modal
        size="lg"
        show={discountModal}
        onCloseClick={() => setDiscountModal(false)}
        title={`Grant Discount`}
      >
        <FormAddDiscount
          user={user}
          refresh={() => fetchUserDetails({ force: true })}
          close={() => setDiscountModal(false)}
          showToastMessage={showToastMessage}
        />
      </Modal>

      {/* BLOCK MODAL */}
      <Modal
        size="md"
        show={blockModal}
        onCloseClick={() => setBlockModal(false)}
        title={`${action} Customer`}
      >
        <FormBlockCustomer
          action={action}
          user={user}
          refresh={() => fetchUserDetails({ force: true })}
          close={() => setBlockModal(false)}
          showToastMessage={showToastMessage}
        />
      </Modal>

      <Modal
        show={notifModal}
        onCloseClick={() => setNotifModal(false)}
        title={`Send Notification`}
        size="md"
      >
        <FormSendNotif
          user={user}
          refresh={() => fetchUserDetails({ force: true })}
          close={() => setNotifModal(false)}
          showToastMessage={showToastMessage}
        />
      </Modal>

      <div className="page-content">
        <MetaTags>
          <title>Customer Detail | Gazal</title>
        </MetaTags>
        <div className="container-fluid">
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Customers"
            titleLink="/customers"
            breadcrumbItem="Customer Detail"
          />
          {errorMsg && (
            <Alert color="danger" className="text-center">
              {errorMsg}
            </Alert>
          )}
          <Restricted to="customers:VIEW_CUSTOMER" fallback={<AccessDenied />}>
            {
              <>
                <Row>
                  {" "}
                  <Col lg="3">
                    <Row>
                      <Card>
                        <CardBody>
                          <Col>
                            <ul
                              className={`d-sm-flex align-items-center justify-content-end text-capitalize`}
                            >
                              {/* <ul className="list-inline user-chat-nav space-between mb-0"> */}

                              <li className="list-inline-item">
                                <Dropdown
                                  isOpen={other_Menu}
                                  toggle={toggleOther}
                                  // disabled={loadingDetails}
                                >
                                  {/* TODO extract into a component */}
                                  <DropdownToggle
                                    className="btn btn-outline-dark"
                                    tag="p"
                                  >
                                    Actions
                                    <i className="bx bx-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem header>
                                      User Actions
                                    </DropdownItem>
                                    <Restricted to="customers:ADD_CUSTOMER_BALANCE">
                                      <DropdownItem onClick={addBalanceToUser}>
                                        Add Balance
                                      </DropdownItem>
                                    </Restricted>

                                    <DropdownItem
                                      onClick={() => setNotifModal(true)}
                                    >
                                      Send Notification
                                    </DropdownItem>
                                    <Restricted to="customers:ADD_CUSTOMER_DISCOUNT">
                                      <DropdownItem onClick={addDiscountToUser}>
                                        Grant Discount
                                      </DropdownItem>
                                    </Restricted>
                                    <Restricted to="customers:UPDATE_STATUS">
                                      <DropdownItem
                                        // className="text-dark"
                                        onClick={() =>
                                          handleBlockModal("Block")
                                        }
                                      >
                                        Block
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() =>
                                          handleBlockModal("Unblock")
                                        }
                                      >
                                        Unblock
                                      </DropdownItem>

                                      <DropdownItem
                                        // className="text-info"
                                        onClick={() => updateStatus(0)}
                                        disabled={user.statusCode !== 3}
                                      >
                                        Revert Delete
                                      </DropdownItem>

                                      <DropdownItem
                                        className="text-light bg-danger"
                                        onClick={() => updateStatus(3)}
                                        disabled={user.statusCode === 3}
                                      >
                                        Delete
                                      </DropdownItem>
                                    </Restricted>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                          </Col>
                          {/* <h4 className="card-title mb-4">Overview</h4> */}
                          <div className="m-4 text-center rounded-circle img-fluid">
                            {/* <img src={placeholderUser} alt="logo" height="150" /> */}
                            <i className="bx bx-user display-1 text-light"></i>
                          </div>
                          <div className="text-muted text-center mb-4">
                            {loading ? (
                              <Placeholder animation="glow">
                                <Placeholder xs={8} className="rounded-pill" />
                              </Placeholder>
                            ) : (
                              <>
                                <div className="d-flex justify-content-center align-items-center mb-2">
                                  <CopyToClipboard text={user.userId || ""}>
                                    {user.userId}
                                  </CopyToClipboard>
                                </div>
                                {user.hasActiveDiscount ? (
                                  <Badge color="primary" pill>
                                    {`Active ${user.discount.value}% Discount`}
                                  </Badge>
                                ) : (
                                  <></>
                                )}

                                {user.partiallyBlockedAt?.length ? (
                                  user.partiallyBlockedAt.map((log, i) => (
                                    <Badge
                                      key={i}
                                      pill
                                      className="mx-2 badge badge-soft-danger text-capitalize"
                                    >
                                      {`Partially Blocked At ${log.partner}`}
                                    </Badge>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>

                          <div>
                            <PersonalInfo user={user} loading={loading} />
                          </div>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row></Row>
                  </Col>
                  <Col xl={9} lg={8}>
                    <Card>
                      <ul
                        className="nav nav-tabs nav-tabs-custom justify-content-start pt-2"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "details",
                            })}
                            onClick={() => {
                              toggleTab("details")
                              // fetchUserDetails()
                              updateUrl("details")
                            }}
                          >
                            Details
                          </NavLink>
                        </NavItem>
                        <Restricted to="customers:VIEW_CUSTOMER_TRIPS">
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: activeTab === "trips",
                              })}
                              onClick={() => {
                                toggleTab("trips")
                                fetchUserTrips()
                                updateUrl("trips")
                              }}
                            >
                              Trips
                            </NavLink>
                          </NavItem>
                        </Restricted>
                        <Restricted to="customers:VIEW_CUSTOMER_TRANSACTIONS">
                          <NavItem>
                            <NavLink
                              to="?tab=transactions"
                              className={classnames({
                                active: activeTab === "transactions",
                              })}
                              onClick={() => {
                                toggleTab("transactions")
                                fetchUserTransactions()
                                updateUrl("transactions")
                              }}
                            >
                              Transactions
                            </NavLink>
                          </NavItem>
                        </Restricted>
                        <Restricted to="customers:VIEW_CUSTOMER_PAYMENTS">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "payments",
                              })}
                              onClick={() => {
                                toggleTab("payments")
                                fetchUserPayments()
                                updateUrl("payments")
                              }}
                              // disabled
                            >
                              Payments
                            </NavLink>
                          </NavItem>
                        </Restricted>
                        <Restricted to="customers:VIEW_CUSTOMER_INVOICES">
                          <NavItem>
                            <NavLink
                              to="#"
                              className={classnames({
                                active: activeTab === "invoices",
                              })}
                              onClick={() => {
                                toggleTab("invoices")
                                fetchUserInvoices()
                                updateUrl("invoices")
                              }}
                            >
                              Invoices
                            </NavLink>
                          </NavItem>
                        </Restricted>

                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "voucherHistory",
                            })}
                            onClick={() => {
                              toggleTab("voucherHistory")
                              fetchCustomerVoucherHistory()
                              updateUrl("voucherHistory")
                            }}
                          >
                            Voucher History
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "7",
                            })}
                            onClick={() => {
                              toggleTab("7")
                            }}
                            disabled
                          >
                            Passes
                          </NavLink>
                        </NavItem>
                      </ul>

                      <TabContent className="p-4" activeTab={activeTab}>
                        <TabPane tabId="details">
                          <CardBody>
                            <AccountOverview user={user} loading={loading} />
                          </CardBody>
                          <CardBody>
                            <Row>
                              <Col lg={6} sm={12}>
                                <h4 className="card-title mb-4 mt-4">
                                  Personal Information
                                </h4>
                                <DetailsOverview
                                  user={user}
                                  loading={loading}
                                />
                              </Col>
                              <Col lg={6} sm={12}>
                                <h4 className="card-title mb-4 mt-4">
                                  Additional Information
                                </h4>
                                <AdditionalInfo user={user} loading={loading} />
                              </Col>
                            </Row>
                          </CardBody>
                          <CardBody>
                            <AccountHistory
                              logs={blockLogs}
                              loading={loading}
                            />
                          </CardBody>
                          {/* {user.hasActiveDiscount && <CardBody>hi</CardBody>} */}
                        </TabPane>

                        <TabPane tabId="trips">
                          {loadingTab ? (
                            <LoadingTable />
                          ) : (
                            <>
                              {/* <CardBody>
                                <TripsOverView trips={userTrips} />
                              </CardBody> */}
                              {/* <button className="btn btn-sm bg-dark text-light font-size-18">
                                <i className="bx bx-list-ul"></i>
                              </button>
                              <button className=" bg-dark text-light font-size-18">
                                <i className="mdi mdi-table-multiple"></i>
                              </button> */}
                              <UserTripsTable
                                trips={userTrips}
                                isUserView={true}
                                stations={stations}
                                refetch={() => {
                                  fetchUserTrips({ force: true })
                                  fetchUserDetails({ force: true })
                                }}
                                // switchView={switchView}
                              />
                            </>
                          )}
                        </TabPane>

                        <TabPane tabId="transactions">
                          <Restricted
                            to="customers:VIEW_CUSTOMER_TRANSACTIONS"
                            fallback={
                              <AccessDenied
                                text="Access denied to transactions"
                                route="?tab=details"
                                linkLabel="User Details"
                              />
                            }
                          >
                            {loadingTab ? (
                              <LoadingTable />
                            ) : (
                              <TransactionHistory
                                transactions={userTransactions}
                              />
                            )}
                          </Restricted>
                        </TabPane>

                        <TabPane tabId="payments">
                          <Restricted
                            to="customers:VIEW_CUSTOMER_PAYMENTS"
                            fallback={
                              <AccessDenied
                                text="Access denied to payments"
                                route="?tab=payments"
                                linkLabel="User Details"
                              />
                            }
                          >
                            {loadingTab ? (
                              <LoadingTable />
                            ) : (
                              <PaymentHistory
                                payments={userPayments}
                                refetch={() =>
                                  fetchUserPayments({ force: true })
                                }
                                showToastMessage={showToastMessage}
                              />
                            )}
                          </Restricted>
                        </TabPane>
                        <TabPane tabId="invoices">
                          <Restricted
                            to="customers:VIEW_CUSTOMER_INVOICES"
                            fallback={
                              <AccessDenied
                                text="Access denied to invoices"
                                route="?tab=details"
                                linkLabel="User Details"
                              />
                            }
                          >
                            {loadingTab ? (
                              <LoadingTable />
                            ) : (
                              <InvoiceHistory invoices={userInvoices} />
                            )}
                          </Restricted>
                        </TabPane>

                        <TabPane tabId="voucherHistory">
                          {loadingTab ? (
                            <LoadingTable />
                          ) : !userVouchers.length ? (
                            <EmptyState text="This user hasn't redeemed any vouchers" />
                          ) : (
                            <TableContainer
                              columns={columns}
                              data={userVouchers}
                              customPageSize={10}
                              className="custom-header-css"
                            />
                          )}
                        </TabPane>
                      </TabContent>
                    </Card>
                  </Col>
                </Row>
              </>
            }
          </Restricted>
        </div>
      </div>
    </React.Fragment>
  )
}

CustomerDetails.propTypes = {
  match: PropTypes.any,
}

export default withRouter(CustomerDetails)
