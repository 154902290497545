/* ZONES */
export const GET_ZONES = "GET_ZONES"
export const GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS"
export const GET_ZONES_FAIL = "GET_ZONES_FAIL"

export const SET_ZONE = "SET_ZONE"

export const GET_ZONE_DETAILS = "GET_ZONE_DETAILS"
export const GET_ZONE_DETAILS_SUCCESS = "GET_ZONE_DETAILS_SUCCESS"
export const GET_ZONE_DETAILS_FAIL = "GET_ZONE_DETAILS_FAIL"

export const UPDATE_ZONE = "UPDATE_ZONE"
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS"
export const UPDATE_ZONE_FAIL = "UPDATE_ZONE_FAIL"
