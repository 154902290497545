import React from "react"
import { Card, CardBody, Spinner } from "reactstrap"
import SkeletonDataTable from "./skeleton-data-table"

const LoadingTable = () => {
  return (
    <>
      <Card className="d-none d-lg-block">
        <CardBody>
          <SkeletonDataTable className="mb-4" />
        </CardBody>
      </Card>

      <div className="d-flex justify-content-center align-items-center">
        <Spinner
          size="md"
          color="primary"
          className="d-md-block d-sm-block d-lg-none"
        />
      </div>
    </>
  )
}

export default LoadingTable
