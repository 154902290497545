import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, FormGroup, Label, Col, Input, Row } from "reactstrap"
import { getAllPermissions } from "helpers/backend_helper"

const FormPermissions = ({ permissions, setPermissions }) => {
  const [allPermissions, setAllPermissions] = useState([])
  const [list, setList] = useState([])
  const [isCheckAll, setIsCheckAll] = useState([])

  const fetchPermissions = async () => {
    const response = await getAllPermissions()
    const allPerms = response.data
    let resources = []

    allPerms.forEach(perm => {
      const [name, action] = perm.split(":")

      const found = resources.findIndex(el => el.name === name)

      if (found !== -1) {
        resources[found] = {
          ...resources[found],
          permissions: [...resources[found].permissions, action],
        }
      } else {
        resources.push({ name, permissions: [action] })
      }
    })

    setAllPermissions(resources)
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  const handleSelectAll = e => {
    const alreadyChecked = isCheckAll.findIndex(
      checked => checked === e.target.value
    )
    const result = allPermissions.find(p => p.name === e.target.value)
    if (!e.target.checked) {
      if (alreadyChecked !== -1) {
        setIsCheckAll(prev => prev.filter(name => name !== e.target.value))
      } else {
        setIsCheckAll(prev => [...prev, e.target.value])
      }
      const newChecked = [
        ...permissions,
        ...result.permissions.map(scope => `${e.target.value}:${scope}`),
      ]
      setPermissions([...new Set(newChecked)])
    } else {
      setPermissions(prev =>
        prev.filter(
          name =>
            !result.permissions
              .map(scope => `${e.target.value}:${scope}`)
              .includes(name)
        )
      )
      setIsCheckAll(prev => prev.filter(name => name !== e.target.value))
    }
  }

  const handleClick = e => {
    const { checked, value, name } = e.target
    const alreadyChecked = permissions.findIndex(
      checked => checked === e.target.value
    )

    const alreadyCheckedAll = isCheckAll.findIndex(checked => checked === name)

    if (alreadyCheckedAll !== -1) {
      if (checked) {
        setIsCheckAll(prev => prev.filter(item => item !== name))
      }
    } else {
    }

    let newCheck = []
    if (checked) {
      newCheck = permissions.filter(item => item !== value)
      setPermissions(newCheck)
    } else {
      if (alreadyChecked === -1) {
        newCheck = [...permissions, value]
        setPermissions(newCheck)
      }
    }

    const similarChecked = newCheck.filter(checked => checked.includes(name))
    const allCount = list.find(li => li.name === name)?.permissions.length
    if (allCount === similarChecked.length) {
      setIsCheckAll(prev => [...prev, name])
    }
  }

  useEffect(() => {
    setList(allPermissions)
  }, [list])

  useEffect(() => {
    if (permissions.length) {
      allPermissions.forEach(p => {
        const items = p.permissions
        const savedItems = permissions.filter(s => s.split(":")[0] === p.name)
        if (items.length === savedItems.length) {
          setIsCheckAll(prev => [...prev, p.name])
        }
      })
    }
  }, [permissions])

  return (
    <div>
      <Form>
        <FormGroup className="mb-4 row">
          <Row className="g-3 justify-content-center">
            {allPermissions.map((permission, index) => (
              <Col
                key={index}
                sm={3}
                className="bg-light rounded border border-light m-1 ms-2"
              >
                <div className="p-1">
                  <div className="mt-2 form-check form-check-inline ">
                    <Label check>
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        // name={permission.name}
                        onChange={() => {}}
                        onClick={handleSelectAll}
                        checked={isCheckAll.includes(permission.name)}
                        value={permission.name}
                        id={permission.name}
                      />
                      <h5 className="font-size-14 mt-0 text-capitalize form-check-label">
                        <strong> {permission.name}</strong>
                      </h5>
                    </Label>
                  </div>
                  <hr />
                  <ul className="list-unstyled megamenu-list">
                    {permission.permissions.map((option, key) => (
                      <li key={key}>
                        <div
                          className={`form-check form-check-inline text-capitalize ${
                            permissions.includes(`${permission.name}:${option}`)
                              ? ""
                              : "text-muted"
                          }`}
                        >
                          <Label check>
                            <Input
                              className="form-check-input "
                              type="checkbox"
                              id={option}
                              onChange={() => {}}
                              onClick={handleClick}
                              checked={permissions.includes(
                                `${permission.name}:${option}`
                              )}
                              name={permission.name}
                              value={`${permission.name}:${option}`}
                            />

                            {option.split("_").join(" ").toLowerCase()}
                          </Label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </FormGroup>
      </Form>
    </div>
  )
}

FormPermissions.propTypes = {
  setPermissions: PropTypes.func,
  permissions: PropTypes.array,
}

export default FormPermissions
