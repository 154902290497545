export const vehicleStatus = [
  {
    label: "In Use",
    value: "InUse",
    color: "info",
    iconClass: "",
    borderClass: "",
    colorHex: "#f1b44c",
  },
  {
    label: "Available",
    value: "Available",
    color: "success",
    iconClass: "text-success bx bxs-circle bx-flashing",
    borderClass: "text-success",
    colorHex: "#00a82d",
  },
  {
    label: "Stray",
    value: "Stray",
    color: "danger",
    iconClass: "",
    borderClass: "text-danger",
    colorHex: "#ed3548",
  },
  {
    label: "Maintenance",
    value: "Maintenance",
    color: "secondary",
    iconClass: "",
    borderClass: "text-warning",
    colorHex: "#7ababb",
  },
  {
    label: "Charging",
    value: "Charging",
    color: "warning",
    iconClass: "",
    borderClass: "text-primary",
    colorHex: "#757c9a",
  },
  {
    label: "LTD",
    value: "LTD",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#005b96",
  },
  {
    label: "SLM",
    value: "SLM",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#913945",
  },
  {
    label: "Redistribute",
    value: "Redistribute",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#FF8000",
  },
  {
    label: "Ops Pickup",
    value: "Ops_PickUp",
    color: "light",
    iconClass: "",
    borderClass: "text-dark",
    colorHex: "#8000FF",
  },
  {
    label: "Ops Dropoff",
    value: "Ops_DropOff",
    color: "dark",
    iconClass: "",
    borderClass: "text-secondary",
    colorHex: "#FF0080",
  },
  {
    label: "Repair Start",
    value: "Repair_Start",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#00FFFF",
  },
  {
    label: "Repair End",
    value: "Repair_End",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#FFFF00",
  },
  {
    label: "NIL",
    value: "NIL",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#808080",
  },
  {
    label: "BatteryLow",
    value: "BatteryLow",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#000",
  },
]

export const vehicleFields = [
  {
    label: "Status",
    value: "status",
    isDisabled: false,
  },
  {
    label: "Zone",
    value: "stationId",
    isDisabled: false,
  },

  {
    label: "Vehicle Config",
    value: "vehicleConfig",
    isDisabled: false,
  },
]
