import React from "react"
import PropTypes from "prop-types"
import { Input, Badge } from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
import { sidebarContent } from "constants/sidebarContent"

const FormSidebar = ({
  savedDefaultRoute,
  setSavedDefaultRoute,
  savedRoutes,
  setSavedRoutes,
}) => {
  const handleCheck = e => {
    const { checked, value } = e.target
    const alreadyChecked = savedRoutes.findIndex(route => route.path === value)

    if (alreadyChecked !== -1) {
      if (checked) {
        savedRoutes[alreadyChecked].sidebar = true
      } else {
        savedRoutes[alreadyChecked].sidebar = false
      }
    }
  }

  const handleSelect = (value, { name }) => {
    const newChildren = value.map(v => ({
      path: strAfterSlash(v.value),
      sidebar: true,
      label:
        sidebarContent.find(s => s.path === v.value)?.label ||
        strAfterSlash(v.value),
    }))

    const newData = {
      sidebar: true,
      path: name,
      children: newChildren,
    }

    const newState = savedRoutes.map(r => {
      if (r.path === name) {
        return newData
      }
      return r
    })
    setSavedRoutes(newState)
  }

  const strAfterSlash = str => str.substring(str.lastIndexOf("/"))

  return (
    <div>
      <div className="table-responsive">
        <table className="table align-middle table-nowrap">
          <tbody>
            <tr>
              <th>Default Route</th>
            </tr>

            <tr>
              <td style={{ width: "30%" }}>
                <select
                  style={{
                    minWidth: "140px",
                  }}
                  className="form-select"
                  value={savedDefaultRoute}
                  onChange={e => {
                    setSavedDefaultRoute(e.target.value)
                    setSavedDefaultRoute(e.target.value)
                  }}
                >
                  <option key={"route"} value={""}>
                    Select Route
                  </option>
                  {savedRoutes.map((parentRoute, i) => (
                    <option key={i} value={parentRoute.path}>
                      {parentRoute.path}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-responsive mt-4">
        <table className="table align-middle table-nowrap">
          <tbody>
            <tr>
              <th className={savedDefaultRoute === "" ? "text-muted" : ""}>
                Type
              </th>
              <th>Route</th>
              <th>Sidebar</th>
              <th>Children Routes (optional)</th>
            </tr>
            {savedRoutes.map((route, i) => (
              <tr key={i}>
                <td style={{ width: "10%" }}>
                  {savedDefaultRoute === route.path ? (
                    <span>
                      <Badge className="badge-soft-dark">default</Badge>
                    </span>
                  ) : (
                    ""
                  )}
                </td>
                <td style={{ width: "30%" }}>
                  <h5 className="mb-0">
                    <Link to={route.path} target="_blank">
                      {route.path}
                    </Link>
                  </h5>
                </td>
                <td style={{ width: "25%" }}>
                  <div className="form-check form-switch form-switch-md mb-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id={route.path}
                      value={route.path}
                      defaultChecked={route.sidebar}
                      onClick={handleCheck}
                      name={route.path}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={route.path}
                    ></label>
                  </div>
                </td>

                <td>
                  <Select
                    key={i}
                    options={savedRoutes
                      .filter(r => {
                        return r?.path.includes(route.path.split("/")[1])
                      })
                      ?.filter(r => r.path !== route.path)
                      ?.map(r => ({
                        value: r.path,
                        label: strAfterSlash(r.path),
                      }))}
                    isMulti
                    closeMenuOnSelect={false}
                    name={route.path}
                    defaultValue={route?.children?.map(r => ({
                      label: r.path,
                      value: r.path,
                    }))}
                    onChange={handleSelect}
                    placeholder="None"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

FormSidebar.propTypes = {
  savedDefaultRoute: PropTypes.string,
  setSavedDefaultRoute: PropTypes.func,
  setSavedRoutes: PropTypes.func,
  savedRoutes: PropTypes.array,
}

export default FormSidebar
