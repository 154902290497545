import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table, Alert, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"
import SimpleBar from "simplebar-react"
import { formatStandard } from "utils/formatDate"
import EmptyState from "components/Common/EmptyState"

const RouteCoordinates = ({ coordinates, loading }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-5">Route Coordinates</CardTitle>
        {loading ? (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Spinner size="md" color="primary" />
            </div>
          </>
        ) : coordinates.length ? (
          <SimpleBar
            style={{ height: "380px", maxHeight: "380px" }}
            forceVisible="y"
            autoHide={false}
          >
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>#</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {map(coordinates, (coord, index) => (
                    <tr key={"_coord_" + index}>
                      <td style={{ width: "50px" }}>{index + 1}</td>
                      <td>
                        <h5 className="font-size-14 m-0">{coord.lat}</h5>
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">{coord.lng}</h5>
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          {formatStandard(Number(coord.timestamp))}
                        </h5>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </SimpleBar>
        ) : (
          <div
            style={{ height: "380px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <EmptyState text="Route coordinates not found" />
          </div>
        )}
        {/* </SimpleBar> */}
      </CardBody>
    </Card>
  )
}
RouteCoordinates.propTypes = {
  coordinates: PropTypes.array,
  loading: PropTypes.bool,
}
export default RouteCoordinates
