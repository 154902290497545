/* eslint-disable react/prop-types */
import React from "react"
import { motion } from "framer-motion"
import { UncontrolledTooltip } from "reactstrap"

// eslint-disable-next-line react/prop-types
const ClusterMarker = ({ cluster, ratio, handleClusterClick }) => {
  const { point_count: pointCount } = cluster.properties
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 20,
        delay: Math.random() * 0.3,
      }}
    >
      <h5
        className="cluster-marker"
        id={`cluster-${cluster.id}`}
        style={{
          width: `${10 + ratio * 20}px`,
          height: `${10 + ratio * 20}px`,
          zIndex: 1,
        }}
        onClick={() => {
          handleClusterClick(cluster)
        }}
      >
        {pointCount}
      </h5>
      <UncontrolledTooltip placement="top" target={`cluster-${cluster.id}`}>
        View {pointCount} Vehicles
      </UncontrolledTooltip>
    </motion.div>
  )
}

export default ClusterMarker
