import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Spinner } from "reactstrap"

const LoadingButton = ({ onClick, disabled = false, children, ...props }) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    await onClick()

    setLoading(false)
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === "Enter") {
        event.preventDefault()
        handleClick()
      }
    }
    document.addEventListener("keydown", keyDownHandler)
    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  }, [onClick])
  //
  return (
    <Button onClick={handleClick} disabled={loading || disabled} {...props}>
      {loading ? <Spinner size="sm" /> : children}
    </Button>
  )
}

LoadingButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
}

export default LoadingButton
