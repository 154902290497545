import Restricted from "components/Common/Restricted"
import ZoneFilter from "components/Zones/zone-filter"
import ZoneList from "components/Zones/zone-list"
import ZoneListLoader from "components/Zones/zone-list-loader"
import AccessDenied from "pages/Utility/access-denied"
import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { getZones as OnGetZones } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ZonesFilter from "./zones-filter"
import ZonesPagination from "./zones-pagination"

const Zones = () => {
  const dispatch = useDispatch()

  const { zones, loading, error } = useSelector(state => ({
    zones: state.zones.zones,
    loading: state.zones.loading,
    error: state.zones.error,
  }))
  
  const [isLoading, setIsLoading] = useState(true)
  const [filteredZones, setFilteredZones] = useState([])
  const [zoneType, setZoneType] = useState("operation")

  const fetchZones = () => {
    dispatch(OnGetZones())
  }

  const refetchZones = () => {
    fetchZones()
    setFilteredZones([])
  }

  useEffect(() => {
    if (zones && !filteredZones.length) {
      fetchZones()
    }
  }, [zones, dispatch])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Zones | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zones"
            titleLink="/zones"
            breadcrumbItem="Zones"
          />
          <Restricted to="vehicles:VIEW_VEHICLE" fallback={<AccessDenied />}>
            <>
              <Row>
                {error && (
                  <Col lg={12}>
                    <div
                      className="alert alert-danger text-center mb-4 placeholder-glow"
                      role="alert"
                    >
                      An error has occured fetching zones,
                      <a
                        className="alert-link"
                        onClick={() => window.location.reload()}
                      >
                        {" "}
                        please try again.
                      </a>
                    </div>
                  </Col>
                )}
                
                <ZoneFilter key={`zonePagination_${zones.length}`} zones={zones} zoneType={zoneType} setZoneType={setZoneType} setFilteredZones={setFilteredZones}  />
                {!isLoading ? (
                  <ZoneList zones={filteredZones} refetch={refetchZones} type={zoneType} />
                ) : (
                  <ZoneListLoader />
                )}
              </Row>
            </>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Zones
