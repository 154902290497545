import React from "react"
import { formatStandard } from "utils"
import { Badge, UncontrolledTooltip, Col, Row } from "reactstrap"
import CopyToClipboard from "components/Common/CopyToClipboard"
import { Link } from "react-router-dom/cjs/react-router-dom"

const Status = cell => {
  return new Date(cell.row.original.expiryDate) < Date.now() ? (
    <Badge>Expired</Badge>
  ) : Boolean(cell.value) ? (
    <Badge color="success">Active</Badge>
  ) : (
    <Badge color="danger">Inactive</Badge>
  )
}

const ReferralCode = cell => {
  return cell.value ? (
    <ul className="list-inline user-chat-nav  mb-0">
      <li className="list-inline-item  d-none d-sm-inline-block">
        <CopyToClipboard
          text={`https://b2b.gazal.app/discount?referral=${cell.value}`}
        />
      </li>
      <li className="list-inline-item  d-none d-sm-inline-block">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://b2b.gazal.app/discount?referral=${cell.value}`}
          className="btn btn-sm btn-soft-success"
        >
          <i className="mdi mdi-eye-outline font-size-16" id="viewtooltip" />
          <UncontrolledTooltip placement="top" target="viewtooltip">
            View
          </UncontrolledTooltip>
        </a>
      </li>
    </ul>
  ) : (
    ""
  )
}

const Partner = cell => {
  return cell.row.original.logoUrl ? (
    <Row>
      <Col sm="5">
        <img
          className="avatar-sm mx-auto"
          alt={cell.row.original.company}
          src={cell.row.original.logoUrl}
        />
      </Col>
      <Col sm="5">{cell.row.original.company}</Col>
    </Row>
  ) : (
    <Row>
      <Col sm="5">
        <div className="display-4 text-center rounded-circle img-fluid">
          {/* <img src={placeholderUser} alt="logo" height="150" /> */}
          <i className="mdi mdi-office-building-outline text-light"></i>
        </div>
      </Col>
      <Col sm="5">-</Col>
    </Row>
  )
}

const Value = cell => {
  return cell.value ? (
    <p>
      <strong>{`${cell.value}%`} </strong> off
    </p>
  ) : (
    ""
  )
}

const StartDate = cell => {
  return cell.value ? formatStandard(new Date(cell.value)) : ""
}

const IdLink = (cell) => {
  return cell.value ? <Link to={`${cell.path}/${cell.value}`}>{cell.value}</Link> : ""
}

const ExpiryDate = cell => {
  return cell.value ? formatStandard(cell.value) : ""
}

const Summary = cell => {
  return (
    <Col lg={9} sm={12}>
      <h5 className="text-primary">{`${cell.row.original.discountId}`}</h5>
      <p>{cell.row.original.description}</p>
    </Col>
  )
}

export { Status, ExpiryDate, Summary, ReferralCode, Value, StartDate, Partner, IdLink }
