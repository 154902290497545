export const isEmptyObject = object =>
  Object.values(object).every(
    x => x === null || x === "" || (typeof x === "object" && !x.length)
  )

  export const substituteComparator = comparator => {
    switch (comparator) {
      case "=":
        return "equals"
      case "LIKE":
        return "contains"
      case ">":
        return "gt"
      case ">=":
        return "gte"
      case "<":
        return "lt"
      case "<=":
        return "lte"
      default:
        return "equals"
    }
  }