import React from "react"
import PropTypes from "prop-types"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Placeholder,
  Row,
  Table,
} from "reactstrap"
import CopyToClipboard from "components/Common/CopyToClipboard"

const PersonalInfo = ({ user, loading }) => {
  return (
    <div className="">
      <div className="table-responsive">
        <Table className="table-nowrap mb-0">
          <tbody>
            <tr>
              <th scope="row">Full Name :</th>
              <td style={{ width: "50%" }}>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={10} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  `${user.userName} ${user.userSurname}`
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">Mobile :</th>
              <td style={{ width: "50%" }}>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  <span className="d-flex">
                    {user.countryCode} {user.contact}
                  <CopyToClipboard text={user.contact} position="right" iconClassNames="mx-1 font-size-12 text-muted" />
                  </span>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Partner ID :</th>
              <td style={{ width: "50%" }}>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={5} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  user.partnerId
                )}
              </td>
            </tr>

            {user.customerId !== "NIL" && (
              <tr>
                <th
                  scope="row"
                  style={{ width: "50%" }}
                  className={"text-capitalize"}
                >
                  Customer ID
                </th>
                <td>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={1} className="rounded-pill" />
                    </Placeholder>
                  ) : (
                    user.customerId
                  )}
                </td>
              </tr>
            )}
            <tr>
              <th scope="row">Balance :</th>
              <td style={{ width: "50%" }}>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={4} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  `${parseFloat(user.credits / 100).toFixed(2)} SAR`
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}
PersonalInfo.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
}

export default PersonalInfo
