export const tripActionsContent = {
  "Trip Started": "Trip Started",
  "End Trip": "End Trip",
  "End Trip Success": "Successfully Ended the Trip",
  "External End Trip Success": "Successfully Ended the Trip",
  "End Trip Fail": "Failed When Ending the Trip",
  "External End Trip Fail": "Failed When Ending the Trip",
  "Rate Trip": "Rate Trip",
  "Rate Trip Success": "Trip Rated Successfully",
  "Rate Trip Fail": "Failed when Rating the Trip",
  "Check End Zone": "Check End Zone",
  "Check End Zone Success": "Vehicle Inside Operation Area",
  "Check End Zone Fail": "Vehicle outside Operation Area",
  "Warn Notification Sent": "Successfully Sent Warning Notification",
  "Error Notification Sent": "Sent End Trip Error Notification",
  "End Notification Sent": "Successfully Sent End Trip Notification",
}

export const endTripLogReasons = {
  "stationary-vehicle": "Stationary Vehicle",
  "low-battery": "Low Battery",
  "low-balance": "Low Balance",
}

export const tripMessagesContent = {
  tripAlreadyEnded: "The Trip has been already ended",
  tripNotStarted: "Cannot end trip that is not started",
  stationNotFound: "Scooter or Station were not found",
  bikeNotLocked: "Cannot end trip when Bike lock is open",
  exclusivePickUpZone: "Cannot end the trip in a different zone",
  exclusiveDropOffZone: "Cannot end the trip in this zone",
  priceCalcError: "There was an error calculating Total Fare",
  setTripObjError: "There was an error updating Trip Object",
  vehicleOutsideZone: "Vehicle is outside operation zone",
  vehicleInsideZone: "Vehicle is inside operation zone",
}
