import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

const usePermission = permission => {
  const [loading, setLoading] = useState(false)
  //   const [allowed, setAllowed] = useState(false)

  const { permissions } = useSelector(state => ({
    permissions: state.Login.permissions,
  }))

  const isAllowedTo = permission => permissions.includes(permission)

  //   await isAllowedTo(permission).then(allowed => {
  //     setAllowed(allowed)
  //     setLoading(false)
  //   })
  //   useEffect(() => {
  //     setLoading(true)
  //     const allowed = isAllowedTo(permission)
  //     setAllowed(allowed)
  //     setLoading(false)
  //   }, [permission])
  const allowed = isAllowedTo(permission)
  return [allowed, loading]
}

export default usePermission
