/* eslint-disable react/prop-types */
import React from "react"
import { Row } from "reactstrap"
export function CitySelect({ handleCitySelect, locations }) {
  return (
    <div id="city-visibility">
      <Row
        className="mt-3"
        style={{
          width: "200px",
          height: "50px",
        }}
      >
        <select
          style={{
            minWidth: "140px",
          }}
          className="form-select input-group-lg font-size-18 floating-shadow text-center"
          onChange={handleCitySelect}
        >
          <option key={"endZoneDefault"} value={""}>
            Select City
          </option>
          {locations.map((location, i) => (
            <option key={i} value={JSON.stringify(location)}>
              {location.name_en}
            </option>
          ))}
        </select>
      </Row>
    </div>
  )
}
