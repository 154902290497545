import {
  GET_ZONES,
  GET_ZONES_FAIL,
  GET_ZONES_SUCCESS,
  GET_ZONE_DETAILS,
  GET_ZONE_DETAILS_FAIL,
  GET_ZONE_DETAILS_SUCCESS,
  SET_ZONE,
  UPDATE_ZONE,
  UPDATE_ZONE_FAIL,
  UPDATE_ZONE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  zones: [],
  loading: false,
  zone: null,
  loadingDetails: false,
  loadingEdit: false,
  error: "",
  success: false,
  message: "",
}

const Zones = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ZONES:
      return {
        ...state,
        loading: true,
      }
    case GET_ZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        zones: action.payload,
        error: "",
        message: "",
        success: false,
      }

    case GET_ZONES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_ZONE_DETAILS:
      return {
        ...state,
        zone: null,
        loadingDetails: true,
        error: null,
        message: "",
      }
    case GET_ZONE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        zone: action.payload,
      }

    case GET_ZONE_DETAILS_FAIL:
      return {
        ...state,
        zone: null,
        loadingDetails: false,
        error: action.payload,
      }

    case SET_ZONE:
      return {
        ...state,
        zone: action.payload,
      }

    case UPDATE_ZONE:
      return {
        ...state,
        loadingEdit: true,
        message: "",
        success: false,
        error: ""
      }
    case UPDATE_ZONE_SUCCESS:
      return {
        ...state,
        loadingEdit: false,
        success: true,
        error: null,
        zone: action.updates,
        message: "Zone updated successfully",
      }
    case UPDATE_ZONE_FAIL:
      return {
        ...state,
        loadingEdit: false,
        error: action.payload,
        success: false,
        message: "Zone failed to update",
      }

    default:
      return state
  }
}

export default Zones
