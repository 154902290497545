import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Spinner } from "reactstrap"
import { sendCommand } from "../../helpers/backend_helper"

const FormSendCommand = ({ vehicle, close, showToastMessage, refetch }) => {
  const [command, setCommand] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleOnSubmit = async () => {
    setLoading(true)
    try {
      await sendCommand(vehicle.IMEI, command)
      showToastMessage({ success: true, message: "Command sent successfully" })
      refetch()
      close()
    } catch (error) {
      if (error.message !== "Network Error") {
        // console.error("fetchUserDetails -> error", error)
        setError(true)
      } else {
        showToastMessage({
          success: true,
          message: "Command sent successfully",
        })
        refetch()
        close()
      }
    }
    setLoading(false)
  }

  const handleCommand = e => {
    setCommand(e.target.value)
  }
  return (
    <div>
      <Row>
        <Col lg={12}>
          <dl className="text-muted row ">
            <dt className="col-sm-3">IMEI:</dt>
            <dd className="col-sm-9">{vehicle.IMEI}</dd>
            <dt className="col-sm-3">ID:</dt>
            <dd className="col-sm-9">{vehicle.scooterId}</dd>
            <dt className="col-sm-3">Type:</dt>
            <dd className="col-sm-9 text-capitalize">{vehicle.vehicleType}</dd>
          </dl>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="form">
            <input
              className={`form-control ${error && "is-invalid"}`}
              type="text"
              placeholder="Enter Command Data"
              value={command}
              onChange={handleCommand}
              onFocus={() => setError(false)}
            />

            {error && (
              <div className="invalid-feedback d-block">
                Command failed, try again
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg={6} sm={12}>
          <button
            type="button"
            className="mt-4 btn btn-outline-secondary text-dark w-100"
            onClick={() => {
              setCommand("")
              setError(false)
            }}
            disabled={loading}
          >
            Clear
          </button>
        </Col>
        <Col lg={6} sm={12}>
          <button
            type="submit"
            className="mt-4 btn btn-info btn-block w-100"
            onSubmit={e => {
              e.preventDefault()
              handleOnSubmit()
            }}
            onClick={e => {
              e.preventDefault()
              handleOnSubmit()
            }}
            disabled={loading || !command}
          >
            {loading ? <Spinner size="sm" /> : "Execute"}
          </button>
        </Col>
      </Row>
    </div>
  )
}
FormSendCommand.propTypes = {
  vehicle: PropTypes.object,
  close: PropTypes.func,
  refetch: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormSendCommand
