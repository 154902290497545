import React from "react"
import PropTypes from "prop-types"

const TotalPreview = ({ from, to, size, entity, loading }) => {
  const loadingTag = <i className="bx bx-hourglass bx-spin" />
  const sizeTag = <strong>{size.toLocaleString() + " "} </strong>
  if (size || loading) {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size ? sizeTag : loadingTag}
        {entity}
      </span>
    )
  }

  return <span>no {entity} found</span>
}

TotalPreview.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  size: PropTypes.number,
  entity: PropTypes.string,
  loading: PropTypes.bool,
}

export default TotalPreview
