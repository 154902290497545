import React, { useState } from "react"
import PropTypes from "prop-types"

import { Row, Col, Label, InputGroup, Input, Button, Badge } from "reactstrap"
import { addCustomerBalance } from "helpers/backend_helper"
import LoadingButton from "components/Common/LoadingButton"

const FormAddBalance = ({ user, refresh, close, showToastMessage }) => {
  const [amount, setAmount] = useState(null) // always in credits
  const [credits, setCredits] = useState(null)
  const [active, setActive] = useState(false)
  const [unit, setUnit] = useState("sar")

  const calculateNewBalance = () => {
    let newBalance
    if (amount) {
      newBalance = parseFloat(credits) + parseFloat(user.credits)
    } else {
      newBalance = parseFloat(user.credits)
    }
    return newBalance
  }

  const handleOnChange = e => {
    if (e.target.value) {
      setActive(true)
    } else {
      setActive(false)
    }
    const value = e.target.value
    const credits = unit === "sar" ? value * 100 : value
    setCredits(credits)
    setAmount(e.target.value)
  }

  const handleSelect = e => {
    setUnit(e.target.value)
    const exchange = amount
      ? e.target.value === "sar"
        ? amount / 100
        : amount * 100
      : ""
    setCredits(exchange)
    setAmount(exchange)
  }

  const handleSubmit = async () => {
    const data = {
      userId: user.userId,
      paymentAmount: `${credits} * 100`,
    }
    try {
      const response = await addCustomerBalance(data)
      await refresh()
      showToastMessage({
        success: true,
        message: "Balance Added successfully",
      })
    } catch (error) {
      showToastMessage({ success: false, message: "Failed to add balance" })
    }
    close()
  }
  return (
    <>
      <Row>
        <Col md="6" sm="12">
          <div className="border p-3 rounded">
            <div className="d-flex">
              {/* <div className="me-3 align-self-center">
                <i className="mdi mdi-wallet h2 text-muted mb-0" />
              </div> */}
              <div className="flex-grow-1">
                <p className="text-muted mb-2">Current Credit Balance</p>
                <h5 className="mb-0">
                  {/* {user.credits}{" "} */}
                  <span className="font-size-14 text-muted">
                    {parseFloat(user.credits / 100).toFixed(2)} SAR
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </Col>
        <Col md="6" sm="12">
          <div
            className={`border p-3 rounded ${active ? "border-success" : ""}`}
          >
            <div className="d-flex">
              {/* <div className="me-3 align-self-center">
                <i className="mdi mdi-wallet h2 text-info mb-0" />
              </div> */}
              <div className="flex-grow-1">
                <p className="text-muted mb-2">New Credit Balance</p>
                <h5 className="mb-0">
                  {/* {calculateNewBalance()}{" "} */}
                  <span className="font-size-14 text-muted">
                    {parseFloat(calculateNewBalance() / 100).toFixed(2)} SAR
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="my-4">
        <div>
          <Label>Add Amount :</Label>
          <InputGroup className="mb-3">
            <Label className="input-group-text" style={{ minWidth: "120px" }}>
              SAR
            </Label>
            {/* <select
              className="form-select"
              style={{ maxWidth: "90px" }}
              onChange={handleSelect}
            >
              <option value="credits" defaultValue>
                Credits
              </option>
              <option value="sar">SAR</option>
            </select> */}
            <Input
              className="form-control"
              value={amount}
              onChange={handleOnChange}
              type="number"
            />
          </InputGroup>
        </div>
      </Row>
      <div className="text-center">
        <LoadingButton
          type="button"
          color="success"
          className="w-md"
          disabled={!amount}
          onSubmit={handleSubmit}
          onClick={handleSubmit}
        >
          Add Balance
        </LoadingButton>
      </div>
    </>
  )
}

FormAddBalance.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormAddBalance
