import {
  GoogleMap,
  InfoWindow,
  Polygon,
  DrawingManager,
  useJsApiLoader,
} from "@react-google-maps/api"
import React, { useCallback, useEffect, useRef, useState } from "react"
import MapControl from "./map-control"
import { Badge, Button, UncontrolledTooltip } from "reactstrap"
import { calclulateCenter } from "components/Zones/common"
import PropTypes from "prop-types"
import { getZones } from "helpers/backend_helper"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const mapLibraries = ["drawing"]

const ZoneMap = ({
  bounds,
  setBounds,
  zone = null,
  setIsEdit = null,
  initialCenter = {
    // Gazal HQ coordinates
    lat: 24.833723,
    lng: 46.734186,
  },
}) => {
  const [map, setMap] = useState()
  const [center, setCenter] = useState({})
  const [opZones, setOpZones] = useState([])
  const [controlZones, setControlZones] = useState([])
  const [clickPos, setClickPos] = useState({})
  const [stationsView, setStationsView] = useState(true)
  const [cZonesView, setCZonesView] = useState(true)
  const [delInfoWindow, setDelInfoWindow] = useState(false)
  const [viewPolygonDetails, setViewZoneDetails] = useState(false)
  const [polygonDetails, setPolygonDetails] = useState({})
  const [drawingManager, setDrawingManager] = useState(null)
  const [drawingOn, setDrawingOn] = useState(false)

  const getZoneColorFromType = type => {
    switch (type) {
      case "operation":
        return "#000000"

      case "forbidden":
        return "#FF0000"

      case "lowSpeed":
        return "#0000FF"

      default:
        return "#000000"
    }
  }

  const selectedZoneColor = "#FFA500"
  const defaultZoom = 15
  const polygonRef = useRef(null)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: mapLibraries,
  })

  const containerStyle = {
    width: "100%",
    height: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    minHeight: "500px",
  }

  const handleMapClick = e => {
    const newCenter = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }
    setClickPos(newCenter)

    setViewZoneDetails(false)
  }

  const onLoad = useCallback(polygon => {
    polygon.setOptions({ fillColor: selectedZoneColor })
    polygonRef.current = polygon
  }, [])

  const onEdit = useCallback(() => {
    if (setIsEdit) setIsEdit(true)
    if (polygonRef.current) {
      setNextPolygon(polygonRef.current)
    }
  }, [])

  const setNextPolygon = polygon => {
    const nextPath = polygon
      .getPath()
      .getArray()
      .map(latLng => {
        return { lat: latLng.lat(), lng: latLng.lng() }
      })
    setBounds(nextPath)
  }

  const handlePolygonClick = (e, z) => {
    setPolygonDetails(z)
    const newCenter = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    }
    setClickPos(newCenter)
    setViewZoneDetails(true)
  }

  const fetchZones = async () => {
    const { data } = await getZones()
    return data
  }

  const handleDrawingComplete = event => {
    const polygon = event.overlay
    setNextPolygon(polygon)
    polygon.setMap(null)
  }

  const createDrawingManager = () => {
    const dm = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
    })

    google.maps.event.addListener(dm, "overlaycomplete", handleDrawingComplete)

    dm.setMap(map)
    setDrawingManager(dm)
  }

  const toggleDrawingManager = () => {
    if (!drawingManager) {
      createDrawingManager()
    } else {
      drawingManager.setDrawingMode(
        drawingManager.drawingMode === null ? "polygon" : null
      )
    }
  }

  useEffect(() => {
    fetchZones().then(opZones => {
      setControlZones(
        opZones
          .filter(z => z.controlZones)
          .map(z => Object.values(z.controlZones))
          .flat()
          .filter(z => z.zoneName !== zone?.zoneName)
      )

      setOpZones(opZones.filter(item => item.zoneName !== zone?.zoneName))
    })
  }, [])

  useEffect(() => {
    if (!zone) setCenter(initialCenter)
  }, [initialCenter])

  useEffect(() => {
    if (zone && zone.zoneBoundaries) {
      const zoneBoundaries = (zone.zoneBoundaries || []).map(boundry => {
        return {
          lat: boundry?.latitude || boundry.lat,
          lng: boundry?.longitude || boundry.lng,
        }
      })

      setBounds(zoneBoundaries)
      setCenter(calclulateCenter(zoneBoundaries))
    }
  }, [zone])

  useEffect(() => {
    if (map) map.setZoom(defaultZoom)
  }, [center])

  return (
    <>
      {isLoaded && (
        <GoogleMap
          onLoad={m => {
            setMap(m)
          }}
          center={center}
          mapContainerStyle={containerStyle}
          zoom={defaultZoom}
          onClick={handleMapClick}
          id="zone-map"
          options={{ mapTypeControl: false }}
          yesIWantToUseGoogleMapApiInternals
        >
          <MapControl position="RIGHT_TOP">
            <button
              className={`mx-2 floating-shadow btn btn-small bg-${
                stationsView ? "dark" : "white"
              }`}
              id="toggleOpZones"
              onClick={() => {
                setStationsView(!stationsView)
              }}
            >
              <i
                className={`bx bx-map-pin font-size-18 text-${
                  stationsView ? "white" : "dark"
                }`}
              />
            </button>
          </MapControl>
          <UncontrolledTooltip placement="left-start" target="toggleOpZones">
            Toggle Operation Zones
          </UncontrolledTooltip>

          <MapControl position="RIGHT_TOP">
            <button
              className={`mx-2 my-2 floating-shadow btn btn-small bg-${
                cZonesView ? "dark" : "white"
              }`}
              id="toggleCZones"
              onClick={() => {
                setCZonesView(!cZonesView)
              }}
            >
              <i
                className={`mdi mdi-traffic-light font-size-18 text-${
                  cZonesView ? "white" : "dark"
                }`}
              />
            </button>
          </MapControl>
          <UncontrolledTooltip placement="left-start" target="toggleCZones">
            Toggle Control Zones
          </UncontrolledTooltip>

          <MapControl position="RIGHT_TOP">
            <button
              id="drawTooltip"
              className={`mx-2 floating-shadow btn btn-small bg-${
                drawingOn ? "dark" : "white"
              }`}
              onClick={() => {
                setDrawingOn(!drawingOn)
                toggleDrawingManager()
              }}
            >
              <i
                className={`mdi mdi-vector-polygon font-size-18 text-${
                  drawingOn ? "white" : "dark"
                }`}
              />
            </button>
          </MapControl>

          <UncontrolledTooltip placement="left-start" target="drawTooltip">
            Toggle Drawing Mode
          </UncontrolledTooltip>

          <MapControl position="RIGHT_TOP">
            <button
              className={`mx-2 my-2 floating-shadow btn btn-small bg-white`}
              id="helpTooltip"
            >
              <i className={`bx bx-help-circle font-size-18 text-dark`} />
            </button>
          </MapControl>

          <UncontrolledTooltip placement="left-start" target="helpTooltip">
            <li className="mb-2">
              Create a polygon by clicking anywhere on the map
            </li>
            <li className="mb-2">
              Delete the polygon by clicking on it, then delete
            </li>
            <li className="mb-2">Move the polygon by dragging</li>
            <li>Resize the polygon by dragging any vertex</li>
          </UncontrolledTooltip>

          {opZones.length &&
            opZones.map((z, index) => {
              return (
                <Polygon
                  visible={stationsView}
                  onClick={e => handlePolygonClick(e, z)}
                  key={index}
                  path={z.zoneBoundaries.map(boundry => {
                    return {
                      lat: boundry?.latitude || boundry.lat,
                      lng: boundry?.longitude || boundry.lng,
                    }
                  })}
                  options={{
                    fillColor: getZoneColorFromType(z.zoneType),
                  }}
                />
              )
            })}

          {controlZones.length &&
            controlZones.map((z, index) => {
              return (
                <Polygon
                  visible={cZonesView}
                  onClick={e => handlePolygonClick(e, z)}
                  key={index}
                  path={z.zoneBoundaries.map(boundry => {
                    return {
                      lat: boundry?.latitude || boundry.lat,
                      lng: boundry?.longitude || boundry.lng,
                    }
                  })}
                  options={{
                    fillColor: getZoneColorFromType(z.zoneType),
                  }}
                />
              )
            })}

          {bounds.length && (
            <Polygon
              onLoad={onLoad}
              path={bounds}
              editable
              draggable
              onMouseUp={onEdit}
              options={{
                clickable: true,
              }}
              onClick={() => {
                setDelInfoWindow(!delInfoWindow)
              }}
            />
          )}

          {delInfoWindow && (
            <InfoWindow
              onCloseClick={() => {
                setDelInfoWindow(false)
              }}
              position={calclulateCenter(bounds)}
            >
              <div>
                <Button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setBounds([])
                    setDelInfoWindow(false)
                  }}
                >
                  Delete
                </Button>
              </div>
            </InfoWindow>
          )}

          {viewPolygonDetails && (
            <InfoWindow
              onCloseClick={() => setViewZoneDetails(false)}
              position={clickPos}
            >
              <div>
                <div className="text-center">
                  <h3>{polygonDetails.zoneName}</h3>
                </div>
                <div className="mb-2 text-center">
                  <Badge
                    color={
                      polygonDetails.zoneType === "operation"
                        ? "dark"
                        : "danger"
                    }
                  >
                    {polygonDetails.zoneType}
                  </Badge>
                </div>
                <div className="text-center">
                  <Link
                    to={`/zone-detail/${polygonDetails.zoneName}?type=${
                      polygonDetails.zoneType === "forbidden" || "lowSpeed"
                        ? "control"
                        : "operation"
                    }`}
                  >
                    Zone Details
                  </Link>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </>
  )
}

ZoneMap.propTypes = {
  path: PropTypes.array,
  setBounds: PropTypes.func,
  setIsEdit: PropTypes.func,
  bounds: PropTypes.array,
  zone: PropTypes.object,
  zoneType: PropTypes.string,
  initialCenter: PropTypes.object,
}

export default ZoneMap
