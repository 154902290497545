import {
  TOGGLE_VEHICLES_VISIBLE,
  TOGGLE_STATIONS_VISIBLE,
  TOGGLE_CLUSTERS_VISIBLE,
  SET_DRAW_MODE,
} from "./actionTypes"

const INIT_STATE = {
  vehiclesView: true,
  stationsView: true,
  clusterView: true,
  loadingClusterView: false,
  rectangleDrawMode: false,
  polygonDrawMode: false,
  drawDisabled: false,
  rectangleDrawMode: false,
  polygonDrawMode: false,
}

const map = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_VEHICLES_VISIBLE:
      return {
        ...state,
        vehiclesView: !state.vehiclesView,
      }
    case TOGGLE_STATIONS_VISIBLE:
      return {
        ...state,
        stationsView: !state.stationsView,
      }
    case TOGGLE_CLUSTERS_VISIBLE:
      return {
        ...state,
        clusterView: !state.clusterView,
      }
    case SET_DRAW_MODE:
      return {
        ...state,
        drawDisabled: action.payload,
      }
    default:
      return state
  }
}

export default map
