import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { formatStandard } from "../../../utils/formatDate"
import EmptyState from "components/Common/EmptyState"
import madalogo from "../../../assets/brand/madalogo.png"
import mastercard from "../../../assets/brand/mastercard.png"
import visacard from "../../../assets/brand/visacard.png"
import stcpaylogo from "../../../assets/brand/stcpaylogo.png"
import applepay from "../../../assets/brand/applepay.png"
import { fixTransaction } from "../../../helpers/backend_helper"
import CopyToClipboard from "components/Common/CopyToClipboard"
import ConfirmModal from "./confirm-modal"
import { capitalizeString } from "utils/formatString"

const PaymentHistory = ({ payments, refetch, showToastMessage }) => {
  const [modal, setModal] = useState(false)
  const [transactionId, setTransactionId] = useState({})
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const confirmContents = {
    action: "fix transaction",
    resource: "",
  }
  const confirmAction = async () => {
    try {
      await fixTransaction(transactionId)
      showToastMessage({ success: true, message: "Fix action successful" })
      refetch()
    } catch (error) {
      showToastMessage({ success: true, message: "Fix action failed" })
    }
  }
  const { SearchBar } = Search
  const handleClickFix = async transactionId => {
    setModal(true)
    setTransactionId(transactionId)
    // setIsDisabled(true)
  }
  const getPaymentBrandIcon = paymentBrand => {
    switch (paymentBrand) {
      case "VISA":
        return { src: visacard, height: "11rem" }
      case "APPLE_PAY":
        return { src: "#", height: "11rem" }
      case "MASTER":
        return { src: mastercard, height: "11rem" }
      case "STC_PAY":
        return { src: stcpaylogo, height: "14rem" }
      case "MADA":
        return { src: madalogo, height: "16rem" }
      default:
        return { src: "", height: "16rem" }
    }
  }

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} payments
    </span>
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
        }}
        // style={{ color: "red" }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: payments.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
  }
  const defaultSorted = [
    {
      dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const paymentsListColumns = [
    {
      text: "MT ID",
      dataField: "merchantTransactionId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => {
        return (
          <CopyToClipboard text={payment?.merchantTransactionId || ""}>
            <span
              style={{
                display: "block",
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {cellContent}
            </span>
          </CopyToClipboard>
        )
      },
    },
    {
      text: "Purchased Package",
      dataField: "packageId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          <h5 className="font-size-14">
            {payment?.package.package_name_en || "-"}
          </h5>
        </>
      ),
    },
    {
      text: "Amount",
      dataField: "amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          {payment.amount ? (
            <h5 className="font-size-14 mb-1">
              {payment.amount} {payment.currency}
            </h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Offer",
      dataField: "amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          {payment.additionalCreditsSource &&
          payment.additionalCreditsSource !== "NIL" ? (
            <>
              <h5 className="font-size-14 mb-1">
                {/* {payment.additionalCreditsSource} */}
                {(payment.offerAmount / 100).toFixed(2)} {payment.currency}
              </h5>

              {/* <Badge className={` badge badge-soft-secondary`}>
                {capitalizeString(payment.additionalCreditsSource)}
              </Badge> */}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      text: "Offer Name",
      dataField: "additionalCreditsSource",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <Badge className={` badge badge-soft-secondary font-size-14`}>
          {capitalizeString(payment.additionalCreditsSource)}
        </Badge>
      ),
    },

    {
      text: "Offer Balance",
      dataField: "additionalCreditsUpdated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          <Badge
            className={` badge badge-soft-${
              payment.additionalCreditsUpdated ? "success" : "danger"
            }`}
            pill
          >
            {payment.additionalCreditsUpdated ? (
              <i className="font-size-16 mdi mdi-check" />
            ) : (
              <i className="font-size-16 mdi mdi-close" />
            )}
          </Badge>
        </>
      ),
    },
    {
      text: "Offer Transaction",
      dataField: "additionalCreditsUpdated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          <Badge
            className={` badge badge-soft-${
              payment.additionalTransactionCreated ? "success" : "danger"
            }`}
            pill
          >
            {payment.additionalTransactionCreated ? (
              <i className="font-size-16 mdi mdi-check" />
            ) : (
              <i className="font-size-16 mdi mdi-close" />
            )}
          </Badge>
        </>
      ),
    },
    {
      text: "Paid At",
      dataField: "createdAt",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <h5 className="font-size-14 mb-1">
          {formatStandard(payment.createdAt)}
        </h5>
      ),
    },

    {
      text: "Payment Brand",
      dataField: "paymentBrand",
      formatter: (cellContent, payment) => (
        <>
          {/* <i
            className={
              payment.paymentBrand !== "COD"
                ? "fab " + getPaymentBrandIcon(payment.paymentBrand) + " me-1"
                : "fab fas fa-money-bill-alt me-1"
            }
          />{" "} */}
          {payment.tokenSource === "APPLEPAY" && (
            <Badge className={` badge badge-soft-secondary p-2 me-2`}>
              <img src={applepay} alt="apple pay image" height="17rem" />
            </Badge>
          )}
          {getPaymentBrandIcon(payment.paymentBrand)?.src ? (
            <Badge className={` badge badge-soft-secondary p-2`}>
              <img
                src={getPaymentBrandIcon(payment.paymentBrand).src}
                alt={payment.paymentBrand}
                height={getPaymentBrandIcon(payment.paymentBrand).height}
              />
            </Badge>
          ) : (
            <Badge className={` badge badge-soft-secondary p-2`}>
              {payment.paymentBrand}
            </Badge>
          )}
          {/* {payment.paymentBrand} */}
        </>
      ),
    },
    {
      text: "Invoiced",
      dataField: "invoiceCreated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <Badge
          className={` badge badge-soft-${
            payment.invoiceCreated ? "success" : "danger"
          }`}
          pill
        >
          {payment.invoiceCreated ? (
            <i className="font-size-16 mdi mdi-check" />
          ) : (
            <i className="font-size-16 mdi mdi-close" />
          )}
        </Badge>
      ),
    },

    {
      text: "Payment",
      dataField: "paymentCreated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <Badge
          className={` badge badge-soft-${
            payment.paymentCreated ? "success" : "danger"
          }`}
          pill
        >
          {payment.paymentCreated ? (
            <i className="font-size-16 mdi mdi-check" />
          ) : (
            <i className="font-size-16 mdi mdi-close" />
          )}
        </Badge>
      ),
    },
    {
      text: "Credit Transaction",
      dataField: "creditTransactionCreated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <Badge
          className={` badge badge-soft-${
            payment.creditTransactionCreated ? "success" : "danger"
          }`}
          pill
        >
          {payment.creditTransactionCreated ? (
            <i className="font-size-16 mdi mdi-check" />
          ) : (
            <i className="font-size-16 mdi mdi-close" />
          )}
        </Badge>
      ),
    },
    {
      text: "Balance Added",
      dataField: "creditsUpdated",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <>
          <Badge
            className={` badge badge-soft-${
              payment.creditsUpdated ? "success" : "danger"
            }`}
            pill
          >
            {payment.creditsUpdated ? (
              <i className="font-size-16 mdi mdi-check" />
            ) : (
              <i className="font-size-16 mdi mdi-close" />
            )}
          </Badge>
        </>
      ),
    },

    {
      text: "Status",
      dataField: "isSuccessful",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <Badge
          className={`font-size-12 badge badge-soft-${
            payment.isSuccessful ? "success" : "danger"
          }`}
          pill
        >
          {payment.isSuccessful ? "Successful" : "Failed"}
        </Badge>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payment) => (
        <div className="d-flex gap-3">{renderMenu(payment)}</div>
      ),
    },
  ]

  const renderMenu = payment => {
    if (payment.isSuccessful === false) {
      return (
        <button disabled className="btn btn-light btn-rounded">
          <span className="me-2">Fix</span>
        </button>
      )
    }

    if (
      payment.isInvoiced &&
      !payment.invoiceCreated &&
      payment.paymentCreated &&
      payment.creditTransactionCreated &&
      payment.creditsUpdated &&
      payment.isSuccessful
    ) {
      return (
        <button disabled className="btn btn-light btn-rounded">
          <span className="me-2">Fix</span>
        </button>
      )
    }

    if (
      payment.invoiceCreated &&
      payment.paymentCreated &&
      payment.creditTransactionCreated &&
      payment.creditsUpdated &&
      payment.isSuccessful
    ) {
      return (
        <button disabled className="btn btn-light btn-rounded">
          <span className="me-2">Fix</span>
        </button>
      )
    }

    return (
      <>
        <button
          className="btn btn-light btn-rounded"
          id={"detailsTooltip" + payment.merchantTransactionId}
          onClick={() => {
            handleClickFix(payment.merchantTransactionId)
          }}
          // disabled={isDisabled}
        >
          <span className="me-2">Fix</span>
          <Badge pill className="bg-danger ms-1 font-size-12">
            {Math.abs(
              4 -
                [
                  payment.invoiceCreated,
                  payment.paymentCreated,
                  payment.creditTransactionCreated,
                  payment.creditsUpdated,
                ].filter(Boolean).length
            ) || ""}
          </Badge>{" "}
          <UncontrolledTooltip
            placement="top"
            target={"detailsTooltip" + payment.merchantTransactionId}
          >
            Fix (
            {Math.abs(
              4 -
                [
                  payment.invoiceCreated,
                  payment.paymentCreated,
                  payment.creditTransactionCreated,
                  payment.creditsUpdated,
                ].filter(Boolean).length
            ) || ""}
            ) payment errors
          </UncontrolledTooltip>
        </button>
      </>
    )
  }

  const toggle = () => {
    setModal(!modal)
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <ConfirmModal
        show={modal}
        confirmContents={confirmContents}
        onConfirmClick={confirmAction}
        onCloseClick={() => setModal(false)}
      />
      {/* <Container fluid> */}
      <Row>
        {/* <Col lg="12"> */}
        {/* <Card> */}
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={paymentsListColumns}
            data={payments}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={payments}
                  columns={paymentsListColumns}
                  bootstrap4
                  //   search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {/* <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                      <Row>
                        <Col lg={6}>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col lg={6}>
                          <div className="float-end">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive table-light invert-scroll">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              // selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover top-horizontal-scroll"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                            />
                          </div>
                          {payments.length === 0 && (
                            <EmptyState text="No payments Found" />
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-3">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </CardBody>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  )
}

PaymentHistory.propTypes = {
  payments: PropTypes.array,
  refetch: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default withRouter(PaymentHistory)
