import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  Row,
  Col,
  InputGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  FormText,
  Button,
  Spinner,
} from "reactstrap"
import {
  getCustomerDetails,
  getAllStations,
  createInvoice,
  endTrip,
  getVehicleDetails,
  getTripsByTripId,
  createCustomerTripInvoice
} from "helpers/backend_helper"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { addToTime } from "utils"

const FormEndTrip = ({
  close,
  user = {},
  trip,
  showToastMessage,
  refetchLogs = async () => {},
}) => {
  const [stations, setStations] = useState([])
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(user)
  const [vehicle, setVehicle] = useState({})

  const errorObj = {
    success: false,
    message: `Something went wrong`,
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      returnTime: "",
      endZone: trip.pickUpStationId,
    },

    onSubmit: values => {
      submitEndRide(values)
    },
  })

  // const addInvoice = async () => {
  //   const isInvoiced = trip.extraUsageInvoiceId
  //   const balance = parseFloat(userDetails.credits / 100)

  //   if (!isInvoiced && balance < 0) {
  //     const data = [
  //       {
  //         amount: Math.abs(balance),
  //         name: "Extra Usage",
  //       },
  //     ]

  //     const userId = userDetails.userId

  //     await createInvoice(userId, data)
  //   } else {
  //     return
  //   }
  // }
 //////////////////////////////// new
   const addInvoice = async () => {
    const { tripId } = trip

    const userId = userDetails.userId
    const user = await fetchUserDetails(userId)

    const tripResponse = await getTripsByTripId(tripId, userDetails.userId)
    const newTripData = tripResponse.data.find(t => t?.tripId === trip.tripId)

    const { totalFare } = newTripData

    const balance = parseFloat(user.credits / 100)

    // const totalFare = 0

    const invoiceAmount =
      totalFare - Math.abs(user.credits) <= 0
        ? totalFare / 100
        : Math.abs(user.credits) / 100

    if (invoiceAmount > 0 && balance < 0) {
      const data = [
        {
          amount: Math.abs(invoiceAmount),
          name: "Extra Usage",
        },
      ]

      await createCustomerTripInvoice(userId, data)
    } else {
      return
    }
  }

  const submitEndRide = async values => {
    setLoading(true)
    try {
      const { returnTime: valueReturnTime, endZone } = values

      let returnTime = Date.now()

      if (valueReturnTime) {
        returnTime = addToTime(trip.pickUpTime, valueReturnTime)
      }

      const { lat, lng } = vehicle

      const payload = {
        userId: userDetails.userId,
        userLat: lat,
        userLng: lng,
        tripId: trip?.tripId || trip?.tripRefId,
        qrString: endZone,
        opsAccountGroup: true,
        returnTime: returnTime,
      }
      await endTrip(payload)
      await addInvoice()
      showToastMessage({
        success: true,
        message: `Ride Ended successfully`,
      })
    } catch (error) {
      showToastMessage(errorObj)
    }
    await refetchLogs(trip.tripId)
    close()
    setLoading(false)
  }

  const fetchUserDetails = async userId => {
    try {
      const response = await getCustomerDetails(userId)
      const user = response.data
      setUserDetails(user)
      return response.data
    } catch (error) {
      showToastMessage({ ...errorObj, message: "Error fetching user details" })
    }
  }

  const fetchStations = async () => {
    try {
      const response = await getAllStations()
      const stations = response.data
      setStations(stations)
    } catch (error) {
      showToastMessage({
        ...errorObj,
        message: "Error fetching zones",
      })
    }
  }

  const fetchVehicleDetails = async () => {
    try {
      const response = await getVehicleDetails(trip.scooterId)
      setVehicle(response.data)
    } catch (error) {
      showToastMessage(errorObj)
    }
  }

  useEffect(() => {
    fetchStations()
    fetchVehicleDetails()
    if (isEmpty(userDetails)) {
      fetchUserDetails(trip.userId)
    }
  }, [])
  return (
    <>
      <Row>
        <Col lg={12}>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Label for="startStation">Start Station</Label>
            <InputGroup className="mb-3">
              <Input
                type="text"
                id="startStation"
                defaultValue={trip.pickUpStationId}
                disabled
              />
            </InputGroup>
            <Label>End Station :</Label>
            <InputGroup className="mb-3">
              <select
                name="endZone"
                className="form-select"
                value={validation.values.endZone}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                // invalid={
                //   validation.touched.endZone && validation.errors.endZone
                //     ? true
                //     : undefined
                // }
              >
                <option key={"endZoneDefault"} value={trip.pickUpStationId}>
                  {trip.pickUpStationId}
                </option>
                {stations
                  .filter(st => st.stationName !== trip.pickUpStationId)
                  .map((station, i) => (
                    <option key={i} value={station.stationName}>
                      {station.stationName}
                    </option>
                  ))}
              </select>

              {validation.touched.endZone && validation.errors.endZone ? (
                <FormFeedback type="invalid">
                  {validation.errors.endZone}
                </FormFeedback>
              ) : null}
            </InputGroup>

            <Label>Trip Duration :</Label>
            <div className="mb-3">
              <InputGroup className="mb-1">
                <Input
                  className="form-control"
                  name="returnTime"
                  type="number"
                  placeholder="Enter minutes"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.returnTime || ""}
                  // invalid={
                  //   validation.touched.returnTime &&
                  //   validation.errors.returnTime
                  //     ? true
                  //     : undefined
                  // }
                />
                <Label
                  className="input-group-text bg-light"
                  style={{ minWidth: "100px" }}
                >
                  minutes
                </Label>

                {validation.touched.returnTime &&
                validation.errors.returnTime ? (
                  <FormFeedback type="invalid">
                    {validation.errors.returnTime}
                  </FormFeedback>
                ) : null}
              </InputGroup>
              <FormText color="muted" className="p-0">
                Leave empty to take the current time as trip end
              </FormText>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                color="danger"
                className="w-md"
                disabled={loading}
                //
              >
                {loading ? <Spinner size="sm" /> : "End Ride"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      <Row></Row>
    </>
  )
}
FormEndTrip.propTypes = {
  close: PropTypes.func,
  user: PropTypes.object,
  trip: PropTypes.object,
  showToastMessage: PropTypes.func,
  refetchLogs: PropTypes.func,
}

export default FormEndTrip
