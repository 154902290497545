import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Row,
  Col,
  Label,
  InputGroup,
  Input,
  Button,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap"
import { addCustomerBalance, updateTrip } from "helpers/backend_helper"
import { formatSeconds } from "../../../utils/formatDate"

import * as Yup from "yup"
import { useFormik } from "formik"

const FormRefundTrip = ({ trip, refetch, close, showToastMessage }) => {
  const [loading, setLoading] = useState(false)
  const maximumCost = trip.totalFare / 100
  const rangeValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cost: "",
    },
    validationSchema: Yup.object().shape({
      cost: Yup.number()
        .required(`Amount required`)
        .min(0.1, `This value should be between 0.1 and ${maximumCost}`)
        .max(
          maximumCost,
          `This value should be between 0.1 and ${maximumCost}`
        ),
    }),
    onSubmit: values => {
      handleSubmit(values.cost)
    },
  })

  const handleSubmit = async cost => {
    setLoading(true)
    try {
      const credits = cost * 100
      const data = {
        userId: trip.userId,
        paymentAmount: `${credits} * 100`,
      }
      await addCustomerBalance(data)
      const newFare = parseInt(trip.totalFare - credits)

      const updateTripData = {
        userId: trip.userId,
        totalFare: newFare,
      }

      await updateTrip(trip.tripId, updateTripData)
      await refetch()
      showToastMessage({ success: true, message: "Refund successful" })
      close()
    } catch (error) {
      showToastMessage({ success: false, message: "Refund failed" })
    }
    setLoading(false)
  }
  return (
    <>
      <Row>
        <dl className="text-muted row ">
          <dt className="col-sm-6">Total Duration:</dt>
          <dd className="col-sm-6">
            {formatSeconds(trip?.totalDuration / 1000)}
          </dd>
          <dt className="col-sm-6">Total Fare:</dt>
          <dd className="col-sm-6">{trip?.totalFare / 100} SAR</dd>
        </dl>
      </Row>
      <Row className="my-4">
        <Form
          onSubmit={e => {
            e.preventDefault()
            rangeValidation.handleSubmit()
            return false
          }}
        >
          <Label>Amount :</Label>
          <InputGroup className="mb-3">
            <Label className="input-group-text" style={{ minWidth: "120px" }}>
              SAR
            </Label>

            <Input
              className="form-control"
              type="number"
              name="cost"
              placeholder={`Amount between 0.1 - ${maximumCost} SAR`}
              onChange={rangeValidation.handleChange}
              onBlur={rangeValidation.handleBlur}
              value={rangeValidation.values.cost || ""}
              // invalid={
              //   rangeValidation.touched.cost && rangeValidation.errors.cost
              //     ? true
              //     : false
              // }
            />
            {rangeValidation.touched.cost && rangeValidation.errors.cost ? (
              <FormFeedback type="invalid">
                {rangeValidation.errors.cost}
              </FormFeedback>
            ) : null}
          </InputGroup>

          <div className="text-center">
            <Button
              type="submit"
              color="success"
              className="w-md"
              disabled={
                !(rangeValidation.isValid && rangeValidation.dirty) || loading
              }
            >
              {loading ? <Spinner size="sm" /> : "Refund"}
            </Button>
          </div>
        </Form>
      </Row>
    </>
  )
}

FormRefundTrip.propTypes = {
  trip: PropTypes.object,
  refetch: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormRefundTrip
