import axios from "axios"
import { getFirebaseBackend } from "./firebase_helper"

//apply base url for axios
const APP_SERVER_URL = process.env.REACT_APP_API_URL
const MAIN_CLIENT_URL = process.env.REACT_APP_MAIN_CLIENT_URL
const INTERNAL_SERVER_URL = process.env.REACT_APP_INTERNAL_API_URL
const TCP_SERVER_URL = process.env.REACT_APP_TCP_SERVER

export const appServer = axios.create({
  baseURL: APP_SERVER_URL,
})

export const mainClient = axios.create({
  baseURL: MAIN_CLIENT_URL,
  headers: {
    "x-access-token": process.env.REACT_APP_MAIN_CLIENT_TOKEN,
  },
})

export const tcpServer = axios.create({
  baseURL: TCP_SERVER_URL,
})

export const internalServer = axios.create({
  baseURL: INTERNAL_SERVER_URL,
})

appServer.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

mainClient.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

tcpServer.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

internalServer.interceptors.response.use(
  response => response,
  async function (error) {
    const msg = error?.response?.data?.messages ?? ""
    //
    if (
      error.response &&
      error.response.status === 401 &&
      msg === "Invalid/Missing token"
    ) {
      const fireBaseBackend = getFirebaseBackend()
      const newToken = await fireBaseBackend.getCurrentIdToken()
      const originalRequest = error.config
      originalRequest.headers.Authorization = `Bearer ${newToken}`
      return internalServer(originalRequest)
    }
    return Promise.reject(error)
  }
)

internalServer.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem("accessToken")
    config.headers.Authorization = ""
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    config.headers["Accept-Language"] = localStorage.getItem("I18N_LANGUAGE")
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export async function get(url, baseUrl = internalServer, config = {}) {
  return await baseUrl.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, baseUrl = internalServer, config = {}) {
  return baseUrl
    .post(url, { ...data }, { ...config }, (baseUrl = internalServer))
    .then(response => response.data)
}

export async function postArray(
  url,
  data,
  baseUrl = internalServer,
  config = {}
) {
  return baseUrl
    .post(url, [...data], { ...config }, (baseUrl = internalServer))
    .then(response => response.data)
}

export async function put(url, data, baseUrl = internalServer, config = {}) {
  return baseUrl
    .put(url, { ...data }, { ...config }, (baseUrl = internalServer))
    .then(response => response.data)
}

export async function patch(url, data, baseUrl = internalServer, config = {}) {
  return baseUrl
    .patch(url, { ...data }, { ...config }, (baseUrl = internalServer))
    .then(response => response.data)
}

export async function del(url, baseUrl = internalServer, config = {}) {
  return await baseUrl
    .delete(url, { ...config })
    .then(response => response.data)
}
