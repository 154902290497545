import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import logoSecondary from "../../assets/images/logo-secondary.png"

const EmptyState = ({ text = "" }) => {
  return (
    <Row>
      <Col lg={12} className="text-center m-auto">
        <img src={logoSecondary} alt="logo" height="100" />
        <p className="text-muted">{text}</p>
      </Col>
    </Row>
  )
}
EmptyState.propTypes = {
  text: PropTypes.string,
}
export default EmptyState
