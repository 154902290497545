export const getTripStatusVariant = status => {
  switch (status) {
    case "active":
      return "success"
    case "blocked":
      return "danger"
    case "disabled":
      return "secondary"
    default:
      return "secondary"
  }
}

export const getBatteryVariant = battery => {
  switch (true) {
    case battery <= 30:
      return "danger"
    case battery > 30 && battery <= 40:
      return "warning"
    case battery > 40 && battery <= 100:
      return "success"
    default:
      return "success"
  }
}

export const getBatteryIconVariant = battery => {
  switch (true) {
    case battery <= 30:
      return "mdi mdi-battery-low text-danger"
    case battery > 30 && battery <= 40:
      return "mdi mdi-battery-medium text-warning"
    case battery > 40 && battery <= 100:
      return "mdi mdi-battery-high text-success"
    default:
      return "text-success"
  }
}
