export const roles = [
  {
    label: "Accountant",
    value: "accountant",
    color: "info",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-primary",
  },
  {
    label: "Care Agent",
    value: "care_agent",
    color: "primary",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-primary",
  },
  {
    label: "Developer",
    value: "developer",
    color: "secondary",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Admin",
    value: "admin",
    color: "dark",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-dark",
  },
  {
    label: "Reseller Admin",
    value: "reseller_admin",
    color: "info",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-info",
  },
  {
    label: "Reseller Employee",
    value: "reseller_employee",
    color: "info",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-info",
  },
]
