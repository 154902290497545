import React, { useState } from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import {
  Col,
  Container,
  Row,
  Alert,
  Card,
  Input,
  InputGroup,
  Label,
  NavItem,
  NavLink,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { withRouter } from "react-router-dom"
import classnames from "classnames"

import "react-phone-input-2/lib/high-res.css"
import PhoneInput from "react-phone-input-2"
import { getVehicleDetails, searchCustomers } from "helpers/backend_helper"
import LoadingButton from "components/Common/LoadingButton"
import Restricted from "components/Common/Restricted"
import { searchDescriptions } from "./search-descriptions"

const SearchCustomers = ({ history }) => {
  const [hasError, setHasError] = useState(false)
  const [errMessage, setErrMessage] = useState(searchDescriptions.phoneError)
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const [userId, setUserId] = useState("")
  const [vehicleId, setVehicleId] = useState("")
  const [description, setDescription] = useState(searchDescriptions.phoneDesc)

  const [activeTab, setActiveTab] = useState("phone")

  const handlePhoneChange = async (value, event) => {
    // if character didn't register in the input field
    if(value === phone) {
      setHasError(true)
      setErrMessage("Only English Numbers are allowed")
      return
    }
    setHasError(false)
    setPhone(value)
    setCode(event.dialCode)
  }

  const handleUserIdChange = async event => {
    setUserId(event.target.value)
  }

  const handleVehicleIdChange = async event => {
    setVehicleId(event.target.value)
  }

  const handleTabChange = tab => {
    setActiveTab(tab)
    setHasError(false)
    setDescription(searchDescriptions[`${tab}Desc`])
    setErrMessage(searchDescriptions[`${tab}Error`])
  }

  const handleClick = async () => {
    let response
    let serverUserId
    try {
      if (activeTab === "userId") {
        response = await searchCustomers(null, userId)
        serverUserId = response.data.userId
      } else if (activeTab === "phone") {
        const digits = phone.toString().substr(code.length)
        response = await searchCustomers(digits, null)
        serverUserId = response.data.userId
      } else if (activeTab === "vehicle") {
        const response = await getVehicleDetails(vehicleId)
        serverUserId = response.data.lastUser
      }
      if (serverUserId) {
        history.push(`/customer-detail/${serverUserId}`)
      }
    } catch (error) {
      setHasError(true)
      if (error.response?.data?.status === 404 && activeTab === "vehicle") {
        setErrMessage(searchDescriptions.vehicleNotFound)
      } else {
        setErrMessage(searchDescriptions.someWrong)
      }
    }
  }

  const phoneInput = () => (
    <PhoneInput
      masks={{ sa: ".. ... ...." }}
      country={"sa"}
      value={phone}
      onChange={handlePhoneChange}
      inputProps={{
        autoFocus: true,
      }}
      inputClass={"form-control"}
      inputStyle={{
        width: "100%",
        margin: 0,
        // height: "32px",
      }}
      placeholder={"+966 5xx xx xx"}
    />
  )

  const vehicleInput = () => (
    <InputGroup>
      <Label className="input-group-text bg-light">
        <i className="mdi mdi-car align-middle"></i>
      </Label>
      <Input
        value={vehicleId}
        onChange={handleVehicleIdChange}
        className="form-control"
        name="vehicle"
        placeholder="Enter Vehicle ID"
        autoFocus
        type="text"
      />
    </InputGroup>
  )

  const userIdInput = () => (
    <InputGroup>
      <Label className="input-group-text bg-light">
        <i className="mdi mdi-account align-middle"></i>
      </Label>
      <Input
        value={userId}
        onChange={handleUserIdChange}
        className="form-control"
        name="User ID"
        placeholder="Enter User ID"
        autoFocus
        type="text"
      />
    </InputGroup>
  )

  const renderActiveTab = () => {
    switch (activeTab) {
      case "phone":
        return phoneInput()

      case "userId":
        return userIdInput()

      case "vehicle":
        return vehicleInput()

      default:
        break
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers Search | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Customers" breadcrumbItem="Search Customers" />
          <Row className="justify-content-center text-center w-100">
            <Col xl={7} lg={11} md={6}>
              <div className="mb-4">
                <h4>Search Customer</h4>
                <p className="text-muted">{description}</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col className="mb-4" xl={9}>
              <ul
                className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTab === "phone",
                    })}
                    onClick={() => {
                      handleTabChange("phone")
                    }}
                  >
                    Phone
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({
                      active: activeTab === "userId",
                    })}
                    onClick={() => {
                      handleTabChange("userId")
                    }}
                  >
                    User ID
                  </NavLink>
                </NavItem>
                <Restricted to="customers:VIEW_CUSTOMER_TRANSACTIONS">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "vehicle",
                      })}
                      onClick={() => {
                        handleTabChange("vehicle")
                      }}
                    >
                      Vehicle
                    </NavLink>
                  </NavItem>
                </Restricted>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col xl={4} lg={6} md={5} sm={6} xs={9}>
              {renderActiveTab()}
            </Col>

            <Col xl={1} lg={1} md={1} sm={1} xs={3}>
              <div className="">
                <LoadingButton
                  type="submit"
                  className="btn btn-success"
                  onClick={handleClick}
                >
                  Search
                </LoadingButton>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-4">
            <Col xl={6} lg={7} md={5} sm={6} xs={9}>
            {hasError && <Alert color="danger">{errMessage}</Alert>}
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
SearchCustomers.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SearchCustomers)
