const convert = (value, low1, high1, low2 = 0, high2 = 100) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export const convertBikeBattery = batt => {
  if (batt === "NIL") {
    return 0
  } else {
    return parseInt(convert(batt, 320, 420))
  }
}

export const convertScooterBattery = batt => {
  if (batt === "-1") {
    return 0
  } else {
    return parseInt(convert(batt, 31, 42))
  }
}
