import { call, put, takeEvery } from "redux-saga/effects"

// Vehicles Redux States
import { GET_USERS, EDIT_USER } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  editUserFail,
  editUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getAllUsers, updateUser } from "../../helpers/backend_helper"

function* fetchUsers() {
  try {
    const response = yield call(getAllUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* editUser({ uid, updates }) {
  try {
    const response = yield call(updateUser, uid, updates)

    yield put(editUserSuccess(response))
  } catch (error) {
    yield put(editUserFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers), yield takeEvery(EDIT_USER, editUser)
}

export default usersSaga
