import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import {
  deleteOperationZone,
  getVehiclesByStationId,
  getZoneB2bPartners,
  getZones,
  moveControlZones,
  movePartnersToStation,
  updateMultiVehicles,
} from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import LoadingTable from "components/Loading/loading-table"

const DeleteZoneModal = ({ zone, refetch }) => {
  const [opZoneOptions, setOpZoneOptions] = useState([])
  const [partners, setPartners] = useState([])
  const [newZone, setNewZone] = useState("")
  const [zoneVehicles, setZoneVehicles] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [action, setAction] = useState("move")

  let cZones = []
  if (zone?.controlZones) {
    cZones = Object.values(zone.controlZones)
  }
  let hasZones = !!cZones.length
  let isMoveCzones = hasZones && action === "move"

  const fetchZones = async () => {
    try {
      const { data } = await getZones()
      setOpZoneOptions(
        data
          .filter(z => z.zoneName !== zone.zoneName)
          .map(z => ({ label: z.zoneName, value: z.zoneName }))
      )
    } catch (err) {
      showToastMessage({
        success: false,
        message: err.response?.data?.messages || "Error Fetching Zones",
      })
    }
  }

  const fetchPartners = async () => {
    try {
      const { data } = await getZoneB2bPartners(zone.zoneName)
      setPartners(data)
      setHasChildren(
        cZones.length > 0 || zoneVehicles.length > 0 || data.length > 0
      )
    } catch (err) {
      showToastMessage({
        success: false,
        message: err.response?.data?.messages || "Error Fetching Partners",
      })
    }
  }

  const fetchZoneVehicles = async () => {
    try {
      setLoading(true)
      const { data } = await getVehiclesByStationId(zone.zoneName)
      setZoneVehicles(data)
      setLoading(false)
      setHasChildren(
        cZones.length > 0 || partners.length > 0 || data.length > 0
      )
    } catch (err) {
      showToastMessage({
        success: false,
        message: err.response?.data?.messages || "Error Fetching Vehicles",
      })
    }
  }

  const handleDelete = async () => {
    await deleteOperationZone(zone.zoneName)
  }

  const handleMoveCZones = async () => {
    if (hasZones) {
      await moveControlZones({
        srcZoneName: zone.zoneName,
        dstZoneName: newZone,
      })
    }
  }

  const handleMoveVehicles = async dstZoneName => {
    const imeis = zoneVehicles.map(v => v.IMEI)
    if (imeis && imeis.length) {
      await updateMultiVehicles({
        ids: imeis,
        field: "stationId",
        value: dstZoneName,
      })
    }
  }

  const handleMovePartners = async dstZoneName => {
    const partnerNames = partners.map(v => v.name)
    if (partnerNames && partnerNames.length) {
      await movePartnersToStation({ partnerNames, dstZoneName })
    }
  }

  const handleSubmit = async () => {
    try {
      const shouldZoneSelect = (zoneVehicles.length || isMoveCzones) && !newZone

      if (cZones.length && !action) {
        throw new Error("Choose how to handle Control Zones")
      }

      if (shouldZoneSelect) {
        throw new Error("Please select a Zone")
      }

      if (action === "move") {
        await handleMoveCZones()
      }

      await handleMoveVehicles(newZone)
      await handleMovePartners(newZone)
      await handleDelete()

      showToastMessage({
        success: true,
        message: "Zone deleted successfully",
      })
      refetch()
    } catch (err) {
      showToastMessage({
        success: false,
        message:
          err.message || err.response?.data?.messages || "Something went wrong",
      })
    }
  }

  const handleRadio = act => {
    setAction(act)
  }

  useEffect(() => {
    fetchZones()
    fetchZoneVehicles()
    fetchPartners()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <div>
          <Row>
            <Col>
              {hasChildren ? (
                <p className="font-size-14">
                  <b>{zone?.zoneName}</b> has Vehicles, Partners and Control
                  Zones that must be handled before deletion
                </p>
              ) : (
                <p className="font-size-14">
                  <b>{zone?.zoneName}</b> has no attached Vehicles, Partners or
                  Control Zones and can be deleted safely
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <ul className="mx-2 my-0">
              <li>
                <span className="font-size-14">
                  <b>{zoneVehicles.length} Vehicles</b> must be moved to another
                  zone
                </span>
              </li>

              <li>
                <span className="font-size-14">
                  <b>{partners.length} B2B Partners</b> have this zone as the
                  default zone and must be moved to another zone
                </span>
              </li>
              <li>
                <span className="font-size-14">
                  <b>{cZones.length} Control Zones</b> must be either moved or
                  deleted:
                </span>
              </li>
            </ul>
          </Row>

          {cZones.length > 0 && (
            <Row className="mt-1">
              <Col lg={6}>
                <FormGroup check>
                  <Input
                    onChange={() => handleRadio("move")}
                    defaultChecked={true}
                    type="radio"
                    name="radio2"
                  />
                  <Label>Move Control Zones</Label>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup check>
                  <Input
                    onChange={() => handleRadio("delete")}
                    type="radio"
                    name="radio2"
                  />
                  <Label>Delete Control Zones</Label>
                </FormGroup>
              </Col>
            </Row>
          )}

          {(isMoveCzones || zoneVehicles.length > 0 || partners.length > 0) && (
            <Row className="mb-2 mt-2">
              <Label className="mb-0">
                New Operation Zone for:
                <b style={{ color: "#CF5A5A" }}>
                  <ul>
                    {isMoveCzones && (
                      <li>{cZones.length + " Control Zones "}</li>
                    )}
                    {zoneVehicles.length > 0 && (
                      <li>{zoneVehicles.length + " Vehicles"}</li>
                    )}
                    {partners.length > 0 && (
                      <li>{partners.length + " B2B Partners"}</li>
                    )}
                  </ul>
                </b>
              </Label>
              <Select
                options={opZoneOptions}
                onChange={op => setNewZone(op.value)}
              />
            </Row>
          )}

          <Row>
            <div className="d-flex justify-content-end">
              <Button
                onClick={handleSubmit}
                className="btn mt-2"
                color="danger"
              >
                Delete
              </Button>
            </div>
          </Row>
        </div>
      )}
    </>
  )
}

DeleteZoneModal.propTypes = {
  zone: PropTypes.object,
  refetch: PropTypes.func,
}

export default DeleteZoneModal
