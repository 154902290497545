import React from "react"
import PropTypes from "prop-types"
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Col,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { vehicleStatus } from "../../constants/vehicleStatus"

const ColorLegend = ({ legend }) => {
  return (
    <div>
      <i
        className={`mdi mdi-circle align-middle me-1`}
        style={{ color: legend.colorHex }}
      />
      {legend.label}
    </div>
  )
}

const HelpPopover = () => {
  return (
    <UncontrolledPopover placement="left" target="helpPopover" trigger="click">
      <PopoverHeader>Vehicle Status</PopoverHeader>
      <PopoverBody>
        {vehicleStatus.map((status, i) => (
          <ColorLegend key={i} legend={status} />
        ))}
      </PopoverBody>
      <PopoverHeader>Zones</PopoverHeader>
      <PopoverBody>
        <ColorLegend
          legend={{ label: "Forbidden Zone", colorHex: "#FF0000" }}
        />
        <ColorLegend legend={{ label: "Operation Zone", colorHex: "#000" }} />
      </PopoverBody>
    </UncontrolledPopover>
  )
}

ColorLegend.propTypes = {
  legend: PropTypes.object,
}
export default HelpPopover
