import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap"
import TableContainer from "components/Common/Table/table-container"
import ConfirmModal from "components/Common/ConfirmModal"
import { deleteVoucher } from "../../../helpers/backend_helper"
import { showToastMessage } from "../../../components/Common/ToastWrapper"
import LoadingTable from "components/Loading/loading-table"
import FormVoucher from "components/User/FormVoucher"
import { StartDate, Status } from "../Discounts/discounts-col"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
const VouchersTable = ({ vouchers, refetch, tabLoading }) => {
  const [voucher, setVoucher] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const confirmContents = {
    action: "delete",
    resource: "voucher",
  }
  const toggle = () => {
    setModal(!modal)
  }

  const handleEditVoucher = arg => {
    setIsEdit(true)
    const voucher = arg
    setVoucher(voucher)
    toggle()
  }

  const handleDeleteVoucher = voucher => {
    setConfirmDelete(true)
    setVoucher(voucher)
  }

  const confirmDeleteVoucher = async () => {
    try {
      await deleteVoucher(voucher.voucherId)
      showToastMessage({ success: true, message: "Voucher deleted" })
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
    refetch({ force: true })
    setConfirmDelete(false)
  }
  const handleVoucherClick = () => {
    setVoucher()
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Restricted to="vouchers:VIEW_VOUCHER">
                <Link
                  to={`/voucher-details/${cellProps.row.original.voucherId}`}
                  className="text-success"
                  onClick={() => {
                    const voucherData = cellProps.row.original
                    handleDeleteVoucher(voucherData)
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="detailstooltip" />
                  <UncontrolledTooltip placement="top" target="detailstooltip">
                    Details
                  </UncontrolledTooltip>
                </Link>
              </Restricted>

              <Restricted to="vouchers:UPDATE_VOUCHER">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const voucherData = cellProps.row.original
                    handleEditVoucher(voucherData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </Restricted>

              <Restricted to="vouchers:DELETE_VOUCHER">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const voucherData = cellProps.row.original
                    handleDeleteVoucher(voucherData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </Restricted>
            </div>
          )
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },

      {
        Header: "Value (SAR)",
        accessor: "value",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "isActive",
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Expiry Span",
        accessor: "expiryType",
        Cell: cellProps => {
          return (
            <p>
              {cellProps.row.original.expiryValue}{" "}
              {cellProps.row.original.expiryType}
            </p>
          )
        },
      },
      {
        Header: "Redeem Count",
        accessor: "redeemCount",
      },

      {
        Header: "Code Count",
        accessor: "codeCount",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      ,
    ],
    []
  )
  return (
    <Restricted to="vouchers:VIEW_ALL_VOUCHERS" fallback={<AccessDenied />}>
      <Card>
        <CardBody>
          <h4 className="header-title mt-0 mb-1">Vouchers</h4>
          <p className="sub-header">View or create new vouchers</p>
          <hr />
          {tabLoading ? (
            <LoadingTable />
          ) : (
            <Row>
              <ConfirmModal
                show={confirmDelete}
                confirmContents={confirmContents}
                onConfirmClick={confirmDeleteVoucher}
                onCloseClick={() => setConfirmDelete(false)}
              />
              <Col lg="12">
                <TableContainer
                  columns={columns}
                  data={vouchers}
                  isGlobalFilter={true}
                  isAddVoucherList={true}
                  handleVoucherClick={handleVoucherClick}
                  customPageSize={10}
                  className="custom-header-css"
                  defaultSort={"metadata.lastSignInTime"}
                />

                <Modal isOpen={modal} toggle={toggle} size="lg">
                  <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? "Edit Voucher" : "Add Voucher"}
                  </ModalHeader>
                  <ModalBody>
                    <FormVoucher
                      toggle={toggle}
                      voucher={voucher}
                      isEdit={isEdit}
                      refetch={refetch}
                    />
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Restricted>
  )
}
VouchersTable.propTypes = {
  vouchers: PropTypes.array,
  tabLoading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default VouchersTable
