import { showToastMessage } from "components/Common/ToastWrapper"
import { getVouchers } from "helpers/backend_helper"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Button, Col, Row, Table } from "reactstrap"

const Vouchers = () => {
  const [vouchers, setVouchers] = useState([])

  const fetchVouchers = async () => {
    const { data } = await getVouchers()
    if (data.length > 0) {
      return data
    }
  }

  useEffect(() => {
    fetchVouchers().then(v => {
      console.log(v)
      if (!v) {
        showToastMessage({ success: false, message: "no vouchers found" })
      }

      setVouchers(v)
    })
  }, [])

  return (
    <>
      <Table style={{ fontSize: 20 }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Value</th>
            <th>Redeem Count</th>
            <th>Redeem Limit</th>
          </tr>
        </thead>

        <tbody>
          {vouchers.map((v, i) => (
            <tr key={i}>
              <td>{v.title}</td>
              <td>{v.type}</td>
              <td>{v.value}</td>
              <td>{v.redeemCount}</td>
              <td>{v.redeemLimit}</td>
              <td>
                <Link to={`/voucher-details/${v.voucherId}`}>Details</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default Vouchers
