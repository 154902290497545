/* VEHICLES */
export const GET_VEHICLES = "GET_VEHICLES"
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS"
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL"

export const SET_VEHICLE = "SET_VEHICLE"

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS"
export const GET_VEHICLE_DETAILS_SUCCESS = "GET_VEHICLE_DETAILS_SUCCESS"
export const GET_VEHICLE_DETAILS_FAIL = "GET_VEHICLE_DETAILS_FAIL"

export const EDIT_VEHICLE = "EDIT_VEHICLE"
export const EDIT_VEHICLE_SUCCESS = "EDIT_VEHICLE_SUCCESS"
export const EDIT_VEHICLE_FAIL = "EDIT_VEHICLE_FAIL"
