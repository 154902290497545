import React from "react"
import CopyToClipboard from "./CopyToClipboard"
import PropTypes from "prop-types"
const ColorPreview = ({ color, handleEdit }) => {
  return (
    <span className="d-flex">
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: color,
          marginRight: "10px",
        }}
      ></div>
      <CopyToClipboard text={color} />
      <div style={{ maxWidth: "150px" }} className="font-size-12 text-truncate">
        {color}
      </div>
      <div className="mt-8" style={{ marginTop: "-4px" }}>
        <i
          className={`mdi mdi-pencil mx-1 font-size-18`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleEdit()
          }}
        ></i>
      </div>
    </span>
  )
}

ColorPreview.propTypes = {
  color: PropTypes.string,
  handleEdit: PropTypes.func,
}

export default ColorPreview
