import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { getCurrentDuration, formatSeconds } from "utils"
import { isEmpty } from "lodash"
function SkeletonCardTripSummary() {
  return (
    <React.Fragment>
      <Row>
        {/* <Col lg="12"> */}
        <Card
          className={`w-100 placeholder placeholder-wave bg-light`}
          // style={{ zIndex: 1 }}
        >
          <CardBody>
            <div className={`text-center text-dark font-size-16`}>
              <Row>
                <Col sm="4" className="my-2">
                  <div>
                    <i
                      className={`mdi mdi-cash-multiple font-size-22 text-muted`}
                    />
                    <p className={`mb-1 text-muted`}>Total Fare</p>
                    <h3 className={`text-light`}>-</h3>
                  </div>
                </Col>
                <Col sm="4" className="my-2">
                  <div className="mt-4 mt-sm-0">
                    <i
                      className={`mdi mdi-clock-outline font-size-22 text-muted`}
                    />

                    <p className={`mb-1 text-muted`}>Duration</p>

                    <h3 className={`text-light`}>
                      {/* {parseInt(trip.totalDuration / 60000)} min(s) */}
                      <span>-</span>
                    </h3>
                  </div>
                </Col>
                <Col sm="4" className="my-2">
                  <div className="mt-4 mt-sm-0">
                    <i
                      className={`mdi mdi-car-brake-parking font-size-22 text-muted`}
                    />
                    <p className={`mb-1 text-muted`}>Parking Time</p>
                    <h3 className={`text-light`}>-</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        {/* </Col> */}
      </Row>
    </React.Fragment>
  )
}

export default SkeletonCardTripSummary
