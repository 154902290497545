import React, { useState, useEffect } from "react"
import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  UncontrolledCollapse,
} from "reactstrap"
import { authProtectedRoutes } from "routes"
import { getAllPermissions } from "helpers/backend_helper"

const FormAddRole = () => {
  const [filterValue, setFilterValue] = useState("")
  const [permissions, setPermissions] = useState([])
  const [list, setList] = useState([])

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState([])

  // const resources = [
  //   { name: "customer", permissions: [] },
  //   { name: "user", permissions: [] },
  //   { name: "trip", permissions: [] },
  //   { name: "vehicle", permissions: [] },
  //   // { name: "config", permissions: [] },
  //   { name: "agreement", permissions: [] },
  //   { name: "deposit", permissions: [] },
  // ]

  const fetchPermissions = async () => {
    const response = await getAllPermissions()
    const permissions = response.data

    let resources = []

    permissions.forEach(permission => {
      console.log("fetchPermissions -> permission", permission)
      const [name, action] = permission.split(":")
      console.log("fetchPermissions -> name", name)
      const found = resources.findIndex(el => el.name === name)
      console.log("fetchPermissions -> found", found)
      // const found = resource?.length;
      console.log("fetchPermissions -> found", found)
      if (found !== -1) {
        resources[found] = {
          ...resources[found],
          permissions: [...resources[found].permissions, action],
        }
        // console.log("fetchPermissions -> resources[found]", resources[found])
      } else {
        resources.push({ name, permissions: [action] })
      }
    })
    console.log("fetchPermissions -> resources", resources)
    // if (resources) {
    //   resources.forEach(resource => {
    //     if (permission.toLowerCase().includes(resource.name)) {
    //       resource.permissions.push(permission)
    //     }
    //   })
    // } else {

    // }
    // console.log("fetchPermissions -> resources", resources)

    // permissions.forEach(p => {
    //   const split = p.split("_")
    //   //   const entity = split[split.length - 1]
    //   const entity = split.slice(1, -1)
    //   resources.add(entity)
    // })
    // console.log("fetchPermissions -> resources", resources)
    // // const actions = Object.assign(...Array.from(resources, v => ({ [v]: "" })))
    // console.log("fetchPermissions -> actions", actions)

    // permissions.forEach(p => {
    //   const split = p.split("_")
    //   const entity = split[split.length - 1]
    //   actions[entity] = { permission: split.slice(0, -1).join("_") }
    // })
    // console.log("fetchPermissions -> actions", actions)
    // console.log("fetchPermissions -> options", resources)
    setPermissions(resources)
  }

  useEffect(() => {
    fetchPermissions()
  }, [])

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(list.map(li => li.id))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClick = e => {
    const { id, checked } = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id))
    }
  }

  useEffect(() => {
    setList(permissions)
  }, [list])
  return (
    <Form>
      <FormGroup className="mb-4" row>
        <Label htmlFor="projectname" className="col-form-label col-lg-2">
          Role Name
        </Label>
        <Col lg="4">
          <Input
            id="projectname"
            name="projectname"
            type="text"
            className="form-control"
            placeholder="Enter Role Name..."
          />
        </Col>
        <Label htmlFor="projectdesc" className="col-form-label col-lg-2">
          Default Route
        </Label>
        <Col lg="4">
          <select
            id="custom-select"
            className="form-select"
            value={filterValue}
            onChange={e => {
              setFilterValue(e.target.value || undefined)
            }}
            // defaultValue={"/trips/active"}
          >
            <option>{"/"}</option>
            {authProtectedRoutes.map(option => (
              <option key={option.path} value={option.path}>
                {option.path}
              </option>
            ))}
          </select>
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row></FormGroup>

      <FormGroup className="mb-4" row>
        <label htmlFor="projectbudget" className="col-form-label col-lg-2">
          Permissions
        </label>

        <Col lg={10}>
          <Row>
            {permissions.map((permission, index) => (
              // <Row className="g-3" key={index}>

              <Col key={index} sm={4} className="">
                {/* <div className="p-4"> */}
                <div className="p-3">
                  <div className="mt-2 form-check form-check-inline ">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                    <h5 className="font-size-14 mt-0 text-capitalize form-check-label">
                      {permission.name}
                    </h5>
                  </div>
                  {/* <UncontrolledCollapse toggler={`#toggler-${permission.name}`}> */}
                  <hr />
                  <ul className="list-unstyled megamenu-list">
                    {permission.permissions.map((option, key) => (
                      <li key={key}>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id={option}
                            onChange={handleClick}
                            checked={isCheck.includes(option)}
                            name={option}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="inlineCheckbox1"
                          >
                            {option}
                          </Label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* </UncontrolledCollapse> */}
                  {/* </div> */}
                </div>
              </Col>

              // </Row>
            ))}
          </Row>
        </Col>
      </FormGroup>
    </Form>
  )
}

export default FormAddRole
