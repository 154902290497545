import React, { useState } from "react"
import PropTypes from "prop-types"
import LoadingButton from "components/Common/LoadingButton"
import { createBlockReason } from "helpers/backend_helper"
import { Col, Input, Row } from "reactstrap"

const FormAddBlockReason = ({ close, refresh }) => {
  const [reason, setReason] = useState("")

  const handleClick = async () => {
    if (reason) {
      await createBlockReason({ reason })
      refresh()
    }
    close()
  }

  return (
    <>
      <Row>
        <Col xl={9}>
          <Input
            value={reason}
            onChange={e => {
              setReason(e.target.value)
            }}
            placeholder="Reason"
          />
        </Col>

        <Col xl={2}>
          <LoadingButton className="btn btn-success" onClick={handleClick}>
            Add
          </LoadingButton>
        </Col>
      </Row>
    </>
  )
}

FormAddBlockReason.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormAddBlockReason
