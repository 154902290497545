import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {
  textFilter,
  selectFilter,
  numberFilter,
} from "react-bootstrap-table2-filter"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import Restricted from "components/Common/Restricted"
import { isEmpty } from "lodash"
import {
  lockVehicle,
  unlockVehicle,
  unlockVehicleBattery,
  buzzVehicle,
  updateVehicle,
  getZones,
} from "../../helpers/backend_helper"
import { editVehicle as onEditVehicleDetails } from "store/vehicles/actions" //redux
import { getBatteryVariant } from "../../utils/colorVariants"
import { vehicleStatus } from "../../constants/vehicleStatus"
import { convertBikeBattery, convertScooterBattery } from "utils/convertBattery"
import bike from "../../assets/images/bike.png"
import scooter from "../../assets/images/scooter.png"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import { useDispatch } from "react-redux"
import useQuery from "hooks/useQuery"
import CopyToClipboard from "components/Common/CopyToClipboard"
import Editable from "react-bootstrap-editable"
import { showToastMessage } from "components/Common/ToastWrapper"

const confirmElement = (
  <button type="button" className="btn btn-primary editable-cancel btn-sm">
    <i className="mdi mdi-check"></i>
  </button>
)

const cancelElement = (
  <button type="button" className="btn btn-danger editable-cancel btn-sm ">
    <i className="mdi mdi-close"></i>
  </button>
)

const VehiclesTable = ({ vehicles, refetch }) => {
  let url = new URL(window.location.href)
  const query = useQuery()
  const sizePerPage = 10
  const page = query.get("page") ? Number(query.get("page")) : 1
  const pageSize = query.get("pageSize")
    ? Number(query.get("pageSize"))
    : sizePerPage
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)
  const [stations, setStations] = useState(["loading..."])
  const { SearchBar } = Search
  var node = useRef()
  const dispatch = useDispatch()

  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="Vehicles" />
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
          url.searchParams.set("pageSize", page)
          window.history.replaceState(null, null, url)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: vehicles?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
    page: currentPage,
  }

  const sortFunc = (a, b, order) => {
    if (order === "asc") return a - b
    else return b - a
  }

  const keyField = "IMEI"

  const vehicleListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: vehicle => <>{vehicle.scooterId}</>,
    },
    {
      dataField: "vehicleType",
      text: "Type",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <>
          {vehicle.vehicleType === "scooter" ? (
            <div>
              <img className="rounded-circle avatar-md" src={scooter} alt="" />
            </div>
          ) : (
            <div>
              <img className="rounded-circle avatar-md" src={bike} alt="" />
            </div>
          )}
        </>
      ),

      filter: selectFilter({
        options: {
          scooter: "scooter",
          bicycle: "bicycle",
        },
      }),
      editable: false,
    },
    {
      text: "Vehicle ID",
      dataField: "scooterId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, vehicle) => (
        <>
          <h5 className="font-size-14 mb-1">{vehicle.scooterId}</h5>
        </>
      ),
      filter: textFilter(),
      editable: false,
    },
    {
      text: "Status",
      dataField: "status",
      editable: false,
      sort: true,
      formatter: (_cellContent, vehicle) => (
        <Editable
          initialValue={vehicle.status}
          disabled={vehicle.status === "LTD" || vehicle.status === "InUse"}
          mode="popover"
          placement="bottom"
          isValueClickable={true}
          onSubmit={async value =>
            updateVehicle(vehicle.IMEI, { status: value })
              .then(() => {
                showToastMessage({
                  success: true,
                  message: `Vehicle ${vehicle.scooterId} updated successfully`,
                })
              })
              .catch(() =>
                showToastMessage({
                  success: false,
                  message: "Error Updating Vehicle",
                })
              )
          }
          showText
          type="select"
          options={vehicleStatus.map(s => s.value)}
          renderConfirmElement={confirmElement}
          renderCancelElement={cancelElement}
        />
      ),
      filter: selectFilter({
        options: vehicleStatus,
      }),
    },
    {
      dataField: "IMEI",
      text: "IMEI",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <>
          <CopyToClipboard text={vehicle.IMEI || ""}>
            {vehicle.IMEI}
          </CopyToClipboard>
        </>
      ),
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "Battery",
      text: "Battery",
      sort: true,
      filterValue: (cellContent, vehicle) =>
        vehicle.vehicleType === "scooter"
          ? convertScooterBattery(vehicle.Battery)
          : convertBikeBattery(vehicle.lockBattery),
      formatter: (cellContent, vehicle) => (
        <>
          <>
            <i
              className={`mdi mdi-circle text-${
                vehicle.vehicleType === "scooter"
                  ? getBatteryVariant(convertScooterBattery(vehicle.Battery))
                  : getBatteryVariant(convertBikeBattery(vehicle.lockBattery))
              } align-middle me-1`}
            />
            {`${
              vehicle.vehicleType === "scooter"
                ? convertScooterBattery(vehicle.Battery)
                : convertBikeBattery(vehicle.lockBattery) || "N/A"
            }%`}
          </>
        </>
      ),
      filter: numberFilter({
        withoutEmptyComparatorOption: true,
      }),
      editable: false,
    },
    {
      dataField: "stationId",
      text: "Station",
      sort: true,
      formatter: (cellContent, vehicle) => <>{vehicle.stationId}</>,
      filter: selectFilter({
        options: stations,
      }),
      editor: {
        type: Type.SELECT,
        options: stations,
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, vehicle, rowIndex) => (
        <ul className="list-inline user-chat-nav text-end mb-0">
          <li className="list-inline-item  d-none d-sm-inline-block">
            <UncontrolledDropdown style={{ cursor: "pointer" }}>
              <DropdownToggle tag="i">
                <i className="mdi mdi-dots-vertical font-size-18" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Restricted to="vehicles:VIEW_VEHICLE">
                  <DropdownItem header>Vehicle Actions</DropdownItem>
                  <DropdownItem
                    href={`${window.location.origin}/vehicle-detail/${vehicle.scooterId}`}
                  >
                    View Details
                  </DropdownItem>
                </Restricted>
                {/* <DropdownItem
                  href="#"
                  onClick={() => handleVehicleEdit(vehicle)}
                >
\                  Edit Details
                </DropdownItem> */}
                <DropdownItem header>Hardware Actions</DropdownItem>
                {/* <DropdownItem
                  onClick={() => {
                    vehicle.vehicleType === "scooter"
                      ? refreshScooterState(vehicle.IMEI)
                      : refreshBikeState(vehicle.IMEI)
                  }}
                >
                  Refresh State
                </DropdownItem> */}
                <DropdownItem onClick={() => lockVehicle(vehicle.IMEI)}>
                  Lock
                </DropdownItem>
                <Restricted to="commands:UNLOCK_VEHICLE">
                  <DropdownItem onClick={() => unlockVehicle(vehicle.IMEI)}>
                    Unlock
                  </DropdownItem>
                </Restricted>
                <DropdownItem
                  onClick={() => unlockVehicleBattery(vehicle.IMEI)}
                >
                  Battery Unlock
                </DropdownItem>
                <DropdownItem onClick={() => buzzVehicle(vehicle.IMEI)}>
                  Buzz
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <Restricted to="vehicles:VIEW_VEHICLE">
            <li className="list-inline-item  d-none d-sm-inline-block">
              <Link to={"/vehicle-detail/" + vehicle.scooterId}>
                <i
                  className="mdi mdi-chevron-right font-size-18"
                  id="expandtooltip"
                ></i>
              </Link>
            </li>
          </Restricted>
        </ul>
      ),
    },
  ]
  const handleSaveEdit = async (newValue, row, column) => {
    const imei = row.IMEI
    if (column.dataField === "stationId") {
      const stationId = newValue
      // await updateVehicle(imei, { stationId })
      dispatch(onEditVehicleDetails(imei, { stationId }))
      // showToastMessage({
      //   success: true,
      //   message: "Vehicle Station updated successfully",
      // })
    }

    if (column.dataField === "status") {
      const status = newValue
      // await updateVehicle(imei, { status })
      // showToastMessage({
      //   success: true,
      //   message: "Vehicle Status updated successfully",
      // })
    }
  }

  // useEffect(() => {
  //   if (!isEmpty(vehicles)) {
  //     const uniqueStations = [
  //       ...new Set(vehicles.map(vehicle => vehicle.stationId)),
  //     ]
  //     const stations = uniqueStations.map(station => ({
  //       value: station,
  //       label: station,
  //     })).sort((a, b) => {
  //       if (a.label < b.label) {
  //         return -1
  //       }
  //       if (a.label > b.label) {
  //         return 1
  //       }
  //       return 0
  //     })
  //     setStations(stations)
  //   }
  // }, [vehicles])

  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data
      ?.map(({ zoneName }) => ({
        label: zoneName,
        value: zoneName,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    setStations(stations)
  }

  useEffect(() => {
    if (!isEmpty(vehicles)) {
      fetchStations()
    }
  }, [vehicles])
  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField={keyField}
          columns={vehicleListColumns}
          data={vehicles}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <ToolkitProvider
                keyField={keyField}
                data={vehicles}
                columns={vehicleListColumns}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col lg={6}>
                        <div className="float-end">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12 mt-4">
                        <div className="table-responsive invert-scroll">
                          <BootstrapTable
                            keyField={keyField}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            // selectRow={selectRow}
                            sort={{
                              dataField: "scooterId",
                              order: "asc",
                              sortFunc: sortFunc,
                            }}
                            classes={
                              "table align-middle table-nowrap table-hover top-horizontal-scroll"
                            }
                            headerClasses="table-light"
                            bordered={false}
                            striped={false}
                            responsive
                            ref={node}
                            filter={filterFactory()}
                            filterPosition="top"
                            cellEdit={cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              beforeSaveCell: (
                                oldValue,
                                newValue,
                                row,
                                column
                              ) => {
                                handleSaveEdit(newValue, row, column)
                              },
                            })}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

VehiclesTable.propTypes = {
  vehicles: PropTypes.array,
  refetch: PropTypes.func,
}

export default VehiclesTable
