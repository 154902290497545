import React from "react"
import PropTypes from "prop-types"
import { Table, Placeholder } from "reactstrap"

const DetailsOverview = ({ user, loading }) => {
  return (
    <>
      <div className="table-responsive">
        <Table className="table mb-0 table-bordered">
          <tbody style={{ lineHeight: "0.5rem" }}>
            <tr>
              <td>
                <div className="d-flex">
                  <i className="me-2 text-muted mdi mdi-account noti-icon font-size-14" />

                  <p className="text-muted">Full Name</p>
                </div>
                <div style={{ marginLeft: "1.5rem" }}>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={1} className="rounded-pill me-2" />
                      <Placeholder xs={2} className="rounded-pill" />
                    </Placeholder>
                  ) : (
                    `${user.userName} ${user.userSurname}`
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <i className="me-2 text-muted mdi mdi-phone noti-icon font-size-14" />

                  <p className="text-muted">Phone Number</p>
                </div>
                <div style={{ marginLeft: "1.5rem" }}>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={2} className="rounded-pill" />
                    </Placeholder>
                  ) : (
                    <a href={`tel:${user.countryCode} ${user.contact}`}>
                      {user.countryCode} {user.contact}
                    </a>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              {user.email !== "NIL" && (
                <td>
                  <div className="d-flex">
                    <i className="me-2 text-muted mdi mdi-email noti-icon font-size-14" />

                    <p className="text-muted">Email</p>
                  </div>
                  <div style={{ marginLeft: "1.5rem" }}>
                    {loading ? (
                      <Placeholder animation="glow">
                        <Placeholder xs={2} className="rounded-pill" />
                      </Placeholder>
                    ) : (
                      <>{user.email}</>
                    )}
                  </div>
                </td>
              )}
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <i className="me-2 text-muted mdi mdi-map-marker noti-icon font-size-14" />

                  <p className="text-muted">Location</p>
                </div>
                <div style={{ marginLeft: "1.5rem" }}>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={1} className="rounded-pill" />
                    </Placeholder>
                  ) : (
                    user.userCity ?? "-"
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <i className="me-2 text-muted mdi mdi-gender-male-female noti-icon font-size-14" />

                  <p className="text-muted">Gender</p>
                </div>
                <div style={{ marginLeft: "1.5rem" }}>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={1} className="rounded-pill" />
                    </Placeholder>
                  ) : user.gender === 1 ? (
                    "Male"
                  ) : (
                    "Female"
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="d-flex">
                  <i className="me-2 text-muted mdi mdi-cake-variant noti-icon font-size-14" />

                  <p className="text-muted">Date of Birth</p>
                </div>
                <div style={{ marginLeft: "1.5rem" }}>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={2} className="rounded-pill" />
                    </Placeholder>
                  ) : (
                    user.birthDate ?? "-"
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
DetailsOverview.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
}
export default DetailsOverview
