import { getExport } from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Progress,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

const ExportItem = ({ exportItem, index }) => {
  const [progress, setProgress] = useState(0)
  const [downloadInterval, setDownloadInterval] = useState(null)
  const [downloadStarted, setDownloadStarted] = useState(false)
  const [downloadFinished, setDownloadFinished] = useState(false)
  const [signal, setSignal] = useState(null)
  const controller = new AbortController()
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleSubmit = async type => {
    setDownloadStarted(true)
    setProgress(0)
    setDownloadInterval(
      setInterval(() => {
        setProgress(prevProgress => (prevProgress < 80 ? prevProgress + 5 : 80))
      }, 1000)
    )
    await DownloadFile(type, signal)
  }

  async function DownloadFile(type) {
    try {
      const config = {
        params: {
          type,
          startDate,
          endDate,
        },
        responseType: "blob",
      }
      const response = await getExport(exportItem.path, config)
      const url = window.URL.createObjectURL(response)
      const a = document.createElement("a")
      a.style.display = "none"
      a.href = url
      a.download = `${exportItem.path}_${startDate}_${endDate}.${type}`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      setDownloadStarted(false)
      clearInterval(downloadInterval)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    return () => {
      if (downloadInterval) {
        clearInterval(downloadInterval)
      }
    }
  }, [downloadInterval])

  useEffect(() => {
    setSignal(controller.signal)
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <React.Fragment>
      <Card key={`exportType_${exportItem.value}`} className="border">
        <CardBody>
          <Row>
            {downloadStarted && (
              <Col md={12} className="w-100 m-1">
                <Progress value={progress} max={100}>
                  {progress}%
                </Progress>
              </Col>
            )}
            <Col className="d-flex align-items-center" md={2}>
              <h5>{exportItem.label}</h5>
            </Col>
            <Col className="d-flex align-items-center" md={4}>
              {/* Start Date Input */}
              <Input
                type="date"
                name="startDate"
                defaultValue={startDate}
                onChange={e => setStartDate(e.target.value)}
                required
              />
            </Col>
            <Col className="d-flex align-items-center" md={4}>
              {/* End Date Input */}
              <Input
                type="date"
                name="startDate"
                defaultValue={endDate}
                onChange={e => setEndDate(e.target.value)}
                required
              />
            </Col>
            <Col
              className="d-flex align-items-center justify-content-between"
              md={2}
            >
              <div>
                {/* XLSX Export Button */}
                <Button
                  id={`export_xlsx_button_${index}`}
                  color="primary"
                  type="button"
                  onClick={() => handleSubmit("xlsx")}
                  disabled={
                    (startDate === "" && endDate === "") || downloadStarted
                  }
                >
                  <i className="mdi mdi-microsoft-excel"></i> Excel
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={`export_xlsx_button_${index}`}
                >
                  Export XLSX
                </UncontrolledTooltip>
              </div>
              <div>
                {/* CSV Export Button */}
                <Button
                  id={`export_csv_button_${index}`}
                  color="primary"
                  type="button"
                  onClick={() => handleSubmit("csv")}
                  disabled={
                    (startDate === "" && endDate === "") || downloadStarted
                  }
                >
                  <i className="mdi mdi-comma-box-outline"></i> CSV
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target={`export_csv_button_${index}`}
                >
                  Export CSV
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ExportItem.propTypes = {
  exportItem: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default ExportItem
