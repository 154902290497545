import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardBody,
  Table,
  Placeholder,
} from "reactstrap"
import { Link } from "react-router-dom"

const AccountOverview = ({ user, loading = true }) => {
  // TODO make this fetchable from config
  const getStatusStyles = status => {
    switch (status) {
      case 0:
        return {
          variant: "success",
          label: "Active",
          icon: "mdi mdi-account-check",
        }
      case 2:
        return {
          variant: "danger",
          label: "Blocked",
          icon: "mdi mdi-account-cancel",
        }
      case 3:
        return {
          variant: "secondary",
          label: "Deleted",
          icon: "mdi mdi-account-remove",
        }
      default:
        return {
          variant: "secondary",
          label: "Active",
          icon: "mdi mdi-account",
        }
    }
  }

  const statusStyles = getStatusStyles(user.statusCode)

  return (
    <Row>
      <Col lg="4">
        <div className="border p-3 rounded">
          <div className="d-flex">
            <div className="me-3 align-self-center">
              <i className="mdi mdi-wallet h2 text-info mb-0" />
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-2">Balance</p>
              {loading ? (
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={3} className="rounded-pill" /> <br /> <br />
                </Placeholder>
              ) : (
                <h5 className="mb-0">
                  {/* {user.credits}{" "} */}
                  <span className="font-size-14 text-dark">
                    {parseFloat(user.credits / 100).toFixed(2)} SAR
                  </span>
                </h5>
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col lg="4">
        <div className="border p-3 rounded">
          <div className="d-flex">
            <div className="me-3 align-self-center">
              <i className="mdi mdi-human-scooter h2 text-dark mb-0" />
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-2">Total Trips</p>
              {loading ? (
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={2} className="rounded-pill" /> <br /> <br />
                </Placeholder>
              ) : (
                <h5 className="mb-0">{user.tripCount} </h5>
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col lg="4">
        <div className="border p-3 rounded">
          <div className="d-flex">
            <div className="me-3 align-self-center">
              <i
                className={`h2 ${statusStyles.icon} text-${statusStyles.variant} mb-0`}
              />
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-2">Account Status</p>
              {loading ? (
                <Placeholder as={Card.Text} animation="glow">
                  <Placeholder xs={2} className="rounded-pill" /> <br /> <br />
                </Placeholder>
              ) : (
                <>
                  <h5 className="mb-1">{statusStyles.label}</h5>
                  {user.blockReason && user.blockReason !== "NIL" && (
                    <h6 className="mb-0">({user.blockReason})</h6>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

AccountOverview.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
}

export default AccountOverview
{
  /* 
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                <i className="mdi mdi-wallet" />
              </span>
            </div>
            <h5 className="font-size-14 mb-0">Balance</h5>
          </div>

          <Row>
            <div className="col-lg-6">
              <div className="text-muted mt-3">
                <p>Current Credits</p>
                <h4>{user.credits}</h4>
              </div>
            </div>
          </Row>
        </div> */
}
