import React, { useState } from "react"
import PropTypes from "prop-types"

import { Row, Col, Label, Input } from "reactstrap"
import { addCustomerBalance, sendCustomerNotif } from "helpers/backend_helper"
import LoadingButton from "components/Common/LoadingButton"

const FormSendNotif = ({ user, refresh, close, showToastMessage }) => {
  const [form, setForm] = useState({ title: "", body: "" })

  const handleOnChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    console.log(form)
  }

  const handleSubmit = async () => {
    if (!form.title || !form.body) {
      showToastMessage({
        success: false,
        message: "Title and Body are required",
      })
      return
    }
    try {
      const payload = {
        FCMToken: user.FCMToken,
        os: user.deviceInfo?.os,
        ...form,
      }
      await sendCustomerNotif(payload)
      await refresh()
      showToastMessage({
        success: true,
        message: "Notification Sent successfully",
      })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Failed to send Notification",
      })
    }
    close()
  }
  return (
    <>
      <Row>
        <Col lg={12}>
          <Label>Title</Label>
          <Input name="title" onChange={handleOnChange} />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col lg={12}>
          <Label>Body</Label>
          <textarea
            name="body"
            onChange={handleOnChange}
            className="form-control"
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col lg={12}>
          <div className="text-center">
            <LoadingButton
              type="button"
              color="success"
              className="w-md"
              onClick={handleSubmit}
            >
              Send Notification
            </LoadingButton>
          </div>
        </Col>
      </Row>
    </>
  )
}

FormSendNotif.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormSendNotif
