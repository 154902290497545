import React from "react"
import PropTypes from "prop-types"

import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Form,
  Row,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

const VehicleEdit = ({ modal, toggle, vehicle }) => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (vehicle && vehicle.id) || "",
      img: (vehicle && vehicle.img) || "",
      name: (vehicle && vehicle.name) || "",
      description: (vehicle && vehicle.description) || "",
      status: (vehicle && vehicle.status) || "",
      color: (vehicle && vehicle.color) || "",
      dueDate: (vehicle && vehicle.dueDate) || "",
      team: (vehicle && vehicle.team) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      description: Yup.string().required("Please Enter Your Description"),
      status: Yup.string().required("Please Enter Your Status"),
      color: Yup.string().required("Please Enter Your Color"),
    }),
    onSubmit: values => {
      const updateProject = {
        id: project.id,
        img: values.img,
        name: values.name,
        description: values.description,
        status: values.status,
        color: values.color,
        dueDate: values.dueDate,
        team: values.team,
      }

      // update project
      //   dispatch(onUpdateProject(updateProject))
      toggle()
    },
  })
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Vehicle
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col xs={12}>
              <Input
                type="hidden"
                value={validation.values.img || ""}
                name="img"
              />

              <Input
                type="hidden"
                value={validation.values.team || ""}
                name="team"
              />
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <Input
                  name="description"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Status</Label>
                <Input
                  name="status"
                  id="status1"
                  type="select"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.status || ""}
                >
                  <option>Completed</option>
                  <option>Pending</option>
                  <option>Delay</option>
                </Input>
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Color</Label>
                <Input
                  name="color"
                  type="select"
                  className="form-select"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.color || ""}
                  invalid={
                    validation.touched.color && validation.errors.color
                      ? true
                      : false
                  }
                >
                  <option>success</option>
                  <option>warning</option>
                  <option>danger</option>
                </Input>
                {validation.touched.color && validation.errors.color ? (
                  <FormFeedback type="invalid">
                    {validation.errors.color}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">dueDate</Label>
                <Input
                  name="dueDate"
                  type="date"
                  format="YYYY/MM/DD"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.dueDate && validation.errors.dueDate
                      ? true
                      : false
                  }
                  value={validation.values.dueDate || ""}
                ></Input>
                {validation.touched.dueDate && validation.errors.dueDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dueDate}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

VehicleEdit.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  vehicle: PropTypes.object,
}

export default VehicleEdit
