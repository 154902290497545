import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { Row, Col, Spinner } from "reactstrap"
import { addCustomerDiscount, getDiscounts } from "helpers/backend_helper"
import CardDiscount from "./card-discount"
import SimpleBar from "simplebar-react"
import LoadingButton from "../../Common/LoadingButton"

const FormAddDiscount = ({ user, refresh, close, showToastMessage }) => {
  const [discounts, setDiscounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [checkedValue, setCheckedValue] = useState(
    user.discountId === "NIL" ? "" : user.discountId
  )

  const handleSubmit = async () => {
    const data = {
      discountId: checkedValue,
    }
    try {
      await addCustomerDiscount(user.userId, data)
      await refresh()
      showToastMessage({
        success: true,
        message: "Discount granted successfully",
      })
    } catch (error) {
      showToastMessage({ success: false, message: "Failed to grant discount" })
    }
    close()
  }

  const fetchDiscounts = async () => {
    const response = await getDiscounts()

    setDiscounts(Object.values(response.data))
    setLoading(false)
  }

  useEffect(() => {
    fetchDiscounts()
  }, [])
  return (
    <>
      <Row className="mb-4">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center text-primary">
            <Spinner size={"md"} />
          </div>
        ) : (
          <>
            <h5 className="my-4">Select:</h5>
            <SimpleBar
              style={{ maxHeight: "400px", height: "400px" }}
              forceVisible="y"
              autoHide={false}
            >
              <Row>
                {discounts.map((discount, i) => (
                  <Col lg="6" key={i}>
                    <CardDiscount
                      discount={discount}
                      checkedValue={checkedValue}
                      setCheckedValue={setCheckedValue}
                    />
                  </Col>
                ))}
              </Row>
            </SimpleBar>
            <p className="text-muted">
              Showing total of {discounts.length} Discounts
            </p>
          </>
        )}
      </Row>
      <div className="text-end my-3">
        <LoadingButton
          type="button"
          color="primary"
          className="w-md"
          disabled={!checkedValue}
          onSubmit={handleSubmit}
          onClick={handleSubmit}
        >
          Add Discount
        </LoadingButton>
      </div>
    </>
  )
}

FormAddDiscount.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormAddDiscount
