import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Spinner, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import { useSelector, useDispatch } from "react-redux"
import { getVehicles as onGetVehicles } from "store/vehicles/actions"

import VehicleEdit from "./vehicle-edit"
import VehiclesTable from "./vehicles-table"
import LoadingTable from "components/Loading/loading-table"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"

const VehiclesList = props => {
  const [vehicle, setVehicle] = useState()
  const [modal, setModal] = useState(false)

  const dispatch = useDispatch()
  const { vehicles, loading } = useSelector(state => ({
    vehicles: state.vehicles.vehicles,
    loading: state.vehicles.loading,
  }))

  const toggle = () => {
    if (modal) {
      setModal(false)
      setVehicle(null)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {
    if (vehicles && !vehicles.length) {
      dispatch(onGetVehicles())
    }
  }, [dispatch, vehicles])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vehicles List | Gazal</title>
        </MetaTags>
        <Restricted to="vehicles:VIEW_ALL_VEHICLES" fallback={<AccessDenied />}>
          <Container fluid>
            <Breadcrumbs title="Vehicles" breadcrumbItem="Vehicle List" />
            <Row>
              <Col lg="12">
                {loading ? (
                  <LoadingTable />
                ) : (
                  <VehiclesTable
                    refetch={() => dispatch(onGetVehicles())}
                    vehicles={vehicles}
                  />
                )}
              </Col>
            </Row>
            <VehicleEdit modal={modal} toggle={toggle} vehicle={vehicle} />
          </Container>
        </Restricted>
      </div>
    </React.Fragment>
  )
}

export default withRouter(VehiclesList)
