import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
// import Dashboard from "../pages/Dashboard/index"


//Tables
// import BasicTables from "../pages/Tables/BasicTables"
// import DatatableTables from "../pages/Tables/DatatableTables"
// import ResponsiveTables from "../pages/Tables/ResponsiveTables"
// import EditableTables from "../pages/Tables/EditableTables"
// import DragDropTables from "../pages/Tables/DragDropTables"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import TripDetails from "pages/Trips/trip-details"
import ActiveTripsList from "pages/Trips/active-trips-list"
import AllTripsList from "pages/Trips/all-trips-list"
import VehiclesList from "pages/Vehicles/vehicles-list"
import VehicleDetail from "pages/Vehicles/VehicleDetail/vehicle-detail"
// import UserDetails from "pages/Users/UserDetails/user-details"
import CustomerDetails from "pages/Customers/CustomerDetails/customer-details"
// import UsersList from "pages/Users/users-list"
import CustomersList from "pages/Customers/customers-list"
import ComingSoon from "pages/General/coming-soon"
import Map from "pages/Map"
import AccessDenied from "pages/Utility/access-denied"
import SearchCustomers from "pages/Customers/search-customers"
import Settings from "pages/Settings"
import Home from "pages/Home/home"
import Zones from "pages/Zones"
import ZoneDetails from "pages/Zones/zone-details"
import ResellersList from "pages/Resellers/resellers-list"
import Exports from "pages/Exports"
import VoucherDetails from "pages/VoucherDetails/VoucherDetails"
import Vouchers from "pages/Vouchers/Vouchers"
import RedeemedCodes from "pages/RedeemedCodes/RedeemedCodes"
import AddUpdateRole from "pages/AddUpdateRole/AddUpdateRole"
import AddControlZone from "pages/Zones/add-control-zone"
import AddOperationZone from "pages/Zones/add-operation-zone"

const authProtectedRoutes = [
  { path: "/coming-soon", component: ComingSoon },
  { path: "/trips/active", component: ActiveTripsList },
  { path: "/trips", component: AllTripsList },
  { path: "/trips/all", component: AllTripsList },
  { path: "/trip-detail", component: TripDetails },
  { path: "/map", component: Map },
  { path: "/zones", component: Zones },
  { path: "/zone-detail/:zoneName?", component: ZoneDetails },

  { path: "/exports", component: Exports },

  { path: "/vehicles", component: VehiclesList },
  { path: "/vehicle-detail/:id?", component: VehicleDetail },

  { path: "/settings", component: Settings },
  { path: "/customers", component: CustomersList },
  { path: "/customers/search", component: SearchCustomers },
  { path: "/customer-detail/:id?", component: CustomerDetails },
  { path: "/voucher-details/:id?", component: VoucherDetails },
  { path: "/redeemed-codes", component: RedeemedCodes },
  { path: "/access-denied", component: AccessDenied },

  { path: "/resellers", component: ResellersList },

  // { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/add-role", component: AddUpdateRole },
  { path: "/update-role/:name", component: AddUpdateRole },

  { path: "/add-control-zone", component: AddControlZone },
  { path: "/add-operation-zone", component: AddOperationZone },

  // // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },
  // { path: "/tables-dragndrop", component: DragDropTables },

  // // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  //Utility
  // { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-faqs", component: PagesFaqs },
  // { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: Home },
]

const publicRoutes = [
  // { path: "/", exact: true, component: () => <Redirect to={redirectTo} /> },
  { path: "/", exact: true, component: Home },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/access-denied", component: AccessDenied },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
  { path: "/vouchers", component: Vouchers },
]

export { authProtectedRoutes, publicRoutes }
