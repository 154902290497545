import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Button,
  FormGroup,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { Formik, useFormik, Form } from "formik"
import Switch from "react-switch"

import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"

import { createPackage, updatePackage } from "helpers/backend_helper"
import { showToastMessage } from "../Common/ToastWrapper"
import { FormikSelect, FormikTextInput } from "components/Common/FormikFields"

const Offsymbol = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    </>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}

const FormPackage = ({ toggle, pkg, isEdit, refetch }) => {
  const [loading, setLoading] = useState(false)

  const priorityOptions = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
  ]

  const handleChecked = () => {
    validation.setFieldValue("is_active", !validation.values.is_active)
  }

  const submitEdit = async values => {
    try {
      await updatePackage(pkg.packageId, values)
      showToastMessage({
        success: true,
        message: "Voucher Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Something went wrong",
      })
    }
  }
  const submitCreate = async values => {
    try {
      await createPackage(values)
      showToastMessage({
        success: true,
        message: "Voucher Created",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response?.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data.messages,
        })
        setLoading(false)
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
        setLoading(false)
      }
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        package_name_ar: (pkg && pkg.package_name_ar) || "",
        package_name_en: (pkg && pkg.package_name_en) || "",
        desc_ar: (pkg && pkg.desc_ar) || "",
        desc_en: (pkg && pkg.desc_en) || "",
        packageCtaAr: (pkg && pkg.packageCtaAr) || "",
        packageCtaEn: (pkg && pkg.packageCtaEn) || "",
        cost: (pkg && pkg.cost) || "",
        credit: (pkg && pkg.credit / 100) || "",
        is_active: (pkg && pkg.is_active) || false,
        priority: (pkg && pkg.priority) || "",
      }}
      validationSchema={Yup.object().shape({
        package_name_ar: Yup.string()
          .trim()
          .matches(
            "^[\u0621-\u064A0-9٠-٩ ]+$",
            "only arabic letters are allowed"
          )
          .required("Arabic Name is required"),
        package_name_en: Yup.string()
          .trim()
          .matches("^[a-zA-Z0-9 ]+$", "only english letters are allowed")
          .required("English Name is required"),
        desc_ar: Yup.string().matches(
          "^[\u0621-\u064A0-9٠-٩ ]+$",
          "only arabic letters are allowed"
        ),
        desc_en: Yup.string()
          .trim()
          .matches(
            "^[a-zA-Z0-9,. ]+$",
            "only english letters and symbols are allowed"
          ),
        packageCtaAr: Yup.string()
          .matches(
            "^[\u0621-\u064A0-9٠-٩ ]+$",
            "only arabic letters are allowed"
          )
          .required("Arabic Call to Action is required"),
        packageCtaEn: Yup.string()
          .trim()
          .matches(
            "^[a-zA-Z0-9,. ]+$",
            "only english letters and symbols are allowed"
          )
          .required("English Call to Action is required"),
        cost: Yup.number().min(0).required(),
        credit: Yup.number().min(0).required(),
        is_active: Yup.boolean().required(),
        priority: Yup.number().min(1).required(),
      })}
      onSubmit={values => {
        const valsCopy = structuredClone(values)
        valsCopy.credit = (valsCopy.credit * 100).toString()
        valsCopy.cost = valsCopy.cost.toString()
        setLoading(true)

        if (isEdit) {
          submitEdit(valsCopy)
        } else {
          submitCreate(valsCopy)
        }
      }}
    >
      {formik => (
        <Form>
          <Row>
            <Col lg={6}>
              <h4 className="font-size-14 mb-3">
                {formik.values.is_active ? "Active" : "Disabled"}
              </h4>
              <Switch
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8 mb-2"
                onColor="#00a82d"
                onChange={() =>
                  formik.setFieldValue("is_active", !formik.values.is_active)
                }
                onBlur={formik.handleBlur}
                checked={formik.values.is_active}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={6}>
              <FormikTextInput label="Name English" name="package_name_en" />
            </Col>

            <Col lg={6}>
              <FormikTextInput label="Name Arabic" name="package_name_ar" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={6}>
              <FormikTextInput
                label="Call to Action English"
                name="packageCtaEn"
              />
            </Col>

            <Col lg={6}>
              <FormikTextInput
                label="Call to Action Arabic"
                name="packageCtaAr"
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6}>
              <FormikTextInput
                type="textarea"
                label="Description English"
                name="desc_en"
              />
            </Col>

            <Col lg={6}>
              <FormikTextInput
                type="textarea"
                label="Description Arabic"
                name="desc_ar"
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={4}>
              <FormikTextInput type="number" label="Cost (SAR)" name="cost" />
            </Col>
            <Col lg={4}>
              <FormikTextInput
                type="number"
                label="Credit (SAR)"
                name="credit"
              />
            </Col>

            <Col lg={4}>
              <FormikSelect
                label="Order"
                name="priority"
                options={priorityOptions}
                defaultValue={priorityOptions.find(
                  p => p.value === formik.values.priority
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <Button
                  type="submit"
                  className="btn btn-dark save-user"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Save"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

FormPackage.propTypes = {
  pkg: PropTypes.object,
  isEdit: PropTypes.bool,
  refetch: PropTypes.func,
  toggle: PropTypes.any,
}

export default FormPackage
