import { call, put, takeEvery } from "redux-saga/effects"

// Zones Redux States
import {
  GET_LOGS, GET_LOG_DETAILS
} from "./actionTypes"

import {
  getLogDetailsFail, getLogDetailsSuccess, getLogsFail, getLogsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getLogDetails, getLogs } from "../../helpers/backend_helper"

function* fetchLogs({payload}) {
  try {
    const response = yield call(getLogs, payload)
    yield put(getLogsSuccess(response.data))
  } catch (error) {
    yield put(getLogsFail(error))
  }
}

function* fetchLogDetails({ logId }) {
  try {
    const response = yield call(getLogDetails, logId)

    yield put(getLogDetailsSuccess(response.data))
  } catch (error) {
    yield put(getLogDetailsFail(error))
  }
}

function* auditSage() {
  yield takeEvery(GET_LOGS, fetchLogs)
  yield takeEvery(GET_LOG_DETAILS, fetchLogDetails)
}

export default auditSage
