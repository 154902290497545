/* eslint react/prop-types: 0 */
import React from "react"
import { useField } from "formik"
import { Input, Label } from "reactstrap"
import Select from "react-select"

export const FormikTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

export const FormikCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <Input type="checkbox" {...field} {...props} />
      <Label className="mx-1">{label}</Label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export const FormikSelect = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props)

  const onChange = ({ value }) => {
    if (props.onChange) props.onChange(value)
    setValue(value)
  }

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <Select {...props} onChange={onChange} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  )
}

export const FormikMultiSelect = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props)

  const onChange = op => {
    const values = op.map(e => e.value)
    if (props.onChange) props.onChange(values)
    setValue(values)
  }

  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <Select isMulti {...props} onChange={onChange} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  )
}
