import { call, put, takeEvery } from "redux-saga/effects"

// Vehicles Redux States
import { GET_VEHICLES, GET_VEHICLE_DETAILS, EDIT_VEHICLE } from "./actionTypes"

import {
  getVehiclesSuccess,
  getVehiclesFail,
  getVehicleDetailsSuccess,
  getVehicleDetailsFail,
  editVehicleSuccess,
  editVehicleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getVehicles,
  getVehicleDetails,
  updateVehicle,
} from "../../helpers/backend_helper"

function* fetchVehicles() {
  try {
    const response = yield call(getVehicles)
    yield put(getVehiclesSuccess(response.data))
  } catch (error) {
    yield put(getVehiclesFail(error))
  }
}

function* fetchVehicleDetails({ scooterId }) {
  try {
    const response = yield call(getVehicleDetails, scooterId)

    yield put(getVehicleDetailsSuccess(response.data))
  } catch (error) {
    yield put(getVehicleDetailsFail(error))
  }
}

function* updateVehicleDetails({ imei, updates }) {
  try {
    const response = yield call(updateVehicle, imei, updates)

    yield put(editVehicleSuccess(imei, updates))
  } catch (error) {
    yield put(editVehicleFail(error))
  }
}

function* vehiclesSaga() {
  yield takeEvery(GET_VEHICLES, fetchVehicles)
  yield takeEvery(GET_VEHICLE_DETAILS, fetchVehicleDetails)
  yield takeEvery(EDIT_VEHICLE, updateVehicleDetails)
}

export default vehiclesSaga
