import {
  GET_VEHICLES,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_SUCCESS,
  GET_VEHICLE_DETAILS_FAIL,
  SET_VEHICLE,
  EDIT_VEHICLE,
  EDIT_VEHICLE_SUCCESS,
  EDIT_VEHICLE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  vehicles: [],
  loading: false,
  vehicle: {},
  loadingDetails: false,
  loadingEdit: false,
  error: {},
  success: false,
  message: "",
}

const vehicles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VEHICLES:
      return {
        ...state,
        loading: true,
        error: {},
      }
    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        vehicles: action.payload,
      }

    case GET_VEHICLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_VEHICLE_DETAILS:
      return {
        ...state,
        loadingDetails: true,
        error: {},
      }
    case GET_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        vehicle: action.payload,
      }

    case GET_VEHICLE_DETAILS_FAIL:
      return {
        ...state,
        loadingDetails: false,
        error: action.payload,
      }

    case SET_VEHICLE:
      return {
        ...state,
        vehicle: action.payload,
      }

    case EDIT_VEHICLE:
      return {
        ...state,
        loadingEdit: true,
        error: {},
      }
    case EDIT_VEHICLE_SUCCESS:
      return {
        ...state,
        loadingEdit: false,
        vehicles: [
          ...vehicles,
          ...{
            ...vehicles[
              vehicles.findIndex(obj => obj.IMEI == action.payload.imei)
            ],
            ...action.payload.updates,
          },
        ],
        success: true,
        message: "Vehicle updated successfully",
      }
    // let test = [...vehicles,  {...vehicles.find(item => item.t === updates.imei).map(v=>({...v, ...updates.updates})) }]

    case EDIT_VEHICLE_FAIL:
      return {
        ...state,
        loadingEdit: false,
        error: action.payload,
        success: false,
        message: "Vehicle failed to update",
      }

    default:
      return state
  }
}

export default vehicles
