import React from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledCollapse,
  Row,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { formatTime } from "../../utils/formatDate"
import { tripStatus } from "constants/tripStatus"
import EmptyState from "components/Common/EmptyState"
import {
  endTripLogReasons,
  tripActionsContent,
  tripMessagesContent,
} from "../../constants/tripActivityContent"
import { formatDateString } from "../../utils/formatDate"

const CustomerActions = ({ logs, loading }) => {
  const getVariant = level => {
    switch (level) {
      case "error":
        return "text-danger bxs-error-circle"
      case "info":
        return "text-info bxs-right-arrow-circle"
      case "success":
        return "text-success bxs-check-circle"
    }
  }

  const getActionInitiator = initBy => {
    if (!initBy || !initBy.type) return { name: "Customer", color: "success" }
    if (initBy.type === "b2bPartner")
      return { name: "B2B Partner", color: "dark" }
    if (initBy.type === "system") return { name: "System", color: "info" }
    if (initBy.type === "gazal") return { name: "Care Agent", color: "danger" }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Activity</CardTitle>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner size="md" color="primary" />
            </div>
          ) : logs.length ? (
            <SimpleBar
              style={{ maxHeight: "380px", height: "380px" }}
              forceVisible="y"
              autoHide={false}
            >
              <ul className="verti-timeline list-unstyled">
                {logs.map((log, i) => {
                  const ts = tripStatus.find(t => t.value === log.tripStatus)

                  return (
                    <li className="event-list" key={i}>
                      <div className="event-timeline-dot">
                        {/* <i className="bx bx-right-arrow-circle font-size-18" /> */}
                        <i
                          className={`bx font-size-24 ${getVariant(log.level)}`}
                        />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14">
                            {formatTime(log.createdAt)}
                            <i className="ms-4 bx bx-right-arrow-alt font-size-16 text-dark align-middle ms-2" />
                          </h5>
                          <h6 className="text-muted font-size-13">
                            {formatDateString(log.createdAt)}
                          </h6>
                          <p className="text-muted font-size-11">
                            #{log.createdAt}
                          </p>
                        </div>
                        <div className="flex-grow-1">
                          <h5 id="activitytext">
                            {tripActionsContent[log.action]}
                          </h5>
                          <p>
                            {log.actionType}
                            <span
                              className={`badge badge-soft-${
                                getActionInitiator(log.initiatedBy).color
                              } mx-2`}
                            >
                              {getActionInitiator(log.initiatedBy).name}
                            </span>
                          </p>
                          <div>
                            <Link to="#" id={`toggler-${log.createdAt}`}>
                              <span className="text-success">Details</span>
                            </Link>
                            <UncontrolledCollapse
                              toggler={`toggler-${log.createdAt}`}
                            >
                              <Row className="mt-3">
                                <dl className="text-muted row">
                                  {log.tripStatus ? (
                                    <>
                                      <dt className="col-sm-6">Trip Status</dt>
                                      <dd className="col-sm-6">
                                        <h5
                                          className={`badge rounded-pill ${ts?.badgeClass} font-size-13`}
                                        >
                                          <i className={"align-middle me-1"} />
                                          {ts?.label}
                                        </h5>
                                      </dd>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {log.initiatedBy ? (
                                    <>
                                      <dt className="col-sm-6">Initiated By</dt>
                                      <dd className="col-sm-6">
                                        {" "}
                                        <h5
                                          className={`badge rounded-pill badge-soft-dark font-size-13`}
                                        >
                                          <i className={""} />
                                          {log.initiatedBy.email
                                            ? log.initiatedBy.email
                                            : log.initiatedBy.type}
                                        </h5>
                                      </dd>

                                      {log.initiatedBy.reason ? (
                                        <>
                                          <dt className="col-sm-6">Reason</dt>
                                          <dd className="col-sm-6">
                                            {" "}
                                            <h5
                                              className={`badge rounded-pill badge-soft-dark font-size-13`}
                                            >
                                              <i className={""} />
                                              {
                                                endTripLogReasons[
                                                  log.initiatedBy.reason
                                                ]
                                              }
                                            </h5>
                                          </dd>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}

                                  {log.messages?.length ? (
                                    <>
                                      <dt className="col-sm-6">Messages</dt>
                                      <dd className="col-sm-6">
                                        {tripMessagesContent[log.messages]}
                                      </dd>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {log.zoneId ? (
                                    <>
                                      <dt className="col-sm-6">Zone Name</dt>
                                      <dd className="col-sm-6">{log.zoneId}</dd>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </dl>
                                <hr className="w-75" />
                              </Row>
                            </UncontrolledCollapse>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </SimpleBar>
          ) : (
            <div
              style={{ height: "380px" }}
              className="d-flex align-items-center justify-content-center"
            >
              <EmptyState text="Activity logs not found" />
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

CustomerActions.propTypes = {
  logs: PropTypes.array,
  loading: PropTypes.bool,
}

export default CustomerActions
