import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { sidebarContent } from "constants/sidebarContent"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [sidebarItems, setSidebarItems] = useState([])
  const { allowedRoutes } = useSelector(state => ({
    allowedRoutes: state.Login.allowedRoutes,
  }))
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  useEffect(() => {
    const sidebarRoutes = allowedRoutes.filter(route => route.sidebar)
    const sidebarItems = sidebarRoutes.map(route => ({
      ...sidebarContent.find(content => content.path === route.path),
      ...route,
    }))

    const orderedRoutes = sidebarItems.sort((a, b) => a.priority - b.priority)

    setSidebarItems(orderedRoutes)
  }, [allowedRoutes])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {sidebarItems.map((route, i) => (
                  <React.Fragment key={"sidebarRoute" + i}>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        // className={`${route.children?.length && "has-arrow"}`}

                        to={route.path}
                      >
                        <i className={route.icon}></i>
                        <span className="mx-2">{props.t(route.label)}</span>
                        <div
                          className={`${
                            route.children?.length && "arrow-down"
                          }`}
                        ></div>
                      </Link>
                      {route.children?.length && (
                        <div
                          className={classname("dropdown-menu", {
                            show: false,
                          })}
                        >
                          {route.children.map((childRoute, i) => (
                            <Link
                              to={`${route.path}${childRoute.path}`}
                              className="dropdown-item"
                              key={i}
                            >
                              {props.t(childRoute.label)}
                            </Link>
                          ))}
                        </div>
                      )}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
