import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getCurrentUser } from "../../store/auth/login/actions"

const Home = props => {
  const dispatch = useDispatch()
  const { defaultRoute, loadingConfig, error } = useSelector(state => ({
    defaultRoute: state.Login.defaultRoute,
    loadingConfig: state.Login.loadingConfig,
    error: state.Login.error,
  }))

  const accessToken = localStorage.getItem("accessToken")
  useEffect(() => {
    if (!defaultRoute && accessToken) {
      dispatch(getCurrentUser())
    }
  }, [])

  useEffect(() => {
    if (defaultRoute) {
      props.history.push(defaultRoute)
    }

    if (!accessToken || error) {
      props.history.push("/login")
    }
  }, [defaultRoute, loadingConfig])

  return <></>
}

Home.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Home)
