import {
  GET_LOGS,
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
  GET_LOG_DETAILS,
  GET_LOG_DETAILS_FAIL,
  GET_LOG_DETAILS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  logs: [],
  loading: false,
  log: null,
  loadingDetails: false,
  loadingEdit: false,
  error: "",
  success: false,
  message: "",
}

const Audit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOGS:
      return {
        ...state,
        loading: true,
      }
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: action.payload,
        error: "",
        message: "",
        success: false,
      }

    case GET_LOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_LOG_DETAILS:
      return {
        ...state,
        log: null,
        loadingDetails: true,
        error: null,
        message: "",
      }
    case GET_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        log: action.payload,
      }

    case GET_LOG_DETAILS_FAIL:
      return {
        ...state,
        log: null,
        loadingDetails: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Audit
