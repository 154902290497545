import { exportTypes } from "constants/exportTypes"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ExportItem from "./export-item"

const Exports = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Exports | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Exports" titleLink="#" breadcrumbItem="Exports" />
          {exportTypes.map((type, index) => (
            <ExportItem exportItem={type} key={index} index={index} />
          ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Exports
