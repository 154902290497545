import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

import {
  Spinner,
  Col,
  Container,
  Row,
  Alert,
  CardBody,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Placeholder,
  Nav,
  CardTitle,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"
import { getUsers as onGetUsers } from "store/users/actions"
import { isEmpty } from "lodash"
import UsersTable from "./users-table"
import LoadingTable from "components/Loading/loading-table"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import useQuery from "hooks/useQuery"
import RolesTable from "./roles-table"
import { getAllRoles } from "helpers/backend_helper"

const UsersList = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const queryTab = query.get("tab") || "users"
  const [activeTab, toggleTab] = useState(queryTab)
  const [roles, setRoles] = useState([])

  const [tabLoading, setTabLoading] = useState(false)
  const { users, error, loading } = useSelector(state => ({
    users: state.users.users,
    error: state.users.error,
    loading: state.users.loading,
  }))
  const updateUrl = tab => {
    let url = new URL(window.location.href)
    url.searchParams.set("tab", tab)
    window.history.replaceState(null, null, url)
  }

  const fetchUsers = args => {
    setTabLoading(true)
    if (!users.length || args?.force) {
      dispatch(onGetUsers())
    }
    setTabLoading(false)
  }

  const fetchRoles = async () => {
    setTabLoading(true)
    if (!roles.length) {
      // TODO move it to dispatch
      const response = await getAllRoles()
      setRoles(Object.values(response.data))
    }
    setTabLoading(false)
  }

  useEffect(() => {
    switch (activeTab) {
      case "users":
        return fetchUsers()
      case "roles":
        return fetchRoles()
      case "invitations":
        return null
    }
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title> Users Management | Gazal</title>
      </MetaTags>
      <Container fluid>
        <Restricted
          to="customers:VIEW_ALL_CUSTOMERS"
          fallback={<AccessDenied />}
        >
          <Col lg={10}>
            <Card>
              <ul
                className="nav nav-tabs nav-tabs-custom justify-content-start pt-2"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "users",
                    })}
                    onClick={() => {
                      toggleTab("users")
                      // fetchUserDetails()
                      updateUrl("users")
                      fetchUsers()
                    }}
                  >
                    Users
                  </NavLink>
                </NavItem>
                {/* <Restricted to="VIEW_CUSTOMER_TRIPS"> */}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "roles",
                    })}
                    onClick={() => {
                      toggleTab("roles")
                      fetchRoles()
                      updateUrl("roles")
                    }}
                  >
                    Roles
                  </NavLink>
                </NavItem>
                {/* </Restricted> */}
                {/* <Restricted to="VIEW_CUSTOMER_TRANSACTIONS"> */}

                {/* </Restricted> */}
              </ul>

              <TabContent className="p-4" activeTab={activeTab}>
                <TabPane tabId="users">
                  <CardBody>
                    {loading ? (
                      <LoadingTable />
                    ) : (
                      <>
                        <h4 className="header-title mt-0 mb-1">Users</h4>
                        <p className="sub-header">
                          Manage users of Admin Portal
                        </p>
                        <hr />
                        <UsersTable users={users} refetch={fetchUsers} />
                      </>
                    )}
                  </CardBody>
                </TabPane>

                <TabPane tabId="roles">
                  {tabLoading ? <LoadingTable /> : <RolesTable roles={roles} />}
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Restricted>
      </Container>
    </React.Fragment>
  )
}

UsersList.propTypes = {
  users: PropTypes.array,
}

export default UsersList
