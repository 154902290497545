import { API_ERROR, GET_STATUSES, GET_STATUSES_SUCCESS } from "./actionTypes";

export const getStatuses = () => ({
    type: GET_STATUSES
})

export const getStatusesSuccess = data => ({
    type: GET_STATUSES_SUCCESS,
    payload: data
})

export const apiError = error => ({
  type: API_ERROR,
  payload: error,
})
