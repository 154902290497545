import React, { useEffect, useMemo, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Badge, Card, CardBody, Container } from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"
import TableContainer from "components/Common/Table/table-container"
import { IdLink, StartDate } from "pages/Settings/Discounts/discounts-col"
import { getRedeemedCodes } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import { Link } from "react-router-dom/cjs/react-router-dom"

const RedeemedCodes = () => {
  const [codes, setCodes] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Status",
        accessor: "redeemed",
        Cell: cellProps => {
          return cellProps.value ? (
            <Badge color="danger">Redeemed</Badge>
          ) : (
            <Badge color="success">Available</Badge>
          )
        },
      },
      {
        Header: "Expires At",
        accessor: "expiresAt",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "Redeemed At",
        accessor: "redeemedAt",
        Cell: cellProps => {
          return <StartDate {...cellProps} />
        },
      },
      {
        Header: "redeemed By",
        accessor: "redeemedBy",
        Cell: cellProps => {
          return <IdLink {...cellProps} path="/customer-detail" />
        },
      },
      {
        Header: "Voucher",
        accessor: "voucherId",
        Cell: cellProps => {
          return <IdLink {...cellProps} path="/voucher-details" />
        },
      },
    ],
    []
  )

  useEffect(() => {
    getRedeemedCodes()
      .then(({ data }) => setCodes(data))
      .catch(err =>
        showToastMessage({
          success: false,
          message: "error retrieving redeeemd codes",
        })
      )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Redeemed Codes | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb
            title="Vouchers"
            titleLink="/vouchers"
            breadcrumbItem="redeemed Codes"
          />
          <Card>
            <CardBody>
              {codes && (
                <TableContainer
                  columns={columns}
                  data={codes}
                  isGlobalFilter={true}
                  customPageSize={10}
                  className="custom-header-css"
                  defaultSort={"metadata.lastSignInTime"}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RedeemedCodes
