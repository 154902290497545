import React from "react"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"
import TransactionsTable from "./transactions-table"
const TransactionsPreview = () => {
  return (
    <React.Fragment>
      <Col lg={12}>
        <div className="d-flex">
          <h4 className="card-title mb-4 flex-grow-1">Recent Transactions</h4>
          <div>
            <Link to="/resellers/all" className="btn btn-primary btn-sm">
              View All <i className="bx bx-right-arrow-alt"></i>
            </Link>
          </div>
        </div>
      </Col>

      <Card>
        <CardBody className="p-4">
          <TransactionsTable />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TransactionsPreview
