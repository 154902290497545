/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Row,
  Col,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  CardFooter,
  UncontrolledCollapse,
} from "reactstrap"
import scooter from "../../assets/images/scooter.png"
import bike from "../../assets/images/bike.png"
import {
  convertBikeBattery,
  convertScooterBattery,
  getBatteryVariant,
  formatSeconds,
} from "utils"
import { isEmpty } from "lodash"
import EmptyState from "components/Common/EmptyState"
import { vehicleStatus } from "../../constants/vehicleStatus"
import { getVehicleDetails, updateVehicle, getB2bPartners } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import useMobile from "hooks/useMobile"
import { getBatteryIconVariant } from "utils"
import useVehicleMap from "hooks/useVehicleMap"
import Restricted from "components/Common/Restricted"

const VehicleDetails = ({ vehicle }) => {
  const isMobile = useMobile()
  const [stations] = useVehicleMap()
  const [other_Menu, setother_Menu] = useState(false)
  // const [status, setStatus] = useState()
  const [b2bPartner, setB2bPartner] = useState("")

  const [updates, setUpdates] = useState({
    stationId: null,
    status: null,
  })
  const [isEdit, setIsEdit] = useState(false)
  const [isStatusDisabled, setIsStatusDisabled] = useState(true)

  const [b2bPartners, setB2bPartners] = useState([])
  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const fetchB2bPartners = async () => {
    const { data } = await getB2bPartners()
    setB2bPartners([...data, { name: "NIL" }])
  }

  const saveEdit = async e => {
    let { name, value } = e.target

    try {
      const { data } = await getVehicleDetails(vehicle.scooterId)
      if (data.status !== vehicle.status && data.status !== updates.status) {
        showToastMessage({
          success: false,
          message: `the vehicle status was changed while you were away, refresh and try again`,
        })
        return
      }

      if (name === "vehicleConfig") {
        setB2bPartner(value)
        value = {
          b2bPartner: value,
          configType: value === "NIL" ? "NIL" : "b2bPartner",
          isExclusive: false,
        }
      }
      await updateVehicle(vehicle.IMEI, { [name]: value })
      setUpdates({ ...updates, [name]: value })

      showToastMessage({
        success: true,
        message: `${name} updated successfully`,
      })
      // }
      if (name === "status" && (value === "LTD" || value === "InUse")) {
        setIsStatusDisabled(true)
      }
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }

    setIsEdit(false)
  }
  useEffect(() => {
    if (vehicle.status === "LTD" || vehicle.status === "InUse") {
      setIsStatusDisabled(true)
    } else {
      setIsStatusDisabled(false)
    }
  }, [vehicle.status])

  useEffect(() => {
    setB2bPartner(
      vehicle.vehicleConfig ? vehicle.vehicleConfig.b2bPartner : "NIL"
    )
  }, [vehicle])

  useEffect(() => {
    fetchB2bPartners().catch(e =>
      showToastMessage({
        success: false,
        message: "error fetching b2bPartners",
      })
    )
  }, [])

  return (
    <>
      {/* <CardBody> */}
      {/* <div> */}
      {isEmpty(vehicle) ? (
        <EmptyState text="No vehicle found, try another search" />
      ) : (
        <CardBody className="p-4">
          <Row>
            <Col lg={6} xs={8}>
              <h5 className="card-title ">Vehicle Details</h5>
            </Col>
            <Col lg={6} xs={4}>
              <ul className="list-inline user-chat-nav text-end mb-0">
                <li className="list-inline-item">
                  <Dropdown
                    isOpen={other_Menu}
                    toggle={toggleOther}
                    // disabled={loadingDetails || error.message}
                  >
                    {/* TODO extract into a component */}
                    <DropdownToggle
                      className="btn btn-outline-dark"
                      tag="p"
                      onClick={e => e.stopPropagation()}
                    >
                      <span className="d-none d-md-inline">Actions</span>
                      <i className="bx bx-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem header>Hardware Actions</DropdownItem>

                      <DropdownItem onClick={() => lockVehicle(vehicle.IMEI)}>
                        Lock
                      </DropdownItem>

                      <Restricted to="commands:UNLOCK_VEHICLE">
                        <DropdownItem
                          onClick={() => unlockVehicle(vehicle.IMEI)}
                        >
                          Unlock
                        </DropdownItem>
                      </Restricted>
                      <DropdownItem
                        onClick={() => unlockVehicleBattery(vehicle.IMEI)}
                      >
                        Battery Unlock
                      </DropdownItem>
                      <DropdownItem onClick={() => buzzVehicle(vehicle.IMEI)}>
                        Buzz
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
          {isMobile ? (
            <>
              <Row>
                <Col xs={7} className="h-100">
                  <Link
                    to={`/vehicle-detail/${vehicle.scooterId}`}
                    target="_blank"
                    // className="text-center"
                  >
                    <h2 className="text-center">{vehicle.scooterId}</h2>
                  </Link>
                  <p className="text-muted text-center text-capitalize ">
                    {vehicle.GPS_Model} {vehicle.vehicleType}
                  </p>
                </Col>

                <Col xs={5} className="m-auto">
                  <Link
                    to={`/vehicle-detail/${vehicle.scooterId}`}
                    target="_blank"
                    className="text-center "
                  >
                    <div>
                      <img
                        src={vehicle.vehicleType === "scooter" ? scooter : bike}
                        alt=""
                        className="img-fluid mb-4"
                        height="60px"
                        width="60px"
                      />
                    </div>
                  </Link>
                </Col>
              </Row>
              <Row>
                <dl className="mb-0">
                  <dt className="col-sm-3">IMEI</dt>
                  <dd className="col-sm-9">{vehicle.IMEI}</dd>

                  <dt className="col-xs-5">Status</dt>
                  <dd className="col-xs-7">
                    <select
                      className="form-select form-select-sm"
                      value={updates.status || vehicle.status}
                      onFocus={() => setIsEdit(true)}
                      onChange={saveEdit}
                      name="status"
                    >
                      <option value={vehicle.status} defaultValue>
                        {vehicle.status}
                      </option>
                      {vehicleStatus.map((s, i) => {
                        if (s.value !== vehicle.status) {
                          return (
                            <option key={i} value={s.value}>
                              {s.label}
                            </option>
                          )
                        }
                      })}
                    </select>
                  </dd>
                  <Link
                    to="#"
                    className="text-primary font-16 float-end my-2"
                    id="moreToggler"
                  >
                    View more <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </dl>
                <UncontrolledCollapse toggler="#moreToggler">
                  <dl className="mb-0">
                    <dt className="col-xs-5">Total Usage</dt>
                    <dd className="col-xs-7">
                      {formatSeconds(vehicle.totalUsageTime)}
                    </dd>
                    <dt className="col-xs-5">Last Used By:</dt>
                    <dd className="col-xs-7">
                      <Link
                        to={`/customer-detail/${vehicle.lastUser}`}
                        target="_blank"
                      >
                        {vehicle.lastUser}
                      </Link>
                    </dd>
                  </dl>
                </UncontrolledCollapse>
              </Row>
              {/* <Row> */}
              <ul className="list-inline user-chat-nav mb-0">
                <li className="list-inline-item">
                  <i
                    className={`${getBatteryIconVariant(
                      convertScooterBattery(vehicle.Battery)
                    )} me-1`}
                  />
                  {convertScooterBattery(vehicle.Battery)} %
                </li>
                <li className="list-inline-item">
                  <i className={`mdi mdi-map-marker me-1`} />
                  {vehicle.stationId}
                </li>
              </ul>
              {/* </Row> */}
            </>
          ) : (
            <>
              <Row className="mt-2">
                <Col lg="7" sm="12">
                  <div>
                    <dl className="text-muted row">
                      <dt className="col-sm-5">IMEI</dt>
                      <dd className="col-sm-7"> {vehicle.IMEI}</dd>
                      <dt className="col-sm-5">Battery</dt>
                      <dd className="col-sm-7">
                        <i
                          className={`mdi mdi-circle text-${
                            vehicle.vehicleType === "scooter"
                              ? getBatteryVariant(vehicle.Battery)
                              : getBatteryVariant(vehicle.lockBattery)
                          } align-middle me-1`}
                        />
                        {`${
                          vehicle.vehicleType === "scooter"
                            ? convertScooterBattery(vehicle.Battery)
                            : convertBikeBattery(vehicle.lockBattery) || "N/A"
                        }%`}
                      </dd>
                      {/* <dt className="col-xs-5">Last Status Change</dt>
                <dd className="col-xs-7">
                  {" "}
                  {formatStandard(vehicle.lastStatusChange)}
                </dd> */}
                      <dt className="col-sm-5">Station</dt>
                      <dd className="col-sm-7">
                        {" "}
                        <select
                          className="form-select form-select-sm"
                          value={updates.stationId || vehicle.stationId}
                          onFocus={() => setIsEdit(true)}
                          onChange={saveEdit}
                          // onChange={sa}
                          name="stationId"
                        >
                          <option value={vehicle.stationId} defaultValue>
                            {vehicle.stationId}
                          </option>
                          {stations.map((s, i) => {
                            if (s.zoneName !== vehicle.stationId) {
                              return (
                                <option key={i} value={s.zoneName}>
                                  {s.zoneName}
                                </option>
                              )
                            }
                          })}
                        </select>
                      </dd>
                      <dt className="col-sm-5">Status</dt>
                      <dd className="col-sm-7">
                        {" "}
                        <select
                          disabled={isStatusDisabled}
                          className="form-select form-select-sm"
                          value={updates.status || vehicle.status}
                          onFocus={() => setIsEdit(true)}
                          onChange={saveEdit}
                          name="status"
                        >
                          <option value={vehicle.status} defaultValue>
                            {vehicle.status}
                          </option>
                          {vehicleStatus.map((s, i) => {
                            if (s.value !== vehicle.status) {
                              return (
                                <option key={i} value={s.value}>
                                  {s.label}
                                </option>
                              )
                            }
                          })}
                        </select>
                      </dd>

                      <dt className="col-sm-5">B2B Partner</dt>
                      <dd className="col-sm-7">
                        {" "}
                        <select
                          className="form-select form-select-sm"
                          value={b2bPartner}
                          onFocus={() => setIsEdit(true)}
                          onChange={saveEdit}
                          name="vehicleConfig"
                        >
                          {b2bPartners.map((b, i) => {
                            return (
                              <option key={i} value={b.name}>
                                {b.name}
                              </option>
                            )
                          })}
                        </select>
                      </dd>
                      <dt className="col-sm-5">Total Usage</dt>
                      <dd className="col-sm-7">
                        {formatSeconds(vehicle.totalUsageTime)}
                      </dd>
                      <dt className="col-sm-5">Last Used By:</dt>
                      <dd className="col-sm-7">
                        <Link
                          to={`/customer-detail/${vehicle.lastUser}`}
                          target="_blank"
                        >
                          {vehicle.lastUser}
                        </Link>
                      </dd>
                    </dl>
                  </div>
                </Col>
                <Col
                  lg="5"
                  sm="12"
                  className="align-self-center text-center d-none d-lg-block"
                >
                  <>
                    <Link
                      to={`/vehicle-detail/${vehicle.scooterId}`}
                      target="_blank"
                      // className="text-center"
                    >
                      <h2 className="text-center">{vehicle.scooterId}</h2>
                    </Link>
                    <p className="text-muted text-center text-capitalize ">
                      {vehicle.GPS_Model} {vehicle.vehicleType}
                    </p>
                  </>
                  <Link
                    to={`/vehicle-detail/${vehicle.scooterId}`}
                    target="_blank"
                    // className="text-center"
                  >
                    <div>
                      <img
                        src={vehicle.vehicleType === "scooter" ? scooter : bike}
                        alt=""
                        className="img-fluid d-block m-auto"
                        height="100px"
                        width="100px"
                      />
                    </div>
                  </Link>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      )}
      {/* </div> */}
      {/* </CardBody> */}
    </>
  )
}

export default VehicleDetails
