import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import { showToastMessage } from "components/Common/ToastWrapper"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledAlert,
  Placeholder,
  Alert,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import GoogleMapReact from "google-map-react"
import useQuery from "hooks/useQuery"
import {
  getCustomerDetails,
  getVehicleDetails,
  getStationDetails,
  getTripRoute,
  getDiscount,
  getTripsByTripId,
} from "helpers/backend_helper"
import { getRtdbBackend } from "helpers/rtdb_helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import CardTripSummary from "components/Trip/card-trip-summary"
import CardUserTrip from "components/Trip/card-user-trip"
import Modal from "components/Common/Modal"
import FormEndTrip from "components/Trip/form-end-trip"
import RouteCoordinates from "./route-coordinates"
import SkeletonCardTripSummary from "components/Trip/skeleton-card-trip-summary"
import { getZones as OnGetZones } from "store/actions"

import Restricted from "../../components/Common/Restricted"
import CustomerActions from "components/Trip/customer-actions"
import EmptyState from "components/Common/EmptyState"
import { getTripLogs } from "../../helpers/backend_helper"
import MultiTripHeader from "components/Trip/multi-trip-header"
import CardTripDiscount from "components/Trip/card-trip-discount"
import { unlockVehicle } from "helpers/backend_helper"
import ConfirmModal from "components/Common/ConfirmModal"

const defaultCenter = { lat: 24.7136, lng: 46.6753 } // TODO extract to const
const TripDetails = props => {
  const db = getRtdbBackend()

  const dispatch = useDispatch()

  const { zones, loading: zonesLoading } = useSelector(state => ({
    zones: state.zones.zones,
    loading: state.zones.loading,
  }))

  const query = useQuery()
  const imei = query.get("imei")
  const start = query.get("start")
  const end = query.get("end")
  const tripId = query.get("tripId")
  const userId = query.get("userId")
  const isActive = query.get("isActive")
  const scooterId = query.get("scooterId")

  // TODO- optimize
  const [trip, setTrip] = useState({})

  const [multiTrips, setMultiTrips] = useState([])

  const [tripLoading, setTripLoading] = useState(false)
  const [user, setUser] = useState({})
  const [userLoading, setUserLoading] = useState(false)
  const [stationDetails, setStationDetails] = useState({})
  const [vehicle, setVehicle] = useState({})
  const [vehicleLoading, setVehicleLoading] = useState(false)
  const [coordinates, setCoordinates] = useState([])
  const [coordinatesLoading, setCoordinatesLoading] = useState(false)
  const [center, setCenter] = useState(defaultCenter)
  const [map, setMap] = useState()
  const [maps, setMaps] = useState()
  const [error, setError] = useState("")
  const [mapError, setMapError] = useState("")
  const [loading, setLoading] = useState(false)
  const [endTripConfirmModal, setEndTripConfirmModal] = useState(false)
  const [unlockConfirmModal, setUnlockConfirmModal] = useState(false)
  const [zonesList, setZonesList] = useState([])
  const [logs, setLogs] = useState([])
  const [polygons, setPolygons] = useState([])
  const [zoneInfo, setZoneInfo] = useState()
  const [refreshLoading, setRefreshLoading] = useState(false)

  const [startMarkerView, setStartMarkerView] = useState(null)
  const [lastMarkerView, setLastMarkerView] = useState(null)
  const [pathView, setPathView] = useState(null)

  const fetchZones = () => {
    dispatch(OnGetZones())
  }

  const fetchUserDetails = async userId => {
    setUserLoading(true)
    try {
      const response = await getCustomerDetails(userId)
      const user = response.data
      if (user.hasActiveDiscount) {
        const response = await getDiscount(user.discountId)
        user.discount = response.data
      }

      setUser(user)
    } catch (error) {}

    setUserLoading(false)
  }

  const fetchVehicle = async tripScooterId => {
    try {
      const sId = tripScooterId || scooterId

      const response = await getVehicleDetails(sId)

      setVehicle(response.data)
      setTrip(prev => ({ ...prev, vehicleType: response.data.vehicleType }))

      return response.data
    } catch (error) {}
  }

  const fetchTripRoute = async (startTime, endTime, vehicleIMEI) => {
    setMapError("")
    setError("")
    setCoordinatesLoading(true)

    try {
      const data = {
        startTime: startTime,
        endTime: endTime,
        imei: vehicleIMEI,
      }

      const response = await getTripRoute(data)

      if (!response.data.length) {
        setMapError("Route not found")
        setCoordinatesLoading(false)

        return null
      } else {
        const coordinates = response.data
        const { lat, lon: lng } = coordinates[0]
        setCenter({ lat, lng })

        const route = coordinates.map(({ lat, lon, timestamp }) => ({
          lat: lat,
          lng: lon,
          timestamp,
        }))
        const sortedCoords = route.sort((a, b) => b.timestamp - a.timestamp)

        setCoordinates(sortedCoords)
        setCoordinatesLoading(false)
      }
    } catch (error) {
      if (!vehicleIMEI || !startTime || !endTime) {
        setError("Invalid URL")
      } else {
        setError("Something went wrong")
      }
      setCoordinatesLoading(false)
    }
  }

  const fetchStationDetails = async stationId => {
    const response = await getStationDetails(stationId)
    const stationDetails = response.data
    setStationDetails(stationDetails)
  }

  const renderZones = async () => {
    const colorLegend = {
      operation: "#000000",
      forbidden: "#FF0000",
    }
    const infowindow = new maps.InfoWindow()
    setZoneInfo(infowindow)
    zonesList.forEach(zone => {
      const zonePolygon = new maps.Polygon({
        paths: zone.zoneBoundaries.map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        })),
        strokeColor: colorLegend[zone.zoneType],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colorLegend[zone.zoneType],
        fillOpacity: 0.1,
        // visibility: false,
      })

      zonePolygon.setMap(map)
      maps.event.addListener(zonePolygon, "click", function (event) {
        infowindow.setContent(
          `<div id="content">
            '<div id="siteNotice">
            </div>
            <h5 id="firstHeading" class="firstHeading">${zone.zoneName}</h5>
            <p> ${zone.zoneType} zone</p>
            </div>
            </div>`
          // event.feature.getProperty("letter")
        )
        infowindow.setPosition(event.latLng)
        infowindow.open(map)
      })
      setPolygons(prev => [...prev, zonePolygon])

      if (zone.controlZones) {
        const controlZoneValues = Object.values(zone.controlZones)
        controlZoneValues.forEach(controlZone => {
          const controlZonePolygon = new maps.Polygon({
            paths: controlZone.zoneBoundaries.map(
              ({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
              })
            ),
            strokeColor: colorLegend[controlZone.zoneType],
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: colorLegend[controlZone.zoneType],
            fillOpacity: 0.1,
          })

          controlZonePolygon.setMap(map)
          maps.event.addListener(controlZonePolygon, "click", function (event) {
            infowindow.setContent(
              `<div id="content">
            '<div id="siteNotice">
            </div>
            <h5 id="firstHeading" class="firstHeading">${controlZone.zoneName}</h5>
            <p style="color: red;"> ${controlZone.zoneType} zone</p>
            </div>
            </div>`
              // event.feature.getProperty("letter")
            )
            infowindow.setPosition(event.latLng)
            infowindow.open(map)
          })
          setPolygons(prev => [...prev, controlZonePolygon])
        })
      }
    })
  }

  useEffect(() => {
    if (userId) {
      fetchUserDetails(userId)
      // fetchUserTrips(userId)
    }
  }, [])

  useEffect(() => {
    if (!zones.length) {
      fetchZones()
    } else if (zones && !zonesList.length) {
      setZonesList(zones)
    }
  }, [zones, dispatch])

  useEffect(() => {
    if (zonesList.length && maps) {
      renderZones()
    }
  }, [zonesList])

  const fetchUserTrips = async (userId, tripId) => {
    try {
      // const response = await getCustomerTrips(userId)
      // const trips = response.data
      // let trip = trips.find(trip => trip?.tripId === tripId)
      // if (!trip) {
      //   trip = trips.find(trip => trip?.tripRefId === tripId)
      // }
      // setTrip(trip)

      const trips = await fetchMultiTrips(userId, tripId)
      let trip = trips.find(trip => trip?.tripId === tripId)
      setTrip(trip)
      await fetchLogs(trip.tripId)

      setTripLoading(false)
    } catch (error) {}
  }

  const fetchLogs = async tripId => {
    const logsResponse = await getTripLogs(tripId)
    setLogs(logsResponse.data)
  }

  const fetchMultiTrips = async (userId, tripId) => {
    const response = await getTripsByTripId(tripId, userId)
    const trips = response.data
    setMultiTrips(trips)
    return trips
  }

  useEffect(() => {
    if (userId && tripId) {
      setTripLoading(true)
      if (Boolean(isActive)) {
        db.activeTrip(userId, tripId).on("value", snapshot => {
          setTrip(snapshot.val())
          setTripLoading(false)
          fetchLogs(tripId)
          fetchMultiTrips(userId, tripId)
        })
      } else {
        // fetch trip details here
        fetchUserTrips(userId, tripId)
      }
    }
    return () => {
      setUser({})
      setStationDetails({})
    }
  }, [tripId])

  useEffect(() => {
    if (trip.pickUpStationId && isEmpty(stationDetails)) {
      fetchStationDetails(trip.pickUpStationId)
    }
    if (trip.pickUpStationId && isEmpty(vehicle)) {
      fetchVehicle(trip.scooterId)
    }
  }, [trip])

  const activeContentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<h5 id="firstHeading" class="firstHeading">Last Position Recorded</h5>' +
    "</div>" +
    "</div>"
  const contentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<h5 id="firstHeading" class="firstHeading">Ride Ended</h5>' +
    "</div>" +
    "</div>"

  const startContentString =
    '<div id="content">' +
    '<div id="siteNotice">' +
    "</div>" +
    '<h5 id="firstHeading" class="firstHeading">Ride Started</h5>' +
    "</div>" +
    "</div>"

  const apiIsLoaded = async (map, maps) => {
    setLoading(true)

    setMap(map)
    setMaps(maps)

    let vehicleIMEI = null
    if (imei === "undefined") {
      if (isEmpty(vehicle)) {
        setVehicleLoading(true)
        const fetched = await fetchVehicle()
        setVehicle(fetched)
        setVehicleLoading(false)
        vehicleIMEI = fetched.IMEI
      } else {
        vehicleIMEI = vehicle.IMEI
      }
    } else {
      vehicleIMEI = imei
    }

    await fetchTripRoute(start, end, vehicleIMEI)
    setLoading(false)
  }

  function toggleBounce(marker) {
    if (marker.getAnimation() !== null) {
      marker.setAnimation(null)
    } else {
      marker.setAnimation(maps.Animation.BOUNCE)
    }
  }

  const handleChangeMultiTrip = async trip => {
    setLoading(true)
    setTrip(trip)
    const { IMEI } = await fetchVehicle(trip.scooterId)
    const { bookingTime, dropOffTime, tripId } = trip

    let url = new URL(window.location.href)
    url.searchParams.set("start", bookingTime)
    url.searchParams.set("end", dropOffTime)
    url.searchParams.set("tripId", tripId)
    url.searchParams.set("imei", IMEI)
    window.history.replaceState(null, null, url)

    await fetchTripRoute(bookingTime.toString(), dropOffTime.toString(), IMEI)
    await fetchLogs(tripId)
    setLoading(false)
  }

  const handleUnlockVehicle = IMEI => {
    unlockVehicle(IMEI)
    setUnlockConfirmModal(false)
  }

  useEffect(() => {
    if (coordinates.length) {
      const end = {
        lat: coordinates[0].lat,
        lng: coordinates[0].lng,
      }

      const start = {
        lat: coordinates[coordinates.length - 1].lat,
        lng: coordinates[coordinates.length - 1].lng,
      }
      const endMarkerWindow = new maps.InfoWindow({
        content: imei === "undefined" ? activeContentString : contentString,
      })

      const startMarkerWindow = new maps.InfoWindow({
        content: startContentString,
      })

      if (!lastMarkerView) {
        const endMarker = new maps.Marker({
          position: end,
          title: "Ride Ended",
          animation: maps.Animation.DROP,
          // icon: svgMarker,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
          },
        })
        endMarker.addListener("mouseover", () => {
          toggleBounce(endMarker)
        })
        endMarker.addListener("click", () => {
          map.setCenter(endMarker.getPosition())
          endMarkerWindow.open({
            anchor: endMarker,
            map,
            shouldFocus: false,
          })
        })
        endMarker.addListener("mouseout", () => {
          if (endMarker.getAnimation() !== null) {
            endMarker.setAnimation(null)
          }
        })
        endMarkerWindow.open({
          anchor: endMarker,
          map,
          shouldFocus: false,
        })

        endMarker.setMap(map)
        setLastMarkerView(endMarker)
      } else {
        lastMarkerView.setPosition(end)
      }

      if (!startMarkerView) {
        const firstMarker = new maps.Marker({
          position: start,
          title: "Ride Started",

          animation: maps.Animation.DROP,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
          },
        })
        firstMarker.addListener("mouseover", () => {
          toggleBounce(firstMarker)
        })
        firstMarker.addListener("click", () => {
          map.setCenter(firstMarker.getPosition())
          startMarkerWindow.open({
            anchor: firstMarker,
            map,
            shouldFocus: false,
          })
        })
        firstMarker.addListener("mouseout", () => {
          if (firstMarker.getAnimation() !== null) {
            firstMarker.setAnimation(null)
          }
        })
        startMarkerWindow.open({
          anchor: firstMarker,
          map,
          shouldFocus: false,
        })
        firstMarker.setMap(map)
        setStartMarkerView(firstMarker)
      }

      if (!pathView) {
        const path = coordinates.map(({ lat, lng }) => ({ lat: lat, lng: lng }))

        const vehicleRoute = new google.maps.Polyline({
          path: path,
          geodesic: true,
          strokeColor: "#00a82d",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        })

        vehicleRoute.setMap(map)
        setPathView(vehicleRoute)
      } else {
        const path = pathView.getPath().getArray()
        path.push(new google.maps.LatLng(end))
        pathView.setPath(path)
      }
    }
  }, [coordinates, trip])

  const refreshTripRoute = async trip => {
    setRefreshLoading(true)

    setMapError("")
    let url = new URL(window.location.href)
    const end = Date.now()
    if (
      trip.status === "TRIP_STARTED" ||
      trip.status === "MULTI_TRIP_STARTED"
    ) {
      url.searchParams.set("end", end)
    } else {
    }
    window.history.replaceState(null, null, url)
    const { bookingTime } = trip
    await fetchTripRoute(bookingTime.toString(), end.toString(), vehicle.IMEI)
    setRefreshLoading(false)
  }
  return (
    <React.Fragment>
      <Modal
        show={endTripConfirmModal}
        onCloseClick={() => setEndTripConfirmModal(false)}
        title={`End Ride for trip #${trip?.tripId || trip?.tripRefId}`}
        size="md"
      >
        <FormEndTrip
          refetchLogs={fetchLogs}
          close={() => setEndTripConfirmModal(false)}
          user={user}
          trip={trip}
          showToastMessage={showToastMessage}
        />
      </Modal>
      <ConfirmModal
        show={unlockConfirmModal}
        onCloseClick={() => setUnlockConfirmModal(false)}
        onConfirmClick={() => handleUnlockVehicle(vehicle.IMEI)}
        confirmContents={{
          action: "Unlock",
          resource: " Vehicle",
        }}
      />
      <div className="page-content">
        <MetaTags>
          <title>Trip Details | Gazal App</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Trips"
            titleLink={`/trips/${
              trip.status === "TRIP_STARTED" ||
              trip.status === "MULTI_TRIP_STARTED"
                ? "active"
                : "all"
            }`}
            breadcrumbItem="Trip Details"
          />
          {!loading && error ? (
            <Alert color="danger" role="alert">
              {error}
            </Alert>
          ) : (
            <>
              {multiTrips.length > 1 && !isEmpty(trip) ? (
                <Row>
                  <Col>
                    <MultiTripHeader
                      multiTrips={multiTrips}
                      trip={trip}
                      handleChangeMultiTrip={handleChangeMultiTrip}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col lg={6}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2">Vehicle</p>
                              {vehicleLoading ? (
                                <>
                                  <Placeholder as={Card.Text} animation="glow">
                                    <Placeholder
                                      xs={7}
                                      className="rounded-pill"
                                    />
                                  </Placeholder>
                                </>
                              ) : (
                                <>
                                  {trip.status === "TRIP_STARTED" ||
                                  trip.status === "MULTI_TRIP_STARTED" ? (
                                    <>
                                      <h5 className="mb-2">{trip.scooterId}</h5>
                                      <br />
                                    </>
                                  ) : (
                                    <h5 className="mb-2">{trip.scooterId}</h5>
                                  )}
                                </>
                              )}
                              <div className="mb-0 mt-4 d-flex flex-wrap">
                                {vehicleLoading ? (
                                  <span className="text-muted">
                                    Details
                                    <i className="mdi mdi-arrow-right ms-1"></i>
                                  </span>
                                ) : (
                                  <>
                                    <Link
                                      className="text-success"
                                      to={`/vehicle-detail/${trip.scooterId}`}
                                      target="_blank"
                                    >
                                      <span>
                                        Details
                                        <i className="mdi mdi-arrow-right ms-1"></i>
                                      </span>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="ms-auto avatar-sm">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                <i
                                  className={`mdi mdi-${trip.vehicleType}`}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card className="blog-stats-wid">
                        <CardBody>
                          <div className="d-flex flex-wrap">
                            <div className="me-3">
                              <p className="text-muted mb-2">User</p>
                              {userLoading ? (
                                <>
                                  <Placeholder
                                    as={Card.Text}
                                    animation="glow"
                                    className="rounded"
                                  >
                                    <Placeholder
                                      xs={12}
                                      className="rounded-pill"
                                    />{" "}
                                    <Placeholder
                                      xs={10}
                                      className="rounded-pill"
                                    />{" "}
                                  </Placeholder>
                                </>
                              ) : (
                                <>
                                  <h5
                                    className="mb-1"
                                    style={{
                                      display: "block",
                                      width: "100px",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                    id="userfullname"
                                  >
                                    {user.userName} <br /> {user.userSurname}
                                  </h5>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="userfullname"
                                  >
                                    {user.userName} {user.userSurname}
                                  </UncontrolledTooltip>
                                </>
                              )}
                              <p className="mb-0 mt-4">
                                {userLoading ? (
                                  <span className="text-muted">
                                    Profile
                                    <i className="mdi mdi-arrow-right ms-1"></i>
                                  </span>
                                ) : (
                                  <Link
                                    to={`/customer-detail/${user.userId}`}
                                    target="_blank"
                                  >
                                    <span className="text-success">
                                      Profile
                                      <i className="mdi mdi-arrow-right ms-1"></i>
                                    </span>
                                  </Link>
                                )}
                              </p>
                            </div>

                            <div className="avatar-sm ms-auto">
                              <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                <i className="mdi mdi-account"></i>
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <CardUserTrip
                    vehicle={vehicle}
                    trip={trip}
                    openEndTrip={() => setEndTripConfirmModal(true)}
                    openUnlockVehicle={() => setUnlockConfirmModal(true)}
                    loading={tripLoading}
                  />
                </Col>
                <Col lg={8}>
                  <Row>
                    {trip.status === "TRIP_STARTED" ||
                    trip.status === "MULTI_TRIP_STARTED" ? (
                      <>
                        {!isEmpty(vehicle) &&
                        !isEmpty(stationDetails) &&
                        !isEmpty(trip) &&
                        !isEmpty(user) ? (
                          <CardTripSummary
                            trip={trip}
                            vehicle={vehicle}
                            stationDetails={stationDetails}
                            user={user}
                          />
                        ) : (
                          <SkeletonCardTripSummary />
                        )}
                      </>
                    ) : (
                      <>
                        {!isEmpty(vehicle) &&
                        !isEmpty(user) &&
                        // !isEmpty(stationDetails) &&
                        !isEmpty(trip) ? (
                          <CardTripSummary
                            trip={trip}
                            vehicle={vehicle}
                            stationDetails={stationDetails}
                            user={user}
                          />
                        ) : (
                          <SkeletonCardTripSummary />
                        )}
                      </>
                    )}
                  </Row>
                  <Restricted to="trips:FETCH_TRIP_ROUTE">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <div className="d-flex align-items-center mb-4">
                              <CardTitle className="card-title flex-grow-1">
                                Trip Route
                              </CardTitle>
                              {(trip.status === "TRIP_STARTED" ||
                                trip.status === "MULTI_TRIP_STARTED") && (
                                <>
                                  <a
                                    href="#"
                                    className="btn btn-outline-dark me-1"
                                    id="refreshTooltip"
                                    onClick={() => refreshTripRoute(trip)}
                                  >
                                    {refreshLoading ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      <>
                                        <i className="mdi mdi-refresh"></i>{" "}
                                        <span>Refresh</span>
                                      </>
                                    )}
                                  </a>
                                  <UncontrolledTooltip target="#refreshTooltip">
                                    Refresh route coordinates
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </div>
                            {mapError ? (
                              <div
                                style={{ height: "430px", width: "100%" }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                <EmptyState text={mapError} />
                              </div>
                            ) : (
                              <div
                                style={{ height: "430px", width: "100%" }}
                                id="#tripRouteMap"
                              >
                                <GoogleMapReact
                                  bootstrapURLKeys={{
                                    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                                  }}
                                  defaultCenter={defaultCenter}
                                  defaultZoom={16}
                                  center={center}
                                  yesIWantToUseGoogleMapApiInternals
                                  onGoogleApiLoaded={({ map, maps }) => {
                                    apiIsLoaded(map, maps)
                                  }}
                                ></GoogleMapReact>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Restricted>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <CardTripDiscount trip={trip} loading={loading} />
                </Col>
                <Restricted to="trips:FETCH_TRIP_ROUTE">
                  <Col lg={4}>
                    <RouteCoordinates
                      coordinates={coordinates}
                      loading={coordinatesLoading}
                    />
                  </Col>
                </Restricted>
                <Restricted to="trips:VIEW_LOGS">
                  <Col lg={4}>
                    <CustomerActions logs={logs} loading={loading} />
                  </Col>
                </Restricted>
              </Row>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TripDetails.propTypes = {
  google: PropTypes.object,
}

export default TripDetails

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
//     LoadingContainer: LoadingContainer,
//     v: "3",
//   })(Trips)
// )
