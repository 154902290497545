import CopyToClipboard from "components/Common/CopyToClipboard"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import { tripStatus } from "constants/tripStatus"
import { getZones } from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {
  Comparator,
  dateFilter,
  numberFilter,
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { getRemoteTrips } from "store/trips/actions"
import { formatSeconds, formatStandard, substituteComparator } from "utils"

const RemoteTripsTable = ({ history }) => {
  var node = useRef()
  const dispatch = useDispatch()

  const { trips, loading, pageInfo } = useSelector(state => ({
    trips: state.trips.trips,
    loading: state.trips.loading,
    pageInfo: state.trips.pageInfo,
  }))

  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(0)
  const [stations, setStations] = useState([])

  const customTotal = (from, to, size) => (
    <TotalPreview
      from={from}
      to={to}
      size={size}
      entity="Trips"
      loading={loading}
    />
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setSizePerPage(page)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const keyField = "tripId"

  const tripsListColumns = [
    {
      text: "Trip ID",
      dataField: "tripId",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <h5 className="font-size-14 mb-1">
            <CopyToClipboard text={trip?.tripId || trip?.tripRefId}>
              {trip?.tripId || trip?.tripRefId}
            </CopyToClipboard>
          </h5>
        )
      },
    },
    {
      text: "Vehicle ID",
      dataField: "scooterId",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/vehicle-detail/${trip.scooterId}`}>{trip.scooterId}</Link>
        </h5>
      ),
    },
    {
      text: "User",
      dataField: "userId",
      sort: true,
      hidden: false,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <span
            className="text-success"
            style={{
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Link to={`/customer-detail/${trip.userId}`}>{cellContent}</Link>
          </span>
        )
      },
    },
    {
      text: "Start Time",
      dataField: "pickUpTime",
      // type: date, // TODO add types everywhere
      sort: true,
      filter: dateFilter({
        withoutEmptyComparatorOption: true,
        defaultValue: {
          comparator: Comparator.GE,
        },
      }),
      filterValue: (cellContent, trip) => new Date(trip.pickUpTime),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">
            {formatStandard(trip.pickUpTime)}
          </h5>
        </>
      ),
    },
    {
      text: "End Time",
      dataField: "dropOffTime",
      filter: dateFilter({
        withoutEmptyComparatorOption: true,
        defaultValue: {
          comparator: Comparator.GE,
        },
      }),
      sort: true,
      filterValue: (cellContent, trip) => new Date(trip.dropOffTime),
      formatter: (cellContent, trip) => (
        <>
          {trip.dropOffTime !== -1 ? (
            <h5 className="font-size-14 mb-1">
              {formatStandard(trip.dropOffTime)}
            </h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Status",
      dataField: "status",
      filter: selectFilter({
        options: tripStatus,
      }),
      sort: true,
      formatter: (cellContent, trip) => (
        <Badge
          className={
            "font-size-12 badge-soft-" +
            tripStatus.find(s => s.value == trip.status)?.color
          }
          pill
        >
          {trip.status}
        </Badge>
      ),
    },
    {
      text: "Pickup Station",
      dataField: "pickUpStationId",
      filter: selectFilter({
        options: stations,
      }),
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">{trip.pickUpStationId}</h5>
        </>
      ),
    },
    {
      text: "Dropoff Station",
      dataField: "dropOffStationId",
      filter: selectFilter({
        options: stations,
      }),
      formatter: (cellContent, trip) => (
        <>
          {" "}
          {trip.status !== "TRIP_STARTED" ? (
            <h5 className="font-size-14 mb-1">{trip.dropOffStationId}</h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Total Fare (SAR)",
      dataField: "totalFare",
      filter: numberFilter(),
      filterValue: (cellContent, trip) => parseFloat(trip.totalFare / 100),
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <>
              <h5 className="font-size-14 mb-1 text-dark">
                {parseFloat(trip.totalFare / 100).toFixed(2)} SAR
              </h5>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Discount",
      dataField: "discountAmount",
      filter: textFilter(),
      filterValue: (cellContent, trip) => parseFloat(trip.discountAmount / 100),
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <>
              {trip.discountAmount ? (
                <h5 className="font-size-14 mb-1 text-dark">
                  <span>
                    <i className="mdi mdi-sale font-size-16 text-primary" />
                  </span>
                  {parseFloat(trip.discountAmount / 100).toFixed(2)} SAR
                </h5>
              ) : (
                "-"
              )}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Total Duration (Minutes)",
      dataField: "totalDuration",
      filter: numberFilter(),
      filterValue: (cellContent, trip) =>
        formatSeconds(trip.totalDuration / 1000),
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <h5 className="font-size-14 mb-1 text-dark">
              {formatSeconds(trip.totalDuration / 1000)}
            </h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <ul className="list-inline user-chat-nav text-end mb-0">
          <li className="list-inline-item d-sm-inline-block">
            <a
              type="button"
              color="info"
              className="btn btn-sm btn-soft-primary"
              onClick={() => {
                history.push(
                  `/trip-detail?imei=${trip.IMEI}&start=${
                    trip.bookingTime
                  }&end=${trip.dropOffTime}&tripId=${
                    trip?.tripId || trip?.tripRefId
                  }&userId=${trip.userId}`
                )
              }}
              id={"viewtooltip"}
            >
              <i className="mdi mdi-eye-outline font-size-16" />
            </a>
            <UncontrolledTooltip placement="top" target="viewtooltip">
              View
            </UncontrolledTooltip>
          </li>
        </ul>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "pickUpTime", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      // history.push(`/customer-detail/${row.userId}`)
    },
  }

  const getFilterVal = (filter, key) => {
    if (filter.filterType === "DATE") {
      return new Date(filter.filterVal.date).getTime()
    }
    if (filter.filterType === "NUMBER") {
      if (key === "totalDuration") {
        return parseFloat(filter.filterVal.number * 1000 * 60) //convert ms to min
      }
      if (key === "totalFare") {
        return parseFloat(filter.filterVal.number * 100) // convert sar to hallalah
      }
      else {
        return parseFloat(filter.filterVal.number)
      }
    }
    return filter.filterVal
  }

  const onTableChange = (type, newState) => {
    const filters = Object.keys(newState.filters).reduce((acc, key) => {
      const filter = newState.filters[key]
      if (filter.filterVal) {
        acc[key] = {
          comparator:
            filter.filterType === "DATE" || filter.filterType === "NUMBER"
              ? substituteComparator(filter.filterVal.comparator)
              : substituteComparator(filter.comparator),
          value: getFilterVal(filter, key),
          filterType: filter.filterType,
        }
      }
      if (
        filter.filterType === "DATE" &&
        filter.filterVal?.date === null &&
        filter.filterVal?.comparator === ""
      ) {
        delete acc[key]
      }
      return acc
    }, {})
    const params = {
      params: {
        order: newState.sortOrder,
        orderBy: newState.sortField,
        page: newState.page,
        pageSize: newState.sizePerPage,
        filters: JSON.stringify(filters),
      },
    }
    dispatch(getRemoteTrips(params))
  }

  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data
      ?.map(({ zoneName }) => ({
        label: zoneName,
        value: zoneName,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    setStations(stations)
  }

  useEffect(() => {
    if (stations.length === 0) {
      fetchStations()
    }
  }, [])

  useEffect(() => {
    if (pageInfo) {
      setPage(Number(pageInfo.page))
      setSizePerPage(pageInfo.pageSize)
      setTotalSize(pageInfo.total)
    }
  }, [pageInfo])

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            custom: true,
            sizePerPageOptionRenderer,
            paginationTotalRenderer: customTotal,
          })}
          keyField={keyField}
          columns={tripsListColumns}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <ToolkitProvider
                keyField={keyField}
                data={trips}
                columns={tripsListColumns}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col lg={6}>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col lg={6}>
                        <div className="float-end">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12 mt-4">
                        <div className="table-responsive invert-scroll">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            rowEvents={rowEvents}
                            keyField={keyField}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap table-hover top-horizontal-scroll"
                            }
                            loading={true}
                            bordered={false}
                            striped={false}
                            responsive
                            ref={node}
                            filter={filterFactory()}
                            filterPosition="top"
                            headerClasses="table-light"
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: false,
                              cellEdit: false,
                            }}
                            onTableChange={(type, newState) =>
                              onTableChange(type, newState)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

RemoteTripsTable.propTypes = {
  history: PropTypes.object,
}

export default withRouter(RemoteTripsTable)
