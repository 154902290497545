import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  EDIT_CUSTOMER,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAIL,
} from "./actionTypes"

export const getCustomers = config => ({
  type: GET_CUSTOMERS,
  payload: config
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const editCustomer = updates => ({
  type: EDIT_CUSTOMER,
  updates,
})

export const editCustomerSuccess = user => ({
  type: EDIT_CUSTOMER_SUCCESS,
  payload: user,
})

export const editCustomerFail = error => ({
  type: EDIT_CUSTOMER_FAIL,
  payload: error,
})
