import { API_ERROR, GET_STATUSES, GET_STATUSES_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  statuses: [],
  loading: false,
  error: null,
}

const statuses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATUSES:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: action.payload.data,
        loading: false,
      }
    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default statuses
