import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  FormGroup,
  Spinner,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"

import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"

import { createVoucher, updateVoucher } from "helpers/backend_helper"
import { showToastMessage } from "../Common/ToastWrapper"

const Offsymbol = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    </>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}

const FormVoucher = ({ toggle, voucher, isEdit, refetch }) => {
  const [loading, setLoading] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (voucher && voucher.title) || "",
      value: (voucher && voucher.value) || "",
      redeemLimit: (voucher && voucher.redeemLimit) || 0,
      isActive: (voucher && voucher.isActive) || false,
      description: (voucher && voucher.description) || "",
      expiryType: (voucher && voucher.expiryType) || "months",
      expiryValue: (voucher && voucher.expiryValue) || "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      value: Yup.number()
        .required("Value is required")
        .min(1, "Please enter a number greater than 0"),
      redeemLimit: Yup.number()
        .required()
        .min(1, "Please enter a number greater than 0"),
      description: Yup.string(),
      isActive: Yup.boolean(),
      expiryType: Yup.string().required().oneOf(["years", "months", "days"]),
      expiryValue: Yup.number().required(),
    }),
    onSubmit: values => {
      setLoading(true)
      values.value = parseFloat(values.value)
      if (isEdit) {
        submitEdit(values)
      } else {
        submitCreate(values)
      }
    },
  })

  const dateTypesDropdown = () => {
    return ["Years", "Months", "Days"].map(x => {
      return {
        label: x,
        value: x.toLowerCase(),
      }
    })
  }

  const handleChecked = () => {
    validation.setFieldValue("isActive", !validation.values.isActive)
  }

  const submitEdit = async values => {
    try {
      await updateVoucher(voucher.voucherId, values)
      showToastMessage({
        success: true,
        message: "Voucher Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Something went wrong",
      })
    }
  }
  const submitCreate = async values => {
    try {
      await createVoucher(values)
      showToastMessage({
        success: true,
        message: "Voucher Created",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data?.messages,
        })
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
      }
    }
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        validation.handleSubmit()
        return false
      }}
    >
      <Row>
        <Col lg={6}>
          <h4 className="font-size-14 mb-3">
            {validation.values.isActive ? "Active" : "Draft"}
          </h4>
          <Switch
            uncheckedIcon={<Offsymbol />}
            checkedIcon={<OnSymbol />}
            className="me-1 mb-sm-8 mb-2"
            onColor="#00a82d"
            onChange={handleChecked}
            onBlur={validation.handleBlur}
            checked={validation.values.isActive}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup>
            <Label htmlFor="titleInput">Title</Label>
            <Input
              autoFocus
              type="text"
              className="form-control"
              id="titleInput"
              name="title"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={
                validation.touched.title && validation.errors.title
                  ? true
                  : false
              }
              disabled={isEdit}
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">
                {validation.errors.title}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Label htmlFor="expiryValueInput">Expires After</Label>
          <InputGroup className="mb-1">
            <Input
              name="expiryValue"
              id="expiryValueInput"
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.expiryValue || ""}
              invalid={
                validation.touched.expiryValue && validation.errors.expiryValue
                  ? true
                  : false
              }
            />
            <Select
              style={{ minWidth: "100px" }}
              onChange={val => {
                validation.setFieldValue("expiryType", val.value)
              }}
              onBlur={validation.handleBlur}
              value={dateTypesDropdown().find(
                op => op.value === validation.values.expiryType
              )}
              invalid={
                validation.touched.expiryType && validation.errors.expiryType
                  ? true
                  : false
              }
              // defaultValue={dateTypesDropdown()[1]}
              options={dateTypesDropdown()}
            />

            {validation.touched.expiryValue && validation.errors.expiryValue ? (
              <FormFeedback type="invalid">
                {validation.errors.expiryValue}
              </FormFeedback>
            ) : null}
          </InputGroup>
        </Col>

        <Col lg={3}>
          <FormGroup>
            <Label htmlFor="valueInput">Value (SAR)</Label>
            <Input
              type="number"
              className="form-control"
              id="valueInput"
              name="value"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.value || ""}
              invalid={
                validation.touched.value && validation.errors.value
                  ? true
                  : false
              }
            />
            {validation.touched.value && validation.errors.value ? (
              <FormFeedback type="invalid">
                {validation.errors.value}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>

        <Col lg={3}>
          <FormGroup>
            <Label htmlFor="redeemLimitInput">Redeem Limit</Label>
            <Input
              type="number"
              className="form-control"
              id="redeemLimitInput"
              name="redeemLimit"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.redeemLimit || ""}
              invalid={
                validation.touched.redeemLimit && validation.errors.redeemLimit
                  ? true
                  : false
              }
            />
            {validation.touched.redeemLimit && validation.errors.redeemLimit ? (
              <FormFeedback type="invalid">
                {validation.errors.redeemLimit}
              </FormFeedback>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup>
          <Label htmlFor="textarea">Description</Label>
          <Input
            type="textarea"
            id="textarea"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ""}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
            maxLength="225"
            name="description"
            rows="6"
            height={"auto"}
            placeholder="This textarea has a limit of 225 chars."
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </FormGroup>
      </Row>

      <Row>
        <Col>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-dark save-user"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : "Save"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

FormVoucher.propTypes = {
  voucher: PropTypes.object,
  isEdit: PropTypes.bool,
  refetch: PropTypes.func,
  toggle: PropTypes.any,
}

export default FormVoucher
