import { GoogleMap, Polygon } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap"
import {
  calclulateCenter,
  containerStyle,
  getZoneTypeColor,
  polygonOptions,
} from "./common"
import { deleteControlZone } from "helpers/backend_helper"
import ConfirmModal from "components/Common/ConfirmModal"
import { showToastMessage } from "components/Common/ToastWrapper"

const ControlZoneListItem = ({ zone, refetch }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const center = calclulateCenter(zone.zoneBoundaries)
  const defaultCenter = center || zone.zoneBoundaries[0]

  const handleDeleteZone = async () => {
    try {
      await deleteControlZone(zone.zoneName, zone.zoneUnder)
      showToastMessage({ success: true, message: "Zone deleted successfully" })
      refetch()
    } catch (err) {
      showToastMessage({
        success: false,
        message: err?.response?.data?.messages,
      })
    }
  }

  const getZoneSpeed = speedOrder => {
    switch (speedOrder) {
      case 1:
        return "15 km/h"

      case 2:
        return "20 km/h"

      case 3:
        return "25 km/h"

      default:
        return "15 km/h"
    }
  }

  return (
    <div>
      <Row key={zone.zoneName}>
        <Card>
          <CardBody>
            <Row>
              <Col md={7} sm={12} xs={12} className={"mb-4"}>
                <GoogleMap
                  center={defaultCenter}
                  mapContainerStyle={containerStyle}
                  zoom={13}
                  options={{
                    disableDefaultUI: true,
                  }}
                >
                  <Polygon
                    paths={zone.zoneBoundaries || []}
                    options={polygonOptions}
                  />
                </GoogleMap>
              </Col>
              <Col sm={12} md={5} className="px-3">
                <div className="d-flex flex-column">
                  <h3>{zone.zoneName}</h3>
                  <p className="fs-6 mb-2">Parent Zone: {zone.zoneUnder}</p>
                  <Badge
                    className="mb-3"
                    color={getZoneTypeColor(zone.zoneType)}
                    pill
                    style={{ width: "6rem" }}
                  >
                    {zone.zoneType.toUpperCase()}
                  </Badge>
                  <hr className="m-0" />
                  <table className="table">
                    <thead>
                      <tr>
                        <td></td>
                        <th className="d-flex align-items-center justify-content-center">
                          <span>Details</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Is Allowed End Trip?</td>
                        <td className="d-flex align-items-center justify-content-center">
                          <Badge
                            className={` badge badge-soft-${
                              zone.isAllowEndTrip ? "success" : "danger"
                            }`}
                            pill
                          >
                            {zone.isAllowEndTrip ? (
                              <i className="font-size-16 mdi mdi-check" />
                            ) : (
                              <i className="font-size-16 mdi mdi-close" />
                            )}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <td>Zone Type</td>
                        <td className="d-flex align-items-center justify-content-center">
                          <Badge
                            className={` badge badge-soft-${
                              zone.zoneType === "lowSpeed" ? "info" : "danger"
                            }`}
                            pill
                          >
                            {zone.zoneType === "forbidden"
                              ? "Forbidden"
                              : "Low Speed"}
                          </Badge>
                        </td>
                      </tr>
                      {zone.zoneType === "lowSpeed" && (
                        <tr>
                          <td>Vehicle Speed limit</td>
                          <td className="d-flex align-items-center justify-content-center">
                            {getZoneSpeed(zone.speed)}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Zone Radius</td>
                        <td className="d-flex align-items-center justify-content-center">
                          {zone.zoneRadius} m
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3">
                          <Link
                            to={`/zone-detail/${zone.zoneName}?type=control&zoneUnder=${zone.zoneUnder}`}
                            className="btn-sm btn-rounded btn btn-primary"
                          >
                            View Details
                          </Link>

                          <Button
                            onClick={() => {
                              setShowDeleteModal(true)
                            }}
                            className="btn-sm btn-rounded btn btn-danger mx-1"
                          >
                            Delete Zone
                          </Button>
                          <ConfirmModal
                            confirmContents={{
                              action: "delete",
                              resource: zone.zoneName,
                            }}
                            show={showDeleteModal}
                            onCloseClick={() => setShowDeleteModal(false)}
                            onConfirmClick={handleDeleteZone}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </div>
  )
}

ControlZoneListItem.propTypes = {
  zone: PropTypes.object,
  refetch: PropTypes.func,
}

export default ControlZoneListItem
