import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useGoogleMap } from "@react-google-maps/api"

const MapControl = ({ position, children }) => {
  const map = useGoogleMap()
  const ref = useRef()
  useEffect(() => {
    if (map && ref) {
      map.controls[window.google.maps.ControlPosition[position]].push(
        ref.current
      )
    }
  }, [map, ref])
  return <div ref={ref}>{children}</div>
}

MapControl.propTypes = {
  position: PropTypes.any,
  children: PropTypes.any,
}

export default MapControl
