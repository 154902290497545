import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"

import * as Yup from "yup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  createOperationZone,
  getB2bPartners,
  getLocations,
} from "helpers/backend_helper"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import { showToastMessage } from "components/Common/ToastWrapper"
import {
  calclulateCenter,
  calculatePolygonRadius,
} from "components/Zones/common"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import ZoneMap from "./zone-map"
import { Form, Formik } from "formik"
import {
  FormikCheckbox,
  FormikMultiSelect,
  FormikSelect,
  FormikTextInput,
} from "components/Common/FormikFields"

const AddOperationZone = () => {
  const [bounds, setBounds] = useState([])
  const [locations, setLocations] = useState([])
  const [locationsSelect, setLocationsSelect] = useState([])
  const [b2bPartnersSelect, setB2bPartnersSelect] = useState([])

  const [center, setCenter] = useState({
    // Gazal HQ coordinates
    lat: 24.833723,
    lng: 46.734186,
  })

  const isSharedSelect = [
    { name: "isShared", label: "Yes", value: true },
    { name: "isShared", label: "No", value: false },
  ]

  const configTypeSelect = [
    { name: "configType", label: "B2B Partner", value: "b2bPartner" },
    { name: "configType", label: "Normal", value: "NIL" },
  ]

  const history = useHistory()

  const handleSubmit = async values => {
    if (!bounds.length) {
      showToastMessage({ success: false, message: "Please create a polygon" })
      return
    }

    if (bounds.length < 3) {
      showToastMessage({
        success: false,
        message: "A polygon has a minimum of 3 points",
      })
      return
    }

    const center = calclulateCenter(bounds)
    const radius = calculatePolygonRadius(bounds, center)

    const valuesCopy = structuredClone(values)
    for (const key in valuesCopy) {
      if (key.includes("Time")) {
        valuesCopy[key] *= 1000
      }

      if (key.includes("Fare")) {
        valuesCopy[key] *= 100
      }
    }

    const payload = {
      ...valuesCopy,
      zoneType: "operation",
      zoneName: valuesCopy.zoneName.trim(),
      zoneBoundaries: bounds.map(b => {
        return { latitude: b.lat, longitude: b.lng }
      }),
      zoneRadius: radius,
      lat: center.lat,
      lng: center.lng,
      l: [-1, -1],
      country: "SAU",
      zoneColor: "#000000",
      currency: "SAR",
      hasBuzzer: false,
      isExclusive: false,
      isSuspended: false,
    }

    try {
      await createOperationZone(payload)
      showToastMessage({
        success: true,
        message: "Operation zone created successfully",
      })
      history.push("/zones")
    } catch (err) {
      showToastMessage({ success: false, message: "Problem creating zone" })
    }
  }

  const handleCityChange = e => {
    const location = locations.find(l => l.name_en === e)
    if (!bounds.length) {
      setCenter({ lat: location.lat, lng: location.lon })
    }
  }

  useEffect(() => {
    getLocations()
      .then(ls => {
        setLocations(ls.data)
        setLocationsSelect(
          ls.data.map(l => ({
            name: "city",
            label: l.name_en,
            value: l.name_en,
          }))
        )
      })
      .catch(() => {
        showToastMessage({
          success: false,
          message: `Error retrieving locations`,
        })
      })
  }, [])

  useEffect(() => {
    getB2bPartners()
      .then(b2b => {
        setB2bPartnersSelect(
          b2b.data.map(b => ({
            name: "b2bPartner",
            label: b.name,
            value: b.name,
          }))
        )
      })
      .catch(err =>
        showToastMessage({
          success: false,
          message: "error retrieving b2b partners",
        })
      )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add operation zone | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zones"
            titleLink="/add-operation-zone"
            breadcrumbItem="Add operation zone"
          />
          <Restricted to="zones:VIEW_ZONE" fallback={<AccessDenied />}>
            <>
              <section>
                <Row>
                  <Col lg={7} md={7} sm={12} xs={12}>
                    <Card>
                      <CardBody>
                        <ZoneMap
                          bounds={bounds}
                          setBounds={setBounds}
                          initialCenter={center}
                        />
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        zoneName: "",
                        zoneCity: "",
                        zoneUnlockFare: "",
                        zoneConfig: {
                          configType: "",
                          b2bPartner: "NIL",
                          isShared: false,
                        },
                        bikeInitialFare: "",
                        scooterInitialFare: "",
                        bikeInitialTimeBlock: "",
                        scooterInitialTimeBlock: "",
                        bikeSubsequentFare: "",
                        scooterSubsequentFare: "",
                        bikeSubsequentTimeBlock: "",
                        scooterSubsequentTimeBlock: "",
                      }}
                      validationSchema={Yup.object().shape({
                        zoneName: Yup.string().required("Zone ID is required"),
                        zoneCity: Yup.string().required("City is required"),
                        zoneUnlockFare: Yup.number()
                          .min(0)
                          .required("Unlock Fare is required"),
                        zoneConfig: Yup.object().shape({
                          configType: Yup.string().required(
                            "Config Type is required"
                          ),
                          b2bPartner: Yup.mixed().when("configType", {
                            is: "b2bPartner",
                            then: Yup.mixed()
                              .required("B2B Partner is required")
                              .notOneOf(["NIL"], "B2B Partner is required"),
                            otherwise: Yup.mixed(),
                          }),
                          isShared: Yup.boolean().when("configType", {
                            is: "b2bPartner",
                            then: Yup.boolean().required("Field is required"),
                            otherwise: Yup.boolean(),
                          }),
                        }),
                        bikeInitialFare: Yup.number()
                          .min(0)
                          .required("Bike initial fare is required"),
                        scooterInitialFare: Yup.number()
                          .min(0)
                          .required("Scooter initial fare is required"),
                        bikeInitialTimeBlock: Yup.number()
                          .min(
                            0.1,
                            "Bike initial time block must be greater than 0"
                          )
                          .required("Bike initial time block is required"),
                        scooterInitialTimeBlock: Yup.number()
                          .min(
                            0.1,
                            "Scooter initial time block must be greater than 0"
                          )
                          .required("Scooter initial time block is required"),
                        bikeSubsequentFare: Yup.number()
                          .min(0)
                          .required("Bike subsequent fare is required"),
                        scooterSubsequentFare: Yup.number()
                          .min(0)
                          .required("Scooter subsequent fare is required"),
                        bikeSubsequentTimeBlock: Yup.number()
                          .min(
                            0.1,
                            "Bike subsequent time block must be greater than 0"
                          )
                          .required("Bike subsequent time block is required"),
                        scooterSubsequentTimeBlock: Yup.number()
                          .min(
                            0.1,
                            "Scooter subsequent time block must be greater than 0"
                          )
                          .required(
                            "Scooter subsequent time block is required"
                          ),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {formik => (
                        <Form>
                          <Card className="h-100">
                            <CardBody>
                              <div className="d-flex flex-column">
                                <h3>Add operation zone</h3>
                                <hr />

                                <Row>
                                  <Col lg={12}>
                                    <FormikTextInput
                                      label="Zone ID"
                                      name="zoneName"
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-4">
                                  <Col lg={7}>
                                    <FormikSelect
                                      label="Zone City"
                                      name="zoneCity"
                                      options={locationsSelect}
                                      onChange={handleCityChange}
                                    />
                                  </Col>
                                  <Col lg={5}>
                                    <FormikTextInput
                                      label="Zone Unlock Fare (SAR)"
                                      name="zoneUnlockFare"
                                      type="number"
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3 mb-3">
                                  <Col lg={12}>
                                    <FormikSelect
                                      label="Config Type"
                                      name="zoneConfig.configType"
                                      options={configTypeSelect}
                                      onChange={val => {
                                        if (val === "NIL") {
                                          formik.setFieldValue(
                                            "zoneConfig.b2bPartner",
                                            "NIL"
                                          )
                                          formik.setFieldValue(
                                            "zoneConfig.isShared",
                                            false
                                          )
                                        }
                                      }}
                                    />
                                  </Col>
                                </Row>

                                {formik.values.zoneConfig.configType ===
                                  "b2bPartner" && (
                                  <>
                                    <Row className="mb-3">
                                      <Col lg={8}>
                                        <FormikMultiSelect
                                          label="B2B Partner"
                                          name="zoneConfig.b2bPartner"
                                          options={b2bPartnersSelect}
                                          onChange={x => {
                                            formik.setFieldValue(
                                              "zoneConfig.isShared",
                                              x.length > 1 ? true : false
                                            )
                                          }}
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <FormikSelect
                                          label="Is Shared"
                                          name="zoneConfig.isShared"
                                          options={isSharedSelect}
                                          value={isSharedSelect.find(
                                            x =>
                                              x.value ===
                                              formik.values.zoneConfig.isShared
                                          )}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}

                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>
                                        <span>Scooter </span>{" "}
                                        <i className="mdi mdi-human-scooter me-2"></i>
                                      </th>
                                      <th>
                                        <span>Bike </span>{" "}
                                        <i className="mdi mdi-bike-fast me-2"></i>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td id="scooterInitialFare">
                                        <FormikTextInput
                                          label="Initial Fare (SAR)"
                                          name="scooterInitialFare"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                      <td id="bikeInitialFare">
                                        <FormikTextInput
                                          label="Initial Fare (SAR)"
                                          name="bikeInitialFare"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td id="scooterInitialTimeBlock">
                                        <FormikTextInput
                                          label="Initial Time Block (Seconds)"
                                          name="scooterInitialTimeBlock"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                      <td id="bikeInitialTimeBlock">
                                        <FormikTextInput
                                          label="Initial Time Block (Seconds)"
                                          name="bikeInitialTimeBlock"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td id="scooterSubsequentFare">
                                        <FormikTextInput
                                          label="Subsequent Fare (SAR)"
                                          name="scooterSubsequentFare"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                      <td id="bikeSubsequentFare">
                                        <FormikTextInput
                                          label="Subsequent Fare (SAR)"
                                          name="bikeSubsequentFare"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td id="scooterSubsequentTimeBlock">
                                        <FormikTextInput
                                          label="Subsequent Time Block (Seconds)"
                                          name="scooterSubsequentTimeBlock"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                      <td id="bikeSubsequentTimeBlock">
                                        <FormikTextInput
                                          label="Subsequent Time Block (Seconds)"
                                          name="bikeSubsequentTimeBlock"
                                          type="number"
                                          style={{
                                            width: "60%",
                                            height: "30px",
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <Row>
                                  <Col lg={10}>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="mt-4"
                                    >
                                      Create Zone
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </section>
            </>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddOperationZone)
