import firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("uid", user.uid)
        } else {
          localStorage.removeItem("uid")
        }
      })
    }
  }

  /**
   * get token
   */
  getCurrentIdToken = () => {
    return new Promise((resolve, reject) => {
      // if (firebase.auth().currentUser) {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
              // if (localStorage.getItem("accessToken") !== token) {
              // }
              localStorage.setItem("accessToken", token)
              localStorage.setItem("NEW", token)
              resolve(token)
              return token
            })
        }
      })

      // } else {
      //   const token = localStorage.getItem("accessToken")
      //   localStorage.setItem("poi", token)
      //   if (token) {
      //     resolve(token)
      //   }
      //   reject()
      // }
    })
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (localStorage.getItem("uid")) {
            localStorage.removeItem("uid")
          }
          if (localStorage.getItem("accessToken")) {
            localStorage.removeItem("accessToken")
          }
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({
      hd: "gazal.app",
    })

    return new Promise((resolve, reject) => {
      if (!!provider) {
        firebase
          .auth()
          .signInWithPopup(provider)
          .then(user => {
            resolve(firebase.auth().currentUser)
          })
          .catch(error => {
            firebase.auth().signOut()
            reject(error)
          })
      } else {
        reject(error)
      }
    })
  }

  setLoggeedInUser = user => {
    localStorage.setItem("uid", user.uid)
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("uid")) return null
    return JlocalStorage.getItem("uid")
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
