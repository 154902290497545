const { Modal, ModalHeader, ModalBody, Row, Button } = require("reactstrap")
import React, { useState } from "react"
import { ChromePicker } from "react-color"
import PropTypes from "prop-types"

const ColorPickerModal = ({
  prevColor,
  showModal,
  setShowModal,
  saveChanges,
}) => {
  const [tempColor, setTempColor] = useState(prevColor)

  return (
    <Modal
      isOpen={showModal}
      toggle={() => {
        setShowModal(!showModal)
      }}
      size="sm"
      centered={true}
    >
      <ModalHeader>
        <p>Select Color</p>
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <ChromePicker
            onChange={color => {
              setTempColor(color.hex)
            }}
            color={tempColor}
          />
          <Button
            className="btn btn-success mt-4 w-50"
            onClick={() => {
              saveChanges(tempColor)
              setShowModal(false)
            }}
          >
            Confirm
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ColorPickerModal.propTypes = {
  prevColor: PropTypes.string,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  saveChanges: PropTypes.func,
}

export default ColorPickerModal
