import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const editUser = (uid, updates) => ({
  type: EDIT_USER,
  uid,
  updates,
})

export const editUserSuccess = user => ({
  type: EDIT_USER_SUCCESS,
  payload: user,
})

export const editUserFail = error => ({
  type: EDIT_USER_FAIL,
  payload: error,
})

export const createUser = user => ({
  type: CREATE_USER,
  user,
})

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  payload: user,
})

export const createUserFail = error => ({
  type: CREATE_USER_FAIL,
  payload: error,
})
