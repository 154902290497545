import React from "react"
import PropTypes from "prop-types"
import { Table, Placeholder, Badge } from "reactstrap"
import { formatStandard } from "utils"
const AdditionalInfo = ({ user, loading }) => {
  return (
    <>
      <div className="table-responsive">
        <Table className="table mb-0 table-bordered">
          <tbody>
            <tr>
              <th
                scope="row"
                style={{ width: "50%" }}
                className={"text-capitalize"}
              >
                Language
              </th>
              <td>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={1} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  user.lang
                )}
              </td>
            </tr>

            <tr>
              <th
                scope="row"
                style={{ width: "50%" }}
                className={"text-capitalize"}
              >
                Registration Date
              </th>
              <td>
                {" "}
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={3} className="rounded-pill" />
                  </Placeholder>
                ) : (
                  formatStandard(user.registrationDate)
                )}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                style={{ width: "50%" }}
                className={"text-capitalize"}
              >
                Referral Code
              </th>
              <td>{user.referralCode ?? "-"} </td>
            </tr>
            <tr>
              <th
                scope="row"
                style={{ width: "50%" }}
                className={"text-capitalize"}
              >
                Referral By User ID
              </th>
              <td>{user.referredByUserCode ?? "-"} </td>
            </tr>

            <tr>
              <th
                scope="row"
                style={{ width: "50%" }}
                className={"text-capitalize"}
              >
                Discount Applied
              </th>
              <td>
                {user.hasActiveDiscount ? (
                  <div>
                    <span>{user.discount.discountId}</span> -
                    <strong> {user.discount.value}%</strong> off
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>

            {user.blocked && (
              <tr>
                <th
                  scope="row"
                  style={{ width: "50%" }}
                  className={"text-capitalize"}
                >
                  Block Details
                </th>
                <td>
                  <dl className="row mb-0">
                    <dt className="col-sm-3">Blocked At:</dt>
                    <dd className="col-sm-9">
                      {formatStandard(user.blockTime)}
                    </dd>
                  </dl>
                  <dl className="row mb-0">
                    <dt className="col-sm-3">Reason:</dt>
                    <dd className="col-sm-9">{user.blockReason}</dd>
                  </dl>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  )
}

AdditionalInfo.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
}

export default AdditionalInfo
{
  /* 
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                <i className="mdi mdi-wallet" />
              </span>
            </div>
            <h5 className="font-size-14 mb-0">Balance</h5>
          </div>

          <Row>
            <div className="col-lg-6">
              <div className="text-muted mt-3">
                <p>Current Credits</p>
                <h4>{user.credits}</h4>
              </div>
            </div>
          </Row>
        </div> */
}
