/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const EDIT_USER = "EDIT_USER_FAIL"
export const EDIT_USER_SUCCESS = "EDIT_USER_FAIL"
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"

export const CREATE_USER = "CREATE_USER_FAIL"
export const CREATE_USER_SUCCESS = "CREATE_USER_FAIL"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"
