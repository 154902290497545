import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { CardBody, Col, Row, Badge, Button } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { showToastMessage } from "components/Common/ToastWrapper"
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
// import overlayFactory from "react-bootstrap-table2-overlay"
import { isEmpty } from "lodash"

import logoSecondary from "../../assets/images/logo-secondary.png"
import { formatStandard } from "../../utils/formatDate"
import { tripStatus } from "constants/tripStatus"
import Modal from "components/Common/Modal"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import FormEndTrip from "components/Trip/form-end-trip"
import useQuery from "hooks/useQuery"
import {
  getBatteryVariant,
  convertBikeBattery,
  convertScooterBattery,
} from "utils"
import CopyToClipboard from "components/Common/CopyToClipboard"

const ActiveTripsTable = ({ trips, loading, history }) => {
  const [selectedTrip, setSelectedTrip] = useState({})
  const [endTripModal, setEndTripModal] = useState(false)

  const [stations, setStations] = useState([])
  let url = new URL(window.location.href)
  const query = useQuery()
  const sizePerPage = 10
  const page = query.get("page") ? Number(query.get("page")) : 1
  const pageSize = query.get("pageSize")
    ? Number(query.get("pageSize"))
    : sizePerPage
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)
  const { SearchBar } = Search
  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="Active Trips" />
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
          url.searchParams.set("pageSize", page)
          window.history.replaceState(null, null, url)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: trips.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    page: currentPage,
    sizePerPageOptionRenderer,
  }
  const defaultSorted = [
    {
      dataField: "bookingTime", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const tripsListColumns = [
    {
      dataField: "view",
      isDummyField: true,
      text: "Actions",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <ul className="list-inline user-chat-nav text-end mb-0">
          <li
            className="list-inline-item"
          >
            <Button
              type="button"
              color="danger"
              className="btn-sm btn-rounded"
              onClick={() => {
                setSelectedTrip(trip)
                setEndTripModal(true)
              }}
            >
              End Trip
            </Button>
          </li>
          <li
            className="list-inline-item"
          >
            <Link
              to={`/trip-detail?imei=${trip.IMEI}&start=${
                trip.bookingTime
              }&end=${trip.dropOffTime || Date.now()}&tripId=${
                trip.tripId
              }&userId=${trip.userId}&scooterId=${
                trip.scooterId
              }&isActive=true`}
              target="_blank"
              className="btn-sm btn-rounded btn btn-primary"
            >
              Details
            </Link>
          </li>
        </ul>
      ),
    },
    {
      text: "Vehicle ID",
      dataField: "scooterId",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <Link to={`/vehicle-detail/${trip.scooterId}`}>
          <h5 className="text-primary">{trip.scooterId}</h5>
        </Link>
      ),
    },
    {
      text: "Trip ID",
      dataField: "tripId",
      sort: true,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <span
          className="text-dark"
          style={{
            display: "block",
            width: "100px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <CopyToClipboard text={trip?.tripId || trip?.tripRefId}>
            {trip?.tripId || trip?.tripRefId}
          </CopyToClipboard>
        </span>
      ),
    },
    {
      text: "Customer",
      dataField: "userId",
      sort: true,
      filter: textFilter(),
      //   filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <span
            className="text-success"
            style={{
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <CopyToClipboard text={trip?.userId || ""}>
              <Link to={`/customer-detail/${trip.userId}`}>{cellContent}</Link>
            </CopyToClipboard>
          </span>
        )
      },
    },
    {
      text: "Balance",
      dataField: "balance",
      sort: true,
      filter: textFilter(),
      //   filter: textFilter(),
      // eslint-disable-next-line react/display-name
      // filterValue: (cellContent, trip) => `${trip.countryCode}${trip.contact}`,
      formatter: (cellContent, trip) => (
        <span>
          {(trip.balance / 100).toFixed(2)} <strong>SAR</strong>
        </span>
      ),
    },
    {
      text: "Phone Number",
      dataField: "contact",
      sort: true,
      filter: textFilter(),
      //   filter: textFilter(),
      // eslint-disable-next-line react/display-name
      filterValue: (cellContent, trip) => `${trip.countryCode} ${trip.contact}`,
      formatter: (cellContent, trip) => {
        return (
          <span className="text-success">
            {/* TODO add country code */}
            <CopyToClipboard text={`${trip.countryCode} ${trip.contact}` || ""}>
              <a href={`tel:${trip.countryCode} ${trip.contact}`}>
                {`${trip.countryCode} ${trip.contact}`}
              </a>
            </CopyToClipboard>
          </span>
        )
      },
    },
    {
      text: "Battery Level",
      dataField: "battery",
      sort: true,
      filter: textFilter(),
      //   filter: textFilter(),
      // eslint-disable-next-line react/display-name
      // filterValue: (cellContent, trip) => `${trip.countryCode}${trip.contact}`,
      formatter: (cellContent, trip) => {
        return (
          <>
            <i
              className={`mdi mdi-circle text-${getBatteryVariant(
                trip.vehicleType === "scooter"
                  ? convertScooterBattery(trip.battery)
                  : convertBikeBattery(trip.battery)
              )} align-middle me-1`}
            />

            <span>{`${
              trip.vehicleType === "scooter"
                ? convertScooterBattery(trip.battery)
                : convertBikeBattery(trip.battery) || "N/A"
            }%`}</span>
          </>
        )
      },
    },
    {
      text: "Lock State",
      dataField: "GPS_Status",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <Badge
          className={` badge badge-soft-${
            trip.GPS_Status ? "success" : "danger"
          }`}
          pill
        >
          {trip.GPS_Status ? (
            <>
              <i className="font-size-16 mdi mdi-lock-open-variant" /> unlocked
            </>
          ) : (
            <>
              <i className="font-size-16 mdi mdi-lock" /> locked
            </>
          )}
        </Badge>
      ),
    },

    {
      text: "Start Time",
      dataField: "startTime",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">
            {formatStandard(trip.bookingTime)}
          </h5>
        </>
      ),
    },

    {
      text: "Start Station",
      dataField: "pickUpStationId",
      filter: selectFilter({
        options: stations,
      }),
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">{trip.pickUpStationId}</h5>
        </>
      ),
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <Badge
          className={
            "font-size-12 " +
            tripStatus.find(s => s.value == trip.status)?.badgeClass
          }
          pill
        >
          {trip.status}
        </Badge>
      ),
    },
  ]

  var node = useRef()

  const keyField = "tripId"

  useEffect(() => {
    if (!isEmpty(trips)) {
      const uniqueStations = [
        ...new Set(trips.map(trip => trip.pickUpStationId)),
      ]
      const stations = uniqueStations
        .map(station => ({
          value: station,
          label: station,
        }))
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
          return 0
        })
      setStations(stations)
    }
  }, [trips])

  return (
    <React.Fragment>
      <Modal
        show={endTripModal}
        onCloseClick={() => setEndTripModal(false)}
        title={`End Ride for trip #${
          selectedTrip?.tripId || selectedTrip?.tripRefId
        }`}
        size="md"
      >
        <FormEndTrip
          close={() => setEndTripModal(false)}
          user={{}}
          trip={selectedTrip}
          showToastMessage={showToastMessage}
        />
      </Modal>
      {/* <Container fluid> */}
      <Row>
        {/* <Col lg="12"> */}
        {/* <Card> */}
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={tripsListColumns}
            data={trips}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={trips}
                  columns={tripsListColumns}
                  bootstrap4
                  //   search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col lg={6}>
                          <div className="float-end">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12 mt-4">
                          <div className="table-responsive table-light invert-scroll">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              // selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover top-horizontal-scroll"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                              loading={loading}
                              headerClasses="table-light"
                              filter={filterFactory()}
                              filterPosition="top"
                            />
                          </div>
                          {trips.length === 0 && (
                            <Row>
                              <Col lg={12} className="text-center m-auto">
                                <img
                                  src={logoSecondary}
                                  alt="logo"
                                  height="100"
                                />
                                <p className="text-muted">No trips found</p>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-3">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </CardBody>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  )
}

ActiveTripsTable.propTypes = {
  trips: PropTypes.array,
  loading: PropTypes.bool,
  history: PropTypes.object,
}

export default withRouter(ActiveTripsTable)
