import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody } from "reactstrap"

const ZoneListLoader = props => {
  return (
    <React.Fragment>
      <Card className="d-none d-lg-block">
        <CardBody>
          <ContentLoader
            viewBox="0 0 1200 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="5" y="5" rx="4" ry="4" width="700" height="300" />

            <rect x="730" y="15" rx="10" ry="10" width="169" height="19" />

            <rect x="730" y="75" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="125" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="175" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="225" rx="10" ry="10" width="350" height="19" />
          </ContentLoader>
        </CardBody>
      </Card>
      <Card className="d-none d-lg-block">
        <CardBody>
          <ContentLoader
            viewBox="0 0 1200 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="5" y="5" rx="4" ry="4" width="700" height="300" />

            <rect x="730" y="15" rx="10" ry="10" width="169" height="19" />

            <rect x="730" y="75" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="125" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="175" rx="10" ry="10" width="350" height="19" />

            <rect x="730" y="225" rx="10" ry="10" width="350" height="19" />
          </ContentLoader>
        </CardBody>
      </Card>

      <React.Fragment>
        <Card className="d-md-block d-sm-block d-lg-none">
          <CardBody>
            <ContentLoader
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
              {...props}
            >
              <rect
                x="0"
                y="0"
                rx="4"
                ry="4"
                height="500"
                style={{ width: "100%" }}
              />
            </ContentLoader>
          </CardBody>
        </Card>
      </React.Fragment>
    </React.Fragment>
  )
}

ZoneListLoader.propTypes = {}

export default ZoneListLoader
