/* eslint-disable react/prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"
import randomstring from "randomstring"
import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  FormGroup,
  FormText,
  Spinner,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"

import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"

import { createDiscount, updateDiscount } from "helpers/backend_helper"
import { showToastMessage } from "../Common/ToastWrapper"

const Offsymbol = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    </>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}
const FormDiscount = ({ toggle, discount, isEdit, refetch }) => {
  const [loading, setLoading] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      discountId: (discount && discount.discountId) || "",
      value: (discount && discount.value) || "",
      redeemLimit: discount && discount.redeemLimit || 0,
      company: (discount && discount.company) || "",
      isActive: (discount && discount.isActive) || "",
      startDate: discount && discount.startDate ? discount.startDate : "",
      expiryDate: discount && discount.expiryDate ? discount.expiryDate : "",
      description: (discount && discount.description) || "",
      isActive: discount && discount.isActive,
      logoUrl: (discount && discount.logoUrl) || "",
      // referralCode: (discount && discount.referralCode) || "",
    },
    validationSchema: Yup.object().shape({
      discountId: Yup.string().required("Discount ID is required"),
      value: Yup.number()
        .required("Value is required")
        .min(0, "Please choose a percentage")
        .max(100, "Please choose a percentage"),
      redeemLimit: Yup.number().required()
        .min(1, "Please enter a number greater than 0"),
      company: Yup.string(),
      description: Yup.string(),
      isActive: Yup.boolean(),
      logoUrl: Yup.string(),
      startDate: Yup.number()
        .required("Start is required")
        .moreThan(new Date(), "please choose future date")
,
      expiryDate: Yup.number()
        .required("Expiry is required")
        .moreThan(Yup.ref("startDate"), "End date has to be greater than start date")
    }),
    onSubmit: values => {
      // validation.resetForm()
      // }
      setLoading(true)
      values.value = parseFloat(values.value)
      if (isEdit) {
        values.updatedAt = Date.now()
        submitEdit(values)
      } else {
        values.createdAt = Date.now()
        values.startDate = new Date(values.startDate).valueOf()
        values.expiryDate = new Date(values.expiryDate).valueOf()

        values.referralCode = randomstring.generate()
        submitCreate(values)
      }
      // toggle()
    },
  })

  const handleStartDateChange = (date, dateStr) => {
    validation.setFieldValue("startDate", new Date(dateStr).valueOf())
  }
  const handleExpiryDateChange = (date, dateStr) => {
    validation.setFieldValue("expiryDate", new Date(dateStr).valueOf())
  }

  const handleChecked = () => {
    validation.setFieldValue("isActive", !Boolean(validation.values.isActive))
  }

  const submitEdit = async values => {
    try {
      await updateDiscount(values.discountId, values)
      showToastMessage({
        success: true,
        message: "Discount Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Something went wrong",
      })
    }
  }
  const submitCreate = async values => {
    try {
      const response = await createDiscount(values)
      showToastMessage({
        success: true,
        message: "Discount Created",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data?.messages,
        })
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
      }
    }
  }

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <Col lg={6}>
            <h4 className="font-size-14 mb-3">
              {Boolean(validation.values.isActive) ? "Active" : "Draft"}
            </h4>
            <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor="#00a82d"
              onChange={handleChecked}
              onBlur={validation.handleBlur}
              checked={validation.values.isActive}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <h4 className="font-size-14 mb-3">{"Details"}</h4>
          {validation.values.logoUrl && (
            <Col lg={2}>
              <img
                src={validation.values.logoUrl}
                alt="partner-logo"
                width={"60px"}
                height="auto"
                id="image-preview"
                className="mb-4"
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  autoFocus
                  type="text"
                  className="form-control"
                  id="discountIdInput"
                  name="discountId"
                  // placeholder={"Discount ID"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.discountId || ""}
                  invalid={
                    validation.touched.discountId &&
                    validation.errors.discountId
                      ? true
                      : false
                  }
                  disabled={isEdit}
                />
                <FormText>The unique identifier for the discount</FormText>
                <Label htmlFor="discountIdInput">Discount ID</Label>
                {validation.touched.discountId &&
                validation.errors.discountId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.discountId}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  autoFocus
                  type="text"
                  className="form-control"
                  id="logoUrlInput"
                  name="logoUrl"
                  // placeholder={"Discount ID"}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.logoUrl || ""}
                  invalid={
                    validation.touched.logoUrl && validation.errors.logoUrl
                      ? true
                      : false
                  }
                />
                <Label htmlFor="logoUrlInput">Logo URL</Label>
                {validation.touched.logoUrl && validation.errors.logoUrl ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logoUrl}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="companyInput"
                  name="company"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.company}
                />
                <Label htmlFor="companyInput">Partner (Optional)</Label>
                <FormText>Partner or company discount is created for</FormText>
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="valueInput"
                  name="value"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.value || ""}
                  invalid={
                    validation.touched.value && validation.errors.value
                      ? true
                      : false
                  }
                />
                <Label htmlFor="companyInput">Value (%)</Label>
                {validation.touched.value && validation.errors.value ? (
                  <FormFeedback type="invalid">
                    {validation.errors.value}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup>
              <div className="form-floating mb-3">
                <Input
                  type="number"
                  className="form-control"
                  id="redeemLimitInput"
                  name="redeemLimit"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.redeemLimit || ""}
                  invalid={
                    validation.touched.redeemLimit && validation.errors.redeemLimit
                      ? true
                      : false
                  }
                />
                <Label htmlFor="redeemLimitInput">Redeem Limit</Label>
                {validation.touched.redeemLimit && validation.errors.redeemLimit ? (
                  <FormFeedback type="invalid">
                    {validation.errors.redeemLimit}
                  </FormFeedback>
                ) : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <div className="form-floating mb-3">
              <Input
                type="textarea"
                id="textarea"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }
                maxLength="225"
                name="description"
                rows="6"
                height={"auto"}
                placeholder="This textarea has a limit of 225 chars."
              />
              <Label htmlFor="textarea">Description</Label>
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </FormGroup>
        </Row>
        <hr />
        <h4 className="font-size-14 mb-3">{"Schedule"}</h4>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <div className="position-relative">
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block bg-white"
                    placeholder="Select time"
                    data-enable-time
                    onChange={handleStartDateChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.startDate}
                    invalid={
                      validation.touched.startDate &&
                      validation.errors.startDate
                        ? true
                        : false
                    }
                  />
                </InputGroup>
              </div>
              {/* <div className="form-group mb-3"> */}

              {/* </div> */}
              <FormText>The day the discount will activate</FormText>
              {validation.errors.startDate && (
                <div className="text-danger font-size-11">
                  {validation.errors.startDate}
                </div>
              )}
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <div className="position-relative">
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block bg-white"
                    placeholder="Select Expiry"
                    data-enable-time
                    onChange={handleExpiryDateChange}
                    value={validation.values.expiryDate}
                    // onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.expiryDate &&
                      validation.errors.expiryDate
                        ? true
                        : false
                    }
                  />
                </InputGroup>
              </div>
              <FormText>The day the discount will expire</FormText>
              {validation.errors.expiryDate && (
                <div className="text-danger font-size-11">
                  {validation.errors.expiryDate}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-dark save-user"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Save"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

FormDiscount.propTypes = {
  toggle: PropTypes.any,
}

export default FormDiscount
