import axios from "axios"
import {
  del,
  get,
  post,
  postArray,
  put,
  patch,
  mainClient,
  tcpServer,
  internalServer,
  appServer,
} from "./api_helper"

// -----VEHICLES
export const getVehicles = () => get("/vehicles")

export const getVehicleDetails = async scooterId =>
  get(`/vehicles/${scooterId}`)

export const getVehiclesByStationId = async stationId =>
  get(`/vehicles/station/${stationId}`)

export const updateVehicle = (imei, data) => put(`/vehicles/${imei}`, data)
export const updateMultiVehicles = data => patch(`/vehicles`, data)

// VEHICLES-----

// -----TRIPS
export const getTrips = (IMEI, userId) =>
  get(`/trips/search?imei=${IMEI}&userId=${userId}`)
export const getTripsByTripId = (tripId, userId) =>
  get(`/trips/${tripId}?userId=${userId}`)
export const getActiveTrips = () => get(`/trips/active`)
export const endTrip = data => post(`/trips/end`, data)
export const getTripRoute = data => post(`/trips/route`, data)
export const updateTrip = (tripId, data) => put(`/trips/${tripId}`, data)
export const getTripLogs = tripId => get(`/trips/${tripId}/logs`)
export const getRemoteTrips = (config = {}) =>
  get(`/trips/get`, internalServer, config)
// TRIPS-----

// -----USERS
export const getLoggedInUser = () => get(`/users/me`)
export const getAllUsers = () => get(`/users`)
export const createUser = data => post(`/users`, data)
export const updateUser = (uid, data) => put(`/users/${uid}`, data)
export const deleteUser = uid => del(`/users/${uid}`)
// USERS-----

// -----CUSTOMERS
export const getAllCustomers = (config = {}) => {
  return get(`/customers`, internalServer, config)
}

export const searchCustomers = (contact, userId) =>
  get(`/customers/search?contact=${contact}&userId=${userId}`)

export const getCustomerById = userId =>
  get(`/customers/${userId}`, internalServer)

export const getCustomerTransactions = userId =>
  get(`/customers/${userId}/transactions`)

export const getCustomerPayments = userId =>
  get(`/customers/${userId}/payments`)

export const getCustomerInvoices = userId =>
  get(`/customers/${userId}/invoices`)

export const getInvoicePdf = invoiceId => get(`/customers/${invoiceId}/pdf`)

export const getCustomerDetails = userId => get(`/customers/${userId}`)

export const getCustomerTrips = userId => get(`/customers/${userId}/trips`)

export const updateCustomer = (userId, updates) =>
  put(`/customers/${userId}`, updates, internalServer)

export const updateCustomerStatus = (userId, updates) => {
  return put(`/customers/${userId}/status`, updates)
}

export const addCustomerBalance = data => post(`/customers/addBalance`, data)

export const addCustomerDiscount = (userId, data) =>
  put(`/customers/${userId}/discounts`, data)

export const createCustomerTripInvoice = (userId, tripId) =>
  post(`/customers/${userId}/tripInvoices?tripId=${tripId}`)
// CUSTOMERS-----

// -----COMMANDS
export const refreshBikeState = imei =>
  post(`/commands/refreshBike?IMEI=${imei}`)

export const refreshScooterState = imei =>
  post(`/commands/refreshScooter?IMEI=${imei}`)

export const sendCommand = (imei, command) =>
  post(
    `/commands/sendCommand?IMEI=${imei}&command=${encodeURIComponent(command)}`
  )

export const bulkSendCommand = data =>
  post(`/commands/bulkSendCommand`, data, internalServer)

export const lockVehicle = imei => post(`/commands/lock?IMEI=${imei}`)

export const unlockVehicle = imei => post(`/commands/unlock?IMEI=${imei}`)

export const unlockVehicleBattery = imei =>
  post(`/commands/unlockBattery?IMEI=${imei}`)

export const buzzVehicle = imei => post(`/commands/buzz?IMEI=${imei}`)
// COMMANDS-----

//-------STATIONS
export const getAllStations = () => get(`/stations`, internalServer)
export const getStationDetails = stationId =>
  get(`/stations/${stationId}`, internalServer)
//STATIONS-----

//-------INVOICES
export const createInvoice = (userId, data) =>
  postArray(`/customer/${userId}/od-invoices`, data, appServer)
//INVOICES-----

//-------CONFIG
export const getPackages = () => get(`/config/packages`, internalServer)
export const deletePackage = id => del(`/config/packages/${id}`, internalServer)
export const updatePackage = (id, data) =>
  put(`/config/packages/${id}`, data, internalServer)
export const getPackageById = id =>
  get(`/config/packages/${id}`, internalServer)
export const createPackage = data =>
  post(`/config/packages`, data, internalServer)

export const getRoleConfig = roleName => get(`/config/roles/${roleName}`)
export const getAllRoles = () => get(`/config/roles`)
export const getRoleTypes = () => get(`/config/roleTypes`)
export const addRole = data => post(`/config/roles`, data)
export const updateRole = (name, data) => patch(`/config/roles/${name}`, data)
export const getAllPermissions = () => get(`/config/permissions`)
export const getLocations = () => get(`/config/locations`, internalServer)
export const getDiscounts = () => get(`/config/discounts`, internalServer)
export const createDiscount = data => post(`/config/discounts`, data)
export const getDiscount = discountId =>
  get(`/config/discounts/${discountId}`, internalServer)

export const updateDiscount = (discountId, updates) =>
  put(`/config/discounts/${discountId}`, updates)
export const deleteDiscount = discountId =>
  del(`/config/discounts/${discountId}`)

export const getStatuses = () => get(`/config/status`, internalServer)
export const createStatus = data => post(`/config/status`, data)
export const updateStatus = (statusId, updates) =>
  put(`/config/status/${statusId}`, updates)
export const deleteStatus = statusId => del(`/config/status/${statusId}`)

//CONFIG-----

// -----TRANSACTIONS
export const fixTransaction = transactionId =>
  post(`/transactions/${transactionId}/fix`, {}, internalServer)
// TRANSACTIONS-----

// -----ZONES

export const getZones = () => get("/zones", internalServer)
export const createControlZone = data =>
  post("/zones/control", data, internalServer)
export const createOperationZone = data =>
  post("/zones/operation", data, internalServer)
export const getZoneDetails = (zoneId, type = "operation", zoneUnder) =>
  get(`/zones/${zoneId}?type=${type}&zoneUnder=${zoneUnder}`, internalServer)
export const updateZone = (zoneId, zoneType, data) =>
  put(`/zones?zoneName=${zoneId}&zoneType=${zoneType}`, data, internalServer)
export const deleteOperationZone = zoneName =>
  del(`/zones/operation?zoneName=${zoneName}`)
export const deleteControlZone = (zoneName, zoneUnder) =>
  del(`/zones/control?zoneName=${zoneName}&zoneUnder=${zoneUnder}`)
export const moveControlZones = data => post(`/zones/moveControlZones`, data)

// ZONES------------

// -----AUDIT

export const getLogs = ({ fromDate, toDate }) =>
  get(`/logs?fromDate=${fromDate}&toDate=${toDate}`, internalServer)

export const getLogDetails = logId => get(`/logs/${logId}`, internalServer)

// AUDIT------------

// EXPORT -------------
export const getExport = (path, config) => {
  const url = `/exports/${path}`
  return get(url, internalServer, config)
}

// BLOCK REASONS -------------
export const getBlockReasons = () => get("/config/blockReasons", internalServer)
export const createBlockReason = data =>
  post("/config/blockReasons", data, internalServer)
export const getCustomerBlockLogs = customerId =>
  get(`/logs/customerBlockLogs/${customerId}`, internalServer)
export const createCustomerBlockLog = (customerId, data) =>
  post(`/logs/customerBlockLogs/${customerId}`, data, internalServer)

// VOUCHERS
export const getCustomerVoucherHistory = customerId =>
  get(`/vouchers/customer/${customerId}`, internalServer)
export const getVoucherDetails = voucherId =>
  get(`/vouchers/${voucherId}`, internalServer)
export const getVoucherCodes = voucherId =>
  get(`/vouchers/voucherCodes/${voucherId}`, internalServer)
export const addBulkCodes = data =>
  post(`/vouchers/voucherCodes/bulk`, data, internalServer)
export const redeemVoucher = code =>
  post(`/vouchers/redeem/${code}`, internalServer)
export const getVouchers = () => get(`/vouchers`, internalServer)
export const createVoucher = data => post(`/vouchers`, data, internalServer)
export const updateVoucher = (voucherId, data) =>
  put(`/vouchers/${voucherId}`, data, internalServer)
export const deleteVoucher = voucherId =>
  del(`/vouchers/${voucherId}`, internalServer)
export const getRedeemedCodes = () =>
  get(`/vouchers/voucherCodes/redeemed`, internalServer)
// B2B
export const getB2bPartners = () => get("/b2bPartners", internalServer)
export const getZoneB2bPartners = zone =>
  get(`/b2bPartners/zone/${zone}`, internalServer)
export const createPartner = data => post("/b2bPartners", data, internalServer)
export const updatePartner = (name, data) =>
  put(`/b2bPartners/${name}`, data, internalServer)
export const deletePartner = name => del(`/b2bPartners/${name}`, internalServer)
export const movePartnersToStation = data =>
  put(`/b2bPartners/moveToStation`, data, internalServer)

// Notifications
export const sendCustomerNotif = data =>
  post("/customers/notification", data, internalServer)
