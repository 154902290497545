import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Tooltip } from "reactstrap"

const CopyToClipboard = props => {
  const clipId = `clipboardCopy_${(props?.text || `0`).length}`
  const [isCopied, setIsCopied] = React.useState(false)
  const [direction, setDirection] = React.useState("left")
  const copyIcon = (
    <i
      className={`mdi mdi-content-copy ${props?.iconClassNames}`}
      style={{ cursor: "pointer" }}
    ></i>
  )
  const copyToClipboard = () => {
    setIsCopied(true)
    navigator.clipboard.writeText(props.text)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  useEffect(() => {
    if (props?.position) {
      setDirection(props.position)
    }
  }, [props?.position])
  return (
    <React.Fragment>
      <div className="row" id={clipId} onClick={() => copyToClipboard()}>
        <span className="d-flex flex-row">
          {direction === "right" ? (
            <>
              {props.children}
              {copyIcon}
            </>
          ) : (
            <>
              {copyIcon}
              {props.children}
            </>
          )}
        </span>
      </div>
      <Tooltip placement="left" target={clipId} isOpen={isCopied}>
        Copied
      </Tooltip>
    </React.Fragment>
  )
}

CopyToClipboard.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  iconClassNames: PropTypes.string,
  position: PropTypes.string,
}

export default CopyToClipboard
