export const sidebarContent = [
  {
    path: "/dashboard",
    label: "Dashboard",
    icon: "mdi mdi-map",
    priority: 1,
  },
  {
    path: "/map",
    label: "Map",
    icon: "mdi mdi-map",
    priority: 0,
  },
  {
    path: "/vehicles",
    label: "Vehicles",
    icon: "mdi mdi-car",
    priority: 3,
  },
  {
    path: "/customers",
    label: "Customers",
    icon: "mdi mdi-account-multiple",
    priority: 4,
  },
  {
    path: "/customers/search",
    label: "Search Customers",
    icon: "mdi mdi-account-search",
    priority: 4,
  },
  {
    path: "/trips",
    label: "Trips",
    icon: "mdi mdi-human-scooter",
    priority: 5,
  },
  {
    path: "/settings",
    label: "Settings",
    icon: "mdi mdi-cog",
    priority: 7,
  },
  {
    path: "/trips/active",
    label: "Active Trips",
    icon: "mdi mdi-cog",
    priority: 5,
  },
  {
    path: "/trips/all",
    label: "All Trips",
    icon: "",
    priority: 5,
  },

  {
    path: "/zones",
    label: "Zones",
    icon: "mdi mdi-layers-search",
    priority: 6,
  },
  {
    path: "/exports",
    label: "Exports",
    icon: "mdi mdi-file-export",
    priority: 1,
  },
  {
    path: "/redeemed-codes",
    label: "Redeemed Codes",
    icon: "mdi mdi-ticket-percent",
    priority: 8,
  },
]
