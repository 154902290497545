/* eslint-disable react/prop-types */
import React from "react"
import { Button, Offcanvas, Row, OffcanvasBody } from "reactstrap"

export function MobileCanvas({ canvasOpen, toggleCanvas, children }) {
  return (
    <div id="bottom-canvas-visibility" className="d-lg-none text-center">
      {!canvasOpen && (
        <Button // color="primary"
          className="w-100 floating-shadow bg-primary fixed-bottom"
          onClick={toggleCanvas}
        >
          <i className="mdi mdi-chevron-up font-size-18" />
        </Button>
      )}
      <Offcanvas
        direction="bottom"
        toggle={toggleCanvas}
        isOpen={canvasOpen}
        backdrop={false}
        className="floating-shadow d-lg-none h-50 "
        style={{ backgroundColor: "rgba(255, 255, 255, 0.94)" }}
      >
        <Row>
          <Button
            color="light"
            className="btn-rounded float-end bg-soft bg-primary"
            onClick={toggleCanvas}
          >
            <i className="mdi mdi-chevron-down font-size-18" />
          </Button>
        </Row>
        {/* <OffcanvasHeader toggle={toggleCanvas}></OffcanvasHeader> */}
        <OffcanvasBody>
          <Row>{children}</Row>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
}
