import {
  GET_TRIPS,
  GET_TRIPS_SUCCESS,
  GET_TRIPS_FAIL,
  GET_ACTIVE_TRIPS,
  GET_ACTIVE_TRIPS_SUCCESS,
  GET_ACTIVE_TRIPS_FAIL,
  GET_REMOTE_TRIPS,
} from "./actionTypes"

const INIT_STATE = {
  trips: [],
  activeTrips: [],
  loading: false,
  loadingActiveTrips: false,
  error: {},
}

const trips = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRIPS:
      return {
        ...state,
        loading: true,
      }
    case GET_REMOTE_TRIPS:
      return {
        ...state,
        loading: true,
        error: null
      }
    case GET_TRIPS_SUCCESS:
      return {
        ...state,
        loading: false,
        trips: action.payload.data,
        pageInfo: action.payload.pageInfo,
      }
    case GET_TRIPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_ACTIVE_TRIPS:
      return {
        ...state,
        loadingActiveTrips: true,
      }
    case GET_ACTIVE_TRIPS_SUCCESS:
      return {
        ...state,
        loadingActiveTrips: false,
        activeTrips: action.payload,
      }
    case GET_ACTIVE_TRIPS_FAIL:
      return {
        ...state,
        loadingActiveTrips: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default trips
