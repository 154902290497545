import React from "react"
import PropTypes from "prop-types"

import { motion } from "framer-motion/dist/es/index"
import { Button } from "reactstrap"

export function SidenavHeader({
  setHistory,
  showDefaultMapView,
  showAllVehicles,
  panToPrevious,
}) {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        type: "spring",
        stiffness: 400,
        damping: 20,
        delay: Math.random() * 0.3,
      }}
    >
      <Button
        color="link"
        className="btn-rounded"
        onClick={() => {
          if (history.length > 1) {
            panToPrevious()
            setHistory(prev => prev.slice(0, -1))
          } else {
            showDefaultMapView()
            setHistory([])
            showAllVehicles()
          }
        }}
      >
        <i className="mdi mdi-arrow-left" />
        Back
      </Button>
      <Button
        color="light"
        className="btn-rounded float-end"
        onClick={() => {
          history.length > 1 ? panToPrevious() : showDefaultMapView()
          setHistory([])
          showAllVehicles()
        }}
      >
        <i className="mdi mdi-close" />
      </Button>
    </motion.div>
  )
}

SidenavHeader.propTypes = {
  setHistory: PropTypes.func,
  showDefaultMapView: PropTypes.func,
  showAllVehicles: PropTypes.func,
  panToPrevious: PropTypes.func,
}
