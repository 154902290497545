import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Button,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import EmptyState from "./EmptyState"
const ListTransfer = ({ dataSource, target, saveChanges, colOne, colTwo }) => {
  const [source, setSource] = useState(dataSource)

  const [selectedSource, setSelectedSource] = useState([])
  const [selectedTarget, setSelectedTarget] = useState([])

  const handleSourceCheck = e => {
    const { checked, value } = e.target
    const alreadyChecked = selectedSource.findIndex(
      checked => checked === value
    )

    if (alreadyChecked === -1) {
      setSelectedSource(prev => [...prev, value])
    }
    if (checked) {
      setSelectedSource(selectedSource.filter(item => item !== value))
    }
  }

  const handleTargetCheck = e => {
    const { checked, value } = e.target
    const alreadyChecked = selectedTarget.findIndex(
      checked => checked === value
    )

    if (alreadyChecked === -1) {
      setSelectedTarget(prev => [...prev, value])
    }
    if (checked) {
      setSelectedTarget(selectedTarget.filter(item => item !== value))
    }
  }

  const handleTransfer = () => {
    const newTarget = [...selectedSource, ...target]
    const uniqueTarget = [...new Set(newTarget.filter(target => target !== ""))]
    setSource(prev => prev.filter(name => !selectedSource.includes(name)))
    setSelectedSource([])
    saveChanges(uniqueTarget)
  }

  const handleRevert = () => {
    const newSource = [...selectedTarget, ...source]
    const uniqueSource = [...new Set(newSource.filter(source => source !== ""))]
    setSource(uniqueSource)
    setSelectedTarget([])
    saveChanges(target.filter(name => !selectedTarget.includes(name)))
  }

  const handleTransferAll = () => {
    saveChanges(dataSource)
    // setTarget(dataSource)
    setSource([])
    setSelectedTarget([])
    setSelectedSource([])
  }

  const handleRevertAll = () => {
    saveChanges([])
    setSource(dataSource)
    setSelectedTarget([])
    setSelectedSource([])
  }

  useEffect(() => {
    setSource(prev => prev.filter(name => !target.includes(name)))
  }, [target])

  return (
    <Row>
      <Col md={5}>
        <ListGroupItemHeading tag="p" className="text-muted">
          {colOne}
          <span className="float-end">
            {selectedSource.length} / {source.length}
          </span>
        </ListGroupItemHeading>
        <SimpleBar style={{ maxHeight: "400px" }}>
          <ListGroup>
            {source.length ? (
              source.map((item, i) => (
                <Label check key={i}>
                  <ListGroupItem
                    key={i}
                    className={`${
                      selectedSource.includes(item) ? "bg-primary bg-soft" : ""
                    }`}
                  >
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id={item}
                        onChange={() => {}}
                        onClick={handleSourceCheck}
                        checked={selectedSource.includes(item)}
                        value={item}
                        name={item}
                      />

                      {item}
                    </div>
                  </ListGroupItem>
                </Label>
              ))
            ) : (
              <EmptyListItem text="" />
            )}
          </ListGroup>
        </SimpleBar>
      </Col>
      <Col md={1} className="m-auto">
        <Row>
          <Button
            color="dark"
            outline
            className="w-50 mb-3 m-auto"
            onClick={handleTransferAll}
            disabled={!source.length}
          >
            {">>"}
          </Button>
        </Row>
        <Row>
          <Button
            color="dark"
            outline
            className="w-50 mb-3 m-auto"
            onClick={handleTransfer}
            disabled={!selectedSource.length || !source.length}
          >
            {">"}
          </Button>
        </Row>
        <Row>
          <Button
            color="dark"
            outline
            className="w-50 m-auto mb-3"
            onClick={handleRevert}
            disabled={!selectedTarget.length}
          >
            {"<"}
          </Button>
        </Row>
        <Row>
          <Button
            color="dark"
            outline
            className="w-50 m-auto"
            onClick={handleRevertAll}
            disabled={!target.length}
          >
            {"<<"}
          </Button>
        </Row>
      </Col>
      <Col md={5}>
        <ListGroupItemHeading tag="p" className="text-muted">
          {colTwo}
          <span className="float-end">
            {selectedTarget.length} / {target.length}
          </span>
        </ListGroupItemHeading>
        <SimpleBar style={{ maxHeight: "400px" }}>
          <ListGroup>
            {target.length ? (
              target.map((item, i) => (
                <Label check key={i}>
                  <ListGroupItem
                    key={i}
                    className={`${
                      selectedTarget.includes(item) ? "bg-primary bg-soft" : ""
                    }`}
                  >
                    <div className="form-check form-check-inline">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        onChange={() => {}}
                        onClick={handleTargetCheck}
                        checked={selectedTarget.includes(item)}
                        value={item}
                        name={item}
                      />

                      {item}
                    </div>
                  </ListGroupItem>
                </Label>
              ))
            ) : (
              <EmptyListItem text={`Select from ${colOne}`} />
            )}
          </ListGroup>
        </SimpleBar>
      </Col>
    </Row>
  )
}
const EmptyListItem = ({ text }) => (
  <div
    style={{ height: "400px", maxHeight: "400px" }}
    className="border rounded d-flex align-items-center justify-content-center"
  >
    <EmptyState text={text} />
  </div>
)

ListTransfer.propTypes = {
  dataSource: PropTypes.array,
  target: PropTypes.array,
  saveChanges: PropTypes.func,
  colOne: PropTypes.string,
  colTwo: PropTypes.string,
}

EmptyListItem.propTypes = {
  text: PropTypes.string,
}

export default ListTransfer
