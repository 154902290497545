import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { CardBody, Col, Row, Badge, UncontrolledTooltip } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { formatStandard } from "../../../utils/formatDate"
import EmptyState from "components/Common/EmptyState"
import { getInvoicePdf } from "helpers/backend_helper"
import Restricted from "components/Common/Restricted"
const InvoiceHistory = ({ invoices }) => {
  const [previewLoading, setPreviewLoading] = useState([])
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const { SearchBar } = Search

  const toggleLoadingState = index => {
    const position = previewLoading.indexOf(index)
    let newPreviewLoading = previewLoading.slice()
    if (position !== -1) {
      newPreviewLoading.splice(position, 1)
    } else {
      newPreviewLoading = [...previewLoading, index]
    }
    setPreviewLoading(newPreviewLoading)
  }

  const fetchInvoicePdf = async (invoiceId, index) => {
    toggleLoadingState(index)
    const pdfString = await getInvoicePdf(invoiceId)
    let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(pdfString.content) +
        "'></iframe>"
    )
    toggleLoadingState(index)
    // window.open(linkSource)
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} invoices
    </span>
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
        }}
        // style={{ color: "red" }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: invoices?.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
  }
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const invoicesListColumns = [
    {
      text: "Actions",
      isDummyField: true,
      dataField: "isSuccessful",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice, rowIndex) => (
        <Restricted to="customers:VIEW_CUSTOMER_INVOICE_PDF" fallback={"-"}>
          {invoice.name?.includes("INV") ? (
            <a
              onClick={() => {
                fetchInvoicePdf(invoice.id, rowIndex)
              }}
              className="btn btn-sm btn-soft-success"
            >
              {previewLoading.includes(rowIndex) ? (
                <i
                  className="bx bx bx-loader-alt bx-spin bfont-size-16"
                  id="viewtooltip"
                />
              ) : (
                <>
                  <i
                    className="mdi mdi-eye-outline font-size-16"
                    id="viewtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </>
              )}
            </a>
          ) : (
            <button disabled className="btn btn-sm btn-soft-secondary">
              <>
                <i className="mdi mdi-eye-outline font-size-16" />
              </>
            </button>
          )}
        </Restricted>
      ),
    },
    {
      text: "ID",
      dataField: "id",
      sort: true,
      // eslint-disable-next-line react/display-name
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
    },
    {
      text: "Date",
      dataField: "create_date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => (
        <>
          <h5 className="font-size-14">
            {formatStandard(invoice.create_date)}
          </h5>
        </>
      ),
    },
    {
      text: "Total Amount",
      dataField: "amount_total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => (
        <>
          <h5 className="font-size-14">
            {invoice.amount_total.toFixed(2)} SAR
          </h5>
        </>
      ),
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const keyField = "id"

  return (
    <React.Fragment>
      {/* <Container fluid> */}
      <Row>
        {/* <Col lg="12"> */}
        {/* <Card> */}
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={invoicesListColumns}
            data={invoices}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={invoices}
                  columns={invoicesListColumns}
                  bootstrap4
                  //   search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {/* <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                      <Row>
                        <Col lg={6}>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col lg={6}>
                          <div className="float-end">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive table-light invert-scroll">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              // selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover top-horizontal-scroll"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                            />
                          </div>
                          {invoices.length === 0 && (
                            <EmptyState text="No invoices Found" />
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-3">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </CardBody>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  )
}

InvoiceHistory.propTypes = {
  invoices: PropTypes.array,
}

export default withRouter(InvoiceHistory)
