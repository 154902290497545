import { GoogleMap, Polygon } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import {
  calclulateCenter,
  containerStyle,
  getZoneTypeColor,
  polygonOptions,
} from "./common"
import ConfirmModal from "components/Common/ConfirmModal"
import { deleteOperationZone } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import DeleteZoneModal from "./delete-zone-modal"

const OperatingZoneListItem = ({ zone, refetch }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const center = calclulateCenter(zone.zoneBoundaries)
  const defaultCenter = center || zone.zoneBoundaries[0]

  const handleDeleteZone = async () => {
    try {
      await deleteOperationZone(zone.zoneName)
      showToastMessage({ success: true, message: "Zone deleted successfully" })
      refetch()
    } catch (err) {
      showToastMessage({ success: false, message: err.response.data.messages })
    }
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  return (
    <React.Fragment>
      <Row key={zone.zoneName}>
        <Card>
          <CardBody>
            <Row>
              <Col md={7} sm={12} xs={12} className={"mb-4"}>
                <GoogleMap
                  center={defaultCenter}
                  mapContainerStyle={containerStyle}
                  zoom={13}
                  options={{
                    disableDefaultUI: true,
                  }}
                >
                  <Polygon
                    paths={zone.zoneBoundaries || []}
                    options={polygonOptions}
                  />
                </GoogleMap>
              </Col>
              <Col sm={12} md={5} className="px-3">
                <div className="d-flex flex-column">
                  <h3>{zone.zoneName}</h3>
                  <p className="fs-6 mb-2">
                    Number of Control Zones:{" "}
                    {zone.controlZones
                      ? Object.values(zone.controlZones).length
                      : 0}
                  </p>
                  <Badge
                    className="mb-3"
                    color={getZoneTypeColor(zone.zoneType)}
                    pill
                    style={{ width: "6rem" }}
                  >
                    {zone?.zoneType?.toUpperCase()}
                  </Badge>

                  <hr className="m-0" />
                  <table className="table">
                    <thead>
                      <tr>
                        <td></td>
                        <th>
                          <span>Scooter </span>{" "}
                          <i className="mdi mdi-human-scooter me-2"></i>
                        </th>
                        <th>
                          <span>Bike </span>{" "}
                          <i className="mdi mdi-bike-fast me-2"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Initial Fare</td>
                        <td>{zone.scooterInitialFare}</td>
                        <td>{zone.bikeInitialFare}</td>
                      </tr>
                      <tr>
                        <td>Initial Time Block</td>
                        <td>{zone.scooterInitialTimeBlock}</td>
                        <td>{zone.bikeInitialTimeBlock}</td>
                      </tr>
                      <tr>
                        <td>Subsequent Fare</td>
                        <td>{zone.scooterSubsequentFare}</td>
                        <td>{zone.bikeSubsequentFare}</td>
                      </tr>
                      <tr>
                        <td>Subsequent Time Block</td>
                        <td>{zone.scooterSubsequentTimeBlock}</td>
                        <td>{zone.bikeSubsequentTimeBlock}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3">
                          <Link
                            to={`/zone-detail/${zone.zoneName}?type=operation`}
                            // target="_blank"
                            className="btn-sm btn-rounded btn btn-primary"
                          >
                            View Details
                          </Link>

                          <Button
                            onClick={() => {
                              setShowDeleteModal(true)
                            }}
                            className="btn-sm btn-rounded btn btn-danger mx-1"
                          >
                            Delete Zone
                          </Button>
                          <Modal
                            isOpen={showDeleteModal}
                            toggle={toggleDeleteModal}
                            centered
                            size="md"
                          >
                            <ModalHeader toggle={toggleDeleteModal} tag="h4">
                              Delete Operation Zone
                            </ModalHeader>
                            <ModalBody>
                              <DeleteZoneModal zone={zone} refetch={refetch} />
                            </ModalBody>
                          </Modal>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  )
}

OperatingZoneListItem.propTypes = {
  zone: PropTypes.object,
  center: PropTypes.object,
  refetch: PropTypes.func,
}

export default OperatingZoneListItem
