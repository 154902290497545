import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import TableContainer from "components/Common/Table/table-container"
import ConfirmModal from "components/Common/ConfirmModal"
import { deletePartner } from "../../../helpers/backend_helper"
import { showToastMessage } from "../../../components/Common/ToastWrapper"
import LoadingTable from "components/Loading/loading-table"
import FormPartner from "components/User/FormPartner"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
const PartnersTable = ({ partners, refetch, tabLoading }) => {
  const [partner, setPartner] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const confirmContents = {
    action: "delete",
    resource: "partner",
  }
  const toggle = () => {
    setModal(!modal)
  }

  const handleEditPartner = arg => {
    setIsEdit(true)
    const partner = arg
    setPartner(partner)
    toggle()
  }

  const handleDeletePartner = partner => {
    setConfirmDelete(true)
    setPartner(partner)
  }

  const confirmDeletePartner = async () => {
    try {
      await deletePartner(partner.name)
      showToastMessage({ success: true, message: "Partner Deleted" })
    } catch (error) {
      showToastMessage({
        success: false,
        message: error.response?.data?.messages || "Something went wrong",
      })
    }
    refetch({ force: true })
    setConfirmDelete(false)
  }
  const handlePartnerClick = () => {
    setPartner()
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Restricted to="b2bPartners:UPDATE_PARTNER">
                <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const voucherData = cellProps.row.original
                    handleEditPartner(voucherData)
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </Restricted>

              <Restricted to="b2bPartners:DELETE_PARTNER">
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const partnerData = cellProps.row.original
                    handleDeletePartner(partnerData)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </Restricted>
            </div>
          )
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "Discount ID",
        accessor: "discountRefId",
        filterable: false,
      },
      {
        Header: "Minimum Credit (SAR)",
        accessor: "minCredits",
        Cell: cellProps => cellProps.value / 100,
      },
      {
        Header: "Default Zone",
        accessor: "defaultZone",
      },
      {
        Header: "bypass Credit Check",
        accessor: "bypassCreditCheck",
        Cell: cellProps =>
          cellProps.value ? (
            <Badge color="success">True</Badge>
          ) : (
            <Badge color="danger">False</Badge>
          ),
      },
    ],
    []
  )
  return (
    <Restricted to="b2bPartners:VIEW_ALL_PARTNERS" fallback={<AccessDenied />}>
      <Card>
        <CardBody>
          <h4 className="header-title mt-0 mb-1">B2B Partners</h4>
          <p className="sub-header">View or create new Partners</p>
          <hr />
          {tabLoading ? (
            <LoadingTable />
          ) : (
            <Row>
              <ConfirmModal
                show={confirmDelete}
                confirmContents={confirmContents}
                onConfirmClick={confirmDeletePartner}
                onCloseClick={() => setConfirmDelete(false)}
              />
              <Col lg="12">
                <TableContainer
                  columns={columns}
                  data={partners}
                  isGlobalFilter={true}
                  isAddPartnerList={true}
                  handlePartnerClick={handlePartnerClick}
                  customPageSize={10}
                  className="custom-header-css"
                  defaultSort={"metadata.lastSignInTime"}
                />

                <Modal isOpen={modal} toggle={toggle} size="lg">
                  <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? "Edit Partner" : "Add Partner"}
                  </ModalHeader>
                  <ModalBody>
                    <FormPartner
                      toggle={toggle}
                      partner={partner}
                      isEdit={isEdit}
                      refetch={refetch}
                    />
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Restricted>
  )
}
PartnersTable.propTypes = {
  partners: PropTypes.array,
  tabLoading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default PartnersTable
