import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { CardBody, Col, Row, Badge } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { formatStandard } from "../../../utils/formatDate"
import EmptyState from "components/Common/EmptyState"
import { capitalizeString } from "utils/formatString"

const TransactionHistory = ({ transactions }) => {
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} transactions
    </span>
  )
  // function capitalizeString(inputString) {
  //   if (inputString && inputString !== "NIL") {
  //     const parts = inputString.split("_")

  //     const capitalizedParts = parts.map(
  //       part => part.charAt(0).toUpperCase() + part.slice(1)
  //     )

  //     return capitalizedParts.join(" ")
  //   } else {
  //     return inputString
  //   }
  // }

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
        }}
        // style={{ color: "red" }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: transactions.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
  }
  const defaultSorted = [
    {
      dataField: "paymentTime", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const transactionsListColumns = [
    {
      text: "Transaction Type",
      dataField: "transactionType",
      sort: true,
    },
    {
      text: "Offer",
      dataField: "offer",
      sort: true,
      formatter: (cellContent, transaction) => (
        <>
          <h5 className="font-size-14 mb-1">
            {capitalizeString(transaction.offer)}
          </h5>
        </>
      ),
    },
    {
      text: "Date",
      dataField: "paymentTime",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, transaction) => (
        <>
          <h5 className="font-size-14 mb-1">
            {formatStandard(Number(transaction.paymentTime))}
          </h5>
        </>
      ),
    },
    {
      text: "Amount",
      dataField: "paymentAmount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, transaction) => (
        <h5 className="font-size-14 mb-1">
          {transaction?.paymentAmount?.toString().split(" * ").length
            ? (
                transaction?.paymentAmount?.toString().split(" * ")[0] / 100
              ).toFixed(2)
            : transaction?.paymentAmount.toFixed(2)}{" "}
          SAR
        </h5>
      ),
    },
    {
      text: "Pass",
      dataField: "passId",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, transaction) => (
        <h5 className="font-size-14 mb-1">{transaction.passId}</h5>
      ),
    },
  ]

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const keyField = "id"

  return (
    <React.Fragment>
      {/* <Container fluid> */}
      <Row>
        {/* <Col lg="12"> */}
        {/* <Card> */}
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={transactionsListColumns}
            data={transactions}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={transactions}
                  columns={transactionsListColumns}
                  bootstrap4
                  //   search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {/* <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box ms-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row> */}
                      <Row>
                        <Col lg={6}>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col lg={6}>
                          <div className="float-end">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive table-light invert-scroll">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              // selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover top-horizontal-scroll"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                            />
                          </div>
                          {transactions.length === 0 && (
                            <EmptyState text="No transactions Found" />
                          )}
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-3">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </CardBody>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  )
}

TransactionHistory.propTypes = {
  transactions: PropTypes.array,
}

export default withRouter(TransactionHistory)
