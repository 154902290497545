export const tripStatus = [
  {
    label: "COMPLETED",
    value: "COMPLETED",
    color: "info",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge-soft-info",
  },
  {
    label: "DROPOFF_COMPLETION",
    value: "DROPOFF_COMPLETION",
    color: "dark",
    iconClass: "bx bx-medal text-dark",
    badgeClass: "badge-soft-dark",
  },
  {
    label: "TRIP_STARTED",
    value: "TRIP_STARTED",
    color: "success",
    iconClass: "text-light bx bxs-circle bx-flashing",
    badgeClass: "badge bg-success",
  },
  {
    label: "MULTI_TRIP_STARTED",
    value: "MULTI_TRIP_STARTED",
    color: "success",
    iconClass: "text-success bx bxs-circle bx-flashing",
    badgeClass: "badge-soft-success",
  },
  {
    label: "Stray",
    value: "Stray",
    color: "danger",
    iconClass: "bx bx bxs-error-circle text-danger",
    badgeClass: "badge-soft-danger",
  },
  {
    label: "Maintenance",
    value: "Maintenance",
    color: "warning",
    iconClass: "bx bxs-wrench text-warning",
    badgeClass: "badge-soft-warning",
  },
  {
    label: "Charging",
    value: "Charging",
    color: "primary",
    iconClass: "bx bxs-bolt-circle text-primary",
    badgeClass: "badge-soft-primary",
  },
  {
    label: "LTD",
    value: "LTD",
    color: "secondary",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "SLM",
    value: "SLM",
    color: "secondary",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Redistribute",
    value: "Redistribute",
    color: "secondary",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Ops Pickup",
    value: "Ops_PickUp",
    color: "danger",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Ops Dropoff",
    value: "Ops_DropOff",
    color: "info",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Repair Start",
    value: "Repair_Start",
    color: "secondary",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "Repair End",
    value: "Repair_End",
    color: "secondary",
    iconClass: "",
    badgeClass: "badge-soft-secondary",
  },
  {
    label: "N/A",
    value: "NIL",
    color: "dark",
    iconClass: "",
    badgeClass: "badge-soft-dark",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
    color: "dark",
    iconClass: "",
    badgeClass: "badge-soft-dark",
  },
]
