/* eslint-disable react/prop-types */
import { Row, Col, UncontrolledTooltip } from "reactstrap"
import React from "react"
import Restricted from "components/Common/Restricted"
import { useSelector, useDispatch } from "react-redux"
import {
  toggleClusterVisibility,
  toggleVehicleVisibility,
  toggleStationVisibility,
} from "store/actions"
export function FloatingSideButtons({
  handleLocateMeClick,
  handleSelectMultipleClick,
}) {
  const {
    vehiclesView,
    stationsView,
    clusterView,
    rectangleDrawMode,
    polygonDrawMode,
    drawDisabled,
  } = useSelector(state => ({
    vehiclesView: state.map.vehiclesView,
    stationsView: state.map.stationsView,
    clusterView: state.map.clusterView,
    rectangleDrawMode: state.map.rectangleDrawMode,
    polygonDrawMode: state.map.polygonDrawMode,
    drawDisabled: state.map.drawDisabled,
  }))

  const dispatch = useDispatch()
  return (
    <div className="m-2">
      <Row className="mb-3 mt-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-${
              vehiclesView ? "dark" : "white"
            }`}
            id="vehicleTooltip"
            onClick={() => dispatch(toggleVehicleVisibility())}
          >
            <i
              className={`mdi mdi-car-multiple font-size-18 text-${
                vehiclesView ? "white" : "dark"
              }`}
            />
          </button>
          <UncontrolledTooltip placement="left" target="vehicleTooltip">
            {vehiclesView ? "Hide" : "View"} Vehicles
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-${
              stationsView ? "dark" : "white"
            }`}
            id="stationsTooltip"
            onClick={() => dispatch(toggleStationVisibility())}
          >
            <i
              className={`bx bx-map-pin font-size-18 text-${
                stationsView ? "white" : "dark"
              }`}
            />
          </button>
          <UncontrolledTooltip placement="left" target="stationsTooltip">
            {stationsView ? "Hide" : "View"} Stations
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-${
              clusterView ? "dark" : "white"
            }`}
            id="clusterTooltip"
            onClick={() => dispatch(toggleClusterVisibility())}
          >
            <i
              className={`mdi mdi-google-circles-extended font-size-18 text-${
                clusterView ? "white" : "dark"
              }`}
            />
          </button>
          <UncontrolledTooltip placement="left" target="clusterTooltip">
            {clusterView ? "Hide" : "View"} Cluster View
          </UncontrolledTooltip>
        </Col>
      </Row>
      <Restricted to="vehicles:SELECT_VEHICLES">
        <Row className="mb-3">
          <Col lg="2">
            <button
              disabled={drawDisabled}
              className={`floating-shadow btn btn-small bg-${
                polygonDrawMode ? "dark" : "white"
              }`}
              id="polygonPopover"
              onClick={() => handleSelectMultipleClick(1)}
            >
              <i
                className={`mdi mdi-vector-polygon font-size-20 text-${
                  polygonDrawMode ? "white" : "dark"
                }`}
              />
            </button>
            <UncontrolledTooltip placement="left" target="polygonPopover">
              {drawDisabled ? "Max selection exceeded" : "Draw Polygon"}
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg="2">
            <button
              disabled={drawDisabled}
              className={`floating-shadow btn btn-small bg-${
                rectangleDrawMode ? "dark" : "white"
              }`}
              id="rectanglePopover"
              onClick={() => handleSelectMultipleClick(2)}
            >
              <i
                className={`mdi mdi-vector-rectangle font-size-20 text-${
                  rectangleDrawMode ? "white" : "dark"
                }`}
              />
            </button>
            <UncontrolledTooltip placement="left" target="rectanglePopover">
              {drawDisabled ? "Max selection exceeded" : "Draw Rectangle"}
            </UncontrolledTooltip>
          </Col>
        </Row>
      </Restricted>
      <Row className="mb-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-white`}
            id="locateTooltip"
            onClick={handleLocateMeClick}
          >
            <i className={`mdi mdi-crosshairs-gps font-size-18 text-dark`} />
          </button>
          <UncontrolledTooltip placement="left" target="locateTooltip">
            Locate Me
          </UncontrolledTooltip>
        </Col>
      </Row>

      {/* <Row className="mb-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-white`}
            id="locateTooltip" // onClick={handleLocateMeClick}
            disabled
          >
            <i className={`mdi mdi-download font-size-18 text-dark`} />
          </button>
          <UncontrolledTooltip placement="left" target="locateTooltip">
            Locate Me
          </UncontrolledTooltip>
        </Col>
      </Row> */}
      <Row className="mb-3">
        <Col lg="2">
          <button
            className={`floating-shadow btn btn-small bg-white`}
            id="helpPopover"
          >
            <i
              className={`mdi mdi-information-outline font-size-18 text-dark`}
            />
          </button>
          <UncontrolledTooltip placement="left" target="helpPopover">
            Help
          </UncontrolledTooltip>
        </Col>
      </Row>
    </div>
  )
}
