import React from "react"
import { Badge } from "reactstrap"
import { formatStandard } from "utils"
import { roles } from "constants/roles"

const Email = cell => {
  return cell.value ? cell.value : ""
}

const LastActive = cell => {
  return cell.value ? formatStandard(cell.value) : ""
}

const CreatedAt = cell => {
  return cell.value ? formatStandard(cell.value) : ""
}
const Role = cell => {
  return cell.value ? (
    <Badge
      color="success"
      className={`${
        roles.find(role => role.value === cell.value)?.badgeClass || "light"
      } font-size-12`}
    >
      {roles.find(role => role.value === cell.value)?.label}
    </Badge>
  ) : (
    ""
  )
}

const FullName = cell => {
  return <>{`${cell.row.original.firstName} ${cell.row.original.lastName}`}</>
}

const Position = cell => {
  return cell.value ? cell.value : ""
}

const Status = cell => {
  return cell.value ? cell.value : ""
}

// const Role = cell => {
//   return (
//     <>
//       {map(
//         cell.value,
//         (tag, index) =>
//           index < 2 && (
//             <Link
//               to="#"
//               className="badge badge-soft-primary font-size-11 m-1"
//               key={"_skill_" + cell.value + index}
//             >
//               {tag}
//             </Link>
//           )
//       )}
//       {size(cell.value) > 2 && (
//         <Link
//           to="#"
//           className="badge badge-soft-primary font-size-11 m-1"
//           key={"_skill_" + cell.value}
//         >
//           {size(cell.value) - 1} + more
//         </Link>
//       )}
//     </>
//   )
// }

// const Projects = cell => {
//   return cell.value ? cell.value : ""
// }

// const Img = cell => {
//   return (
//     <>
//       {!cell.value ? (
//         <div className="avatar-xs">
//           <span className="avatar-title rounded-circle">
//             {
//             {cell.data[0].name.charAt(0)}
//           </span>
//         </div>
//       ) : (
//         <div>
//           <img className="rounded-circle avatar-xs" src={cell.value} alt="" />
//         </div>
//       )}
//     </>
//   )
// }

export { Email, LastActive, CreatedAt, Role, FullName, Position, Status }
