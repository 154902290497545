import {
  GET_LOGS,
  GET_LOGS_FAIL,
  GET_LOGS_SUCCESS,
  GET_LOG_DETAILS,
  GET_LOG_DETAILS_FAIL,
  GET_LOG_DETAILS_SUCCESS,
} from "./actionTypes"

export const getLogs = (pl) => ({
  type: GET_LOGS,
  payload: pl
})

export const getLogsSuccess = logs => ({
  type: GET_LOGS_SUCCESS,
  payload: logs,
})

export const getLogsFail = error => ({
  type: GET_LOGS_FAIL,
  payload: error,
})

export const getLogDetails = logId => ({
  type: GET_LOG_DETAILS,
  logId,
})

export const getLogDetailsSuccess = log => ({
  type: GET_LOG_DETAILS_SUCCESS,
  payload: log,
})

export const getLogDetailsFail = error => ({
  type: GET_LOG_DETAILS_FAIL,
  payload: error,
})
