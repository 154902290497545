/* eslint-disable react/prop-types */
import { getStatuses, getZones } from "helpers/backend_helper"
import React, { useState, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { Row, Col, Input, Label, InputGroup } from "reactstrap"
import { getB2bPartners } from "helpers/backend_helper"

const AdvancedFilter = props => {
  const { handleFilter, points } = props

  const [filtersCount, setFiltersCount] = useState(0)
  const [stations, setStations] = useState([])
  const [vehicleStatus, setVehicleStatus] = useState([])
  const [b2bPartners, setB2bPartners] = useState([])
  const [batterySubmitDisabled, setBatterySubmitDisabled] = useState(true)
  const selectInputRef = useRef()

  const animatedComponents = makeAnimated()
  const dispatch = useDispatch()

  const defaultFilters = {
    vehicleType: "",
    isRatedOne: null,
    inStation: null,
    vehicleStatus: null,
    startBattery: 0,
    endBattery: 100,
    b2bPartner: null,
  }

  const [filters, setFilters] = useState(defaultFilters)

  const filterData = newFilters => {
    const activeFilters = Object.fromEntries(
      Object.entries(newFilters).filter(([_, v]) => v != null)
    )

    setFiltersCount(Object.values(activeFilters).length)
    handleFilter(newFilters)
  }
  const handleSelectChange = (e, { name }) => {
    let newFilters = { ...filters, [name]: e }
    setFilters(prev => ({
      ...prev,
      [name]: e,
    }))
    filterData(newFilters)
  }

  const handleCheckChange = e => {
    let newFilters = []
    if (e.target.checked) {
      newFilters = { ...filters, [e.target.name]: e.target.value }
      setFilters(newFilters)
    } else {
      newFilters = { ...filters, [e.target.name]: null }
      setFilters(newFilters)
    }

    filterData(newFilters)
  }

  const clearFilters = () => {
    if (selectInputRef?.current) {
      selectInputRef?.current?.select?.clearValue()
    }
    filterData(defaultFilters)
    setFilters(defaultFilters)
  }

  const handleNumberChange = e => {
    if (batterySubmitDisabled) {
      setBatterySubmitDisabled(false)
    }
    let newFilters = { ...filters, [e.target.name]: parseInt(e.target.value) }
    setFilters(newFilters)
  }

  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data
      ?.map(({ zoneName }) => ({
        label: zoneName,
        value: zoneName,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    setStations(stations)
  }

  const fetchVehicleStatus = async () => {
    const response = await getStatuses()
    const statuses = response.data || []
    setVehicleStatus(statuses)
  }

  const fetchB2bPartners = async () => {
    try {
      const { data } = await getB2bPartners()
      const selectObj = data.map(b => {
        return { label: b.name, value: b.name }
      })
      setB2bPartners(selectObj)
    } catch (err) {
      console.log("fetchB2bPartners -> err:", err)
    }
  }

  useEffect(() => {
    if (stations.length === 0) {
      fetchStations()
    }
    if (vehicleStatus.length === 0) {
      fetchVehicleStatus()
    }
    if (b2bPartners.length === 0) {
      fetchB2bPartners()
    }
  }, [stations, vehicleStatus])

  return (
    <div>
      <Row className="">
        <Col lg={12}>
          <div className="p-2">
            <Row className="g-3">
              <Col xxl={12} lg={12}>
                <div>
                  <Label
                    htmlFor="vehicleType"
                    className="form-label fw-semibold"
                  >
                    Vehicle Type
                  </Label>
                </div>

                <div className="d-flex flex-wrap gap-2">
                  <input
                    type="radio"
                    className="btn-check"
                    id="btncheck0"
                    autoComplete="off"
                    name="vehicleType"
                    onChange={handleCheckChange}
                    value={""}
                    // checked={filters.vehicleType === ""}
                  />
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btncheck0"
                  >
                    <i className="mdi mdi-car-multiple font-size-16 me-2" />
                    All
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    id="btncheck1"
                    autoComplete="off"
                    name="vehicleType"
                    onChange={handleCheckChange}
                    value="scooter"
                    // defaultChecked
                  />
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btncheck1"
                  >
                    <i className="mdi mdi-scooter font-size-16 me-2" />
                    Scooter
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    id="btncheck2"
                    autoComplete="off"
                    name="vehicleType"
                    onChange={handleCheckChange}
                    value="bicycle"
                  />
                  <label
                    className="btn btn-outline-secondary"
                    htmlFor="btncheck2"
                  >
                    <i className="mdi mdi-bike font-size-16 me-2" />
                    Bike
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xxl={12} lg={12}>
                <div>
                  <Label
                    htmlFor="batteryLevel"
                    // className="form-label fw-semibold"
                  >
                    Battery Level (%)
                  </Label>
                  <InputGroup className="gap-2 d-flex align-items-center">
                    <Input
                      className="form-control"
                      type="number"
                      name="startBattery"
                      value={filters.startBattery}
                      onChange={handleNumberChange}
                      min={0}
                      max={100}
                    />

                    <div className="m-3 text-muted font-size-12">TO</div>
                    <Input
                      className="form-control"
                      type="number"
                      name="endBattery"
                      value={filters.endBattery}
                      onChange={handleNumberChange}
                      min={0}
                      max={100}
                    />
                    <button
                      className="btn btn-white"
                      disabled={batterySubmitDisabled}
                      onClick={() => filterData(filters)}
                    >
                      <i className="mdi mdi-magnify font-size-18 text-primary" />
                    </button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xxl={12} lg={12}>
                <div>
                  <Label
                    htmlFor="vehicleStatus"
                    // className="form-label fw-semibold"
                  >
                    Vehicle Status
                  </Label>
                </div>

                <Select
                  key={`select-${filters["vehicleStatus"]}`}
                  value={filters["vehicleStatus"] || null}
                  ref={selectInputRef}
                  options={vehicleStatus}
                  isMulti
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  name="vehicleStatus"
                  className="font-size-14"
                  onChange={handleSelectChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xxl={12} lg={12}>
                <div>
                  <Label
                    htmlFor="vehicleStation"
                    // className="form-label fw-semibold"
                  >
                    Station
                  </Label>
                </div>

                <Select
                  key={`select-${filters["vehicleStation"]}`}
                  value={filters["vehicleStation"] || null}
                  ref={selectInputRef}
                  options={stations}
                  isMulti
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  name="vehicleStation"
                  className="font-size-14"
                  onChange={handleSelectChange}
                  menuPlacement={"auto"}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xxl={12} lg={12}>
                <div>
                  <Label
                    htmlFor="b2bPartner"
                    // className="form-label fw-semibold"
                  >
                    B2B Partner
                  </Label>
                </div>

                <Select
                  key={`select-${filters["b2bPartner"]}`}
                  value={filters["b2bPartner"] || null}
                  ref={selectInputRef}
                  options={b2bPartners}
                  isMulti
                  components={animatedComponents}
                  closeMenuOnSelect={false}
                  name="b2bPartner"
                  className="font-size-14"
                  onChange={handleSelectChange}
                  menuPlacement={"auto"}
                />
              </Col>
            </Row>

            <hr />
            <Row className="my-3">
              <Col>
                {filtersCount ? (
                  <>
                    Found <strong>{points.length}</strong> vehicles
                  </>
                ) : null}
              </Col>
              <Col>
                <div className="d-flex align-items-center">
                  <div className="card-title flex-grow-1"></div>
                  <div className="flex-shrink-0">
                    <a
                      href="#!"
                      className="btn btn-light me-1 text-decoration-underline"
                      onClick={() => {
                        clearFilters()
                      }}
                    >
                      Clear Filters
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AdvancedFilter
