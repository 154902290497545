import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import logoSecondary from "../../../assets/images/logo-secondary.png"
import { getStatuses } from "store/config/statuses/actions"
import CopyToClipboard from "components/Common/CopyToClipboard"
import { ChromePicker } from "react-color"
import { updateStatus } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import ColorPickerModal from "components/Common/ColorPickerModal"
import ColorPreview from "components/Common/ColorPreview"

const ConfigTable = props => {
  let node = useRef()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalSize, setTotalSize] = useState(0)
  const [data, setData] = useState([])
  const [showColorPickerModal, setShowColorPickerModal] = useState(false)
  const [selectedColor, setSelectedColor] = useState("")
  const [selectedRow, setSelectedRow] = useState({})

  const { statuses, loading, error } = useSelector(state => ({
    statuses: state.status.statuses,
    loading: state.status.loading,
    error: state.status.error,
  }))

  const keyField = "value"
  const columns = [
    {
      dataField: "configId",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      dataField: "label",
      text: "Label",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "value",
      text: "Value",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "color",
      text: "Badge Color",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => (
        <Badge color={row.color} className={`font-size-12`}>
          {row.color}
        </Badge>
      ),
    },
    {
      dataField: "iconClass",
      text: "Icon Class",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        if (!row.iconClass) {
          return <div className="d-flex align-items-center">--</div>
        } else {
          return (
            <span className="d-flex">
              <CopyToClipboard text={row.iconClass} />
              <div
                style={{ maxWidth: "150px" }}
                className="font-size-12 text-truncate"
              >
                {row.iconClass}
              </div>
            </span>
          )
        }
      },
    },
    {
      dataField: "borderClass",
      text: "Border Class",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        if (!row.borderClass) {
          return <div className="d-flex align-items-center">--</div>
        } else {
          return (
            <span className="d-flex">
              <CopyToClipboard text={row.borderClass} />
              <div
                style={{ maxWidth: "150px" }}
                className="font-size-12 text-truncate"
              >
                {row.borderClass}
              </div>
            </span>
          )
        }
      },
    },
    {
      dataField: "colorHex",
      text: "Color Hex",
      sort: true,
      filter: textFilter(),
      formatter: (cellContent, row) => {
        if (!row.colorHex) {
          return <div className="d-flex align-items-center">--</div>
        } else {
          return (
            <ColorPreview
              color={row.colorHex}
              handleEdit={() => {
                setSelectedColor(row.colorHex)
                setSelectedRow(row)
                setShowColorPickerModal(true)
              }}
            />
          )
        }
      },
    },
  ]

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setPageSize(page)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="statuses" />
  )

  const saveColorChanges = async color => {
    await updateStatus(selectedRow.value, { colorHex: color })
    dispatch(getStatuses())
    setShowColorPickerModal(false)
    showToastMessage({ success: true, message: "Changes updated successfully" })
  }

  useEffect(() => {
    if (statuses && statuses.length > 0) {
      setData(statuses)
    }
    if (!statuses.length) {
      dispatch(getStatuses())
    }
  }, [statuses])

  useEffect(() => {
    if (data.length > 0) {
      setTotalSize(data.length)
    }
  }, [data])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {showColorPickerModal && (
            <ColorPickerModal
              prevColor={selectedColor}
              showModal={showColorPickerModal}
              setShowModal={setShowColorPickerModal}
              saveChanges={saveColorChanges}
            />
          )}

          <h4 className="header-title mt-0 mb-1">Map Config</h4>
          <p className="sub-header">
            This table shows the border config colors for different statuses in
            the Gazal ecosystem.
          </p>
          <hr />
          <Row>
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory({
                  page,
                  sizePerPage: pageSize,
                  totalSize,
                  custom: true,
                  sizePerPageOptionRenderer,
                  paginationTotalRenderer: customTotal,
                })}
              >
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <ToolkitProvider
                      keyField={keyField}
                      data={data}
                      columns={columns}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col lg={6} className={"d-flex align-items-center"}>
                              <PaginationTotalStandalone {...paginationProps} />
                            </Col>
                            <Col lg={6}>
                              <div className="float-end">
                                <span className="p-2">Page Size</span>
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl="12 mt-4">
                              <div className="table-responsive table-light invert-scroll">
                                <BootstrapTable
                                  keyField={keyField}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  classes={
                                    "table align-middle table-nowrap table-hover top-horizontal-scroll"
                                  }
                                  bordered={false}
                                  striped={false}
                                  responsive
                                  ref={node}
                                  loading={loading}
                                  headerClasses="table-light"
                                  filter={filterFactory()}
                                  filterPosition="top"
                                />
                              </div>
                              {data.length === 0 && (
                                <Row>
                                  <Col lg={12} className="text-center m-auto">
                                    <img
                                      src={logoSecondary}
                                      alt="logo"
                                      height="100"
                                    />
                                    <p className="text-muted">No data found</p>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col className="pagination pagination-rounded d-flex align-items-center justify-content-end mb-2">
                              <PaginationListStandalone {...paginationProps} />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )
                }}
              </PaginationProvider>
            </CardBody>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ConfigTable.propTypes = {}

export default ConfigTable
