import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody, Badge } from "reactstrap"
import { getCurrentDuration, formatSeconds } from "utils"
import { isEmpty } from "lodash"
function CardTripSummary({ user, trip, vehicle, stationDetails }) {
  const active =
    trip.status === "TRIP_STARTED" || trip.status === "MULTI_TRIP_STARTED"
  const hasDiscount = active && user.hasActiveDiscount

  const tripDiscount = trip.discountAmount
    ? parseFloat(trip.discountAmount / 100)
    : 0
  const discountOffValue = hasDiscount
    ? parseFloat(100 - user.discount.value) / 100
    : 1 // none
  let totalSeconds = getCurrentDuration(trip.pickUpTime)
  const [duration, setDuration] = useState(totalSeconds)
  // TODO add unlock fare
  // const { vehicleType } = vehicle
  const vehicleType = vehicle.vehicleType === "scooter" ? "scooter" : "bike"
  let initialFare = isEmpty(stationDetails)
    ? null
    : stationDetails[`${vehicleType}InitialFare`] * discountOffValue
  let initialTime = isEmpty(stationDetails)
    ? null
    : stationDetails[`${vehicleType || "scooter"}InitialTimeBlock`]

  let subsequentTime = isEmpty(stationDetails)
    ? null
    : stationDetails[`${vehicleType || "scooter"}SubsequentTimeBlock`]

  let subsequentFare = isEmpty(stationDetails)
    ? null
    : stationDetails[`${vehicleType || "scooter"}SubsequentFare`] *
      discountOffValue

  const [fare, setFare] = useState(initialFare)

  const calculateFare = totalSeconds => {
    const multiple = (totalSeconds * 1000 - initialTime) / subsequentTime
    let newFare = multiple * subsequentFare
    let totalFare = initialFare + newFare
    return totalFare
  }

  const secondsToDhms = seconds => {
    seconds = Number(seconds)
    var d = Math.floor(seconds / (3600 * 24))
    var h = Math.floor((seconds % (3600 * 24)) / 3600)
    var m = Math.floor((seconds % 3600) / 60)
    var s = Math.floor(seconds % 60)

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
    return dDisplay + hDisplay + mDisplay + sDisplay
  }

  useEffect(() => {
    let interval
    if (trip.pickUpTime && active) {
      interval = setInterval(function () {
        // active duration
        totalSeconds += 1
        setDuration(totalSeconds)

        // active fare
        const fare = calculateFare(totalSeconds)
        if (!isNaN(fare)) {
          setFare(fare)
        }
      }, 1000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [trip.pickUpTime, active])

  return (
    <React.Fragment>
      <Row>
        {/* <Col lg="12"> */}
        <Card
          className={`w-100 ${
            active ? "placeholder placeholder-wave bg-success opacity-none" : ""
          }`}
          // style={{ zIndex: 1 }}
        >
          <CardBody>
            {hasDiscount && (
              <div className="ribbon ribbon-top-left">
                <span>{user.discount.value}% off</span>
              </div>
            )}
            <div
              className={`text-center text-${
                active ? "light" : "dark"
              } font-size-16`}
            >
              <Row>
                <Col sm={4} className="my-2">
                  <div>
                    <i
                      className={`mdi mdi-cash-multiple font-size-22 text-${
                        active ? "light" : "primary"
                      }`}
                    />
                    <p className={`mb-1 text-${active ? "light" : "muted"}`}>
                      Total Fare
                    </p>

                    {active ? (
                      <h3 className={`text-${active ? "light" : "dark"} `}>
                        {!isNaN(fare) ? parseFloat(fare / 100).toFixed(2) : "-"}{" "}
                        SAR{" "}
                      </h3>
                    ) : (
                      <>
                        <Row>
                          <h3 className={`text-${active ? "light" : "dark"} `}>
                            {!isNaN(trip?.totalFare)
                              ? (
                                  parseFloat(trip.totalFare / 100) -
                                  tripDiscount
                                ).toFixed(2)
                              : "-"}{" "}
                            SAR
                          </h3>
                        </Row>
                        {/* <Row>
                          <Badge color="success">
                            <i className="mdi mdi-sale font-size-14 text-white m-2" />
                            {parseFloat(trip.discountAmount / 100).toFixed(2)}{" "}
                            SAR
                          </Badge>
                        </Row> */}
                      </>
                    )}
                  </div>
                </Col>

                <Col sm={4} className="my-2">
                  <div className="mt-4 mt-sm-0">
                    <i
                      className={`mdi mdi-clock-outline font-size-22 text-${
                        active ? "light" : "primary"
                      }`}
                    />

                    <p className={`mb-1 text-${active ? "light" : "muted"}`}>
                      Duration
                    </p>
                    {active ? (
                      <h3 className={`text-${active ? "light" : "dark"}`}>
                        <span>{secondsToDhms(duration)}</span>
                      </h3>
                    ) : (
                      <h3 className={`text-${active ? "light" : "dark"}`}>
                        {/* {parseInt(trip.totalDuration / 60000)} min(s) */}
                        <span>{secondsToDhms(trip.totalDuration / 1000)}</span>
                      </h3>
                    )}
                  </div>
                </Col>
                <Col sm={4} className="my-2">
                  <div className="mt-4 mt-sm-0">
                    <i
                      className={`mdi mdi-car-brake-parking font-size-22 text-${
                        active ? "light" : "primary"
                      }`}
                    />
                    <p className={`mb-1 text-${active ? "light" : "muted"}`}>
                      Parking Time
                    </p>
                    <h3 className={`text-${active ? "light" : "dark"}`}>-</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        {/* </Col> */}
      </Row>
    </React.Fragment>
  )
}

CardTripSummary.propTypes = {
  trip: PropTypes.object,
  vehicle: PropTypes.object,
  stationDetails: PropTypes.object,
  user: PropTypes.object,
}
export default CardTripSummary
