import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import { MetaTags } from "react-meta-tags"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getB2bPartners,
  getZoneDetails,
  getZones,
} from "helpers/backend_helper"
import { updateZone as onUpdateZone } from "store/actions"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import { showToastMessage } from "components/Common/ToastWrapper"
import ZoneDetailsLoader from "./zone-details-loader"
import useQuery from "hooks/useQuery"
import { isEmpty } from "lodash"
import ZoneMap from "./zone-map"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
/** Confirm button */
const confirmElement = (
  <button type="button" className="btn btn-primary editable-cancel btn-sm mx-1">
    <i className="mdi mdi-check"></i>
  </button>
)

/** Cancel button */
const cancelElement = (
  <button type="button" className="btn btn-danger editable-cancel btn-sm mx-1">
    <i className="mdi mdi-close"></i>
  </button>
)

const isValidNumber = num => {
  return !isNaN(num)
}

const ZoneDetails = props => {
  const query = useQuery()
  const type = query.get("type")
  const zoneUnder = query.get("zoneUnder")
  const dispatch = useDispatch()
  const history = useHistory()

  const [zone, setZone] = useState()
  const [bounds, setBounds] = useState([])
  const [parentZone, setParentZone] = useState({})
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [updates, setUpdates] = useState({})
  const [b2bPartnersSelect, setB2bPartnersSelect] = useState([])
  const [zoneConfig, setZoneConfig] = useState({})
  const [opZones, setOpZones] = useState([])
  const [zoneUnderOptions, setZoneUnderOptions] = useState([])
  const [zoneType, setZoneType] = useState("")

  const isSharedSelect = [
    { name: "isShared", label: "Yes", value: true },
    { name: "isShared", label: "No", value: false },
  ]

  const configTypeSelect = [
    { name: "configType", label: "B2B Partner", value: "b2bPartner" },
    { name: "configType", label: "Normal", value: "NIL" },
  ]

  const speedOptions = [
    { name: "speed", label: "25 km/h", value: 3 },
    { name: "speed", label: "20 km/h", value: 2 },
    { name: "speed", label: "15 km/h", value: 1 },
  ]

  const zoneTypeOptions = [
    { name: "zoneType", label: "Forbidden", value: "forbidden" },
    { name: "zoneType", label: "Low Speed", value: "lowSpeed" },
  ]

  const allowEndTripOptions = [
    { name: "isAllowEndTrip", label: "Yes", value: true },
    { name: "isAllowEndTrip", label: "No", value: false },
  ]

  const {
    match: { params },
  } = props
  const { zoneName } = params

  const { success, message } = useSelector(state => ({
    success: state.zones.success,
    message: state.zones.message,
  }))

  const getZoneTypeColor = type => {
    switch (type) {
      case "operation":
        return "dark"
      case "forbidden":
        return "danger"
      case "control":
        return "warning"
      default:
        return "primary"
    }
  }

  const handleZoneConfigChange = e => {
    setIsEdit(true)
    if (Array.isArray(e)) {
      setZoneConfig({
        ...zoneConfig,
        b2bPartner: e.map(v => v.value),
        isShared: e.length > 1,
      })
    } else if (e.name === "configType" && e.value === "NIL")
      setZoneConfig({
        configType: "NIL",
        b2bPartner: "NIL",
        isShared: false,
      })
    else {
      setZoneConfig({
        ...zoneConfig,
        [e.name]: e.value,
      })
    }
  }

  const saveEdit = async () => {
    if (!bounds.length) {
      showToastMessage({ success: false, message: "Please create a polygon" })
      return
    }

    if (bounds.length < 3) {
      showToastMessage({
        success: false,
        message: "A polygon has a minimum of 3 points",
      })
      return
    }

    if (
      zoneConfig.configType === "b2bPartner" &&
      (zoneConfig.b2bPartner === "NIL" || !zoneConfig.b2bPartner.length)
    ) {
      showToastMessage({
        success: false,
        message: "Please select B2B Partners",
      })
      return
    }

    const zoneBoundaries = bounds.map(point => {
      return { latitude: point.lat, longitude: point.lng }
    })
    const payload = {
      ...zone,
      ...updates,
      oldZoneUnder: zone.zoneUnder,
      zoneBoundaries: zoneBoundaries,
      zoneConfig: zoneConfig,
    }
    dispatch(onUpdateZone(zoneName, type, payload))
    setIsEdit(false)

    if (type === "control" && payload.zoneUnder !== payload.oldZoneUnder) {
      history.push(
        `/zone-detail/${payload.zoneName}?type=${type}&zoneUnder=${payload.zoneUnder}`
      )
      document.location.reload()
    }
  }

  const setFields = (value, key) => {
    setIsEdit(true)
    const type = typeof value
    const amplifier = key.includes("Fare") ? 100 : 1000
    if (type === "string") {
      setUpdates({
        ...updates,
        [key]: isValidNumber(value) ? Number(value) * amplifier : value,
      })
    } else if (type === "boolean") {
      setUpdates({ ...updates, [key]: value })
    } else {
    }
  }

  const handleEditCZone = update => {
    setIsEdit(true)
    setUpdates({
      ...updates,
      [update.name]: update.value,
    })

    if (update.name === "zoneType") {
      setZoneType(update.value)
    }
  }

  const fetchZoneDetails = async () => {
    setLoading(true)
    let qZoneName = zoneName
    let qType = type
    let qZoneUnder = zoneUnder

    const [name, queryParams] = zoneName.split("?")
    qZoneName = name

    if (!type) {
      const [typeQuery] = queryParams.split("&")
      qType = typeQuery.split("=")[1]
    }

    if (!zoneUnder && qType !== "operation") {
      const [_typeQuery, zoneUnderQuery] = queryParams.split("&")
      qType = zoneUnderQuery.split("=")[1]
    }

    const response = await getZoneDetails(qZoneName, qType, qZoneUnder)
    const { data: zone } = response
    setParentZone(zone)
    if (type === "control") {
      const controlZones = Object.values(zone?.controlZones)
      const childZone = controlZones?.find(
        controlZone => controlZone.zoneName === zoneName
      )
      if (childZone) {
        setParentZone(zone)
        setZone(childZone)
      }
    } else {
      setZone(zone)
      setZoneConfig(zone.zoneConfig)
    }
    setLoading(false)
  }

  const onCancel = useCallback(() => {
    setIsEdit(false)
    fetchZoneDetails()
  }, [zone])

  const fetchZones = async () => {
    const { data } = await getZones()
    return data
  }

  useEffect(() => {
    if (isEmpty(zone)) {
      fetchZoneDetails()
    }
  }, [type, zoneName])

  useEffect(() => {
    if (zone) setZoneType(zone?.zoneType)
  }, [zone])

  useEffect(() => {
    fetchZones().then(d => {
      setOpZones(d)
      setZoneUnderOptions(
        d.map(zone => ({
          name: "zoneUnder",
          label: zone.zoneName,
          value: zone.zoneName,
        }))
      )
    })
  }, [])

  useEffect(() => {
    if (message) {
      showToastMessage({ success, message })
    }
    return () => {}
  }, [success, message])

  useEffect(() => {
    getB2bPartners()
      .then(b2b => {
        setB2bPartnersSelect(
          b2b.data.map(b => ({
            name: "b2bPartner",
            label: b.name,
            value: b.name,
          }))
        )
      })
      .catch(err =>
        showToastMessage({
          success: false,
          message: "error retrieving b2b partners",
        })
      )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{zone?.zoneName || "Zone Details"} | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zones"
            titleLink="/zones"
            breadcrumbItem="Zone Details"
          />
          <Restricted to="zones:VIEW_ZONE" fallback={<AccessDenied />}>
            {!loading ? (
              <>
                <section>
                  {isEdit && (
                    <Alert color="info" isOpen={isEdit} toggle={onCancel}>
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <span>
                          <i className="mdi mdi-grease-pencil me-2"></i>
                          Currently in Editing mode.
                        </span>
                        <button
                          type="button"
                          className="btn btn-info float-end m-0"
                          onClick={saveEdit}
                        >
                          {loading ? <Spinner size="sm" /> : "Save Changes"}
                        </button>
                      </div>
                    </Alert>
                  )}

                  <Row style={{ height: "70vh" }} key={zone?.zoneName}>
                    <Col lg={7} md={7} sm={12} xs={12}>
                      <Card style={{ height: isEdit ? "110%" : "100%" }}>
                        <CardBody>
                          {zone && (
                            <ZoneMap
                              bounds={bounds}
                              setBounds={setBounds}
                              zone={zone}
                              setIsEdit={setIsEdit}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={5} md={5} sm={12} xs={12}>
                      <Card className="h-100">
                        <CardBody>
                          <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between">
                              <h3>{zone?.zoneName}</h3>
                              <p className="mt-1">
                                Radius: {zone?.zoneRadius || "0"} m
                              </p>
                            </div>
                            <Badge
                              className="mb-1"
                              color={getZoneTypeColor(zone?.zoneType)}
                              pill
                              style={{ width: "6rem" }}
                            >
                              {zone?.zoneType &&
                                String.prototype.toUpperCase.call(
                                  zone.zoneType
                                )}
                            </Badge>
                            <hr />
                            {zone?.zoneType !== "operation" ? (
                              <>
                                <Row>
                                  <Col lg={12}>
                                    <Label>Zone Under</Label>
                                    <Select
                                      options={zoneUnderOptions}
                                      defaultValue={{
                                        label: zoneUnder,
                                        value: zoneUnder,
                                      }}
                                      onChange={handleEditCZone}
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col lg={4}>
                                    <Label>Zone Type</Label>
                                    <Select
                                      options={zoneTypeOptions}
                                      defaultValue={zoneTypeOptions.find(
                                        t => t.value === zone?.zoneType
                                      )}
                                      onChange={handleEditCZone}
                                    />
                                  </Col>
                                  {zoneType === "lowSpeed" && (
                                    <Col lg={4}>
                                      <Label>Zone Speed</Label>
                                      <Select
                                        options={speedOptions}
                                        defaultValue={speedOptions.find(
                                          t => t.value === zone?.speed
                                        )}
                                        onChange={handleEditCZone}
                                      />
                                    </Col>
                                  )}
                                  <Col lg={4}>
                                    <Label>Allow End Trip?</Label>
                                    <Select
                                      options={allowEndTripOptions}
                                      defaultValue={allowEndTripOptions.find(
                                        t => t.value === zone?.isAllowEndTrip
                                      )}
                                      onChange={handleEditCZone}
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <>
                                <Row>
                                  <Col lg={12}>
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div className="me-3">
                                        <p className="text-truncate font-size-14 m-2">
                                          Zone Unlock Fare (SAR):
                                        </p>
                                      </div>
                                      <div
                                        className="text-muted"
                                        id="zoneUnlockFare"
                                      >
                                        <Editable
                                          initialValue={String(
                                            zone?.zoneUnlockFare / 100
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(value, "zoneUnlockFare")
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        ></Editable>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="zoneUnlockFare"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <hr />

                                <Row className="">
                                  <Col lg={12}>
                                      <Label>Config Type</Label>
                                    <Select
                                      options={configTypeSelect}
                                      onChange={handleZoneConfigChange}
                                      defaultValue={configTypeSelect.find(
                                        c => c.value === zoneConfig?.configType
                                      )}
                                    />
                                  </Col>

                                  {zoneConfig?.configType === "b2bPartner" && (
                                    <>
                                      <Col lg={8}>
                                        <p className="text-truncate font-size-14 m-2">
                                          B2B Partner :
                                        </p>
                                        <Select
                                          isMulti
                                          options={b2bPartnersSelect}
                                          onChange={handleZoneConfigChange}
                                          defaultValue={
                                            zoneConfig?.b2bPartner !== "NIL"
                                              ? zoneConfig?.b2bPartner?.map(
                                                  b => ({ label: b, value: b })
                                                )
                                              : []
                                          }
                                        />
                                      </Col>

                                      <Col lg={4}>
                                        <p className="text-truncate font-size-14 m-2">
                                          Is Shared :
                                        </p>
                                        <Select
                                          options={isSharedSelect}
                                          value={isSharedSelect.find(
                                            c =>
                                              c.value === zoneConfig?.isShared
                                          )}
                                          onChange={handleZoneConfigChange}
                                        />
                                      </Col>
                                    </>
                                  )}
                                </Row>

                                <hr />

                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td></td>
                                      <th>
                                        <span>Scooter </span>{" "}
                                        <i className="mdi mdi-human-scooter me-2"></i>
                                      </th>
                                      <th>
                                        <span>Bike </span>{" "}
                                        <i className="mdi mdi-bike-fast me-2"></i>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Initial Fare (SAR)</td>
                                      <td id="scooterInitialFare">
                                        <Editable
                                          initialValue={String(
                                            zone?.scooterInitialFare / 100
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "scooterInitialFare"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="scooterInitialFare"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                      <td id="bikeInitialFare">
                                        <Editable
                                          initialValue={String(
                                            zone?.bikeInitialFare/ 100
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(value, "bikeInitialFare")
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="bikeInitialFare"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Initial Time Block (S)</td>
                                      <td id="scooterInitialTimeBlock">
                                        <Editable
                                          initialValue={String(
                                            zone?.scooterInitialTimeBlock / 1000
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "scooterInitialTimeBlock"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="scooterInitialTimeBlock"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                      <td id="bikeInitialTimeBlock">
                                        <Editable
                                          initialValue={String(
                                            zone?.bikeInitialTimeBlock / 1000
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "bikeInitialTimeBlock"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="bikeInitialTimeBlock"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Subsequent Fare (SAR)</td>
                                      <td id="scooterSubsequentFare">
                                        <Editable
                                          initialValue={String(
                                            zone?.scooterSubsequentFare / 100
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "scooterSubsequentFare"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="scooterSubsequentFare"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                      <td id="bikeSubsequentFare">
                                        <Editable
                                          initialValue={String(
                                            zone?.bikeSubsequentFare / 100
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "bikeSubsequentFare"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="bikeSubsequentFare"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Subsequent Time Block (S)</td>
                                      <td id="scooterSubsequentTimeBlock">
                                        <Editable
                                          initialValue={String(
                                            zone?.scooterSubsequentTimeBlock / 1000
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "scooterSubsequentTimeBlock"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="scooterSubsequentTimeBlock"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                      <td id="bikeSubsequentTimeBlock">
                                        <Editable
                                          initialValue={String(
                                            zone?.bikeSubsequentTimeBlock / 1000
                                          )}
                                          isValueClickable={true}
                                          onSubmit={value =>
                                            setFields(
                                              value,
                                              "bikeSubsequentTimeBlock"
                                            )
                                          }
                                          onValidated={value => {
                                            if (value.length > 0) {
                                              return true
                                            } else {
                                              return false
                                            }
                                          }}
                                          mode="inline"
                                          showText
                                          type="textfield"
                                          validate={value => {
                                            if (!value) {
                                              return "Required"
                                            }
                                          }}
                                          renderCancelElement={cancelElement}
                                          renderConfirmElement={confirmElement}
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="bikeSubsequentTimeBlock"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </section>
              </>
            ) : (
              <ZoneDetailsLoader />
            )}
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

ZoneDetails.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
}

export default withRouter(ZoneDetails)
