import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row, Button, FormGroup, Spinner } from "reactstrap"
import * as Yup from "yup"
import { Formik, Form } from "formik"

import "flatpickr/dist/themes/material_blue.css"

import { createPartner, getZones, updatePartner } from "helpers/backend_helper"
import { showToastMessage } from "../Common/ToastWrapper"
import {
  FormikCheckbox,
  FormikSelect,
  FormikTextInput,
} from "components/Common/FormikFields"

const FormPartner = ({ toggle, partner, isEdit, refetch }) => {
  const [loading, setLoading] = useState(false)
  const [zoneOptions, setZoneOptions] = useState([])

  const submitEdit = async values => {
    try {
      await updatePartner(partner.name, values)
      showToastMessage({
        success: true,
        message: "Partner Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: error.response?.data?.messages || "Something went wrong",
      })
    }
  }
  const submitCreate = async values => {
    try {
      await createPartner(values)
      showToastMessage({
        success: true,
        message: "Partner Created",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data?.messages,
        })
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
      }
    }
  }

  const fetchZones = async () => {
    try {
      const { data } = await getZones()
      setZoneOptions(data.map(z => ({ label: z.zoneName, value: z.zoneName })))
    } catch (err) {
      showToastMessage({ success: false, message: "Error Retrieving Zones" })
    }
  }

  useEffect(() => {
    fetchZones()
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: (partner && partner.name) || "",
        discountRefId: (partner && partner.discountRefId) || "",
        defaultZone: (partner && partner.defaultZone) || "",
        minCredits: (partner && partner.minCredits / 100) || "",
        bypassCreditCheck: (partner && partner.bypassCreditCheck) || false,
        applyDiscount: (partner && partner.applyDiscount) || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Name is required"),
        discountRefId: Yup.string(),
        defaultZone: Yup.string(),
        minCredits: Yup.number()
          .required("Minimum Credit is required")
          .min(1, "Please enter a number greater than 0"),
        bypassCreditCheck: Yup.boolean().required(
          "bypass Credit Check is required"
        ),
        applyDiscount: Yup.boolean(),
      })}
      onSubmit={values => {
        const valsCopy = {
          ...values,
          minCredits: values.minCredits * 100,
        }
        setLoading(true)
        if (isEdit) {
          submitEdit(valsCopy)
        } else {
          submitCreate(valsCopy)
        }
      }}
    >
      {formik => (
        <Form>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <FormikTextInput
                  disabled={partner !== undefined}
                  label="Name"
                  name="name"
                />
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <FormikTextInput label="Discount ID" name="discountRefId" />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <FormGroup>
                <FormikTextInput
                  type="number"
                  label="Minimum Credit (SAR)"
                  name="minCredits"
                />
              </FormGroup>
            </Col>

            <Col lg={6}>
              <FormGroup>
                <FormikSelect
                  options={zoneOptions}
                  value={zoneOptions.find(
                    z => z.value === formik.values.defaultZone
                  )}
                  label="Default Zone"
                  name="defaultZone"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <FormGroup>
                <FormikCheckbox
                  defaultChecked={formik.values.applyDiscount}
                  label="Apply Discount"
                  name="applyDiscount"
                />
              </FormGroup>
            </Col>
            <Col lg={3}>
              <FormGroup>
                <FormikCheckbox
                  defaultChecked={formik.values.bypassCreditCheck}
                  label="Bypass Credit Check"
                  name="bypassCreditCheck"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="text-end">
                <Button
                  type="submit"
                  className="btn btn-dark save-user"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" /> : "Save"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

FormPartner.propTypes = {
  partner: PropTypes.object,
  isEdit: PropTypes.bool,
  refetch: PropTypes.func,
  toggle: PropTypes.any,
}

export default FormPartner
