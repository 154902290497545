import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
  Input,
} from "reactstrap"
import { formatStandard } from "../../../utils/formatDate"

const CardDiscount = ({ discount, setCheckedValue, checkedValue }) => {
  return (
    <React.Fragment>
      <Card
        onClick={() => setCheckedValue(discount.discountId)}
        style={{ cursor: "pointer" }}
      >
        <CardBody
          className={`border rounded ${
            checkedValue == discount.discountId && "border-primary bg-light"
          }`}
        >
          <Row>
            <Col lg="4">
              <div className="form-check mb-3">
                <div className="text-lg-center">
                  {discount.logoUrl ? (
                    <img
                      src={discount.logoUrl}
                      className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none "
                      alt="img"
                    />
                  ) : (
                    <div className="display-4 text-center rounded-circle img-fluid">
                      {/* <img src={placeholderUser} alt="logo" height="150" /> */}
                      <i className="mdi mdi-office-building-outline text-secondary"></i>
                    </div>
                  )}

                  <h5 className="mb-1 font-size-15 text-truncate">
                    {discount.company}
                  </h5>
                </div>
              </div>
            </Col>

            <Col lg="8">
              <div>
                Discount
                <h5 className="text-truncate mb-4 mb-lg-5">
                  {discount.discountId}
                </h5>
                <ul className="list-inline mb-0">
                  <li className="list-inline-item me-3">
                    <h5 className="font-size-14" id="amountTooltip">
                      <i className="bx bx-money me-1 text-muted" />
                      {discount.value}% off
                      <UncontrolledTooltip
                        placement="top"
                        target="amountTooltip"
                      >
                        Percentage
                      </UncontrolledTooltip>
                    </h5>
                  </li>{" "}
                  <li className="list-inline-item me-3">
                    <h5 className="font-size-14" id="duedateTooltip">
                      <i className="bx bx-calendar me-1 text-muted" />{" "}
                      {formatStandard(discount.expiryDate)}
                      <UncontrolledTooltip
                        placement="top"
                        target="duedateTooltip"
                      >
                        Expiry Date
                      </UncontrolledTooltip>
                    </h5>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

CardDiscount.propTypes = {
  discount: PropTypes.any,
  setCheckedValue: PropTypes.func,
  checkedValue: PropTypes.string,
}

export default CardDiscount
