import React, { useState, useMemo } from "react"
import { Role, Name, Permissions, DefaultRoute } from "./roles-col"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
} from "reactstrap"
import TableContainer from "components/Common/Table/table-container"
import FormAddRole from "components/User/form-add-role"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

// eslint-disable-next-line react/prop-types
const RolesTable = ({ roles }) => {
  const [role, setRole] = useState({
    id: "123",
    name: "sample",
    designation: "dev",
    email: "email@email.com",
    tags: ["1", "2"],
    projects: [],
  })
  const [roleList, setRoleList] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  const history = useHistory()

  const handleEditRole = roleName => {
    history.push(`/update-role/${roleName}`)
  }
  const handleRoleClicks = () => {
    setRoleList("")
    setIsEdit(false)
    toggle()
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },
      // {
      //   Header: "Role",
      //   accessor: "name",
      //   filterable: true,

      //   Cell: cellProps => {
      //     return <Role {...cellProps} />
      //   },
      // },
      {
        Header: "Default Route",
        accessor: "defaultRoute",
        filterable: true,
        Cell: cellProps => {
          return <DefaultRoute {...cellProps} />
        },
      },
      {
        Header: "Permissions",
        accessor: "permissions",
        filterable: true,
        Cell: cellProps => {
          return <Permissions {...cellProps} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const { name: roleName } = cellProps.row.original
                  handleEditRole(roleName)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              {/**
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original
                  onClickDelete(userData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>

              **/}
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={roles}
              isGlobalFilter={true}
              isAddRoleList={true}
              handleRoleClick={handleRoleClicks}
              customPageSize={10}
              className="custom-header-css"
            />

            <Modal isOpen={modal} toggle={toggle} size={"xl"}>
              <ModalHeader toggle={toggle} tag="h4">
                {!!isEdit ? "Edit Role" : "Add Role"}
              </ModalHeader>
              <ModalBody>
                <FormAddRole toggle={toggle} user={role} />
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default RolesTable
