import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  Badge,
  Button,
} from "reactstrap"
import ConfirmModal from "components/Common/ConfirmModal"
import { deletePackage } from "../../../helpers/backend_helper"
import { showToastMessage } from "../../../components/Common/ToastWrapper"
import LoadingTable from "components/Loading/loading-table"
import FormPackage from "components/User/FormPackage"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import BootstrapTable from "react-bootstrap-table-next"
const PackagesTable = ({ packages, refetch, tabLoading }) => {
  const [pkg, setPkg] = useState()
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const confirmContents = {
    action: "delete",
    resource: "package",
  }
  const toggle = () => {
    setModal(!modal)
  }

  const handleEditPackage = p => {
    setIsEdit(true)
    setPkg(p)
    toggle()
  }

  const handleDeletePackage = p => {
    setConfirmDelete(true)
    setPkg(p)
  }

  const confirmDeletePackage = async () => {
    try {
      await deletePackage(pkg.packageId)
      showToastMessage({ success: true, message: "Package deleted" })
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
    refetch({ force: true })
    setConfirmDelete(false)
  }
  const handlePackageClick = () => {
    setPkg()
    setIsEdit(false)
    toggle()
  }

  const columns = [
    {
      dataField: "desc_en",
      text: "Action",
      formatter: (_, row) => {
        return (
          <div>
            <Restricted to="vouchers:UPDATE_VOUCHER">
              <Link
                to="#"
                className="text-success mx-1"
                onClick={() => {
                  const packageData = row
                  handleEditPackage(packageData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </Restricted>

            <Restricted to="vouchers:DELETE_VOUCHER">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const packageData = row
                  handleDeletePackage(packageData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </Restricted>
          </div>
        )
      },
    },
    { dataField: "packageId", text: "ID" },
    { dataField: "priority", text: "Order", sort: true },
    { dataField: "package_name_en", text: "Name" },
    { dataField: "cost", text: "Cost" },
    {
      dataField: "credit",
      text: "Credit",
      formatter: val => <p>{val / 100}</p>,
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: val =>
        val ? (
          <Badge color="success">Active</Badge>
        ) : (
          <Badge color="danger">Disabled</Badge>
        ),
    },
  ]

  const sortFunc = (a, b, _, __, aCol, bCol) => {
    if (aCol.is_active && !bCol.is_active) {
      return -1
    } else if (!aCol.is_active && bCol.is_active) {
      return 1
    } else {
      return a - b
    }
  }

  return (
    <Restricted to="vouchers:VIEW_ALL_VOUCHERS" fallback={<AccessDenied />}>
      <Card>
        <CardBody>
          <h4 className="header-title mt-0 mb-1">Packages</h4>
          <p className="sub-header">View or create new Packages</p>
          <hr />

          <div className="text-sm-end">
            <Button
              type="button"
              color="dark"
              className="btn-rounded mb-2 me-2"
              onClick={handlePackageClick}
            >
              <i className="mdi mdi-plus me-1" />
              Create New Package
            </Button>
          </div>

          {tabLoading ? (
            <LoadingTable />
          ) : (
            <Row>
              <ConfirmModal
                show={confirmDelete}
                confirmContents={confirmContents}
                onConfirmClick={confirmDeletePackage}
                onCloseClick={() => setConfirmDelete(false)}
              />
              <Col lg="12">
                <BootstrapTable
                  columns={columns}
                  keyField="packageId"
                  data={packages}
                  sort={{
                    dataField: "priority",
                    order: "desc",
                    sortFunc: sortFunc,
                  }}
                />
                <Modal isOpen={modal} toggle={toggle} size="lg">
                  <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? "Edit Package" : "Add Package"}
                  </ModalHeader>
                  <ModalBody>
                    <FormPackage
                      toggle={toggle}
                      pkg={pkg}
                      isEdit={isEdit}
                      refetch={refetch}
                    />
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Restricted>
  )
}
PackagesTable.propTypes = {
  packages: PropTypes.array,
  tabLoading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default PackagesTable
