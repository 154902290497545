/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
  Col,
  Card,
  Table,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import SimpleBar from "simplebar-react"
import Modal from "components/Common/Modal"
//redux
import { vehicleStatus, vehicleFields } from "../../constants/vehicleStatus"
import {
  updateMultiVehicles,
  getZones,
  getB2bPartners,
  bulkSendCommand,
} from "../../helpers/backend_helper"
import { showToastMessage } from "../../components/Common/ToastWrapper"
import LoadingButton from "components/Common/LoadingButton"
import Restricted from "components/Common/Restricted"

const BulkView = ({ selectedVehicles, refetch }) => {
  const [other_Menu, setother_Menu] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [field, setField] = useState(vehicleFields[0].value)
  const [value, setValue] = useState(vehicleStatus[0].value)
  const [response, setResponse] = useState([])
  const [sortedVehicles, setSortedVehicles] = useState(selectedVehicles)
  const [zones, setZones] = useState([])
  const [b2bPartners, setB2bPartners] = useState([])
  const total = selectedVehicles.length
  const scooters = selectedVehicles.filter(
    vehicle => vehicle.vehicleType == "scooter"
  ).length
  const bikes = total - scooters
  const ignoredVehicles = selectedVehicles.filter(
    v => v.status === "LTD" || v.status === "InUse"
  )
  const consideredVehicles = selectedVehicles.filter(
    v => !ignoredVehicles.includes(v)
  )
  const consideredTotal = consideredVehicles.length

  const getChartOptions = status => {
    const statusOptions = vehicleStatus.find(st => st.value == status)

    var options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: [statusOptions?.colorHex || "#000"],
      plotOptions: {
        radialBar: {
          hollow: { margin: 0, size: "60%" },
          track: { margin: 0 },
          dataLabels: { show: !1 },
        },
      },
    }

    return options
  }

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }

  const handleFieldChange = e => {
    setField(e.target.value)
    switch (e.target.value) {
      case "vehicleConfig":
        setValue(b2bPartners[0].name)
        break
      case "stationId":
        setValue(zones[0].zoneName)
        break
      case "status":
        setValue(vehicleStatus[0].value)
        break

      default:
        break
    }
  }

  const handleValueChange = e => {
    setValue(e.target.value)
  }

  const handleHardwareCommand = async command => {
    const imeis = consideredVehicles.map(vehicle => vehicle.IMEI)
    await bulkSendCommand({ IMEIs: imeis, command })
  }

  const saveEdit = async () => {
    try {
      await refetch()
      const ids = consideredVehicles.map(vehicle => vehicle.IMEI)
      let payload = {}
      if (field === "vehicleConfig") {
        payload = {
          ids,
          field,
          value: {
            configType: value === "NIL" ? "NIL" : "b2bPartner",
            b2bPartner: value,
            isExclusive: false,
          },
        }
      } else {
        payload = {
          ids,
          field,
          value,
        }
      }
      const { data } = await updateMultiVehicles(payload)
      setResponse(data)
      showToastMessage({
        success: true,
        message: `Successfully updated ${consideredVehicles.length} vehicles`,
      })
      toggleEdit()
      await refetch()
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
  }

  const fetchZones = async () => {
    const response = await getZones()
    const zones = response.data
    setZones(zones)
  }

  const fetchB2bPartners = async () => {
    const { data } = await getB2bPartners()
    setB2bPartners(data)
  }

  useEffect(() => {
    const aggregated = {}
    for (let { status } of selectedVehicles)
      aggregated[status] = {
        status,
        count: aggregated[status] ? aggregated[status].count + 1 : 1,
      }

    let result = Object.values(aggregated).sort((a, b) => b.count - a.count)

    setSortedVehicles(result)
  }, [selectedVehicles])

  useEffect(() => {
    fetchZones()
  }, [])

  useEffect(() => {
    fetchB2bPartners().catch(e =>
      showToastMessage({
        success: false,
        message: "error fetching b2bPartners",
      })
    )
  }, [])

  return (
    <div className="p-1">
      <Card className="p-3 mt-2 bg-light border rounded border-light">
        <Row></Row>
        <div className="text-muted text-center"></div>

        <Row className="text-center">
          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-scooter font-size-18 text-primary mb-1`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Scooters</p>
              <h5>{scooters}</h5>
            </div>
          </Col>

          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-bike font-size-18 text-primary`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Bikes</p>
              <h5>{bikes}</h5>
            </div>
          </Col>
          <Restricted to="vehicles:UPDATE_VEHICLES">
            <Col xs={4} className="mt-2">
              <ul className="list-inline user-chat-nav text-end mb-3">
                <li className="list-inline-item">
                  <Dropdown
                    isOpen={other_Menu}
                    toggle={toggleOther}
                    // disabled={loadingDetails || error.message}
                  >
                    {/* TODO extract into a component */}
                    <DropdownToggle className="btn btn-outline-dark" tag="p">
                      Actions
                      <i className="bx bx-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem header>Edit</DropdownItem>
                      <DropdownItem onClick={toggleEdit} disabled={!total}>
                        Bulk Edit
                      </DropdownItem>

                      <DropdownItem header>Hardware Actions</DropdownItem>
                      <DropdownItem
                        onClick={() => handleHardwareCommand("lockVehicle")}
                      >
                        Lock
                      </DropdownItem>

                      <Restricted to="commands:UNLOCK_VEHICLE">
                        <DropdownItem
                          onClick={() => handleHardwareCommand("unlockVehicle")}
                        >
                          Unlock
                        </DropdownItem>
                      </Restricted>
                      <DropdownItem
                        onClick={() =>
                          handleHardwareCommand("unlockVehicleBattery")
                        }
                      >
                        Battery Unlock
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleHardwareCommand("buzzVehicle")}
                      >
                        Buzz
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Restricted>
        </Row>

        {selectedVehicles.length ? (
          <>
            <hr />
            <div className="table-responsive">
              <SimpleBar
                style={{ maxHeight: "300px", height: "300px" }}
                forceVisible="y"
                autoHide={false}
              >
                <Table className="table align-middle mb-0">
                  <tbody>
                    {sortedVehicles.map((data, key) => {
                      const options = getChartOptions(data.status)
                      return (
                        <tr key={key}>
                          <td>
                            <h5 className="font-size-14 mb-1">{data.status}</h5>
                            <p className="text-muted mb-0">
                              {data.count} vehicle(s)
                            </p>
                          </td>

                          <td>
                            <div id="radialchart-1">
                              <ReactApexChart
                                options={options}
                                series={[(data.count / total) * 100]}
                                type="radialBar"
                                height={60}
                                width={60}
                                className="apex-charts"
                              />
                            </div>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              {((data.count / total) * 100).toFixed(0)}%
                            </h5>
                            <p className="text-muted mb-1">of {total} total</p>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </SimpleBar>
            </div>
            <hr />
          </>
        ) : (
          <></>
        )}
      </Card>

      <Modal
        show={editModal}
        onCloseClick={toggleEdit}
        title={`Bulk Edit Vehicles`}
        size="md"
      >
        <p className="text-muted">
          <span>
            <i className="text-info bx bxs-info-circle font-size-16 me-2" />
          </span>
          This action will update all the selected{" "}
          <strong>{consideredTotal} vehicles </strong>
          with the new changes
        </p>
        <p className="text-muted">
          vehicle IDs:{" "}
          <strong>
            {consideredVehicles.map((v, i) => v.scooterId).join(", ")}
          </strong>
        </p>

        {ignoredVehicles.length > 0 && (
          <>
            <p className="text-muted">
              <span>
                <i className="text-info bx bxs-info-circle font-size-16 me-2" />
              </span>
              Some vehicles will be ignored as they have LTD or InUse status
            </p>

            <p className="text-muted">
              Ignored vehicle IDs:{" "}
              <strong>
                {ignoredVehicles.map(v => v.scooterId).join(", ")}
              </strong>
            </p>
          </>
        )}
        <Row className="my-4">
          <label className="col-md-6 col-form-label">Field to Update</label>
          <div className="col-md-6">
            <select
              className="form-control"
              onChange={handleFieldChange}
              value={field}
            >
              {vehicleFields.map((s, i) => {
                return (
                  <option key={i} value={s.value}>
                    {s.label}
                  </option>
                )
              })}
            </select>
          </div>
        </Row>
        <Row className="my-2">
          <label className="col-md-6 col-form-label">
            {field === "vehicleConfig" ? "b2bPartner" : "New Value"}
          </label>
          <div className="col-md-6">
            <select
              className="form-control"
              onChange={handleValueChange}
              value={value}
            >
              {field === "status" ? (
                vehicleStatus.map((s, i) => {
                  return (
                    <option key={i} value={s.value}>
                      {s.label}
                    </option>
                  )
                })
              ) : (
                <></>
              )}

              {field === "stationId" ? (
                zones.map((z, i) => {
                  return (
                    <option key={z.zoneName} value={z.zoneName}>
                      {z.zoneName}
                    </option>
                  )
                })
              ) : (
                <></>
              )}
              {field === "vehicleConfig" ? (
                <>
                  <option key="NIL" value="NIL">
                    None
                  </option>
                  {b2bPartners.map(b => {
                    return (
                      <option key={b.name} value={b.name}>
                        {b.name}
                      </option>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </select>
          </div>
        </Row>
        <LoadingButton
          type="button"
          className="btn btn-info w-25 float-end my-3"
          onClick={() => saveEdit()}
        >
          Save
        </LoadingButton>

        <Row>{response?.length ? JSON.stringify(response) : <></>}</Row>
      </Modal>
    </div>
  )
}

export default BulkView
