export const stations = [
  {
    value: "North District",
    label: "North District",
  },
  {
    value: "AlKhair Area",
    label: "AlKhair Area",
  },
  {
    value: "Riyadh Warehouse",
    label: "Riyadh Warehouse",
  },
  {
    value: "Alwaha Area",
    label: "Alwaha Area",
  },
  {
    value: "Dorat Najd Alamaria",
    label: "Dorat Najd Alamaria",
  },
  {
    value: "Ritz Carlton Riyadh",
    label: "Ritz Carlton Riyadh",
  },
  {
    value: "Qassim University",
    label: "Qassim University",
  },
  {
    value: "Altakhassousi Street",
    label: "Altakhassousi Street",
  },
  {
    value: "Dammam Life Park",
    label: "Dammam Life Park",
  },
  {
    value: "Tahliya Street",
    label: "Tahliya Street",
  },
  {
    value: "AlQuds Area",
    label: "AlQuds Area",
  },
  {
    value: "Almalqa Area 2",
    label: "Almalqa Area 2",
  },
  {
    value: "King Fahad Medical City",
    label: "King Fahad Medical City",
  },
  {
    value: "Laysen Valley",
    label: "Laysen Valley",
  },
  {
    value: "Medina Park",
    label: "Medina Park",
  },
  {
    value: "King Abdullah Walking Trail",
    label: "King Abdullah Walking Trail",
  },
  {
    value: "Granada Mall",
    label: "Granada Mall",
  },
  {
    value: "Alyasmin Area",
    label: "Alyasmin Area",
  },
  {
    value: "AlMayadeen Park Dammam",
    label: "AlMayadeen Park Dammam",
  },
  {
    value: "The Business Gate",
    label: "The Business Gate",
  },
  {
    value: "Human Resoures",
    label: "Human Resoures",
  },
  {
    value: "Granada Bussiness",
    label: "Granada Bussiness",
  },
  {
    value: "Riyadh Front Business",
    label: "Riyadh Front Business",
  },
  {
    value: "Alqirwan Area",
    label: "Alqirwan Area",
  },
  {
    value: "Algadier Area",
    label: "Algadier Area",
  },
  {
    value: "Quba",
    label: "Quba",
  },
  {
    value: "Medina Alqaswa",
    label: "Medina Alqaswa",
  },
  {
    value: "King Abdulaziz City",
    label: "King Abdulaziz City",
  },
  {
    value: "Tawan Walking Trail",
    label: "Tawan Walking Trail",
  },
  {
    value: "Khaled Bin Waleed Street",
    label: "Khaled Bin Waleed Street",
  },
  {
    value: "Medina Alaridh",
    label: "Medina Alaridh",
  },
  {
    value: "Alrabie Area",
    label: "Alrabie Area",
  },
  {
    value: "Algadir Walking Trail",
    label: "Algadir Walking Trail",
  },
  {
    value: "NIL",
    label: "NIL",
  },
  {
    value: "Alrawdah park",
    label: "Alrawdah park",
  },
].sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
