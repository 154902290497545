import {
  GET_VEHICLES,
  GET_VEHICLES_FAIL,
  GET_VEHICLES_SUCCESS,
  SET_VEHICLE,
  GET_VEHICLE_DETAILS,
  GET_VEHICLE_DETAILS_FAIL,
  GET_VEHICLE_DETAILS_SUCCESS,
  EDIT_VEHICLE,
  EDIT_VEHICLE_FAIL,
  EDIT_VEHICLE_SUCCESS,
} from "./actionTypes"

export const getVehicles = () => ({
  type: GET_VEHICLES,
})

export const getVehiclesSuccess = vehicles => ({
  type: GET_VEHICLES_SUCCESS,
  payload: vehicles,
})

export const getVehiclesFail = error => ({
  type: GET_VEHICLES_FAIL,
  payload: error,
})

export const setVehicle = vehicle => ({
  type: SET_VEHICLE,
  payload: vehicle,
})

export const getVehicleDetails = scooterId => ({
  type: GET_VEHICLE_DETAILS,
  scooterId,
})

export const getVehicleDetailsSuccess = vehicle => ({
  type: GET_VEHICLE_DETAILS_SUCCESS,
  payload: vehicle,
})

export const getVehicleDetailsFail = error => ({
  type: GET_VEHICLE_DETAILS_FAIL,
  payload: error,
})

export const editVehicle = (imei, updates) => ({
  type: EDIT_VEHICLE,
  imei,
  updates,
})

export const editVehicleSuccess = (imei, updates) => ({
  type: EDIT_VEHICLE_SUCCESS,
  imei,
  updates,
})

export const editVehicleFail = error => ({
  type: EDIT_VEHICLE_FAIL,
  payload: error,
})
