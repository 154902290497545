export const containerStyle = {
  width: "100%",
  height: "100%",
  border: "1px solid #ccc",
  borderRadius: "5px",
  minHeight: "300px",
}

export const polygonOptions = {
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

export const getZoneTypeColor = type => {
  switch (type) {
    case "operation":
      return "dark"
    case "forbidden":
      return "danger"
    case "control":
      return "warning"
    default:
      return "primary"
  }
}

export const calclulateCenter = (boundries = []) => {
  const lat =
    boundries.reduce((acc, curr) => acc + curr.lat, 0) / boundries.length
  const lng =
    boundries.reduce((acc, curr) => acc + curr.lng, 0) / boundries.length
  const center = { lat, lng }
  return center
}

export const createCenterControl = (
  map,
  center = { lat: -28.643387, lng: 153.612224 }
) => {
  const controlUI = document.createElement("button")

  // Set CSS for the control
  controlUI.style.backgroundColor = "#fff"
  controlUI.style.border = "2px solid #fff"
  controlUI.style.borderRadius = "3px"
  controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)"
  controlUI.style.color = "rgb(25,25,25)"
  controlUI.style.cursor = "pointer"
  controlUI.style.fontFamily = "Roboto,Arial,sans-serif"
  controlUI.style.fontSize = "16px"
  controlUI.style.lineHeight = "38px"
  controlUI.style.margin = "8px 0 22px"
  controlUI.style.padding = "0 5px"
  controlUI.style.textAlign = "center"
  controlUI.textContent = "Center Map"
  controlUI.title = "Click to recenter the map"
  controlUI.type = "button"

  // Setup the click event listeners: simply set the map to Chicago.
  controlUI.addEventListener("click", () => {
    map.setCenter(center)
  })
  return controlUI
}

export const allPropertiesHaveValue = obj => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (obj[prop] === null || obj[prop] === undefined || obj[prop] === "") {
        return false
      }
    }
  }
  return true
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  const R = 6371000 // Earth's radius in meters
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

export const calculatePolygonRadius = (points, center) => {
  // Calculate the radius by finding the maximum distance from the center to any vertex
  let maxDistance = 0
  for (const point of points) {
    const distance = haversineDistance(
      center.lat,
      center.lng,
      point.lat,
      point.lng
    )
    if (distance > maxDistance) {
      maxDistance = distance
    }
  }

  return Math.floor(maxDistance)
}
