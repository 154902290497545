export function capitalizeString(inputString) {
  if (inputString && inputString !== "NIL") {
    const parts = inputString.split("_")

    const capitalizedParts = parts.map(
      part => part.charAt(0).toUpperCase() + part.slice(1)
    )

    return capitalizedParts.join(" ")
  } else {
    return inputString
  }
}
