import React from "react"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"

import { CardTitle, UncontrolledCollapse, Row, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { formatTime } from "utils/formatDate"
import EmptyState from "components/Common/EmptyState"
import { formatDateString } from "utils/formatDate"

const AccountHistory = ({ logs, loading }) => {
  //   const logs = Object.values(testLogs)
  const getVariant = type => {
    switch (type) {
      case "unblock":
        return "text-success bxs-circle"
      default:
        return "text-danger bxs-circle"
    }
  }
  return (
    <React.Fragment>
      <CardTitle className="mb-5">Account History</CardTitle>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner size="md" color="primary" />
        </div>
      ) : logs.length ? (
        <SimpleBar
          style={{ maxHeight: "380px", height: "380px" }}
          forceVisible="y"
          autoHide={false}
        >
          <ul className="verti-timeline list-unstyled">
            {logs.map((log, i) => {
              return (
                <li className="event-list" key={i}>
                  <div className="event-timeline-dot">
                    {/* <i className="bx bx-right-arrow-circle font-size-18" /> */}
                    <i className={`bx font-size-24 ${getVariant(log.type)}`} />
                  </div>
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <h5 className="font-size-14">
                        {formatTime(log.timestamp)}
                        <i className="ms-4 bx bx-right-arrow-alt font-size-16 text-dark align-middle ms-2" />
                      </h5>
                      <h6 className="text-muted font-size-13">
                        {formatDateString(log.timestamp)}
                      </h6>
                      <p className="text-muted font-size-11">
                        #{log.timestamp}
                      </p>
                    </div>
                    <div className="flex-grow-1">
                      <h5 id="activitytext text-capitalize">
                        {log.type === "unblock" ? "Activated" : log.type}
                      </h5>
                      <div>
                        <span className="text-muted">{log.type} by:</span>{" "}
                        {log.initiatedBy.email}
                      </div>
                      <p>
                        {log.isPartial ? (
                          <>
                            {log.type === "block" ? (
                              <span className="badge badge-soft-danger">
                                {"Partial Block"}
                              </span>
                            ) : (
                              <span className="badge badge-soft-success">
                                {"Partial Unblock"}
                              </span>
                            )}

                            <span className="badge badge-soft-info mx-1">
                              {log.partner}
                            </span>
                          </>
                        ) : log.type === "block" ? (
                          <span className="badge bg-danger">
                            {"Full Block"}
                          </span>
                        ) : (
                          <span className="badge bg-success">
                            {"Full Unblock"}
                          </span>
                        )}
                      </p>

                      <div>
                        <Link to="#" id={`toggler-${log.timestamp}`}>
                          <span className="text-success">Details</span>
                        </Link>
                        <UncontrolledCollapse
                          toggler={`toggler-${log.timestamp}`}
                        >
                          <Row className="mt-3">
                            {log.reason && (
                              <dl className="text-muted row">
                                <dt className="col-sm-3">Block Reason</dt>
                                <dd className="col-sm-9">{log.reason}</dd>
                              </dl>
                            )}
                            <dl className="text-muted row">
                              <dt className="col-sm-3">Blocker ID</dt>
                              <dd className="col-sm-9">
                                {log.initiatedBy.userId}
                              </dd>
                            </dl>
                            <dl className="text-muted row">
                              <dt className="col-sm-3">Blocker Email</dt>
                              <dd className="col-sm-9">
                                {log.initiatedBy.email}
                              </dd>
                            </dl>
                            <dl className="text-muted row">
                              <dt className="col-sm-3">Blocker Type</dt>
                              <dd className="col-sm-9">
                                {log.initiatedBy.type}
                              </dd>
                            </dl>
                            {log.initiatedBy.b2bPartner ? (
                              <dl className="text-muted row">
                                <dt className="col-sm-3">B2B Partner</dt>
                                <dd className="col-sm-9">
                                  {log.initiatedBy.b2bPartner}
                                </dd>
                              </dl>
                            ) : (
                              <></>
                            )}
                            <hr className="w-75" />
                          </Row>
                        </UncontrolledCollapse>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </SimpleBar>
      ) : (
        <div
          style={{ height: "380px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <EmptyState text="No account history" />
        </div>
      )}
    </React.Fragment>
  )
}

AccountHistory.propTypes = {
  logs: PropTypes.array,
  loading: PropTypes.bool,
}

export default AccountHistory
