/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Card, CardBody, CardTitle, Spinner, Table } from "reactstrap"

import EmptyState from "../Common/EmptyState"

const CardTripDiscount = ({ trip, loading }) => {
  const activeTrip =
    trip.status === "TRIP_STARTED" || trip.status === "MULTI_TRIP_STARTED"
  return (
    <div>
      <Card>
        <CardBody className="border-top">
          <CardTitle className="mb-5">Cost Breakdown</CardTitle>
          <div style={{ height: "380px", maxHeight: "380px" }}>
            {loading ? (
              <>
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner size="md" color="primary" />
                </div>
              </>
            ) : activeTrip ? (
              <div className="d-flex align-items-center justify-content-center h-100">
                <EmptyState text="Cost is calculated after trip ends" />
              </div>
            ) : (
              <>
                <p className="mb-2">
                  Trip ID: <span className="text-primary">#{trip.tripId}</span>
                </p>
                <br />
                <div className="table-responsive">
                  <Table className="table table-centered table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Description</th>
                        <th></th>
                        <th scope="col" className="text-end">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr scope="row">
                        <td>
                          <div>
                            <h5 className="text-truncate font-size-14">
                              Trip Cost
                            </h5>
                          </div>
                        </td>
                        <td></td>
                        <td className="text-end">
                          {parseFloat(trip.totalFare / 100).toFixed(2)}{" "}
                          <strong>SAR</strong>
                        </td>
                      </tr>
                      {trip.discountValue ? (
                        <tr>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {trip.discountValue}% off Discount
                              </h5>
                              <p className="text-muted mb-0">
                                {trip.discountId}
                              </p>
                            </div>
                          </td>
                          <td></td>
                          <td className="text-end text-success">
                            <strong>
                              -{" "}
                              {parseFloat(trip.discountAmount / 100).toFixed(2)}{" "}
                              SAR
                            </strong>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      <tr className="text-end">
                        <td colSpan="2">
                          <h6 className="m-0 text-end">Total Fare:</h6>
                        </td>
                        <td className="font-size-16">
                          <h5>
                            {" "}
                            {(
                              parseFloat(trip.totalFare / 100) -
                              parseFloat(trip.discountAmount / 100)
                            ).toFixed(2)}{" "}
                            <strong>SAR</strong>
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default CardTripDiscount
