import React from "react"
import { Col, Card, CardBody, Row } from "reactstrap"

const ResellerWidgets = () => {
  const reports = [
    {
      title: "Total Resellers",
      iconClass: "mdi mdi-account-multiple",
      description: "30",
    },
    {
      title: "Total Transactions",
      iconClass: "mdi mdi-cash-multiple",
      description: "100",
    },
    {
      title: "Total Trips",
      iconClass: "mdi mdi-human-scooter",
      description: "19",
    },
  ]
  return (
    <Row>
      {reports.map((report, key) => (
        <Col md="4" key={"_col_" + key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{report.title}</p>
                  <h4 className="mb-0">{report.description}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={report.iconClass + " font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default ResellerWidgets
