import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

//Import Images

const AccessDenied = ({
  text = "access denied to this page",
  route = null,
  linkLabel = "Home",
}) => {
  const { defaultRoute } = useSelector(state => ({
    defaultRoute: state.Login.defaultRoute,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Access Denied | Gazal - Admin Dashboard</title>
        </MetaTags>
        <Container className="">
          <Row className="justify-content-center mb-4">
            <Col md="8" xl="6">
              <div className="m-auto avatar-lg">
                <div className="avatar-title bg-soft bg-secondary rounded-circle text-light">
                  <i
                    className={`mdi mdi-lock-outline`}
                    style={{ fontSize: "3rem" }}
                  ></i>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="12">
              <div className="text-center mb-5">
                <h4 className="text-capitalize">{text}</h4>
                <div className="mt-3 text-center">
                  <Link className="btn btn-primary " to={route || defaultRoute}>
                    Back to {linkLabel}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
AccessDenied.propTypes = {
  text: PropTypes.string,
  linkLabel: PropTypes.string,
  route: PropTypes.string,
}

export default withRouter(AccessDenied)
