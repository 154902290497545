export const GET_STATUSES = "GET_STATUSES"
export const GET_STATUSES_SUCCESS = "GET_STATUSES_SUCCESS"

export const ADD_STATUS = "ADD_STATUS"
export const ADD_STATUS_SUCCESS = "ADD_STATUS_SUCCESS"

export const UPDATE_STATUS = "UPDATE_STATUS"
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS"

export const DELETE_STATUS = "DELETE_STATUS"
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS"

export const GET_STATUS = "GET_STATUS"
export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS"

export const API_ERROR = "API_ERROR"