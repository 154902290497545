import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useJsApiLoader } from "@react-google-maps/api"
import OperatingZoneListItem from "./operating-zone-list-item"
import ControlZoneListItem from "./control-zone-list-item"

const mapLibraries = ["drawing"]

const ZoneList = ({ zones = [], type = "operating", refetch }) => {
  const [mappedZones, setMappedZones] = useState([])

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: mapLibraries,
  })

  const getPath = () => {
    return zones.map(zone => {
      if (zone?.zoneBoundaries?.length) {
        zone.zoneBoundaries = zone.zoneBoundaries.map(boundry => {
          return {
            lat: boundry.latitude || boundry.lat,
            lng: boundry.longitude || boundry.lng,
          }
        })
      }
      return zone
    })
  }

  useEffect(() => {
    if (!mappedZones.length && zones.length) {
      const mapped = getPath(zones)
      setMappedZones(mapped)
    }
  }, [zones, mappedZones])

  useEffect(() => {
    if (mappedZones.length) {
      setMappedZones(getPath(mappedZones))
    }
  }, [zones])

  return (
    <div>
      {isLoaded &&
        mappedZones
          .sort((a, b) => {
            return a.zoneName.localeCompare(b.zoneName)
          })
          .map(zone => {
            switch (type) {
              case "operation":
                return (
                  <OperatingZoneListItem
                    key={zone.zoneName}
                    refetch={refetch}
                    zone={zone}
                  />
                )
              case "control":
                return (
                  <ControlZoneListItem
                    key={zone.zoneName + zone.zoneUnder}
                    refetch={refetch}
                    zone={zone}
                  />
                )
              case "forbidden":
                return (
                  <ControlZoneListItem
                    key={zone.zoneName + zone.zoneUnder}
                    refetch={refetch}
                    zone={zone}
                  />
                )
              default:
                return (
                  <OperatingZoneListItem
                    key={zone.zoneName}
                    refetch={refetch}
                    zone={zone}
                  />
                )
            }
          })}
    </div>
  )
}

ZoneList.propTypes = {
  zones: PropTypes.array.isRequired,
  type: PropTypes.string,
  refetch: PropTypes.func,
}

export default ZoneList
