import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"

import Breadcrumbs from "components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"
import { getActiveTrips as onGetActiveTrips } from "store/trips/actions"
import { getVehicles as onGetVehicles } from "store/vehicles/actions"
import Restricted from "components/Common/Restricted"
import RemoteTripsTable from "components/Trip/remote-trips-table"
import AccessDenied from "pages/Utility/access-denied"

const AllTripsList = () => {
  const dispatch = useDispatch()

  const { loading, vehicles, activeTrips, pageInfo, error } = useSelector(
    state => ({
      trips: state.trips.trips,
      loading: state.trips.loading,
      activeTrips: state.trips.activeTrips,
      vehicles: state.vehicles.vehicles,
      pageInfo: state.trips.pageInfo,
    })
  )

  const reports = [
    {
      title: "Total Trips",
      iconClass: "mdi mdi-human-scooter",
      description: pageInfo ? pageInfo.total : "-",
    },
    {
      title: "Active Trips",
      iconClass: "mdi mdi-bike-fast",
      description: activeTrips?.length || "-",
    },
    {
      title: "Total Vehicles",
      iconClass: "mdi mdi-car-multiple",
      description: vehicles.length || "-",
    },
  ]

  useEffect(() => {
    if (vehicles && !vehicles.length) {
      dispatch(onGetVehicles())
    }

    if (activeTrips && !activeTrips.length) {
      dispatch(onGetActiveTrips())
    }
  }, [dispatch, vehicles, activeTrips])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Trips | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Trips"
            titleLink="/trips/all"
            breadcrumbItem="All Trips"
          />
          <Restricted to="trips:SEARCH_TRIPS" fallback={<AccessDenied />}>
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                {error && (
                  <Alert color="danger" role="alert">
                    Something went wrong
                  </Alert>
                )}
                {loading && (
                  <Alert color="info">
                    <i className="bx bx-hourglass bx-spin" /> Fetching rest of
                    trips data
                  </Alert>
                )}
                <Card>
                  <CardBody>
                    <RemoteTripsTable />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllTripsList
