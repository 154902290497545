import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import filterFactory from "react-bootstrap-table2-filter"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import Modal from "components/Common/Modal"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import logoSecondary from "../../../assets/images/logo-secondary.png"
import { getBlockReasons } from "helpers/backend_helper"
import FormAddBlockReason from "./form-add-block-reason"

const blockReasonsConfig = () => {
  let node = useRef()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalSize, setTotalSize] = useState(0)
  const [data, setData] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)

  const { loading } = useSelector(state => ({
    statuses: state.status.statuses,
    loading: state.status.loading,
    error: state.status.error,
  }))

  const keyField = "id"
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
    },
    {
      dataField: "value",
      text: "Block Reason",
    },
    {
      dataField: "timestamp",
      text: "Date Created",
    },
  ]

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        href="#"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setPageSize(page)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="statuses" />
  )

  const fetchReasons = async () => {
    const res = await getBlockReasons()
    const reasons = res.data
    const reasonsData = []
    Object.values(reasons).forEach((reason, i) => {
      const date = new Date(reason.timestamp)
      const dateTime = `${date.toDateString()} ${date.getHours()}:${date.getMinutes()}`
      reasonsData.push({
        id: i + 1,
        value: reason.reason,
        timestamp: dateTime,
      })
    })
    setData(reasonsData)
  }

  useEffect(() => {
    fetchReasons()
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      setTotalSize(data.length)
    }
  }, [data])

  const handleClick = () => {
    setShowAddModal(true)
  }

  return (
    <React.Fragment>
      <Row>
        <Col xl={10}>
          <Card>
            <CardBody>
              <Modal
                show={showAddModal}
                onCloseClick={() => setShowAddModal(false)}
                title={`Add Block Reason`}
                size="md"
              >
                <FormAddBlockReason
                  refresh={fetchReasons}
                  close={() => {
                    setShowAddModal(false)
                  }}
                />
              </Modal>
              <h4 className="header-title mt-0 mb-1">Block Reasons</h4>
              <p className="sub-header">
                This table shows the block reasons created.
              </p>
              <hr />
              <Row>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory({
                      page,
                      sizePerPage: pageSize,
                      totalSize,
                      custom: true,
                      sizePerPageOptionRenderer,
                      paginationTotalRenderer: customTotal,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={data}
                          columns={columns}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col
                                  lg={6}
                                  className={"d-flex align-items-center"}
                                >
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                                <Col lg={6}>
                                  <div className="float-end">
                                    <span className="p-2">Page Size</span>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Button
                                onClick={handleClick}
                                className="btn btn-success"
                              >
                                Add Reason
                              </Button>
                              <Row>
                                <Col xl="12 mt-4">
                                  <div className="table-responsive table-light invert-scroll">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover top-horizontal-scroll"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                      loading={loading}
                                      headerClasses="table-light"
                                      filter={filterFactory()}
                                      filterPosition="top"
                                    />
                                  </div>
                                  {data.length === 0 && (
                                    <Row>
                                      <Col
                                        lg={12}
                                        className="text-center m-auto"
                                      >
                                        <img
                                          src={logoSecondary}
                                          alt="logo"
                                          height="100"
                                        />
                                        <p className="text-muted">
                                          No data found
                                        </p>
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col className="pagination pagination-rounded d-flex align-items-center justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

blockReasonsConfig.propTypes = {}

export default blockReasonsConfig
