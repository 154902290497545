/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"

import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Col,
  Row,
  CardTitle,
} from "reactstrap"
import classnames from "classnames"

const MultiTripHeader = ({
  history,
  trip,
  multiTrips,
  handleChangeMultiTrip,
}) => {
  const [activeTab, setactiveTab] = useState(trip.tripId)

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const fetchData = async trip => {
    setTrip(trip)
  }

  return (
    <Card className="bg-white bg-soft">
      <CardBody>
        <div className="d-flex flex-wrap  align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center mb-0">
            <div className="avatar-sm me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                <i className="bx bx-group font-size-24" />
              </span>
            </div>
            <div>
              <h5 className="card-title mb-0 font-size-18">Group Ride </h5>
              <span className="font-size-12 text-muted">
                {" "}
                {multiTrips.length} total trips
              </span>
            </div>
          </div>

          <div className="ms-auto">
            <ul className="list-inline user-chat-nav text-end mb-0">
              <div className="chat-leftsidebar-nav">
                <Nav pills className="bg-light rounded">
                  {multiTrips.map((childTrip, i) => (
                    <NavItem key={i}>
                      <NavLink
                        className={classnames({
                          active: activeTab === childTrip.tripId,
                        })}
                        onClick={() => {
                          toggleTab(childTrip.tripId)
                          handleChangeMultiTrip(childTrip)
                          // history.push(
                          //   `/trip-detail?imei=${trip.IMEI}&start=${trip.bookingTime}&end=${trip.dropOdffTime}&tripId=${trip.tripId}&userId=${trip.userId}`
                          // )
                        }}
                      >
                        {childTrip.tripId === childTrip.tripRefId
                          ? " Trip #1"
                          : `#${i + 1}`}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </ul>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default withRouter(MultiTripHeader)
