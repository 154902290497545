/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { CardBody, Row, Badge } from "reactstrap"
const ZoneDetails = ({ zone }) => {
  const [other_Menu, setother_Menu] = useState(false)
  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }
  return (
    <>
      {/* <CardBody> */}
      {/* <div> */}
      <CardBody>
        <Row className="mb-3">
          <h2 className="text-center">{zone.zoneName}</h2>
          <h5 className="text-muted text-center text-capitalize">
            <Badge color={zone.zoneType === "operation" ? "dark" : "danger"}>
              {zone.zoneType} Zone
            </Badge>
          </h5>
          {zone.zoneUnder && (
            <p className="text-muted text-center text-capitalize ">
              Zone Under:{" "}
              <Link
                to={`/zone-detail/${encodeURIComponent(
                  zone.zoneUnder
                )}?type=operation`}
                target="_blank"
              >
                {zone.zoneUnder}
              </Link>
            </p>
          )}
        </Row>
        <Row className="text-center">
          <Link
            to={`/zone-detail/${encodeURIComponent(
              zone.zoneUnder || zone.zoneName
            )}?type=${zone.zoneType}&zoneUnder=${encodeURIComponent(
              zone.zoneUnder
            )}`}
            target="_blank"
          >
            <span className="text-primary">
              Zone Details
              <i className="mdi mdi-arrow-right ms-1"></i>
            </span>
          </Link>
          {/* <Col lg="5" sm="4" className="align-self-center">
            <div>
              <img
                src={zone.zoneType === "scooter" ? scooter : bike}
                alt=""
                className="img-fluid d-block"
                height="150px"
                width="150px"
              />
            </div>
          </Col> */}
        </Row>
      </CardBody>
      {/* </div> */}
      {/* </CardBody> */}
    </>
  )
}

export default ZoneDetails
