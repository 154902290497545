export const searchDescriptions = {
  someWrong: "Something went wrong",
  userIdDesc:
    "Search customer by unique User ID string (e.g b1e4b722-c2b0-4618-a549-cefc770a7394)",
  userIdError: "No User found with this ID",
  phoneDesc:
    "Search customer by the phone number used in their account (e.g 053 145 12 98)",
  phoneError: "No User Found with this phone number",
  vehicleDesc: "Search customer by the last used vehicle ID (e.g 518)",
  vehicleError: "No customer has taken a trip with this vehicle",
  vehicleNotFound: "No vehicle found with this ID",
}
