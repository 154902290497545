export const customerStatus = [
  {
    label: "Active",
    value: 0,
    variant: "success",
  },
  {
    label: "Blocked",
    value: 2,
    variant: "danger",
  },
  {
    label: "Deleted",
    value: 3,
    variant: "light ",
  },
]
