import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"

import useQuery from "hooks/useQuery"
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import LoadingTable from "components/Loading/loading-table"
import AuditTable from "./Audit/audit-table"
import DiscountsTable from "./Discounts/discounts-table"
import UsersList from "./Users/users-list"

import {
  getB2bPartners,
  getDiscounts,
  getPackages,
  getVouchers,
} from "helpers/backend_helper"
import ConfigTable from "./Config/config-table"
import BlockReasonsConfig from "./BlockReasons"
import VouchersTable from "./Vouchers/VouchersTable"
import Restricted from "components/Common/Restricted"
import PackagesTable from "./Packages/PackagesTable"
import PartnersTable from "./B2BPartners/PartnersTable"

const Settings = () => {
  const query = useQuery()
  const queryTab = query.get("vtab") || "user-management"
  const [verticalActiveTab, setverticalActiveTab] = useState(queryTab)

  const [discounts, setDiscounts] = useState([])
  const [vouchers, setVouchers] = useState([])
  const [partners, setPartners] = useState([])
  const [packages, setPackages] = useState([])
  const [tabLoading, setTabLoading] = useState(false)

  const updateUrl = tab => {
    let url = new URL(window.location.href)
    url.searchParams.set("vtab", tab)
    window.history.replaceState(null, null, url)
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
      updateUrl(tab)
    }
  }

  const fetchDiscounts = async args => {
    setTabLoading(true)
    if (!discounts.length || args?.force) {
      // TODO move it to dispatch
      const response = await getDiscounts()
      setDiscounts(Object.values(response.data))
    }
    setTabLoading(false)
  }

  const fetchVouchers = async args => {
    setTabLoading(true)
    if (!vouchers.length || args?.force) {
      const { data } = await getVouchers()
      setVouchers(data)
    }
    setTabLoading(false)
  }

  const fetchPartners = async args => {
    setTabLoading(true)
    if (!partners.length || args?.force) {
      const { data } = await getB2bPartners()
      setPartners(data)
    }
    setTabLoading(false)
  }

  const fetchPackages = async args => {
    setTabLoading(true)
    if (!packages.length || args?.force) {
      const { data } = await getPackages()
      setPackages(data)
    }
    setTabLoading(false)
  }

  useEffect(() => {
    switch (verticalActiveTab) {
      case "user-management":
        // return fetchUsers()
        return null
      case "discounts":
        return fetchDiscounts()
      case "vouchers":
        return fetchVouchers()
      case "b2bPartners":
        return fetchPartners()
      case "packages":
        return fetchPackages()
      // case "roles":
      //   return fetchRoles()
      // case "invitations":
      //   return null
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Settings | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={verticalActiveTab.split("-").join(" ")}
            breadcrumbItem="Settings"
          />
          <Row>
            <Col lg={2} className="d-lg-block d-none">
              <Card>
                <CardBody>
                  <Row>
                    {/* <Col md="3"> */}
                    <h5 className="menu-title text-muted">Portal Settings</h5>
                    <Nav pills className="flex-column">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "user-management",
                          })}
                          onClick={() => {
                            toggleVertical("user-management")
                          }}
                        >
                          <i className="mdi mdi-account-multiple font-size-18 me-2" />
                          Users Management
                        </NavLink>
                      </NavItem>

                      <Restricted to="b2bPartners:VIEW_ALL_PARTNERS">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "b2bPartners",
                            })}
                            onClick={() => {
                              toggleVertical("b2bPartners")
                              fetchPartners()
                            }}
                          >
                            <i className="mdi mdi-account-multiple font-size-18 me-2" />
                            B2B Partners
                          </NavLink>
                        </NavItem>
                      </Restricted>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "audit",
                          })}
                          onClick={() => {
                            toggleVertical("audit")
                          }}
                        >
                          <i className="mdi mdi-file-table font-size-18 me-2" />
                          Audit Logs
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "display",
                          })}
                          onClick={() => {
                            toggleVertical("display")
                          }}
                          disabled
                        >
                          <i className="mdi mdi-monitor-clean font-size-18 me-2" />
                          Display
                        </NavLink>
                      </NavItem>
                      <li className="menu-title text-muted border-top">
                        Pricing
                      </li>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "discounts",
                          })}
                          onClick={() => {
                            toggleVertical("discounts")
                            fetchDiscounts()
                          }}
                        >
                          <i className="mdi mdi-sale font-size-18 me-2" />
                          Discounts
                        </NavLink>
                      </NavItem>
                      <Restricted to="vouchers:VIEW_ALL_VOUCHERS">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTab === "vouchers",
                            })}
                            onClick={() => {
                              toggleVertical("vouchers")
                              fetchVouchers()
                            }}
                          >
                            <i className="mdi mdi-ticket-percent font-size-18 me-2" />
                            Vouchers
                          </NavLink>
                        </NavItem>
                      </Restricted>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab === "packages",
                          })}
                          onClick={() => {
                            toggleVertical("packages")
                            fetchPackages()
                          }}
                        >
                          <i className="mdi mdi-cash font-size-18 me-2" />
                          Packages
                        </NavLink>
                      </NavItem>
                      <li className="menu-title text-muted border-top">
                        Other
                      </li>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            // "mb-2": true,
                            active: verticalActiveTab === "config",
                          })}
                          onClick={() => {
                            toggleVertical("config")
                          }}
                        >
                          <i className="bx bx-slider font-size-18 me-2" />
                          Configurations
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            // "mb-2": true,
                            active: verticalActiveTab === "blockReasons",
                          })}
                          onClick={() => {
                            toggleVertical("blockReasons")
                          }}
                        >
                          <i className="bx bx-slider font-size-18 me-2" />
                          Block Reasons
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={10}>
              <TabContent
                activeTab={verticalActiveTab}
                className="text-muted mt-4 mt-md-0"
              >
                <TabPane tabId="user-management">
                  <UsersList />
                </TabPane>
                <TabPane tabId="b2bPartners">
                  <PartnersTable
                    partners={partners}
                    refetch={fetchPartners}
                    tabLoading={tabLoading}
                  />
                </TabPane>
                <TabPane tabId="audit">
                  <AuditTable />
                </TabPane>
                <TabPane tabId="discounts">
                  <Card>
                    <CardBody>
                      <h4 className="header-title mt-0 mb-1">Discounts</h4>
                      <p className="sub-header">
                        View, activate/deactivate, or create new discounts
                      </p>
                      <hr />
                      {tabLoading ? (
                        <LoadingTable />
                      ) : (
                        <DiscountsTable
                          discounts={discounts}
                          refetch={fetchDiscounts}
                        />
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="vouchers">
                  <VouchersTable
                    vouchers={vouchers}
                    refetch={fetchVouchers}
                    tabLoading={tabLoading}
                  />
                </TabPane>
                <TabPane tabId="packages">
                  <PackagesTable
                    packages={packages}
                    refetch={fetchPackages}
                    tabLoading={tabLoading}
                  />
                </TabPane>
                <TabPane tabId="other">
                  <p>
                    Trust fund seitan letterpress, keytar raw denim keffiyeh
                    etsy art party before they sold out master cleanse
                    gluten-free squid scenester freegan cosby sweater. Fanny
                    pack portland seitan DIY, art party locavore wolf cliche
                    high life echo park Austin. Cred vinyl keffiyeh DIY salvia
                    PBR, banh mi before they sold out farm-to-table.
                  </p>
                  <p className="mb-0">
                    Fanny pack portland seitan DIY, art party locavore wolf
                    cliche high life echo park Austin. Cred vinyl keffiyeh DIY
                    salvia PBR, banh mi before they sold out farm-to-table.
                  </p>
                </TabPane>
                <TabPane tabId="config">
                  <ConfigTable />
                </TabPane>
                <TabPane tabId="blockReasons">
                  <BlockReasonsConfig />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
