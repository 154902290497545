import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { Button, Card, CardBody } from "reactstrap"

import TransactionDetailsModal from "./transaction-details-modal"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./latest-transaction-col"

import TableContainer from "components/Common/Table/table-container"
const latestTransaction = [
  {
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "04 Oct, 2019",
    total: "$392",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "04 Oct, 2019",
    total: "$404",
    paymentStatus: "Refund",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "05 Oct, 2019",
    total: "$412",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "06 Oct, 2019",
    total: "$384",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "07 Oct, 2019",
    total: "$380",
    paymentStatus: "Chargeback",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
]
const LatestTranaction = props => {
  const [modal1, setModal1] = useState(false)

  const toggleViewModal = () => setModal1(!modal1)

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />
        },
      },
      {
        Header: "Order ID",
        accessor: "orderId",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Billing Name",
        accessor: "billingName",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <BillingName {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "orderdate",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Total",
        accessor: "total",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />
        },
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
        disableFilters: true,
        Cell: cellProps => {
          return <PaymentMethod {...cellProps} />
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TransactionDetailsModal isOpen={modal1} toggle={toggleViewModal} />
      {/* <Card> */}
      <CardBody>
        <TableContainer
          columns={columns}
          data={latestTransaction}
          isGlobalFilter={false}
          isAddOptions={false}
          customPageSize={6}
        />
      </CardBody>
      {/* </Card> */}
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
