import React, { useContext } from "react"
import PropTypes from "prop-types"

import usePermission from "../../hooks/usePermission"

const Restricted = ({ to, fallback = <></>, loadingComponent, children }) => {
  // We "connect" to the provider thanks to the PermissionContext
  const [allowed, loading] = usePermission(to)

  if (loading) {
    return <>{loadingComponent}</>
  }
  if (allowed) {
    return <>{children}</>
  }

  return <>{fallback}</>
}
Restricted.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  fallback: PropTypes.node,
  loadingComponent: PropTypes.node,
}
export default Restricted
