import PropTypes from "prop-types"
import React from "react"
import {
  Modal as RModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

const Modal = ({
  show,
  onCloseClick,
  title,
  children,
  footer = null,
  size = "sm",
}) => {
  return (
    <RModal isOpen={show} toggle={onCloseClick} centered={true} size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className="py-3 px-5">{children}</ModalBody>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </RModal>
  )
}

Modal.propTypes = {
  onCloseClick: PropTypes.func,
  title: PropTypes.string,
  show: PropTypes.any,
  children: PropTypes.node,
  footer: PropTypes.node,
  size: PropTypes.string,
}

export default Modal
