import React, { useState, useRef, useEffect } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter,
  Comparator,
  numberFilter,
} from "react-bootstrap-table2-filter"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"
import { customerStatus } from "constants/customerStatus"
import { formatStandard } from "../../utils/formatDate"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import Restricted from "components/Common/Restricted"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getCustomers as onGetCustomers } from "store/customers/actions"
import { substituteComparator } from "utils"

const RemoteCustomersTable = ({ history }) => {
  var node = useRef()
  const dispatch = useDispatch()

  const { customers, pageInfo, loading } = useSelector(state => ({
    customers: state.customers.customers,
    pageInfo: state.customers.pageInfo,
    error: state.customers.error,
    loading: state.customers.loading,
  }))

  const [page, setPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(0)

  const customTotal = (from, to, size) => (
    <TotalPreview
      from={from}
      to={to}
      loading={loading}
      size={size}
      entity="Customers"
    />
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setSizePerPage(page)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )

  const keyField = "userId"

  const userListColumns = [
    {
      dataField: "menu",
      isDummyField: true,
      text: "Actions",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user, rowIndex) => (
        <Restricted to="customers:VIEW_CUSTOMER" fallback={"-"}>
          <Link
            to={`/customer-detail/${user.userId}`}
            target="_blank"
            className="btn-sm btn-rounded btn btn-primary"
          >
            View Details
          </Link>
        </Restricted>
      ),
    },
    {
      dataField: "#",
      text: "#",
      sort: false,
      formatter: (cellContent, user) => (
        <>
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle bg-dark">
              {user.name.charAt(0)}
            </span>
          </div>
        </>
      ),
    },
    {
      text: "User ID",
      dataField: "userId",
      // sort: handleSort,
      filter: textFilter({
        style: {
          width: "100px",
        },
        placeholder: "User ID",
      }),
      formatter: (col, user) => {
        return (
          <span
            style={{
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {col}
          </span>
        )
      },
    },
    {
      dataField: "name",
      text: "Full Name",
      // sort: handleSort,
      filter: textFilter({
        placeholder: "Search Name",
      }),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            {user.userName} {user.userSurname}
          </h5>
        </>
      ),
    },
    {
      text: "Phone Number",
      dataField: "contact",
      // sort: handleSort,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            {user.countryCode} {user.contact}
          </h5>
        </>
      ),
      filter: textFilter(),
    },
    {
      text: "Status",
      dataField: "statusCode",
      // sort: handleSort,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <Badge
          color="success"
          className={`bg-${
            customerStatus.find(s => s.value === user.statusCode)?.variant ||
            "light"
          } font-size-12`}
        >
          {customerStatus.find(s => s.value === user.statusCode)?.label || "-"}
        </Badge>
      ),
      filter: selectFilter({
        options: {
          0: "Active",
          2: "Blocked",
          3: "Deleted",
        },
      }),
    },
    {
      text: "Partner ID",
      dataField: "partnerId",
      // sort: handleSort,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => <>{user.partnerId}</>,
    },
    {
      text: "Customer ID",
      dataField: "customerId",
      // sort: handleSort,
      filter: textFilter(),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => <>{user.customerId}</>,
    },

    {
      text: "Registered On",
      dataField: "registrationDate",
      filter: dateFilter({
        withoutEmptyComparatorOption: true,
        defaultValue: {
          comparator: Comparator.GE,
        },
      }),
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>{formatStandard(user.registrationDate)}</>
      ),
    },
    {
      text: "Balance (SAR)",
      dataField: "credits",
      sort: true,
      filter: numberFilter({
        placeholder: "Enter Number",
      }),
      formatter: (_cellContent, customer) => (
        <h5 className="font-size-14 mb-1 text-dark">
          {parseFloat(customer.credits / 100).toFixed(2)} SAR
        </h5>
      ),
    },
  ]

  const defaultSorted = [
    {
      dataField: "registrationDate", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ]

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/customer-detail/${row.userId}`)
    },
  }

  const onTableChange = (type, newState) => {
    const filters = Object.keys(newState.filters).reduce((acc, key) => {
      const filter = newState.filters[key]
      if (filter.filterVal) {
        acc[key] = {
          comparator:
            filter.filterType === "DATE" || filter.filterType === "NUMBER"
              ? substituteComparator(filter.filterVal.comparator)
              : substituteComparator(filter.comparator),
          value:
            filter.filterType === "DATE"
              ? new Date(filter.filterVal.date).getTime()
              : filter.filterType === "NUMBER"
              ? parseFloat(filter.filterVal.number * 100)
              : filter.filterVal,
          filterType: filter.filterType,
        }
      }
      return acc
    }, {})

    const params = {
      params: {
        order: newState.sortOrder,
        orderBy: newState.sortField,
        page: newState.page,
        pageSize: newState.sizePerPage,
        filters: JSON.stringify(filters),
      },
    }
    dispatch(onGetCustomers(params))
  }

  useEffect(() => {
    if (pageInfo) {
      setPage(Number(pageInfo.page))
      setSizePerPage(pageInfo.pageSize)
      setTotalSize(pageInfo.total)
    }
  }, [pageInfo])

  return (
    <Card>
      <CardBody>
        <PaginationProvider
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            custom: true,
            sizePerPageOptionRenderer,
            paginationTotalRenderer: customTotal,
          })}
          keyField={keyField}
          columns={userListColumns}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <ToolkitProvider
                keyField={keyField}
                data={customers}
                columns={userListColumns}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col lg={6}>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col lg={6}>
                        <div className="float-end">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12 mt-4">
                        <div className="table-responsive invert-scroll">
                          <BootstrapTable
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            rowEvents={rowEvents}
                            keyField={keyField}
                            defaultSorted={defaultSorted}
                            classes={
                              "table align-middle table-nowrap table-hover top-horizontal-scroll"
                            }
                            loading={true}
                            bordered={false}
                            striped={false}
                            responsive
                            ref={node}
                            filter={filterFactory()}
                            filterPosition="top"
                            headerClasses="table-light"
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: false,
                              cellEdit: false,
                            }}
                            onTableChange={(type, newState) =>
                              onTableChange(type, newState)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

RemoteCustomersTable.propTypes = {
  history: PropTypes.object,
}

export default withRouter(RemoteCustomersTable)
