import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import AccessDenied from "pages/Utility/access-denied"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isViewAllowed,
  loading,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (loading) {
        return (
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
              </div>
            </div>
          </div>
        )
      }
      if (isAuthProtected) {
        if (!localStorage.getItem("accessToken")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (!isViewAllowed) {
          return (
            <Layout>
              <AccessDenied />
            </Layout>
          )
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  isViewAllowed: PropTypes.bool,
  loading: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
