export const exportTypes = [
  {
    label: "Trips",
    value: "trips",
    iconClass: "mdi mdi-human-scooter",
    path: "trips",
  },
  {
    label: "Customers",
    value: "customers",
    iconClass: "mdi mdi-account-multiple",
    path: "customers",
  },
]
